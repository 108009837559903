import firebase, { db } from "../firebase.js";

export const setPage = (page) => {
  return {
    type: "SET_PAGE",
    page: page,
  };
};

export const setVersion = (version) => {
  return {
    type: "SET_VERSION",
    version: version,
  };
};

export const fetchAppVersion = () => (dispatch, getState) =>
  new Promise(() => {
    //   dispatch(loadMilestone(newMilestone));

    var appRef = db.collection("app").doc("info");

    appRef.onSnapshot(function (doc) {
      var data = doc.data();
      var incomingVersion = data.version;
      var currentVersion = getState().app.version;

      if (currentVersion == null) {
        dispatch(setVersion(incomingVersion));
      }

      if (currentVersion != null && currentVersion < incomingVersion) {
        dispatch(setVersion(incomingVersion));
        if (currentVersion != null) {
          window.location.reload();
        }
      }
    });
  });

export const setLoading = (loading) => {
  return {
    type: "SET_LOADING",
    loading,
  };
};

export default {
  setPage: setPage,
  fetchAppVersion: fetchAppVersion,
  setLoading: setLoading,
  fetchAppVersion: fetchAppVersion,
};
