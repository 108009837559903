import React from "react";
import { message } from "antd";
import { stringReplaceAll } from "../../utils";

export default function NumberField({
  field,
  currentUser,
  permissions,
  valueEditable,
  checkPermissionsAndUpdateValue,
  activelyEditing,
  setActivelyEditing,
}) {
  function validateInput(input) {
    var floatInput = parseFloat(input);
    var valid = true;

    if (field.fieldData.minValue != null) {
      var floatMinValue = parseFloat(field.fieldData.minValue);
      if (floatInput < floatMinValue) {
        valid = false;
        message.error(`Value must be greater than ${floatMinValue}`);
      }
    }

    if (field.fieldData.maxValue != null) {
      var floatMaxValue = parseFloat(field.fieldData.maxValue);
      if (floatInput > floatMaxValue) {
        valid = false;
        message.error(`Value must be less than ${floatMaxValue}`);
      }
    }

    if (valid) {
      checkPermissionsAndUpdateValue(input);
    }
  }

  return (
    <div className="full-width">
      {!activelyEditing && (
        <div style={{ padding: "10px" }}>
          <input
            type="number"
            className="table-input"
            placeholder={field.fieldData.placeholder}
            value={stringReplaceAll(valueEditable, "\n", " ")}
            onChange={(e) => checkPermissionsAndUpdateValue(e.target.value)}
            //    onBlur={e => finishedEditing(e, "name")}
            onFocus={() => setActivelyEditing(true)}
          />
        </div>
      )}

      {activelyEditing && (
        <div className="pop-out">
          <input
            autoFocus
            type="number"
            className="expanded-input single-line"
            placeholder={field.fieldData.placeholder}
            value={valueEditable}
            onChange={(e) => validateInput(e.target.value)}
            //      onBlur={e => finishedEditing(e, "name")}
            onBlur={() => setActivelyEditing(false)}
          />
        </div>
      )}
    </div>
  );
}
