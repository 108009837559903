import React, { Component } from "react";
import * as Colors from "@material-ui/core/colors";
import { MuiThemeProvider, createMuiTheme } from "@material-ui/core/styles";
import "../styles/Auth.css";
import firebase, { db } from "../firebase.js";
import { Card } from "./Objects";
import { LockOutlined, MailOutlined, UserOutlined } from '@ant-design/icons';
import { Input, Button, message, Upload, Alert } from "antd";
import {Form, Checkbox} from 'antd';

const FormItem = Form.Item;

const muiTheme = createMuiTheme({
  palette: {
    textColor: "#000000",
    primary1Color: "#ffffff",
    primary2Color: "#000000",
    accent1Color: "#000000",
    pickerHeaderColor: "#000000",
    alternateTextColor: "#ffffff",
  },
  appBar: {
    height: 60,
  },
});

function getBase64(img, callback) {
  const reader = new FileReader();
  reader.addEventListener("load", () => callback(reader.result));
  reader.readAsDataURL(img);
}

function beforeUpload() {
  /*
    const isLt2M = file.size / 1024 / 1024 < 5;
    if (!isLt2M) {
        
      message.error('Image must smaller than 5MB!');
    } */
  return true;
}

export class SignUp extends Component {
  constructor(props) {
    super(props);
    this.state = {
      newUser: {
        image: "",
      },
    }; // <- set up react state
  }

  createAccount() {
    var goodToGo = true;

    var newUser = this.state.newUser;
    var invites = this.state.invites;

    if (newUser.password != newUser.passwordConfirm) {
      goodToGo = false;
      message.error("Passwords do not match");
    }

    if (newUser.email == null || newUser.email == "") {
      goodToGo = false;
      message.error("Missing Email");
    }

    if (newUser.company == null || newUser.company == "") {
      goodToGo = false;
      message.error("Missing Company");
    }

    if (newUser.name == null || newUser.name == "") {
      goodToGo = false;
      message.error("Missing Name");
    }

    if (goodToGo == true) {
      firebase
        .auth()
        .createUserWithEmailAndPassword(
          newUser.email.toLowerCase(),
          newUser.password
        )
        .then(function success(userData) {
          var uid = userData.user.uid; // The UID of recently created user on firebase

          if (newUser.image == null) {
            newUser["image"] = "";
          }

          var object = {
            image: newUser.image,
            name: newUser.name,
            company: newUser.company,
            email: newUser.email,
            id: uid,
          };

          db.collection("users")
            .doc(uid)
            .set(object)
            .then(
              function () {
                // Now that we have a user, we can iterate through each project and remove invite/add user

                var count = 0;

                invites.forEach(function (invite) {
                  count = count + 1;
                  var tempColab = {
                    user: uid,
                    role: invite.role,
                  };

                  db.collection("projects")
                    .doc(invite.project)
                    .set(
                      {
                        invites: firebase.firestore.FieldValue.arrayRemove(
                          invite.id
                        ),
                        collaborators: firebase.firestore.FieldValue.arrayUnion(
                          tempColab
                        ),
                        collaborator_ids: firebase.firestore.FieldValue.arrayUnion(
                          tempColab.user
                        ),
                      },
                      { merge: true }
                    )
                    .then(function () {
                      db.collection("invites")
                        .doc(invite.id)
                        .delete()
                        .then(function () {}.bind(this))
                        .catch(
                          function () {
                            //  dispatch(changeNetworkStatus(false, "Error Saving, Please Refresh!"))
                          }.bind(this)
                        );
                    })
                    .catch(function (error) {});
                });
              }.bind(this)
            )
            .catch(
              function (error) {
                message.error(error);
                //     dispatch(changeNetworkStatus(false, "Error Saving, Please Refresh!"))
              }.bind(this)
            );
        })
        .catch(
          function failure(error) {
            var errorCode = error.code;
            var errorMessage = error.message;

            message.error(errorMessage);
          }.bind(this)
        );
    }
  }

  forgotPass() {
    this.setState({ forgotPass: !this.state.forgotPass });
  }

  enterPressed(event) {
    var code = event.keyCode || event.which;
    if (code === 13) {
      this.handleClick(event);
    }
  }

  handleChange = (event, type) => {
    var newUser = this.state.newUser;

    newUser[type] = event.target.value;

    this.setState({ newUser: newUser });
  };

  handleChangeImage = (info) => {
    if (info.file.status === "uploading") {
      this.setState({ loading: true });
      return;
    }

    if (info.file.status === "done") {
      // Get this url from response in real world.

      // Create a root reference
      var storageRef = firebase.storage().ref();

      var randomId =
        Math.random().toString(36).substring(2, 15) +
        Math.random().toString(36).substring(2, 15);

      var uploadTask = storageRef
        .child("tempFolder/" + randomId + ".jpg")
        .put(info.file.originFileObj);

      // Register three observers:
      // 1. 'state_changed' observer, called any time the state changes
      // 2. Error observer, called on failure
      // 3. Completion observer, called on successful completion

      uploadTask.on(
        "state_changed",
        function (snapshot) {
          // Observe state change events such as progress, pause, and resume
          // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
          var progress =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;

          switch (snapshot.state) {
            case firebase.storage.TaskState.PAUSED: // or 'paused'
              break;
            case firebase.storage.TaskState.RUNNING: // or 'running'
              break;
          }
        },
        function (error) {
          // Handle unsuccessful uploads
        },
        function () {
          // Handle successful uploads on complete
          // For instance, get the download URL: https://firebasestorage.googleapis.com/...
          uploadTask.snapshot.ref.getDownloadURL().then(
            function (downloadURL) {
              var newUser = this.state.newUser;

              newUser["image"] = downloadURL;

              getBase64(info.file.originFileObj, (imageUrl) =>
                this.setState({
                  imageUrl,
                  newUser: newUser,
                  loading: false,
                })
              );
            }.bind(this)
          );
        }.bind(this)
      );
    }
  };

  componentDidMount() {
    var projectRef = db.collection("invites").doc(this.props.inviteCode);
    projectRef.onSnapshot((doc) => {
      if (doc.exists) {
        const inviteData = doc.data();

        if (inviteData.email != null && inviteData.email != "") {
          this.setState({
            inviteData: inviteData,
            newUser: { email: inviteData.email },
          });

          // Ok so it looks like we have an email! Let's see if any other projects the user is invited to
          var invites = [];
          // Create a reference to the cities collection
          db.collection("invites")
            .where("email", "==", inviteData.email)
            .get()
            .then(
              function (querySnapshot) {
                querySnapshot.forEach(function (doc) {
                  // doc.data() is never undefined for query doc snapshots

                  var data = doc.data();
                  data["id"] = doc.id;
                  invites.push(data);
                });

                this.setState({ invites: invites });
              }.bind(this)
            )
            .catch(function (error) {});
        }

        //   dispatch(loadProject(projectData));
      } else {
        this.setState({ invalid: true });
      }
    });
  }

  render() {
    var newUser = this.state.newUser;

    var invalid = this.state.invalid;

    //  const { getFieldDecorator, getFieldsError, getFieldError, isFieldTouched } = this.props.form;

    const uploadButton = (
      <div>
       
        <div className="ant-upload-text">Choose Profile Pic</div>
      </div>
    );
    const imageUrl = this.state.imageUrl;

    return (
      <MuiThemeProvider muiTheme={muiTheme}>
        <div class="registerArea">
          {invalid && (
            <div>
              <Alert
                message="Invalid Invite URL"
                description="The invite URL is not valid, please double check the URL you are using or make sure the invite was not revoked"
                type="error"
              />
            </div>
          )}
          <img
            src={require("../images/aloa_small_line.png")}
            alt="Logo"
            style={{ height: "40px", marginBottom: "40px", marginTop: "100px" }}
          />
          <Card style={{ width: "100%" }}>
            <Form onSubmit={this.handleSubmit}>
              <div style={{ textAlign: "center", marginBottom: "20px" }}>
                <Upload
                  name="avatar"
                  listType="picture-card"
                  showUploadList={false}
                  action="//jsonplaceholder.typicode.com/posts/"
                  beforeUpload={beforeUpload}
                  onChange={this.handleChangeImage}
                >
                  {imageUrl ? (
                    <img
                      style={{ width: "100px", margin: "auto" }}
                      src={imageUrl}
                      alt="avatar"
                    />
                  ) : (
                    uploadButton
                  )}
                </Upload>
              </div>

              <FormItem>
                <Input
                  value={newUser.name}
                  onChange={(e) => this.handleChange(e, "name")}
                  prefix={
                    <UserOutlined style={{ color: "rgba(0,0,0,.25)" }} />
                  }
                  placeholder="Name"
                />
              </FormItem>
              <FormItem>
                <Input
                  value={newUser.company}
                  onChange={(e) => this.handleChange(e, "company")}
                  prefix={
                    <UserOutlined style={{ color: "rgba(0,0,0,.25)" }} />
                  }
                  placeholder="Company Name"
                />
              </FormItem>
              <FormItem>
                <Input
                  value={newUser.email}
                  onChange={(e) => this.handleChange(e, "email")}
                  prefix={
                    <MailOutlined style={{ color: "rgba(0,0,0,.25)" }} />
                  }
                  placeholder="Email"
                  disabled
                />
              </FormItem>
              <FormItem>
                <Input
                  value={newUser.password}
                  onChange={(e) => this.handleChange(e, "password")}
                  prefix={
                    <LockOutlined style={{ color: "rgba(0,0,0,.25)" }} />
                  }
                  type="password"
                  placeholder="Password"
                />
              </FormItem>
              <FormItem>
                <Input
                  value={newUser.passwordConfirm}
                  onChange={(e) => this.handleChange(e, "passwordConfirm")}
                  prefix={
                    <LockOutlined style={{ color: "rgba(0,0,0,.25)" }} />
                  }
                  type="password"
                  placeholder="Confirm Password"
                />
              </FormItem>

              <Button
                type="primary"
                onClick={(event) => this.createAccount()}
                block
              >
                Create Account
              </Button>
            </Form>
          </Card>
          Have an account? <a href="/login"> Sign in </a>
        </div>
      </MuiThemeProvider>
    );
  }
}
