import { findNewCharacter, stringIsAtEnd } from "../../utils";

export const getNewValue = (value, user, latestCharIndex) => {
  const newTagSpan = createTagSpan(user);

  const latestTagPhrase =
    value.charAt(latestCharIndex) !== "@"
      ? getLatestTagPhrase(value, latestCharIndex)
      : "@";

  const tagPhraseStart = Math.max(
    latestCharIndex - latestTagPhrase.length + 1,
    0
  );

  const beginning = value.substring(0, tagPhraseStart);
  const end = value.substring(tagPhraseStart).replace(latestTagPhrase, "");

  return `${beginning}${newTagSpan}${end}`;
};

export const getSelection = (textField) => {
  const selection = window.getSelection();
  const children = Array.from(textField.childNodes);
  const selectedChild = children.findIndex((child) =>
    selection.containsNode(child)
  );

  const offset = selection.focusOffset;

  return {
    offset,
    selectedChild,
  };
};

export const setCursor = (field, pos, childIndex) => {
  const position = document.createRange();
  const selection = document.getSelection();

  if (position && selection && field?.hasChildNodes()) {
    // Handle case where children from previous selection seem
    // to collapse down to one node at the end
    const selectedChildIndex = Math.min(
      childIndex,
      field.childNodes.length - 1
    );

    const node = field.childNodes[selectedChildIndex];
    position.setStart(node, Math.min(pos, node?.length));
    position.collapse(true);
    selection.removeAllRanges();
    selection.addRange(position);
  }
};

export const getLastTagPhrase = (value) => {
  const regex = /@[\w ]*/g;
  const phrases = [...value.matchAll(regex)].flatMap((item) => item);
  return phrases?.[Math.max(phrases.length - 1, 0)];
};

export const getLatestTagPhrase = (value, latestCharIndex) => {
  return getLastTagPhrase(value.substring(0, latestCharIndex + 1));
};

export const createTagSpan = (user, isCurrentUser = false) => {
  return `<span id="${user.id}" class="tag-span ${
    isCurrentUser ? "current-user" : ""
  }" contenteditable="false">@${user.name}</span>&nbsp;`;
};

export const getTagSpans = (value) => {
  const regex =
    /<span id="(.*?)" class=".*?" contenteditable="false">.*?<\/span>/g;
  const matches = [...value.matchAll(regex)].map((item) => item);
  return matches;
};

export const convertToText = (value = "", oldValue = "") => {
  let newValue = value.replaceAll("<div><br></div>", "\n");
  const isBackspace = newValue.length < oldValue.length;
  const shouldAddNewLine =
    newValue.endsWith("\n") &&
    newValue.match(/\n/g).length === 1 &&
    !isBackspace;

  if (shouldAddNewLine) {
    newValue = `${newValue}\n`;
  }
  return newValue;
};

export const replaceTagSpans = (value) => {
  const matches = getTagSpans(value);

  let valueCopy = value;

  matches.forEach((match) => {
    valueCopy = valueCopy
      .replace("&nbsp;", " ")
      .replace(`${match[0]} `, match[0])
      .replace(match[0], `@${match[1]} `);
  });

  return valueCopy;
};

export const getPopupOffset = (textFieldRef) => {
  if (textFieldRef?.current) {
    const caretPosition = getCaretCoordinates(
      textFieldRef.current.selectionEnd
    );
    const textFieldRec = textFieldRef.current.getBoundingClientRect();
    const windowWidth = window.innerWidth;

    let adjustment = 200;
    let maxHorizontalOffset = 165;

    if (windowWidth <= 1500 && windowWidth > 400) {
      adjustment = 150;
      maxHorizontalOffset = 125;
    } else if (windowWidth < 400) {
      adjustment = 70;
      maxHorizontalOffset = 60;
    }

    return [
      Math.min(
        caretPosition.left - textFieldRec.left - adjustment,
        maxHorizontalOffset
      ),
      caretPosition.top - textFieldRec.top + 2,
    ];
  }

  return [0, 0];
};

export const eventIsTagBackspace = (event, value) =>
  event.nativeEvent?.inputType === "deleteContentBackward" &&
  value.endsWith("</span>");

export const shouldStartTagging = (
  oldValue,
  newValue,
  newOptions,
  latestCharIndex
) => {
  const latestTagPhrase = getLastTagPhrase(newValue);
  const newCharacter = findNewCharacter(oldValue, newValue);
  return (
    newCharacter === "@" ||
    (stringIsAtEnd(latestTagPhrase, newValue) && newOptions.length > 0)
  );
};

export const shouldStopTagging = (
  event,
  newValue,
  newOptions,
  latestCharIndex
) => {
  const newValueNoNbsp = newValue.replace("&nbsp;", " ");

  const latestTagPhrase = getLatestTagPhrase(newValueNoNbsp, latestCharIndex);
  const phraseNotAtEnd = !stringIsAtEnd(
    latestTagPhrase,
    newValueNoNbsp.substring(0, latestCharIndex + 1)
  );

  return newOptions.length === 0 || phraseNotAtEnd;
};

const getCaretCoordinates = () => {
  const sel = document.getSelection();
  if (sel && sel.rangeCount > 0) {
    const r = sel.getRangeAt(0);
    let rect;
    let r2;
    // supposed to be textNode in most cases
    // but div[contenteditable] when empty
    const node = r.startContainer;
    const offset = r.startOffset;

    if (offset > 0) {
      // new range, don't influence DOM state
      r2 = document.createRange();
      r2.setStart(node, offset - 1);
      r2.setEnd(node, offset);
      // https://developer.mozilla.org/en-US/docs/Web/API/range.getBoundingClientRect
      // IE9, Safari?(but look good in Safari 8)
      rect = r2.getBoundingClientRect();
      return { left: rect.right, top: rect.top };
    } else if (offset < node.length) {
      r2 = document.createRange();
      // similar but select next on letter
      r2.setStart(node, offset);
      r2.setEnd(node, offset + 1);
      rect = r2.getBoundingClientRect();
      return { left: rect.left, top: rect.top };
    } else {
      // textNode has length
      // https://developer.mozilla.org/en-US/docs/Web/API/Element.getBoundingClientRect
      rect = node.getBoundingClientRect();
      const styles = getComputedStyle(node);
      const lineHeight = parseInt(styles.lineHeight);
      const fontSize = parseInt(styles.fontSize);
      // roughly half the whitespace... but not exactly
      const delta = (lineHeight - fontSize) / 2;
      return { left: rect.left, top: rect.top + delta };
    }
  } else {
    return { left: 0, top: 0 };
  }
};
