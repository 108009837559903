import object, { deleteAttachmentFromFirebase } from "./object";
import { id } from "../components/utils";
import notifications from "../api/notifications";
import firebase, { db } from "../firebase.js";
import { incrementThreadCountForObject } from "./collections";


export const loadRolesToProject = (projectId, roles) => (dispatch) =>
  dispatch({
    type: "LOAD_ROLES",
    projectId: projectId,
    roles: roles,
  });

export default {
  loadRolesToProject
};
