import { db } from "../firebase.js";
import firebase from "firebase";
// eslint-disable-next-line import/no-cycle
import { updateProject } from "./project";

// Collaborator state data model
// export const collaborators = {
//   id: {
//     data: {
//       data: 'test',
//     },
//     projectRoles: {
//       projectId: 'viewer',
//     },
//   },
// };

/**
 * Basic function to load a collaborator into the store
 * @param {Object} data Collaboator data to add
 */
const loadCollaborator = (collaborator, projectId) => (dispatch) => {
  dispatch({
    type: "LOAD_COLLABORATOR",
    collaborator: collaborator,
    projectId: projectId,
  });
};

/**
 * Basic function to load a collaborator into the store
 * @param {Object} data Collaboator data to add
 */
const loadCollaborators = (collaborators, projectId) => (dispatch) => {
  dispatch({
    type: "LOAD_COLLABORATORS",
    collaborators: collaborators,
    projectId: projectId,
  });
};

/**
 * Load user data to store
 * @param {Array} collaboratorsMetaData collaborators project meta data array
 * @param {String} projectId project id
 *
 * @returns {Promise}
 */
// eslint-disable-next-line import/prefer-default-export
export const fetchCollaborators = (collaboratorsMetaData, projectId) => (
  dispatch,
  getState
) =>
  new Promise((resolve, reject) => {
    var collaborator_ids = collaboratorsMetaData.map(
      (collaborator) => collaborator.user
    );

    var promises = [];

    collaborator_ids.forEach(function (collaborator_id) {
      var tempPromise = new Promise((resolve, reject) => {
        var docRef = db.collection("users").doc(collaborator_id);

        docRef
          .get()
          .then(function (doc) {
            if (doc.exists) {
              resolve(doc);
            } else {
              // doc.data() will be undefined in this case

              resolve();
            }
          })
          .catch(function (error) {
            reject(error);
          });
      });

      promises.push(tempPromise);
    });

    Promise.all(promises).then((values) => {
      var collaborators_data = {};

      values.forEach(function (doc) {
        if (doc) {
          var collaborator_data = {
            id: doc.id,
            data: doc.data(),
          };

          collaborators_data[doc.id] = collaborator_data;
        }
      });

      var collaborators = {};

      collaboratorsMetaData.forEach(function (collaborator_meta) {
        let collaborator = getState().collaborators[collaborator_meta.user];

        if (!collaborator) {
          collaborators[collaborator_meta.user] = {
            projectRoles: {},
          };

          collaborators[collaborator_meta.user].projectRoles[projectId] =
            collaborator_meta.role;

          if (collaborators_data[collaborator_meta.user]) {
            collaborators[collaborator_meta.user].id =
              collaborators_data[collaborator_meta.user].id;
            collaborators[collaborator_meta.user].data =
              collaborators_data[collaborator_meta.user].data;
          }
        } else {
          collaborators[collaborator_meta.user] = collaborator;
        }
      });

      // Load them up
      dispatch(loadCollaborators(collaborators, projectId));
    });
  });

/**
 * Load user data to store, mainly used if the user no longer is in the project and needs
 * to be loaded individually
 * @param {String} collaborator collaborator object from firebase
 * @returns {Promise}
 */
// eslint-disable-next-line import/prefer-default-export
export const fetchCollaborator = (
  collaboratorId,
  projectId = "_",
  role = "_"
) => (dispatch, getState) =>
  new Promise((resolve, reject) => {
    // Only load it if it doesn't exist in the store
    let collaborator = getState().collaborators[projectId]?.[collaboratorId];

    if (!collaborator) {
      db.collection("users")
        .doc(collaboratorId)
        .get()
        .then((doc) => {
          // It was deleted, set it to viewer
          collaborator = {
            id: collaboratorId,
            data: doc.data(),
            projectRoles: {
              projectId: "viewer",
            },
          };

          dispatch(loadCollaborator(collaborator, projectId));
          resolve(collaborator);
        })
        .catch((error) => reject(error));
    } else {
      dispatch(loadCollaborator(collaborator, projectId));
      resolve(collaborator);
    }
  });

/**
 * Remove collaborator from project
 * @param {Object} collaborator
 * @param {Object} project
 */
export const removeCollaboratorFromProject = (collaborator, project) => (
  dispatch,
  getState
) =>
  new Promise((resolve, reject) => {
    let index = 0;
    project.collaborators.forEach((item) => {
      if (item.user === collaborator.id) {
        if (index > -1) {
          project.collaborators.splice(index, 1);
        }
      } else {
        index += 1;
      }
    });

    project.collaborator_ids.forEach((item) => {
      if (item === collaborator.id) {
        if (index > -1) {
          project.collaborator_ids.splice(index, 1);
        }
      } else {
        index += 1;
      }
    });

    dispatch(updateProject(project))
      .then(() => {
        const updatedCollaborator = getState().collaborators[collaborator.id];
        delete updatedCollaborator.projectRoles[project.id];
        dispatch({
          type: "LOAD_COLLABORATOR",
          updatedCollaborator,
        });
        resolve(project);
      })
      .catch((error) => reject(error));
  });

/**
 * Update collaborator role in project
 * @param {Object} collaborator
 * @param {Object} project
 * @param {String} role
 */
export const updateCollaboratorRoleInProject = (
  collaborator,
  project,
  role
) => (dispatch, getState) =>
  new Promise((resolve, reject) => {
    const projectCollaborators = project.collaborators;
    if (projectCollaborators) {
      project.collaborators.forEach((item) => {
        if (item.user === collaborator.id) {
          item.role = role;
        }
      });
    }

    const updateCollaborator = getState().collaborators[collaborator.id];

    updateCollaborator.projectRoles[project.id] = role;
    dispatch(updateProject(project))
      .then(() => {
        dispatch(loadCollaborator(updateCollaborator, project.id));
        resolve(collaborator);
      })
      .catch((error) => reject(error));
  });
