import firebase, { db } from "../firebase.js";
import { changeNetworkStatus } from "./project.js";

export const loadPerson = (person) => {
  return {
    type: "LOAD_PERSON",
    person: person,
  };
};

export const loadPeople = (people) => {
  return {
    type: "LOAD_PEOPLE",
    people: people,
  };
};

export const updateUser = (user) => {
  return {
    type: "UPDATE_USER",
    user,
  };
};

export const updateUserSettings = (projId) => {
  return function (dispatch, getState) {
    var currentUser = getState().currentUser;

    var docRef = db.collection("users").doc(currentUser.id);

    var remove = false;

    if (currentUser.hideOn != null) {
      if (currentUser.hideOn.includes(projId)) {
        remove = true;
      }
    }

    const updatedHideOn = remove
      ? currentUser.hideOn.filter((id) => id != projId)
      : currentUser.hideOn;

    if (!remove) {
      updatedHideOn.push(projId);
    }

    dispatch(updateUser({ ...currentUser, hideOn: updatedHideOn }));

    if (remove) {
      return docRef
        .update({
          hideOn: firebase.firestore.FieldValue.arrayRemove(projId),
        })
        .then(
          function () {
            setTimeout(function () {
              dispatch(changeNetworkStatus(true, "All Changes Saved"));
            }, 1000);
          }.bind(this)
        )
        .catch(
          function (error) {
            // The document probably doesn't exist.
            dispatch(
              changeNetworkStatus(false, "Error Saving, Please Refresh!")
            );
          }.bind(this)
        );
    } else {
      return docRef
        .update({
          hideOn: firebase.firestore.FieldValue.arrayUnion(projId),
        })
        .catch(console.error);
    }
  };
};

export const updateSortingPreferencesForProject = (
  projectId,
  collection,
  type
) => {
  return function (dispatch, getState) {
    var currentUser = getState().currentUser;

    var docRef = db.collection("users").doc(currentUser.id);

    return docRef
      .update({
        ["sortingPreferences." + projectId + "." + collection]: type,
      })
      .then(
        function () {
          setTimeout(function () {
            dispatch(changeNetworkStatus(true, "All Changes Saved"));
          }, 1000);
        }.bind(this)
      )
      .catch(
        function (error) {
          // The document probably doesn't exist.
          dispatch(changeNetworkStatus(false, "Error Saving, Please Refresh!"));
        }.bind(this)
      );
  };
};

export const updateUserSettingsFeedbackSorting = (type) => {
  return function (dispatch, getState) {
    var currentUser = getState().currentUser;

    var docRef = db.collection("users").doc(currentUser.id);

    return docRef
      .update({
        feedbacksSortingMethod: type,
      })
      .then(
        function () {
          setTimeout(function () {
            dispatch(changeNetworkStatus(true, "All Changes Saved"));
          }, 1000);
        }.bind(this)
      )
      .catch(
        function (error) {
          // The document probably doesn't exist.
          dispatch(changeNetworkStatus(false, "Error Saving, Please Refresh!"));
        }.bind(this)
      );
  };
};

export const updateUserSettingsCollapse = (milestoneId) => {
  return function (dispatch, getState) {
    var currentUser = getState().currentUser;

    var docRef = db.collection("users").doc(currentUser.id);

    var remove = false;

    if (currentUser.collapseOn != null) {
      if (currentUser.collapseOn.includes(milestoneId)) {
        remove = true;
      }
    }

    if (remove) {
      return docRef
        .update({
          collapseOn: firebase.firestore.FieldValue.arrayRemove(milestoneId),
        })
        .then(
          function () {
            setTimeout(function () {
              dispatch(changeNetworkStatus(true, "All Changes Saved"));
            }, 1000);
          }.bind(this)
        )
        .catch(
          function (error) {
            // The document probably doesn't exist.
            dispatch(
              changeNetworkStatus(false, "Error Saving, Please Refresh!")
            );
          }.bind(this)
        );
    } else {
      return docRef
        .update({
          collapseOn: firebase.firestore.FieldValue.arrayUnion(milestoneId),
        })
        .then(
          function () {
            setTimeout(function () {
              //      dispatch(changeNetworkStatus(true, "All Changes Saved"))
            }, 1000);
          }.bind(this)
        )
        .catch(
          function (error) {
            // The document probably doesn't exist.
            //      dispatch(changeNetworkStatus(false, "Error Saving, Please Refresh!"))
          }.bind(this)
        );
    }
  };
};

export const updateUserSettingsThreadFilter = (type) => {
  return function (dispatch, getState) {
    var currentUser = getState().currentUser;

    var docRef = db.collection("users").doc(currentUser.id);
    dispatch(updateUser({ ...currentUser, threadFilter: type }));
    return docRef
      .update({
        threadFilter: type,
      })
      .then(
        function () {
          setTimeout(function () {
            dispatch(changeNetworkStatus(true, "All Changes Saved"));
          }, 1000);
        }.bind(this)
      )
      .catch(
        function (error) {
          // The document probably doesn't exist.
          dispatch(changeNetworkStatus(false, "Error Saving, Please Refresh!"));
        }.bind(this)
      );
  };
};

export const updateUserAppVersion = (app_version) => {
  return function (dispatch, getState) {
    var currentUser = getState().currentUser;

    var docRef = db.collection("users").doc(currentUser.id);

    return docRef
      .update({
        app_version: app_version,
      })
      .catch(console.error);
  };
};

export const updateDataForUser = (data) => {
  return function (dispatch, getState) {
    var currentUser = getState().currentUser;

    var docRef = db.collection("users").doc(currentUser.id);

    return docRef.update(data).catch(console.error);
  };
};

export const refreshCurrentUser = () => (dispatch, getState) => {
  const { currentUser } = getState();
  db.collection("users")
    .doc(currentUser.id)
    .get()
    .then((doc) => dispatch(updateUser(doc.data())))
    .catch((error) => console.error(error));
};

export default {
  loadPeople: loadPeople,
  loadPerson: loadPerson,
  updateUserSettings: updateUserSettings,
  updateSortingPreferencesForProject: updateSortingPreferencesForProject,
  updateUserSettingsFeedbackSorting: updateUserSettingsFeedbackSorting,
  updateUserSettingsThreadFilter: updateUserSettingsThreadFilter,
  updateUserSettingsCollapse: updateUserSettingsCollapse,
  updateUserAppVersion: updateUserAppVersion,
};
