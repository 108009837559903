import React, { Component } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import moment from "moment";
import { Badge, Popconfirm, message, Avatar } from "antd";
import { ContextMenu, MenuItem, ContextMenuTrigger } from "react-contextmenu";
import _ from "lodash";
import { Card } from "../Objects";
import { callCloudFunction } from "../../api/cloud-functions";
import actions from "../../actions";
import localRoles from "../ProjectConfigs/Roles";

export default function ProjectRow({ project, currentUser, users }) {
  const dispatch = useDispatch();

  const roles = project.roles || localRoles;

  var currentRole = "user";

  project.collaborators.forEach(function (collaborator) {
    if (collaborator.user === currentUser.id) {
      currentRole = collaborator.role;
    }
  });

  const goToProject = (project) => {
    window.amplitude.getInstance().logEvent("PROJECT_SELECTED_FROM_DASH", {
      project_name: project.name,
      project_id: project.id,
    });
  };

  const confirmDeletion = () => {
    window.amplitude
      .getInstance()
      .logEvent("PROJECT_SETTINGS_UPDATED", { action: "project_deleted" });

    const hide = message.loading(
      "Running deletion, please wait a few seconds",
      0
    );

    if (project.id != null && project.id != "") {
      callCloudFunction("recursiveDelete", { path: `projects/${project.id}` })
        .then(() => {
          hide();
          message.success("Deletion Complete!");
        })
        .catch(() => {
          hide();
          message.error("Deleteion Failed");
        });
    }
  };

  function confirmArchive(e) {
    window.amplitude
      .getInstance()
      .logEvent("PROJECT_SETTINGS_UPDATED", { action: "project_archived" });

    const hide = message.loading(
      "Archiving Project, please wait a few seconds",
      0
    );

    var projectTemp = Object.assign({}, project);

    projectTemp["archived"] = true;

    dispatch(actions.project.updateProject(projectTemp, project.id)).then(
      () => {
        hide();
      }
    );
  }

  var avatars = [];
  project.collaborator_ids.forEach(function (id) {
    if (users[id] != null) {
      if (users[id].image == null || users[id].image == "") {
        avatars.push(
          <Avatar
            key={id}
            tips={users[id].name}
            src="https://firebasestorage.googleapis.com/v0/b/hermes-50f48.appspot.com/o/default_avatar.jpg?alt=media&token=4828d53a-5240-448c-8647-30464a6240f5"
          />
        );
      } else {
        avatars.push(
          <Avatar tips={users[id].name} src={users[id].image} key={id} />
        );
      }
    }
  });

  if (project.last_updated_timestamp != null) {
    var date = moment(project.last_updated_timestamp);
    if (!date.isValid()) {
      project.last_updated_timestamp = project.last_updated_timestamp.toDate();
    }
  }

  return (
    <div className="dashCard" style={{ lineHeight: 1.5 }}>
      <ContextMenuTrigger id={project.id}>
        <Link
          to={"/p/" + project.id + "/table/status_items"}
          style={{ textDecoration: "none" }}
          onClick={() => goToProject(project)}
        >
          <Card
            dashCard={true}
            style={{ marginBottom: "0px", padding: "20px" }}
          >
            <div style={{ textAlign: "left" }}>
              <div className="project-card-title" data-cy="dash-project-title">
                {project.name}
              </div>

              <div className="project-updated-title">
                Updated on{" "}
                {moment(project.last_updated_timestamp).format(
                  "MMM Do hh:mm A"
                )}
              </div>

              <div style={{ marginTop: "10px", marginBottom: "10px" }}>
                <Avatar.Group
                  size="small"
                  maxCount={3}
                  maxStyle={{
                    color: "#f56a00",
                    backgroundColor: "#fde3cf",
                    fontSize: "12px",
                  }}
                >
                  {avatars}
                </Avatar.Group>
              </div>
            </div>
          </Card>
        </Link>
      </ContextMenuTrigger>

      {roles[currentRole]?.permissions?.project?.settings?.delete &&
        currentUser?.acc_type == "2" && (
          <ContextMenu id={project.id}>
            <Popconfirm
              title="Project data cannot be recovered, do you wish to proceed?"
              onConfirm={confirmDeletion}
              // onCancel={this.cancel}
              okText="I understand"
              cancelText="Nevermind"
            >
              <MenuItem data={{ foo: "bar" }}>Delete Project</MenuItem>
            </Popconfirm>
            <Popconfirm
              title="Project will appear in your archives, do you wish to proceed?"
              onConfirm={() => {
                confirmArchive();
              }}
              okText="I understand"
              cancelText="Nevermind"
            >
              <MenuItem data={{ foo: "bar" }}>Archive Project</MenuItem>
            </Popconfirm>
          </ContextMenu>
        )}
    </div>
  );
}
