const config = require("./config");
const baseUrl = "https://api.aloa.co/v1/metrics";

const post = (metric, userId) => {
  const pixel = new Image();

  pixel.src = `${baseUrl}/success?event=${metric}&user=${userId}`;
};

const fail = (metric, userId) => {
  const pixel = new Image();
  pixel.src = `${baseUrl}/fail?event=${metric}&user=${userId}`;
};

module.exports = {
  post: post,
  fail: fail,
  types: config,
};
