import React, { Component } from 'react';
import { Button } from 'antd';

export default class OutlineButton extends Component {
    render() {
        return (
            <div>
                <Button style={{ "float": "right", color: this.props.color, borderColor: this.props.color, "fontFamily": "Avenir Next", fontWeight: 500,  "fontSize": "13px"}}  onClick={event => this.props.action()}>{this.props.name}</Button>
                <div style={{clear: "right"}}> </div>
            </div>
        )
    }
}