import React from 'react'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { Badge } from 'antd'
import { featureTypes } from '../FeatureToggle'
import { styles } from '../../styles/styles.js'

import settings_image from '../../images/settings_v2.svg'

import analytics_icon from '../../images/analytics_icon.svg'
import { has, getBadgeCountForRules, makeLinkForComponent } from '../utils'

export default function Menu({ components, project }) {
  if (!project.addonsEnabled) {
    project['addonsEnabled'] = []
  }

  const active = useSelector(state => state.page)

  // TODO - This does not do anything with latest updates
  let openDocsCount = 0

  // Let's get the badge counts!
  let openCounts = {}

  Object.keys(components).forEach(collectionKey => {
    const component = components[collectionKey]

    if (!component.badge) {
      return
    }

    let objects = {}

    if (project.badges != null && project.badges[collectionKey] != null) {
      openCounts[collectionKey] = project.badges[collectionKey]?.length
    }
  })

  const pageData = {
    settings: {
      title: 'Settings',
      image: settings_image,
      imageHeight: '10px'
    },
    // analytics: {
    //   title: 'Analytics',
    //   image: analytics_icon,
    //   feature: featureTypes.ANALYTICS,
    //   addon: true
    // },
    null: {
      disabled: true
    }
  }

  Object.keys(components).forEach(collectionKey => {
    var component = components[collectionKey]

    pageData[collectionKey] = {}

    pageData[collectionKey]['image'] = component.info.icon

    if (openCounts[collectionKey] != null) {
      pageData[collectionKey]['title'] = (
        <>
          {component.info.name} <TitleBadge count={openCounts[collectionKey]} />
        </>
      )
    } else {
      pageData[collectionKey]['title'] = component.info.name
    }

    if (component.info.default_view == 'table') {
      pageData[collectionKey]['type'] = 'table'
    } else {
      pageData[collectionKey]['type'] = 'cardlist'
    }

    pageData[collectionKey]['addon'] = component.info.addon

    if (component.info.addon) {
      pageData[collectionKey]['disabled'] = !(
        project.addonsEnabled.includes(collectionKey) || false
      )
    }

    if (component.hideOnMenu) {
      pageData[collectionKey]["disabled"] = true
    }
  });

  // Will replace this with a component order eventually
  var order = [
    'status_items',
    'standups',
    'clarifications',
    'feedbacks',
    'bugs',
    'credentials',
    'settings',
    'meeting_minutes',
    'timesheet_entries',
    'additional_requests',
    'environments',
    'documentations',
    'files',
    "overages"
  ]

  const enabledPageDataListMain = Object.keys(pageData)
    .filter(key => !pageData[key].disabled)
    .filter(key => !pageData[key].addon)
    .map(key => ({ ...pageData[key], id: key }))
    .sort(function (a, b) {
      return order.indexOf(a.id) - order.indexOf(b.id)
    })

  const enabledPageDataListAddons = Object.keys(pageData)
    .filter(key => !pageData[key].disabled)
    .filter(key => pageData[key].addon)
    .map(key => ({ ...pageData[key], id: key }))
    .sort(function (a, b) {
      return order.indexOf(a.id) - order.indexOf(b.id)
    })

  return (
    <div style={{ ...styles.stickDivStatus }}>
      <div style={styles.profileInfoCardStatus}>
        <div
          style={{
            display: 'flex',
            fontSize: '15px',
            marginLeft: '5px',
            fontWeight: '600'
          }}
        >
          Main Menu
        </div>

        {enabledPageDataListMain.map(data => {
          const pageActive = active === data.id
          return (
            <MenuRow
              key={data.id}
              data={data}
              link={makeLinkForComponent(data.id, project.id, data.type)}
              active={pageActive}
            />
          )
        })}

        <div
          style={{
            display: 'flex',
            fontSize: '15px',
            marginLeft: '5px',
            fontWeight: '600',
            marginTop: '15px'
          }}
        >
          Add-ons
        </div>

        {enabledPageDataListAddons.map(data => {
          const pageActive = active === data.id
          return (
            <MenuRow
              key={data.id}
              data={data}
              link={makeLinkForComponent(data.id, project.id, data.type)}
              active={pageActive}
            />
          )
        })}
      </div>
    </div>
  )
}

function MenuRow({ data, active, link }) {
  const menuItemStyleHighlighted = {
    backgroundColor: '#dde5ff'
  }

  const menuItemStyle = {}

  return (
    <div>
      <div
        className='menu-item'
        style={active ? menuItemStyleHighlighted : menuItemStyle}
      >
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            marginLeft: '20px',
            marginRight: '10px'
          }}
        >
          <img
            style={{ height: data.imageHeight ? data.imageHeight : '15px' }}
            src={data.image}
          />
        </div>
        <div
          style={{
            display: 'inline-block',
            verticalAlign: 'middle',
            marginRight: '10px',
            textAlign: 'right'
          }}
        >
          <Link
            to={link ? link : '/'}
            style={{ textDecoration: 'none', color: '#616161' }}
          >
            <div style={{ display: 'flex', alignItems: 'center' }}>
              {data.title}
            </div>
          </Link>
        </div>
      </div>
    </div>
  )
}

function TitleBadge({ count }) {
  return (
    <Badge
      count={count}
      style={{
        backgroundColor: '#005fff',
        color: '#ffffff',
        marginLeft: '7px'
      }}
    />
  )
}
