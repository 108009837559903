import React, { Component } from "react";
import { connect } from "react-redux";
import "../index.css";

// Actions
import actions from "../actions";
import { Button } from "antd";

const mapStateToProps = (state) => ({
  currentUser: state.currentUser,
  people: state.people,
  interactions: state.interactions,
  project: state.project.data,
  loaded: state.loaded,
  network_status: state.network_status,
  threads: state.threads,
  page: state.page,
});

const mapDispatchToProps = (dispatch) => ({
  onLoad: (projectId) => {
    dispatch(actions.project.fetchProject(projectId));
  },
  setPage: (page) => {
    dispatch(actions.app.setPage(page));
  },
});

class NotFound extends Component {
  componentWillMount() {
    //this.props.onLoad(this.props.match.params.projectId);
  }

  componentDidUpdate() {}

  goHome() {
    window.location.replace("/");
  }

  render() {
    return (
      <div
        style={{
          position: "fixed",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
        }}
      >
        <img
          style={{ width: "80%" }}
          src="https://gw.alipayobjects.com/zos/rmsportal/KpnpchXsobRgLElEozzI.svg"
        />
        <div
          style={{
            fontFamily: "Avenir Next",
            fontSize: "30px",
            fontWeight: 500,
            marginTop: "40px",
          }}
        >
          Error 404 (Page not found)
        </div>
        <Button
          style={{ marginTop: "20px" }}
          onClick={() => this.goHome()}
          type="primary"
        >
          Go Back Home
        </Button>
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(NotFound);
