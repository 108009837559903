import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Avatar } from "antd";
import { db } from "../../firebase.js";
import moment from "moment";
import "./Objects.scss";
import { deepEqualCheck } from "../utils";

import { useHistory } from "react-router";

export default function ActivityBubble({
  activity,
  component,
  projectId,
  currentUser,
}) {
  const [activityUser, setActivityUser] = useState({});
  const [isValidChange, setIsValidChange] = useState(false);
  const [changes, setChanges] = useState([]);
  const [userCanView, setUserCanView] = useState(true);

  const history = useHistory();

  const collections = useSelector(
    (state) => state.collections[projectId],
    deepEqualCheck
  );

  const components = useSelector((state) => state.configs[projectId]?.sections);

  const roles = useSelector((state) => state.roles[projectId]);

  useEffect(() => {
    // Let's load the user data for the bug here
    var profilesRef = db.collection("users").doc(activity.user);

    profilesRef
      .get()
      .then(
        function (doc) {
          if (doc.exists) {
            var user = doc.data();

            setActivityUser(user);
          }
        }.bind(this)
      )
      .catch(function () {
        var user = {
          name: "No Name",
        };
        setActivityUser(user);
      });
  }, []);

  useEffect(() => {
    var changesTemp = [];
    var isValidChangeTemp = false;

    if (activityType[activity.eventType] == "created") {
      isValidChangeTemp = true;

      changesTemp.push(
        <div>
          <div
            style={{
              fontFamily: "Avenir Next",
              marginTop: "8px",
              fontSize: "13px",
              padding: "15px 15px 15px 15px",
              borderRadius: "6px",
              color: "#787878",
              whiteSpace: "pre-wrap",
              backgroundColor: "#f3f5f9",
              display: "inline-block",
              maxWidth: "95%",
            }}
          >
            Created this record
          </div>
        </div>
      );
    }

    if (activity.timestamp != null) {
      var date = moment(activity.timestamp);
      if (!date.isValid()) {
        activity.timestamp = activity.timestamp.toDate();
      }
    }

    if (component != null) {
      // This is a modular component, use the new generics thing

      let id = 0;
      if (activity.delta != null) {
        activity.delta.forEach(function (delta) {
          var delta_type = delta.kind;
          var delta_path = delta.path[0];
          var lhs = delta.lhs;
          var rhs = delta.rhs;

          var field = component.fields[delta_path];

          // For use in reference fields only
          var referenceFieldComponent = null;

          console.log("field is ", field);

          if (
            field &&
            roles[currentUser.role]?.permissions?.sections[
              component.info.collectionKey
            ]?.fields[field.valueKey]?.read == false
          ) {
            setUserCanView(false);
          }

          if (field != null && (lhs != null || rhs != null)) {
            var field_data = field.fieldData;

            // we need to check for edge cases like attachments where multiple unecessary fields
            // get updated.
            // Another edge case is timestamps, the seconds field counts as a separate change

            if (
              (field_data.type != "date" &&
                field_data.type != "date_with_age") ||
              (field_data.type == "attachments" &&
                (delta_type != "E" ||
                  (delta.path[2] != null && delta.path[2] == "name"))) ||
              ((field_data.type == "date" ||
                field_data.type == "date_with_age") &&
                delta.path[1] == "_seconds")
            ) {
              isValidChangeTemp = true;

              // For reference fields, let's do some special stuff
              if (field_data.type == "reference") {
                if (delta_type == "N") {
                  if (!delta.path[1] && Object.values(rhs)[0]) {
                    rhs = Object.values(rhs)[0];
                  }

                  if (components != null) {
                    referenceFieldComponent = components[rhs.itemType];
                  }
                }

                if (delta_type == "D") {
                  if (components != null) {
                    referenceFieldComponent = components[lhs.itemType];
                  }
                }
              }

              changesTemp.push(
                <span key={delta.eventId + id++} className="change-container">
                  <span className="field-text">{field.title}</span>

                  {(field_data.type == "tag" ||
                    field_data.type == "select") && (
                    <span>
                      {lhs && (
                        <span>
                          {" from "}
                          <span className="change-before">
                            {field_data.options[lhs]?.name ??
                              `Deleted value: ${lhs}`}
                          </span>{" "}
                        </span>
                      )}

                      {rhs && (
                        <span>
                          to{" "}
                          <span className="change-after">
                            {field_data.options[rhs]?.name ??
                              `Deleted value: ${rhs}`}
                          </span>
                        </span>
                      )}
                    </span>
                  )}

                  {(field_data.type == "text" ||
                    field_data.type == "textArea" ||
                    field_data.type == "password" ||
                    field_data.type == "link" ||
                    field_data.type == "number") && (
                    <span>
                      {" from "}
                      <span className="change-before">{lhs}</span> to{" "}
                      <span className="change-after">{rhs}</span>
                    </span>
                  )}

                  {field_data.type == "checkbox" && (
                    <span>
                      {" from "}
                      <span className="change-before">
                        {lhs ? "True" : "False"}
                      </span>{" "}
                      to{" "}
                      <span className="change-after">
                        {rhs ? "True" : "False"}
                      </span>
                    </span>
                  )}

                  {(field_data.type == "date" ||
                    field_data.type == "date_with_age") && (
                    <span>
                      {" from "}
                      <span className="change-before">
                        {lhs != null && (
                          <span>{moment.unix(lhs).format("MMM DD, YYYY")}</span>
                        )}

                        {lhs == null && <span>No Date</span>}
                      </span>
                      {"  ->  "}

                      <span className="change-after">
                        {moment.unix(rhs).format("MMM DD, YYYY")}
                      </span>
                    </span>
                  )}

                  {field_data.type == "reference" && referenceFieldComponent && (
                    <span>
                      {delta_type == "D" && collections[lhs.itemType] && (
                        <span>
                          {" "}
                          by removing{" "}
                          <span
                            onClick={() => {
                              history.push(
                                `/p/${projectId}/${
                                  referenceFieldComponent.info.default_view ||
                                  "cardlist"
                                }/${lhs.itemType}?item=${lhs.id}`
                              );
                            }}
                            className="change-before"
                            style={{ cursor: "pointer" }}
                          >
                            {
                              referenceFieldComponent?.info.vocabulary
                                .singular_upper
                            }{" "}
                            #
                            {collections[lhs.itemType][lhs.id]?.ext_id ||
                              lhs.id}
                          </span>
                        </span>
                      )}

                      {delta_type == "N" && (
                        <span>
                          {" "}
                          by linking{" "}
                          <span
                            onClick={() => {
                              history.push(
                                `/p/${projectId}/${
                                  referenceFieldComponent.info.default_view ||
                                  "cardlist"
                                }/${rhs.itemType}?item=${rhs.id}`
                              );
                            }}
                            className="change-after"
                            style={{ cursor: "pointer" }}
                          >
                            {
                              referenceFieldComponent?.info.vocabulary
                                .singular_upper
                            }{" "}
                            #
                            {collections[rhs.itemType][rhs.id]?.ext_id ||
                              rhs.id}
                          </span>
                        </span>
                      )}
                    </span>
                  )}

                  {field_data.type == "attachments" && (
                    <span>
                      {delta_type == "D" && (
                        <span>
                          {" "}
                          by deleting an attachment{" "}
                          <span className="change-before">{lhs[0].name}</span>
                        </span>
                      )}

                      {delta_type == "N" && (
                        <span>
                          {" "}
                          by adding an attachment{" "}
                          <span className="change-after">{rhs[0].name}</span>
                        </span>
                      )}

                      {delta_type == "E" &&
                        delta.path[2] != null &&
                        delta.path[2] == "name" && (
                          <span>
                            {" from "}
                            <span className="change-before">{lhs}</span> to{" "}
                            <span className="change-after">{rhs}</span>
                          </span>
                        )}
                    </span>
                  )}
                </span>
              );
            }
          }
        });
      }
    }

    setIsValidChange(isValidChangeTemp);
    setChanges(changesTemp);
  }, [activity, collections, components, component, roles]);

  // If the user does not have view permission for the field, do not show the change
  if (userCanView == false) {
    return null;
  }

  if (activityUser == null || activityUser.name == null) {
    return null;
  }

  // Let's process the history
  if (isValidChange) {
    var name = activityUser.name;
    var initials = name.match(/\b\w/g) || [];
    initials = (
      (initials.shift() || "") + (initials.pop() || "")
    ).toUpperCase();

    return (
      <div style={{ marginTop: "20px", display: "flex" }}>
        <div style={{ verticalAlign: "top" }}>
          <div
            style={{
              display: "inline-block",
              verticalAlign: "middle",
              marginTop: "5px",
              width: "100%",
            }}
          >
            {activityUser.image != null && activityUser.image != "" && (
              <img
                style={{ width: "40px", height: "40px", borderRadius: "50%" }}
                src={activityUser.image}
                onError={(ev) =>
                  (ev.target.src = require("../../images/default-avatar.png"))
                }
              />
            )}

            {(activityUser.image == null || activityUser.image == "") && (
              <Avatar
                size="40px"
                style={{
                  fontSize: "14px",
                  color: "#f56a00",
                  backgroundColor: "#fde3cf",
                  verticalAlign: "middle",
                }}
              >
                {initials}
              </Avatar>
            )}
          </div>
        </div>
        <div
          style={{
            //    width: "100%",
            verticalAlign: "middle",
            textAlign: "left",
            marginLeft: "5px",
            width: "95%",
          }}
        >
          <div
            style={{
              display: "inline-block",
              verticalAlign: "top",
              marginLeft: "7px",
              textAlign: "left",
              width: "100%",
              marginBottom: "3px",
            }}
          >
            <span className="activity-bubble-name">
              {activityUser.name}{" "}
              {activity.eventType != "create" && (
                <span style={{ fontWeight: 400 }}>
                  {" "}
                  {activityType[activity.eventType]}{" "}
                  {changes
                    .map((e, i) =>
                      i < changes.length - 1 ? [e, " and "] : [e]
                    )
                    .reduce((a, b) => a.concat(b))}
                </span>
              )}
              {activity.eventType == "create" && (
                <span style={{ fontWeight: 400 }}> created this record</span>
              )}
            </span>
          </div>

          <div style={{ clear: "both" }}> </div>
          <span className="bubble-timestamp">
            {" "}
            {moment(activity.timestamp).format("MMM DD, YYYY hh:mm a")}
          </span>
        </div>
      </div>
    );
  } else {
    return null;
  }
}

const activityType = {
  update: "changed",
  create: "created",
  delete: "deleted",
};

const fields = {
  est_delivery_date: "Est Delivery Date",
  status: "Status",
  qa_status: "Client Approval",
  name: "Name",
  description: "Description",
  steps: "Steps to Reproduce",
  priority: "Priority",
  attachments: "Attachments",
  open: "Status",
  question: "Question",
  problems: "Problems Faced",
  today: "Done Today",
  yesterday: "Done Yesterday",
  created: "Created",
  template_link: "Template Link",
  title: "Document Name",
  due_date: "Due Date",
};

var priority = {
  0: {
    name: "Critical (P0)",
    color: "#ffffff",
    backgroundColor: "#ff0000",
  },
  1: {
    name: "Major (P1)",
    color: "#ffffff",
    backgroundColor: "#F18100",
  },
  2: {
    name: "Minor (P2)",
    color: "#000000",
    backgroundColor: "#FFC403",
  },
  3: {
    name: "Low (P3)",
    color: "#000000",
    backgroundColor: "#ffe800",
  },
};

var status_names = {
  open: "Open",
  in_review: "Ready for Review",
  closed: "Closed",
};
