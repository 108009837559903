import React from "react";
import ReferenceModal from "../../ReferenceModal";

export default function ReferenceField({
  field,
  valueEditable,
  projectId,
  currentUser,
  collectionKey,
  checkPermissionsAndUpdateValue,
  components,
  currentObjectId
}) {
  return (
    <div className="attachments">
      <ReferenceModal
        field={field}
        referencedItems={valueEditable}
        projectId={projectId}
        currentUser={currentUser}
        collectionKey={collectionKey}
        viewType="table"
        updateReferencedItems={checkPermissionsAndUpdateValue}
        components={components}
        currentObjectId={currentObjectId}
      />
    </div>
  );
}
