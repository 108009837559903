import React from "react";
import * as sanitizeHtml from "sanitize-html";
import styled from "styled-components";
import { createTagSpan } from "../Objects/TextField/helpers";

export default function NotificationText({ message, currentUser }) {
  let content = message.item?.content;

  if (message.itemType !== "comment") {
    if(content.seconds) {
      // This means it is a firestore timestamp object, let's convert it to a string
      content = new Date(content.seconds * 1000).toLocaleString();
    } else {
      return content;
    }
  }

  const tags = message.item?.metadata?.tags;

  if (tags) {
    tags.forEach((tag) => {
      content = content.replace(
        `@${tag.id}`,
        createTagSpan(tag, currentUser.id === tag.id)
      );
    });
  }

  content = content.replace(
    /((http|https|ftp|ftps)\:\/\/[a-zA-Z0-9\-\.]+\.[a-zA-Z]{2,3}(\/\S*)?)/g,
    '<a target="_blank" href="$1">$1</a>'
  );

  return (
    <>
      {message.item.content && (
        <span
          dangerouslySetInnerHTML={{
            __html: sanitizeHtml(content, {
              allowedTags: ["a", "span"],
              allowedAttributes: {
                a: ["href", "target"],
                span: ["class", "id"],
              },
            }),
          }}
        ></span>
      )}
    </>
  );
}

const CommentBubble = styled.span``;
