export const setSearchValue = (value) => (dispatch) =>
  dispatch({
    type: "SET_SEARCH_VALUE",
    value,
  });

export const toggleSearchActive = () => (dispatch, getState) => {
  const currentSearchState = getState().search;

  dispatch({
    type: "SET_SEARCH_ACTIVE",
    active: !currentSearchState.active,
  });
};
