import { newTimestampFromDate } from "../utils";

export default {
    info: {
        name: "Meeting Minutes",
        addon: true,
        collectionKey: "meeting_minutes",
        vocabulary: {
            singular_upper: "Meetings Minutes",
            singular_lower: "meeting minutes",
            plural_upper: "Meeting Minutes",
            plural_lower: "meeting minutes",
        },
        default_view: "cardlist",
        type: "meeting_minutes",
        icon:
            "https://firebasestorage.googleapis.com/v0/b/hermes-50f48.appspot.com/o/icons%2Fmeetingminutes_v4.svg?alt=media&token=16252b6e-dada-4531-8f1a-6607e9f61664",
    },
    fields: {
        date: {
            title: "When was this?",
            valueKey: "date",
            fieldData: {
                type: "date",
                defaultValue: newTimestampFromDate(new Date()),
            },
        },
        notes: {
            title: "What was discussed during the meeting?",
            valueKey: "notes",
            fieldData: {
                type: "textArea",
                placeholder: "Example: Decided to prioritize news feed over user profiles.",
            },
            required: true,
        },
        attendees: {
            title: "Who attended?",
            valueKey: "attendees",
            fieldData: {
                type: "collaborator",
                multipleEnabled: true
            },
            required: true,
        },
        attachments: {
            title: "Attachments",
            valueKey: "attachments",
            fieldData: { type: "attachments" },
            hideTitle: true,
        },
    },
    search: {
        searchableAttributes: ["date", "notes"],
    },
    notifications: {
        slack: {
            color: "#2f7eff",
            primaryField: "date",
            fields: {
                create: ["date", "attendees", "notes"],
                edit: ["date", "attendees", "notes"],
            },
            buttons: [
                {
                    text: "Comment on Meeting Minutes",
                },
            ],
        },
    },
    cardlist: {
        sorting: {
            age_ascending: {
                name: "Sort by age (ascending)",
                key: "age_ascending",
                value: "date",
                ascending: true,
            },
            age_descending: {
                name: "Sort by age (descending)",
                key: "age_descending",
                value: "date",
                ascending: false,
            },
        },
        fieldOrder: ["date", "attendees", "notes", "attachments"],
    },
    permissions: {
        view: ["viewer", "vendor", "client", "admin"],
        create: ["vendor", "client", "admin"],
        edit: ["vendor", "client", "admin"],
        delete: ["vendor", "admin"],
    },
};
