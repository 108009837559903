import React, { Component } from "react";
import * as Colors from "@material-ui/core/colors";
import { MuiThemeProvider, createMuiTheme } from "@material-ui/core/styles";
import "./App.css";
import firebase from "../firebase.js";
import { logoutUser } from "../actions/auth";
import { styles } from "../styles/styles.js";
import { db } from "../firebase.js";
import { connect } from "react-redux";
import typography from "@material-ui/core/Typography";

import Snackbar from "@material-ui/core/Snackbar";
import IconButton from "@material-ui/core/IconButton";
import { Card } from "./Objects";
import { Link } from "react-router-dom";

import { Button, message, Switch, Avatar } from "antd";
import metrics from "../api/metrics";

const muiTheme = createMuiTheme({
  palette: {
    textColor: "#000000",
    primary1Color: "#131d35",
    primary2Color: "#000000",
    accent1Color: "#000000",
    pickerHeaderColor: "#000000",
    alternateTextColor: "#ffffff",
  },
  appBar: {
    height: 60,
  },
});

const mapStateToProps = (state) => ({
  ...state,
  projects: state.projects,
  currentUser: state.currentUser,
  transactions: state.transactions,
});

const mapDispatchToProps = (dispatch) => ({
  onLoad: () => {
    // dispatch(fetchTransactions(userId));
  },
  logOut: () => {
    dispatch(logoutUser());
    firebase.auth().signOut();
  },
});

export class Account extends Component {
  componentDidMount() {
    if (this.props.currentUser) {
      this.props.onLoad(this.props.currentUser.id);
    }
  }

  handleClick() {
    this.props.logOut();

    window.location.replace("/");
  }

  render() {
    if (this.props.currentUser) {
      return (
        <MuiThemeProvider muiTheme={muiTheme}>
          <div>
            <div>
              <div className="invoicePage">
                <div
                  style={{
                    textAlign: "left",
                    fontFamily: "Avenir Next",
                    color: "#000000",
                    marginBottom: "20px",
                  }}
                >
                  {" "}
                  <Link
                    to="/"
                    style={{ textDecoration: "none", color: "#7c7c7c" }}
                  >
                    {" "}
                    Home{" "}
                  </Link>{" "}
                  / Account Settings{" "}
                </div>
                <AccountInfoCard currentUser={this.props.currentUser} />
                <div>
                  <a
                    style={{
                      color: "#ababab",
                      fontFamily: "Sofia Pro",
                      fontSize: "15px",
                      float: "right",
                      marginTop: "0.3em",
                      marginBottom: 30,
                      cursor: "pointer",
                    }}
                    onClick={(event) => this.handleClick()}
                  >
                    Log Out
                  </a>
                </div>
              </div>
            </div>
          </div>
        </MuiThemeProvider>
      );
    } else {
      return <div>Loading...</div>;
    }
  }
}

export class AccountInfoCard extends Component {
  constructor() {
    super();

    this.state = {
      currentUser: {
        name: "",
        billing_info: {
          address_1: "",
          address_2: "",
          city: "",
          state: "",
          zip: "",
        },
      },
      newPassword: {
        pass1: "",
        pass2: "",
      },
    };
  }

  handleClick = (message) => {
    this.setState({ open: true, message: message });
  };

  handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    this.setState({ open: false });
  };

  handleChange = (event, type) => {
    var currentUser = this.state.currentUser;
    var newPassword = this.state.newPassword;
    var newEmail = this.state.newEmail;

    if (type == "name") {
      currentUser["name"] = event.target.value;
    }

    if (type == "company") {
      currentUser["company"] = event.target.value;
    }

    var billing_info = this.state.currentUser.billing_info;

    if (type == "address_1") {
      billing_info["address_1"] = event.target.value;
    }

    if (type == "address_2") {
      billing_info["address_2"] = event.target.value;
    }

    if (type == "city") {
      billing_info["city"] = event.target.value;
    }

    if (type == "state") {
      billing_info["state"] = event.target.value;
    }

    if (type == "zip") {
      billing_info["zip"] = event.target.value;
    }

    currentUser["billing_info"] = billing_info;

    // Do something special for email
    if (type == "email") {
      newEmail = event.target.value;
    }

    //For passwords
    if (type == "pass1") {
      newPassword["pass1"] = event.target.value;
    }

    if (type == "pass2") {
      newPassword["pass2"] = event.target.value;
    }

    this.setState({
      currentUser: currentUser,
      newPassword: newPassword,
      newEmail: newEmail,
    });
  };

  imageUpload = (e) => {
    var currentUser = this.state.currentUser;

    var file = e.target.files[0];
    getBase64(file).then((base64) => {
      localStorage["fileBase64"] = base64;
    });

    // Create a root reference
    var storageRef = firebase.storage().ref();

    var randomId =
      Math.random().toString(36).substring(2, 15) +
      Math.random().toString(36).substring(2, 15);

    var uploadTask = storageRef
      .child(this.state.currentUser.id + "/" + randomId + ".jpg")
      .put(file);

    // Register three observers:
    // 1. 'state_changed' observer, called any time the state changes
    // 2. Error observer, called on failure
    // 3. Completion observer, called on successful completion
    uploadTask.on(
      "state_changed",
      function (snapshot) {
        // Observe state change events such as progress, pause, and resume
        // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
        var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;

        switch (snapshot.state) {
          case firebase.storage.TaskState.PAUSED: // or 'paused'
            break;
          case firebase.storage.TaskState.RUNNING: // or 'running'
            break;
        }
      },
      function () {
        // Handle unsuccessful uploads
      },
      function () {
        // Handle successful uploads on complete
        // For instance, get the download URL: https://firebasestorage.googleapis.com/...
        uploadTask.snapshot.ref.getDownloadURL().then(
          function (downloadURL) {
            this.handleClick("Image Ready");
            currentUser["image"] = downloadURL;
            this.setState({ currentUser: currentUser });
            window.amplitude.getInstance().logEvent("ACCOUNT_PHOTO_CHANGED");
          }.bind(this)
        );
      }.bind(this)
    );
  };

  saveBillingInfo = () => {
    var currentUser = this.props.currentUser;
    var user = firebase.auth().currentUser;
    var newEmail = this.state.newEmail;

    db.collection("users")
      .doc(currentUser.id)
      .set(currentUser, { merge: true })
      .then(
        function () {
          if (newEmail != user.email) {
            user
              .updateEmail(newEmail)
              .then(function () {
                // Update successful.
                message.success("Account updated!");
              })
              .catch(function (error) {
                var errorMessage = error.message;
                message.error(errorMessage);
              });
          } else {
            message.success("Account updated!");
          }
          //  this.handleClick("Account Info Saved!");

          window.amplitude.getInstance().logEvent("ACCOUNT_BILLING_SAVED");
          window.location.reload();
        }.bind(this)
      )
      .catch(
        function (error) {
          // The document probably doesn't exist.

          var errorMessage = error.message;
          message.error(errorMessage);
        }.bind(this)
      );
  };

  changePassword = () => {
    var newPassword = this.state.newPassword;

    var user = firebase.auth().currentUser;

    if (newPassword.pass1 === newPassword.pass2) {
      user
        .updatePassword(newPassword.pass2)
        .then(
          function () {
            // Update successful.
            message.success("Password successfully chnaged!");
            this.setState({ newPassword: { pass1: "", pass2: "" } });
          }.bind(this)
        )
        .catch(
          function (error) {
            var errorMessage = error.message;
            message.error(errorMessage);
          }.bind(this)
        );
    } else {
      message.error("Passwords don't match!");
    }
  };

  onChange(checked) {
    var currentUser = this.state.currentUser;
    currentUser.devEnabled = checked;
    this.setState({ currentUser: currentUser });
  }

  componentDidMount() {
    var currentUser = this.props.currentUser;
    var user = firebase.auth().currentUser;

    if (currentUser.billing_info == null) {
      currentUser.billing_info = {};
    }

    if (currentUser.devEnabled == null) {
      currentUser.devEnabled = false;
    }

    this.setState({ currentUser: currentUser, newEmail: user.email });
  }

  render() {
    var currentUser = this.state.currentUser;
    var newPassword = this.state.newPassword;
    var newEmail = this.state.newEmail;

    var name = currentUser.name;
    var initials = name.match(/\b\w/g) || [];
    initials = (
      (initials.shift() || "") + (initials.pop() || "")
    ).toUpperCase();

    return (
      <div>
        <Snackbar
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          open={this.state.open}
          autoHideDuration={6000}
          onClose={this.handleClose}
          ContentProps={{
            "aria-describedby": "message-id",
          }}
          message={<span id="message-id">{this.state.message}</span>}
          action={[
            <IconButton
              key="close"
              aria-label="Close"
              color="inherit"
              onClick={this.handleClose}
            >
              {/* <CloseIcon /> */}
            </IconButton>,
          ]}
        />

        <SettingsCard name={"Account Info"}>
          <div
            style={{
              fontSize: "17px",
              fontWeight: 500,
              fontFamily: "Avenir Next",
            }}
          >
            Account Image
          </div>

          <label for="imageFile" style={{ cursor: "pointer" }}>
            <input
              style={{ display: "none" }}
              type="file"
              id="imageFile"
              name="imageFile"
              onChange={this.imageUpload}
            />
            {currentUser.image && (
              <img
              onError={(ev) => ev.target.src = require("../images/default-avatar.png")}
                src={currentUser.image}
                style={{ width: 100, height: 100, borderRadius: "50%" }}
              />
            )}

            {!currentUser.image && (
              <Avatar
                size={100}
                style={{
                  color: "#f56a00",
                  backgroundColor: "#fde3cf",
                  verticalAlign: "middle",
                }}
              >
                Change
              </Avatar>
            )}
          </label>

          <div
            style={{
              fontSize: "17px",
              fontWeight: 500,
              fontFamily: "Avenir Next",
              marginTop: "20px",
            }}
          >
            Name
          </div>

          <input
            value={currentUser.name}
            style={styles.lineTextBox}
            onInput={(e) => this.handleChange(e, "name")}
          />

          <div
            style={{
              fontSize: "17px",
              fontWeight: 500,
              fontFamily: "Avenir Next",
              marginTop: "20px",
            }}
          >
            Company
          </div>

          <input
            value={currentUser.company}
            style={styles.lineTextBox}
            onInput={(e) => this.handleChange(e, "company")}
          />

          <div
            style={{
              fontSize: "17px",
              fontWeight: 500,
              fontFamily: "Avenir Next",
              marginTop: "20px",
            }}
          >
            Email
          </div>

          <input
            value={newEmail}
            style={styles.lineTextBox}
            onInput={(e) => this.handleChange(e, "email")}
          />

          {this.props.currentUser.acc_type == 2 && (
            <div>
              <div
                style={{
                  fontSize: "17px",
                  fontWeight: 500,
                  fontFamily: "Avenir Next",
                  marginTop: "20px",
                }}
              >
                Developer Mode
              </div>

              <Switch
                onChange={this.onChange.bind(this)}
                checked={this.state.currentUser.devEnabled}
              />
            </div>
          )}

          <div style={{ float: "right", paddingTop: 20, paddingBottom: 20 }}>
            <Button
              type="primary"
              onClick={() => {
                this.saveBillingInfo();
              }}
            >
              Save Account Info
            </Button>
          </div>

          <div style={{ clear: "right" }}> </div>
        </SettingsCard>

        <SettingsCard name={"Security"}>
          <div
            style={{
              fontSize: "17px",
              fontWeight: 500,
              fontFamily: "Avenir Next",
              marginTop: "20px",
            }}
          >
            New Password
          </div>

          <input
            value={newPassword.pass1}
            style={styles.lineTextBox}
            onInput={(e) => this.handleChange(e, "pass1")}
          />

          <div
            style={{
              fontSize: "17px",
              fontWeight: 500,
              fontFamily: "Avenir Next",
              marginTop: "20px",
            }}
          >
            Confirm Password
          </div>

          <input
            value={newPassword.pass2}
            style={styles.lineTextBox}
            onInput={(e) => this.handleChange(e, "pass2")}
          />

          <div style={{ float: "right", paddingTop: 20, paddingBottom: 20 }}>
            <Button
              type="primary"
              onClick={() => {
                this.changePassword();
              }}
            >
              Change Password
            </Button>
          </div>

          <div style={{ clear: "right" }}> </div>
        </SettingsCard>
      </div>
    );
  }
}

class SettingsCard extends Component {
  componentDidMount() {}

  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div>
        <div
          style={{
            fontSize: 16,
            fontWeight: typography.fontWeightMedium,
            paddingBottom: 15,

            color: "#4e4e4e",
            fontFamily: "Brown",
            fontWeight: 500,
            textAlign: "left",
          }}
        >
          {this.props.name}
        </div>

        <Card>
          <div style={{ textAlign: "left" }}>{this.props.children}</div>
        </Card>
      </div>
    );
  }
}

const getBase64 = (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
    reader.readAsDataURL(file);
  });
};

export default connect(mapStateToProps, mapDispatchToProps)(Account);
