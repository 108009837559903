export default {
  info: {
    name: "Status Table",
    addon: false,
    collectionKey: "status_items",
    vocabulary: {
      singular_upper: "Status Item",
      singular_lower: "status item",
      plural_upper: "Status Items",
      plural_lower: "status items",
    },
    default_view: "table",
    type: "status_item",
    icon: "https://firebasestorage.googleapis.com/v0/b/hermes-50f48.appspot.com/o/icons%2Fcheck_v2.svg?alt=media&token=873a3bfd-2ef1-4535-a539-fc715566b176",
  },
  search: {
    searchableAttributes: ["name"],
  },
  fields: {
    name: {
      title: "Name",
      valueKey: "name",
      fieldData: {
        type: "textArea",
        placeholder: "Item Name",
      },
      required: false,
    },
    estimated_hours: {
      title: "Est. Hours",
      valueKey: "estimated_hours",
      fieldData: {
        type: "number",
        placeholder: "ex: 4",
        minValue: 0,
      },
    },
    impacting_items: {
      title: "Linked Tickets",
      valueKey: "impacting_items",
      fieldData: {
        type: "reference",
        itemTypes: ["bugs", "feedbacks"],
        linkedFields: {
          bugs: "impacted_status_items",
          feedbacks: "impacted_status_items",
        },
      },
    },
    overages: {
      title: "Overages",
      valueKey: "overages",
      fieldData: {
        type: "reference",
        itemTypes: ["overages"],
        linkedFields: {
          overages: "status_items",
        },
      },
    },
    priority: {
      title: "Priority",
      valueKey: "priority",
      permissions: {
        view: ["viewer", "vendor", "client", "admin"],
        edit: ["client", "admin"],
      },
      fieldData: {
        type: "tag",
        defaultValue: "-",
        options: {
          "-": {
            id: "-",
            name: "-",
            color: "#4B4B4B",
            backgroundColor: "#f1f1f1",
          },
          Low: {
            id: "Low",
            name: "Low",
            color: "#4B4B4B",
            backgroundColor: "#CEDFFF",
          },
          Medium: {
            id: "Medium",
            name: "Medium",
            color: "#4B4B4B",
            backgroundColor: "#FEE2D5",
          },
          High: {
            id: "High",
            name: "High",
            color: "#4B4B4B",
            backgroundColor: "#ffa6d3",
          },
        },
        options_order: ["-", "Low", "Medium", "High"],
      },
    },
    status: {
      title: "Status",
      valueKey: "status",
      fieldData: {
        type: "tag",
        defaultValue: "Backlog",
        options: {
          Backlog: {
            id: "Backlog",
            name: "Backlog",
            color: "#4B4B4B",
            backgroundColor: "#FFEAB6",
          },
          Complete: {
            id: "Complete",
            name: "Complete",
            color: "#4B4B4B",
            backgroundColor: "#D0F7C3",
          },
          "In Progress": {
            id: "In Progress",
            name: "In Progress",
            color: "#4B4B4B",
            backgroundColor: "#CEDFFF",
          },
          "In QA": {
            id: "In QA",
            name: "In QA",
            color: "#4B4B4B",
            backgroundColor: "#EDE2FE",
          },
          "Bugs Found": {
            id: "Bugs Found",
            name: "Bugs Found",
            color: "#4B4B4B",
            backgroundColor: "#FEE2D5",
          },
          "Needs Info": {
            id: "Needs Info",
            name: "Needs Info",
            color: "#4B4B4B",
            backgroundColor: "#ffa6d3",
          },
        },
        options_order: [
          "Backlog",
          "Complete",
          "In Progress",
          "In QA",
          "Bugs Found",
          "Needs Info",
        ],
      },
    },
    qa_status: {
      title: "Client Approval",
      valueKey: "qa_status",
      permissions: {
        view: ["viewer", "vendor", "client", "admin"],
        edit: ["client", "admin"],
      },
      fieldData: {
        type: "tag",
        defaultValue: "-",
        options: {
          "-": {
            id: "-",
            name: "-",
            color: "#4B4B4B",
            backgroundColor: "#f1f1f1",
          },
          "Pending Approval": {
            id: "Pending Approval",
            name: "Pending Approval",
            color: "#4B4B4B",
            backgroundColor: "#FFEAB6",
          },
          Approved: {
            id: "Approved",
            name: "Approved",
            color: "#4B4B4B",
            backgroundColor: "#D0F7C3",
          },
          "Bugs Found": {
            id: "Bugs Found",
            name: "Bugs Found",
            color: "#4B4B4B",
            backgroundColor: "#FEE2D5",
          },
        },
        options_order: ["-", "Pending Approval", "Approved", "Bugs Found"],
      },
    },
    est_delivery_date: {
      title: "Est Deliv Date",
      valueKey: "est_delivery_date",
      fieldData: {
        type: "date",
        //     defaultValue: newTimestampFromDate(new Date())
      },
    },
    milestone_id: {
      title: "Milestone",
      hidden: true,
      valueKey: "milestone_id",
      fieldData: {
        type: "object",
        collectionKey: "milestones",
        primaryKey: "name",
      },
    },
  },
  notifications: {
    slack: {
      color: "#2E7EFF",
      primaryField: "name",
      fields: {
        create: [
          "name",
          "estimated_hours",
          "priority",
          "status",
          "qa_status",
          "impacting_items",
          "overages",
        ],
        edit: [
          "name",
          "estimated_hours",
          "priority",
          "status",
          "qa_status",
          "impacting_items",
          "overages",
        ],
      },
      buttons: [
        {
          text: "Edit Status Item",
        },
      ],
    },
  },
  permissions: {
    view: ["viewer", "vendor", "client", "admin"],
    create: ["vendor", "client", "admin"],
    edit: ["vendor", "client", "admin"],
    delete: ["client", "admin"],
  },
  cardlist: {
    enabled: false,
  },
  table: {
    index_row_key: "name",
    pivotBy: "milestone_id",
    exclude_from_new_button: ["status_items"],
    summaries: {
      estimated_hours: {
        type: "sum",
      },
    },
    columns: [
      "name",
      "estimated_hours",
      "priority",
      "status",
      "qa_status",
      "est_delivery_date",
      "impacting_items",
      "overages",
    ],
  },
};
