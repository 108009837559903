export default {
  info: {
    name: "Overages",
    addon: true,
    collectionKey: "overages",
    vocabulary: {
      singular_upper: "Overage",
      singular_lower: "overage",
      plural_upper: "Overages",
      plural_lower: "overages",
    },
    default_view: "table",
    type: "overages",
    icon: "https://firebasestorage.googleapis.com/v0/b/hermes-50f48.appspot.com/o/icons%2Foverages_v1.svg?alt=media&token=3c60034c-ac4e-497d-a6fd-4161fee587b0",
  },
  fields: {
    description: {
      title: "Description of Impact",
      valueKey: "description",
      fieldData: {
        type: "textArea",
      },
    },
    status_items: {
      title: "Impacted Items",
      valueKey: "status_items",
      fieldData: {
        type: "reference",
        itemTypes: ["status_items"],
        linkedFields: {
          status_items: "overages",
        },
      },
    },
    discussion_tickets: {
      title: "Discussion Tickets",
      valueKey: "discussion_tickets",
      fieldData: {
        type: "reference",
        itemTypes: ["feedbacks", "bugs", "clarifications"],
        linkedFields: {
          feedbacks: "overages",
          bugs: "overages",
          clarifications: "overages",
        },
      },
    },
    decision: {
      title: "Client Decision",
      valueKey: "decision",
      fieldData: {
        type: "tag",
        defaultValue: "pending",
        options: {
          pending: {
            id: "pending",
            name: "Pending",
            color: "#4B4B4B",
            backgroundColor: "#FFEAB6",
          },
          approved: {
            id: "approved",
            name: "Approved",
            color: "#4B4B4B",
            backgroundColor: "#D0F7C3",
          },
          rejected: {
            id: "rejected",
            name: "Rejected",
            color: "#4B4B4B",
            backgroundColor: "#FEE2D5",
          },
        },
      },
    },
    hours: {
      title: "Overage Hours",
      valueKey: "hours",
      fieldData: {
        type: "number",
        minValue: "0",
      },
    },
    cost: {
      title: "Overage Cost",
      valueKey: "cost",
      fieldData: {
        type: "number",
        minValue: "0",
      },
    },
  },

  search: {
    searchableAttributes: ["description"],
  },
  notifications: {
    slack: {
      color: "#2f7eff",
      primaryField: "description",
      fields: {
        create: [
          "description",
          "decision",
          "hours",
          "cost",
          "status_items",
          "discussion_tickets",
        ],
        edit: [
          "description",
          "decision",
          "hours",
          "cost",
          "status_items",
          "discussion_tickets",
        ],
      },
      buttons: [
        {
          text: "Review Overages",
        },
      ],
    },
  },
  table: {
    columns: [
      "description",
      "status_items",
      "discussion_tickets",
      "decision",
      "hours",
      "cost",
    ],
    index_row_key: "description",
    summaries: {
      hours: {
        type: "sum",
      },
      cost: {
        type: "sum",
      },
    },
  },
  permissions: {
    view: ["viewer", "vendor", "client", "admin"],
    create: ["vendor", "client", "admin"],
    edit: ["vendor", "client", "admin"],
    delete: ["vendor", "admin"],
  },
};
