import React, { Component } from "react";
import { db } from "../../firebase.js";
import { CloseOutlined } from '@ant-design/icons';
import { Button, Select, Avatar } from "antd";
import metrics from "../../api/metrics";

const Option = Select.Option;

export default class CollaboratorCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      collaborator: null,
    };
  }

  removeCollaborator() {
    var project = this.props.project;
    var collaborator = this.state.collaborator;

    var newCollaborators = project.collaborators.filter(function (
      collaborator_temp
    ) {
      return collaborator_temp.user !== collaborator.id;
    });

    var newCollaborator_ids = project.collaborator_ids.filter(function (
      collaborator_id
    ) {
      return collaborator_id !== collaborator.id;
    });

    const currentUserId = this.props.currentUser.id;

    db.collection("projects")
      .doc(project.id)
      .set(
        {
          collaborators: newCollaborators,
          collaborator_ids: newCollaborator_ids,
        },
        { merge: true }
      )
      .then(function () {
        window.amplitude.getInstance().logEvent("PROJECT_SETTINGS_UPDATED", {
          action: "collaborator_removed",
        });
      })
      .catch(function (error) {});
  }

  handleChange(value) {
    var project = this.props.project;
    var collaborator = this.state.collaborator;

    // Replace
    project.collaborators.forEach(function (collaborator_temp) {
      if (collaborator_temp.user === collaborator.id) {
        collaborator_temp["role"] = value;
      }
    });

    db.collection("projects")
      .doc(project.id)
      .set(
        {
          collaborators: project.collaborators,
        },
        { merge: true }
      )
      .catch(console.error);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.collaborator !== this.props.collaborator) {
      var collaborator_temp = this.props.collaborator;

      var docRef = db.collection("users").doc(collaborator_temp.user);

      docRef
        .get()
        .then(
          function (doc) {
            if (doc.exists) {
              //
              var colab = doc.data();
              colab["role"] = collaborator_temp.role;

              this.setState({ collaborator: colab });
            } else {
              // doc.data() will be undefined in this case
            }
          }.bind(this)
        )
        .catch(function (error) {});
    }
  }

  componentDidMount() {
    var collaborator_temp = this.props.collaborator;

    var docRef = db.collection("users").doc(collaborator_temp.user);

    docRef
      .get()
      .then(
        function (doc) {
          if (doc.exists) {
            //
            var colab = doc.data();
            colab["role"] = collaborator_temp.role;

            this.setState({ collaborator: colab });
          } else {
            // doc.data() will be undefined in this case
          }
        }.bind(this)
      )
      .catch(function (error) {});
  }

  render() {
    var collaborator = this.state.collaborator;
    var currentUser = this.props.currentUser;

    if (collaborator == null) {
      return null;
    }

    var name = collaborator.name;
    var initials = name.match(/\b\w/g) || [];
    initials = (
      (initials.shift() || "") + (initials.pop() || "")
    ).toUpperCase();

    return (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          width: "100%",
          marginBottom: "20px",
        }}
      >
        {collaborator.image != null && collaborator.image != "" && (
          <div style={{ display: "inline-block" }}>
            <img
              src={collaborator.image}
              onError={(ev) => ev.target.src = require("../../images/default-avatar.png")}
              style={{
                height: 27,
                width: 27,
                borderRadius: "50%",
                marginRight: "8px",
              }}
            />
          </div>
        )}

        {(collaborator.image == null || collaborator.image == "") && (
          <div style={{ display: "inline-block" }}>
            <Avatar
              style={{
                color: "#f56a00",
                backgroundColor: "#fde3cf",
                marginRight: "8px",
                verticalAlign: "middle",
              }}
            >
              {initials}
            </Avatar>
          </div>
        )}

        <div
          style={{
            display: "inline-block",
            fontFamily: "Avenir Next",
            color: "#000000",
            fontSize: "17px",
            fontWeight: "400",
          }}
        >
          {collaborator.name}
        </div>

        <div
          style={{
            display: "inline-block",
            fontFamily: "Avenir Next",
            color: "#000000",
            fontSize: "17px",
            fontWeight: "400",
            marginLeft: "auto",
          }}
        >
          <div style={{ display: "inline-block", marginRight: "5px" }}>
            {this.props.roles[currentUser.role]?.permissions?.project
              ?.collaborators?.edit && (
              <Select
                defaultValue={collaborator.role}
                style={{ width: 120 }}
                onChange={(e) => this.handleChange(e)}
              >
                <Option value="viewer">Viewer</Option>
                <Option value="vendor">Vendor</Option>
                <Option value="client">Client</Option>
                <Option value="admin">Admin</Option>
              </Select>
            )}

            {!this.props.roles[currentUser.role]?.permissions?.project
              ?.collaborators?.edit && (
              <Select
                defaultValue={collaborator.role}
                style={{ width: 120 }}
                onChange={(e) => this.handleChange(e)}
                disabled
              >
                <Option value="viewer">Viewer</Option>
                <Option value="vendor">Vendor</Option>
                <Option value="client">Client</Option>
                <Option value="admin">Admin</Option>
              </Select>
            )}
          </div>
          {this.props.roles[currentUser.role]?.permissions?.project
            ?.collaborators?.delete && (
            <div style={{ display: "inline-block" }}>
              <Button
                shape="circle"
                icon={<CloseOutlined />}
                style={{ border: "none" }}
                onClick={() => this.removeCollaborator()}
              />
            </div>
          )}
        </div>
      </div>
    );
  }
}
