import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import typography from "@material-ui/core/Typography";
import { Modal } from "antd";
import Search from "antd/lib/input/Search";
import _ from "lodash";
import { db } from "../../firebase";
import actions from "../../actions";
import ProjectCreationModal from "./ProjectCreationModal";
import ProjectRow from "./ProjectRow";
import "../../styles/Dash.css";
import ProjectNotificationBadge from "./ProjectNotificationBadge";
import { BiSearchAlt2 } from "react-icons/bi";

export default function Dashboard({}) {
  const [showArchivedVar, setShowArchivedVar] = useState(false);
  const [creationModalVisible, setCreationModalVisible] = useState(false);
  const [users, setUsers] = useState({});
  const [usersLoaded, setUsersLoaded] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");

  const dispatch = useDispatch();

  const currentUser = useSelector((state) => state.currentUser);

  function showArchived() {
    setShowArchivedVar(!showArchivedVar);
  }

  function handleCancel() {
    window.amplitude.getInstance().logEvent("NEW_PROJ_CANCELED");

    setCreationModalVisible(false);
  }

  const projects = useSelector((state) => state.projects.data || []);
  const projectsLoaded = useSelector((state) => state.projects.loaded);

  useEffect(() => {
    document.title = "Aloa Manage";

    // Legit just going to load every single user (Since we have like...30 of them)
    // Definitley need to change this
    db.collection("users").onSnapshot(function (querySnapshot) {
      var users = {};
      querySnapshot.forEach(function (doc) {
        var data = doc.data();
        var user = {
          name: data.name,
          image: data.image,
        };
        users[doc.id] = user;
      });

      setUsers(users);
      setUsersLoaded(true);
    });
  }, []);

  function newProject() {
    window.amplitude.getInstance().logEvent("NEW_PROJ_INITIATED");

    setCreationModalVisible(true);
  }

  function handleSearchTermChange(event) {
    setSearchTerm(event.target.value);
  }

  if (projectsLoaded != true || usersLoaded != true) {
    return null;
  } else {
    //
  }

  var activeProjects = [];
  var archivedProjects = [];

  console.log("projects is ", projects);

  projects
    .sort(function (a, b) {
      return (
        (a.date_started === null) - (b.date_started === null) ||
        b.date_started.seconds - a.date_started.seconds
      );
    })
    .filter((project) =>
      project.name.toLowerCase().includes(searchTerm.toLocaleLowerCase())
    )
    .forEach((project) => {
      if (project.archived != null && project.archived === true) {
        archivedProjects.push(
          <ProjectNotificationBadge key={project.id} project={project}>
            <ProjectRow
              currentUser={currentUser}
              project={project}
              users={users}
              highlight={searchTerm}
            />
          </ProjectNotificationBadge>
        );
      } else {
        activeProjects.push(
          <ProjectNotificationBadge key={project.id} project={project}>
            <ProjectRow
              currentUser={currentUser}
              project={project}
              users={users}
              highlight={searchTerm}
            />
          </ProjectNotificationBadge>
        );
      }
    });

  //

  return (
    <div className="App">
      <div>
        <Modal
          title="New Project"
          style={{ padding: "20px" }}
          visible={creationModalVisible}
          closable={false}
          footer={null}
        >
          <ProjectCreationModal
            closeModal={handleCancel}
            currentUser={currentUser}
          />
        </Modal>

        <div className="dashContainer">
          {currentUser.acc_type == 2 && (
            <div style={{ marginBottom: "15px" }}>
              <div className="topButton" onClick={() => newProject()}>
                New Project
              </div>

              <div className="searchbar">
                <BiSearchAlt2 className="searchbar-icon" />
                <input
                  type="text"
                  className="searchbar-input"
                  value={searchTerm}
                  onChange={handleSearchTermChange}
                  placeholder="Search Projects"
                />
              </div>

              <div style={{ clear: "right" }}> </div>
            </div>
          )}

          {currentUser.acc_type != 2 && (
            <div style={{ marginBottom: "15px" }}>
              <div className="searchbar">
                <BiSearchAlt2 className="searchbar-icon" />
                <input
                  type="text"
                  className="searchbar-input"
                  value={searchTerm}
                  onChange={handleSearchTermChange}
                  placeholder="Search Projects"
                />
              </div>

              <div style={{ clear: "right" }}> </div>
            </div>
          )}

          <div
            style={{
              fontSize: 20,
              fontWeight: typography.fontWeightMedium,
              color: "#4e4e4e",
              fontFamily: "Brown",
              fontWeight: 500,
              textAlign: "left",
              marginBottom: "20px",
            }}
          >
            Your Projects
          </div>

          {activeProjects.length == 0 && (
            <div style={{ textAlign: "center", marginTop: "30px" }}>
              <div>
                <img
                  style={{ width: "80" }}
                  src={require("../../images/empty.svg")}
                />
              </div>

              <div
                style={{
                  fontFamily: "Avenir Next",
                  color: "#00000040",
                  marginTop: "5px",
                  fontSize: "18px",
                }}
              >
                No Projects
              </div>
            </div>
          )}

          <div className="projectsGrid">
            {activeProjects}

            <div style={{ clear: "both" }} />
          </div>

          <div style={{ clear: "right" }}> </div>

          <div
            style={{
              fontSize: 20,
              color: "#4e4e4e",
              fontFamily: "Brown",
              fontWeight: 500,
              textAlign: "left",
              marginBottom: "20px",
              marginTop: "40px",
            }}
          >
            Archived
            {!showArchivedVar && (
              <span
                style={{
                  cursor: "pointer",
                  fontSize: 17,
                  marginLeft: "5px",
                  color: "#1890ff",
                  fontFamily: "Brown",
                  fontWeight: 500,
                  textAlign: "left",
                  marginBottom: "20px",
                }}
                onClick={() => {
                  showArchived();
                }}
              >
                (show)
              </span>
            )}
            {showArchivedVar && (
              <span
                style={{
                  cursor: "pointer",
                  fontSize: 17,
                  marginLeft: "5px",
                  color: "#1890ff",
                  fontFamily: "Brown",
                  fontWeight: 500,
                  textAlign: "left",
                  marginBottom: "20px",
                }}
                onClick={() => {
                  showArchived();
                }}
              >
                (hide)
              </span>
            )}
          </div>

          {showArchivedVar && archivedProjects.length == 0 && (
            <div style={{ textAlign: "center", marginTop: "30px" }}>
              <div>
                <img
                  style={{ width: "80" }}
                  src={require("../../images/empty.svg")}
                />
              </div>

              <div
                style={{
                  fontFamily: "Avenir Next",
                  color: "#00000040",
                  marginTop: "5px",
                  fontSize: "18px",
                }}
              >
                Nothing Archived
              </div>
            </div>
          )}

          {showArchivedVar && archivedProjects.length != 0 && (
            <div className="projectsGrid">{archivedProjects}</div>
          )}
        </div>

        <div style={{ clear: "both" }} />

        <div style={{ marginBottom: "100px" }} />
      </div>
    </div>
  );
}
