import React from "react";
import AttachmentModal from "../../AttachmentModal";

export default function AttachmentsField({
  valueEditable,
  projectId,
  currentUser,
  collectionKey,
  checkPermissionsAndUpdateValue
}) {
  return (
    <div className="attachments">
      <AttachmentModal
        attachments={valueEditable}
        projectId={projectId}
        currentUser={currentUser}
        type={collectionKey}
        type="table"
        updateAttachments={checkPermissionsAndUpdateValue}
      />
    </div>
  );
}
