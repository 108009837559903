import object from './object';
import project from './project';
import threads from './threads';
import user from './user';
import app from './app';
import collections from "./collections"
import history from "./history"
import configs from "./configs"
import roles from "./roles"
import growth from "./growth"

export default {
    object: object,
    project: project,
    threads: threads,
    user: user,
    app: app,
    collections: collections,
    history: history,
    configs: configs,
    roles: roles,
    growth: growth
}