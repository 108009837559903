import { db } from "../../firebase";

// const xScale = 30000000; // X Scale should probably be a future parameter, or something done after fetching in the future

const hashDateScale = 1000000000;
export const hashToDate = hash => new Date(hash * hashDateScale);
export const docIdHash = date => Math.floor(date.getTime() / hashDateScale);

export const getMetricsForDateRange = (projectId, startDate, endDate) =>
  new Promise((resolve, reject) => {
    const outputData = {};

    const merticsRef = db
      .collection("projects")
      .doc(projectId)
      .collection("metrics");

    merticsRef
      .orderBy("bucketStartDate")
      .startAt(hashToDate(docIdHash(startDate)))
      .endAt(endDate)
      .get()
      .then(snapshot => {
        snapshot.docs.forEach(doc => {
          const bucket = doc.data();
          bucket.data
            .filter(item => item.date.toDate() >= startDate)
            .forEach(item => {
              outputData[item.date.toDate()] = item.data;
            });
        });

        resolve(outputData);
      })
      .catch(error => reject(error));
  });

export const getMetricsSince = async (projectId, date) =>
  await getMetricsForDateRange(projectId, date, new Date());
