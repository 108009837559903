import React, { Component } from 'react';
import { Link } from 'react-router-dom';

export default class Menu extends Component {
    render() {
        return(
            <div
                style={{
                    textAlign: "left",
                    fontFamily: "Avenir Next",
                    color: "#000000",
                    marginLeft: "20px",
                    marginBottom: "20px",
                    marginTop: "30px"
                }}
            >
                {" "}
                <Link
                  to="/"
                  style={{ textDecoration: "none", color: "#7c7c7c" }}
                >
                  {" "}Projects{" "}
                </Link>
                
                {" "}/{" "}

                <Link
                  to={"/p/" + this.props.projectId + "/"}
                  style={{ textDecoration: "none", color: "#7c7c7c" }}
                >
                  {" "}{this.props.projectName}{" "}
                </Link>
                
                {" "}/
                {" "}{this.props.page}{" "}
            </div>
        );
    }
}
            