import React, { useState } from "react";
import { Checkbox } from "antd";

export default function CheckboxField({
  field,
  currentUser,
  permissions,
  valueEditable,
  checkPermissionsAndUpdateValue,
}) {
  return (
    <div>
      <Checkbox
        checked={valueEditable}
        onChange={(e) => checkPermissionsAndUpdateValue(e.target.checked)}
      >
      </Checkbox>
    </div>
  );
}
