import firebase from "firebase";
require("firebase/firestore");

// Initialize Firebase
var config = {
  apiKey: "AIzaSyAjU2xO3yMq8VkrpGLU6NZf0cR85HhugSA",
  authDomain: "hermes-50f48.firebaseapp.com",
  databaseURL: "https://hermes-50f48.firebaseio.com",
  projectId: "hermes-50f48",
  storageBucket: "hermes-50f48.appspot.com",
  messagingSenderId: "1068951380540",
};
firebase.initializeApp(config);
// firebase.functions().useFunctionsEmulator("http://localhost:5001") 

/* 
firebase
  .firestore()
  .enablePersistence({ synchronizeTabs: true })
  .catch(function (err) {
    if (err.code == "failed-precondition") {
      // Multiple tabs open, persistence can only be enabled
      // in one tab at a a time.
      // ...
      console.log("failed mult tab: failed-precondition")
      console.log(err);
    } else if (err.code == "unimplemented") {
      // The current browser does not support all of the
      // features required to enable persistence
      // ...
      console.log("failed mult tab: unimplemented")
    }
  }); */

export const db = firebase.firestore();
export const auth = firebase.auth();
export default firebase;
