import React, { useState } from "react";
import { DatePicker, message } from "antd";
import moment from "moment";
import { newTimestampFromDate } from "../../utils";

export default function DateField({
  field,
  currentUser,
  permissions = {},
  valueEditable,
  checkPermissionsAndUpdateValue,
}) {
  const [dropdownActive, setDropdownActive] = useState(false);

  return (
    <div>
      <div className="dateValue">
        {valueEditable != null && (
          <div>
            <div
              onClick={() => {
                if (!field.editingDisabled) {
                  setDropdownActive(true);
                } else {
                  message.warning("Editing is disabled on this field");
                }
              }}
            >
              {moment(valueEditable.toDate()).format("MMMM Do, YYYY")}
              <span style={{ color: "#ff6c17" }}>
                {" "}
                {moment().diff(moment(valueEditable.toDate())) > 0 &&
                  field.fieldData.type == "date_with_age" && (
                    <span>
                      {" "}
                      ({moment().diff(
                        moment(valueEditable.toDate()),
                        "days"
                      )}{" "}
                      days old)
                    </span>
                  )}
              </span>
            </div>
            <div
              style={{
                visibility: "hidden",
                width: "0px",
                height: "0px",
              }}
            >
              <DatePicker
                allowClear={false}
                open={dropdownActive}
                value={moment(valueEditable.toDate())}
                onOpenChange={(visible) => setDropdownActive(visible)}
                onChange={(e) => {
                  console.log(e);
                  if (e != null) {
                    checkPermissionsAndUpdateValue(
                      newTimestampFromDate(e.toDate())
                    );

                    setDropdownActive(false);
                  }
                }}
                format={"MM/DD/YYYY"}
              />{" "}
            </div>
          </div>
        )}

        {valueEditable == null && (
          <>
            <div
              style={{ color: "#5f6bff" }}
              onClick={() => {
                if (!field.editingDisabled) {
                  setDropdownActive(true);
                } else {
                  message.warning("Editing is disabled on this field");
                }
              }}
            >
              Select Date
            </div>
            <div
              style={{
                visibility: "hidden",
                width: "0px",
                height: "0px",
                position: "absolute",
              }}
            >
              <DatePicker
                allowClear={false}
                style={{ marginLeft: "-105px" }}
                open={dropdownActive}
                value={
                  valueEditable != null
                    ? moment(valueEditable.toDate())
                    : undefined
                }
                onOpenChange={(visible) => setDropdownActive(visible)}
                onChange={(e) => {
                  console.log(e);
                  if (e != null) {
                    checkPermissionsAndUpdateValue(
                      newTimestampFromDate(e.toDate())
                    );

                    setDropdownActive(false);
                  }
                }}
                format={"MM/DD/YYYY"}
              />{" "}
            </div>
          </>
        )}
      </div>
    </div>
  );
}
