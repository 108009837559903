import React, { useState, useEffect } from "react";

import _ from "lodash";

import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

import { Input, Select, Tooltip, Popover } from "antd";

export default function ColumnsEditor({
  objectEditable,
  columns,
  updateField,
  pathToUpdate,
}) {
  const [columnsEditable, setColumnsEditable] = useState(columns);

  var fields = objectEditable?.fields || {};

  useEffect(() => {
    // If the parent has been updated, let's update the child
    if (!_.isEqual(columnsEditable, columns)) {
      var columnsTemp = _.cloneDeep(columns);

      // We should remove any things that don't exist
      columnsTemp.forEach(function(columnId) {
        if(!fields[columnId]) {
          columnsTemp = columnsTemp.filter(e => e !== columnId)
        }
      })

      setColumnsEditable(columnsTemp);
    } else {
      // We should remove any things that don't exist
      var columnsTemp = _.cloneDeep(columns);
      if(columnsTemp) {
        columnsTemp.forEach(function(columnId) {
          if(!fields[columnId]) {
            columnsTemp = columnsTemp.filter(e => e !== columnId)
          }
        })
  
        setColumnsEditable(columnsTemp);
      }
      
    }
  }, [columns]);

  function sendUpdate() {
    updateField(pathToUpdate, columnsEditable);
  }

  function deleteValue(index) {
    var columnsEditableTemp = _.cloneDeep(columnsEditable);

    if (index > -1) {
      columnsEditableTemp.splice(index, 1);
    }
    setColumnsEditable(columnsEditableTemp);
  }

  useEffect(() => {
    // If we upated the child, let's update the parent
    if (!_.isEqual(columnsEditable, columns)) {
      updateField(pathToUpdate, columnsEditable);
    }
  }, [columnsEditable]);

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  function onDragEnd(result) {
    if (!result.destination) {
      return;
    }

    if (result.destination.index === result.source.index) {
      return;
    }

    const newColumnsOrder = reorder(
      _.cloneDeep(columnsEditable),
      result.source.index,
      result.destination.index
    );

    setColumnsEditable(newColumnsOrder);
  }

  return (
    <div>
      <div className="fields">
        <div style={{ padding: "0px 10px" }} className="data-item">
          <div className="data-item-value">
            <div className="selection-options-box">
              <DragDropContext onDragEnd={onDragEnd}>
                <Droppable droppableId={"table-columns-editor"}>
                  {(provided) => (
                    <div ref={provided.innerRef} {...provided.droppableProps}>
                      {columnsEditable?.map(function (id, index) {
                        var field = fields[id];

                        if (field) {
                          return (
                            <ColumnOption
                              title={field.title}
                              key={id}
                              deleteValue={deleteValue}
                              index={index}
                              id={id}
                            />
                          );
                        }
                      })}
                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
              </DragDropContext>

              <Popover
                content={
                  <div>
                    {Object.keys(fields)
                      .filter(function (fieldId) {
                        if (
                          columnsEditable &&
                          !columnsEditable.includes(fieldId)
                        ) {
                          return true;
                        }
                      })
                      .map(function (fieldId) {
                        var field = fields[fieldId];
                        console.log("unavailable: ", fieldId)
                        return (
                          <div
                            key={"available_columns_" + fieldId}
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                              var columnsEditableTemp = _.cloneDeep(
                                columnsEditable
                              );
                              columnsEditableTemp.push(fieldId);

                              setColumnsEditable(columnsEditableTemp);
                            }}
                          >
                            {field.title}
                          </div>
                        );
                      })}
                  </div>
                }
                title="Add a column"
                trigger="click"
              >
                <div className="add-option-button">
                  <img
                    className="add-option-icon"
                    src={require("../../../../images/plus_purp.svg")}
                  />
                  Add Column
                </div>
              </Popover>
            </div>
          </div>
        </div>
      </div>{" "}
    </div>
  );
}

export function ColumnOption({ id, title, index, deleteValue }) {
  return (
    <Draggable draggableId={id} index={index}>
      {(provided) => (
        <div
          ref={provided.innerRef}
          {...provided.draggableProps}
          className="selection-option"
        >
          <img
            {...provided.dragHandleProps}
            className="selection-option-dragger"
            src={require("../../../../images/dragger_option.svg")}
          />
          <div className={"selection-option-data"}>{title}</div>
          <img
            onClick={() => deleteValue(index)}
            className="selection-option-delete"
            src={require("../../../../images/trashv3_icon.svg")}
          />
        </div>
      )}
    </Draggable>
  );
}

var isArrayEqual = function (x, y) {
  var equal = true;

  if (x == null && y != null) {
    return false;
  }

  if (x != null && y == null) {
    return false;
  }

  if (x == null && y == null) {
    return true;
  }

  if (x.length != y.length) {
    equal = false;
  }

  var i = 0;

  x.forEach(function (x_object) {
    if (_.isEqual(x_object, y[i])) {
      i = i + 1;
    } else {
      equal = false;
    }
  });

  return equal;
};
