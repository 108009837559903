import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import _ from 'lodash'

import './Growth.scss'

import { Alert, Popconfirm } from 'antd'

import { init } from 'emailjs-com'
import emailjs from 'emailjs-com'

import { updateDataForUser } from '../../actions/user'

import { GiftTwoTone } from '@ant-design/icons'

init('user_v3wAFLY5HiuAtYbX7PRD0')

export default function Growth ({}) {
  const offers = useSelector(state => state.growth.offers)

  const currentUser = useSelector(state => state.currentUser)

  const [visible, setVisible] = useState(false)

  const handleClose = () => {
    setVisible(false)
  }

  if (offers == null) {
    return <div></div>
  }

  // Order offers
  var offersTemp = [...offers]
  offersTemp = offersTemp.sort((a, b) => (a.order > b.order ? 1 : -1))

  return (
    <div className='growth-home'>
      <div className='growth-header'>
        We do software, they do other cool stuff
      </div>

      <div className='growth-desc'>
        We believe in a world where anyone can innovate freely. It’s our job to
        ensure we guide you wherever needed. We’ve vetted more than just our
        Partner Firms, but all of the resources you need to succeed. Welcome to
        a new world of innovation, welcome to Aloa.{' '}
      </div>

      <div className='offers-section'>
        {offersTemp.map(function (offer) {
          return (
            <OfferCard
              offer={offer}
              currentUser={currentUser}
              setVisible={setVisible}
            />
          )
        })}
      </div>
    </div>
  )
}

export function OfferCard ({ offer, currentUser, setVisible }) {
  const dispatch = useDispatch()

  const [confirmationActive, setConfirmationActive] = useState(false)

  function requestGrowth (feature_id) {
    var templateParams = {
      user_name: currentUser.name,
      user_id: currentUser.id,
      date: new Date(),
      growth_feature_id: feature_id
    }

    var newGrowthOffers = currentUser.growth_offers || {
      redeemed: []
    }

    newGrowthOffers.redeemed.push(offer.id)

    emailjs.send('service_d87arcg', 'template_qc3nz71', templateParams).then(
      function (response) {
        var data = {
          growth_offers: newGrowthOffers
        }

        dispatch(updateDataForUser(data))

        setVisible(true)
      },
      function (error) {}
    )
  }

  return (
    <div className='offer-card'>
     {offer.usedByAloa &&
         <div className="used-by">
         Used by Aloa
       </div>
     }
      <img
        src={offer.image}
        style={offer.imageHeight != null ? { height: offer.imageHeight } : {}}
        className='offer-image'
      />
      <div className='offer-title'>{offer.title}</div>
      <div className='offer-summary'>{offer.summary}</div>
      <div className='offer-details'>
        {offer.benefits.map(function (benefit) {
          return (
            <div className='perk'>
              <div className='desc-check' style={{ fontSize: '6px' }}>
                ⚫
              </div>
              <div className='perk-description'>{benefit}</div>
            </div>
          )
        })}
      </div>

      <div className='perks-title'>Perks</div>
      <div className='perks-section'>
        {offer.perks.map(function (perk) {
          return (
            <div className='perk'>
              <img
                className='perk-check'
                src={require('../../images/check_blue.svg')}
              />
              <div className='perk-description'>{perk}</div>
            </div>
          )
        })}
      </div>

      {!confirmationActive &&
        !currentUser.growth_offers?.redeemed?.includes(offer.id) && (
          <Popconfirm
            onConfirm={() => {
              requestGrowth(offer.title)
              setConfirmationActive(true)
            }}
            okText='Yes'
            cancelText='No'
            title={
              <div style={{ maxWidth: '300px' }}>
                We will notify your strategist of your interest and they will
                reach out to you. Do you want to proceed?
              </div>
            }
            icon={<GiftTwoTone />}
          >
            <div className='redeem-button'>Get Started</div>
          </Popconfirm>
        )}

      {confirmationActive ? (
        <Alert
          style={{ marginTop: '20px' }}
          message='Your strategist will be in touch!'
          description={
            <div style={{ textAlign: 'left' }}>
              Thank you for expressing interest in this offer, your strategist
              will be in touch shortly. No need to do anything else :)
            </div>
          }
          type='success'
          showIcon
          closable
        />
      ) : null}

      {confirmationActive ||
        (currentUser.growth_offers?.redeemed?.includes(offer.id) && (
          <div className='redeem-button redeemed'>Offer Redeemed</div>
        ))}
    </div>
  )
}
