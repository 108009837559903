export const items = {
  bug: "bug",
  bugPriority: "bugPriority",
  bugStatus: "bugStatus",
  clarification: "clarification",
  comment: "comment",
  date: "date",
  feedback: "feedback",
  feedbackStatus: "feedbackStatus",
  file: "file",
  milestone: "milestone",
  milestoneName: "milestoneName",
  qa: "qa",
  standup: "standup",
  status: "status",
  statusName: "statusName"
};

export const types = {
  new: "new",
  update: "update"
};

export const url = process.env.REACT_APP_NOTIFICATION_URL;
