export const startDeepLinkAction = () => {
  return {
    type: "START_DEEP_LINK_ACTION"
  };
};

export const finishDeepLinkAction = () => {
  return {
    type: "FINISH_DEEP_LINK_ACTION"
  };
};
