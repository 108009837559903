import React from "react";
import { useDispatch } from "react-redux";
import { Menu } from "antd";
import {
  CheckCircleOutlined,
  DeleteOutlined,
  NotificationTwoTone,
} from "@ant-design/icons";
import {
  deleteNotification,
  updateNotificationStatus,
} from "../../actions/notifications";

export default function NotificationMenu({
  notification,
  setMenuVisible,
  afterSelect,
}) {
  const DELETE_NOTIFICATION_KEY = "delete_notification";
  const MARK_UNREAD_KEY = "mark_unread";

  const dispatch = useDispatch();

  const handleClick = ({ key, domEvent }) => {
    domEvent.stopPropagation();
    if (key === DELETE_NOTIFICATION_KEY) {
      dispatch(deleteNotification(notification));
    } else if (key === MARK_UNREAD_KEY) {
      if (notification.status === "read") {
        dispatch(updateNotificationStatus(notification, "unread"));
      } else {
        dispatch(updateNotificationStatus(notification, "read"));
      }
    }
    setMenuVisible(false);
    afterSelect();
  };

  return (
    <Menu onClick={handleClick} selectable={false}>
      <Menu.Item key={MARK_UNREAD_KEY}>
        <CheckCircleOutlined />
        {notification.status === "read" ? "Mark as Unread" : "Mark as Read"}
      </Menu.Item>
      <Menu.Item key={DELETE_NOTIFICATION_KEY}>
        <DeleteOutlined />
        Delete Notification
      </Menu.Item>
    </Menu>
  );
}
