import { createReducer } from "@reduxjs/toolkit";

// Case reducers
function loadToCollection(collectionsState, action) {
  if (collectionsState[action.projectId] == null) {
    collectionsState[action.projectId] = {};
  }

  if (collectionsState[action.projectId][action.collectionKey] == null) {
    collectionsState[action.projectId][action.collectionKey] = {};
  }

  collectionsState[action.projectId][action.collectionKey] = {
    ...action.objects,
  };
}

function addToCollection(collectionsState, action) {
  if (!collectionsState[action.projectId]) {
    collectionsState[action.projectId] = {};
  }

  if (!collectionsState[action.projectId][action.collectionKey]) {
    collectionsState[action.projectId][action.collectionKey] = {};
  }

  collectionsState[action.projectId][action.collectionKey][action.object.id] =
    action.object;
}

function updateInCollection(collectionsState, action) {
  if (!collectionsState[action.projectId]) {
    collectionsState[action.projectId] = {};
  }

  if (!collectionsState[action.projectId][action.collectionKey]) {
    collectionsState[action.projectId][action.collectionKey] = {};
  }

  collectionsState[action.projectId][action.collectionKey][action.object.id] =
    action.object;
}

function removeFromCollection(collectionsState, action) {
  delete collectionsState[action.projectId][action.collectionKey][
    action.object.id
  ];
}

function batchRemoveFromCollection(collectionsState, action) {
  action.items.forEach((item) => {
    delete collectionsState[action.projectId][item.collectionKey][
      item.object.id
    ];
  });
}

function incrementBadgeForObject(collectionsState, action) {
  var tempObject = Object.assign(
    {},
    collectionsState[action.projectId][action.collectionKey][action.objectId]
  );

  if (tempObject.badges == null) {
    tempObject["badges"] = {};
  }

  tempObject["badges"][action.field] =
    (tempObject["badges"][action.field] || 0) + action.amount;

  collectionsState[action.projectId][action.collectionKey][
    action.objectId
  ] = tempObject;
}

function addThreadTimestampForObject(collectionsState, action) {
  var tempObject = Object.assign(
    {},
    collectionsState[action.projectId][action.collectionKey][action.objectId]
  );

  var thread = action.thread;

  if (tempObject.thread_timestamps == null) {
    tempObject["thread_timestamps"] = [];
  }

  tempObject["thread_timestamps"].push({
    timestamp: thread.timestamp,
    thread: thread.id,
  });

  collectionsState[action.projectId][action.collectionKey][
    action.objectId
  ] = tempObject;
}


function removeThreadTimestampForObject(collectionsState, action) {
  var tempObject = Object.assign(
    {},
    collectionsState[action.projectId][action.collectionKey][action.objectId]
  );

  var thread = action.thread;

  if (tempObject.thread_timestamps == null) {
    tempObject["thread_timestamps"] = [];
  }

  var index = tempObject.thread_timestamps.map(x => {
    return x.thread;
  }).indexOf(thread.id);
  
  tempObject.thread_timestamps.splice(index, 1);

  collectionsState[action.projectId][action.collectionKey][
    action.objectId
  ] = tempObject;
}


function updateThreadReadTimestampForObject(collectionsState, action) {
  var tempObject = Object.assign(
    {},
    collectionsState[action.projectId][action.collectionKey][action.objectId]
  );

  if (tempObject.thread_read_timestamps == null) {
    tempObject["thread_read_timestamps"] = {};
  }

  tempObject["thread_read_timestamps"][action.currentUserId] = action.timestamp

  collectionsState[action.projectId][action.collectionKey][
    action.objectId
  ] = tempObject;
}

// Slice reducer
export default createReducer(
  {},
  {
    LOAD_TO_COLLECTION: loadToCollection,
    ADD_TO_COLLECTION: addToCollection,
    UPDATE_IN_COLLECTION: updateInCollection,
    REMOVE_FROM_COLLECTION: removeFromCollection,
    BATCH_REMOVE_FROM_COLLECTION: batchRemoveFromCollection,
    INCREMENT_BADGE_FOR_OBJECT: incrementBadgeForObject,
    ADD_THREAD_TIMESTAMP_FOR_OBJECT: addThreadTimestampForObject,
    REMOVE_THREAD_TIMESTAMP_FOR_OBJECT: removeThreadTimestampForObject,
    UPDATE_THREADREAD_TIMESTAMP_FOR_OBJECT: updateThreadReadTimestampForObject
  }
);
