import { createReducer } from "@reduxjs/toolkit";

// Case reducers
function loadThreads(threadsState, action) {
  if (!threadsState[action.objectId]) {
    threadsState[action.objectId] = {};
  }

  threadsState[action.objectId] = {
    ...action.threads,
  };
}

function addThread(threadsState, action) {
  if (!threadsState[action.objectId]) {
    threadsState[action.objectId] = {};
  }

  threadsState[action.objectId][action.thread.id] = action.thread;
}

function updateThread(threadsState, action) {
  if (!threadsState[action.objectId]) {
    threadsState[action.objectId] = {};
  }

  threadsState[action.objectId][action.thread.id] = action.thread;
}

function deleteThread(threadsState, action) {

  if(threadsState[action.objectId][action.thread.id]) {
    delete threadsState[action.objectId][action.thread.id]
  }
}

// Slice reducer
export default createReducer(
  {},
  {
    LOAD_THREADS: loadThreads,
    ADD_THREAD: addThread,
    UPDATE_THREAD: updateThread,
    DELETE_THREAD: deleteThread,
  }
);
