import { id, newTimestampFromDate } from "../components/utils";
import firebase, { db } from "../firebase.js";
import object from "./object";
import _ from "lodash";
import componentsLocal from "../components/ComponentConfigs";

export const loadSections = (sections, projectId) => {
  return {
    type: "LOAD_SECTIONS_FOR_PROJECT",
    sections: sections,
    projectId: projectId,
  };
};

export const loadObjectConfigs = (objects, projectId) => {
  return {
    type: "LOAD_OBJECT_CONFIGS",
    objects: objects,
    projectId: projectId,
  };
};

export const updateObjectConfigsForProject =
  (projectId, objectConfig) => (dispatch) =>
    new Promise((resolve, reject) => {
      db.collection("projects")
        .doc(projectId)
        .collection("objects")
        .doc(objectConfig.info.collectionKey)
        .set(objectConfig, { merge: true })
        .then(() => {
          resolve();
        })
        .catch(() => reject("No bueno"));
    });

export const updateSectionConfigsForProject =
  (projectId, sectionConfig) => (dispatch) =>
    new Promise((resolve, reject) => {
      db.collection("projects")
        .doc(projectId)
        .collection("sections")
        .doc(sectionConfig.info.collectionKey)
        .set(sectionConfig)
        .then(() => {
          resolve();
        })
        .catch(() => reject("No bueno"));
    });

export const removeSectionConfigForObject =
  (sectionConfig, collectionKey, projectId) => (dispatch) =>
    new Promise((resolve, reject) => {
      try {
        dispatch(
          object.deleteObjectFromFirebase(
            collectionKey,
            sectionConfig,
            projectId,
            "sections"
          )
        ).then(function () {
          resolve();
        });
      } catch (error) {
        reject(error);
      }
    });

export const fetchSectionsFromFirebase =
  (projectId, setUnsubscribe) => (dispatch) =>
    new Promise((resolve) => {
      var sectionsRef = db
        .collection("projects")
        .doc(projectId)
        .collection("sections");

      const unsubscribe = sectionsRef.onSnapshot((snapshot) => {
        var sections = {};

        let updateComesFromLocal = false;

        snapshot.forEach((doc) => {
          updateComesFromLocal =
            doc.metadata.hasPendingWrites || updateComesFromLocal;

          if (doc.exists) {
            sections[doc.id] = doc.data();
          }
        });

        // See https://firebase.google.com/docs/firestore/query-data/listen#events-local-changes
        // Firebase is firing for local changes, but we already handle local updates through redux actions directly
        // Our redux updates fire faster and this way keeps our coupling with firebase down
        // So to avoid duplicate loadCollection calls, we should only reload collections when the *remote* data changes
        if (!updateComesFromLocal) {
          // Go through the new components and the objects and swap them out if there are any
          // that exist
          var copyOfLocalComponents = _.cloneDeep(componentsLocal);

          if (!_.isEmpty(sections)) {
            Object.keys(sections).forEach(function (sectionKey) {
              copyOfLocalComponents[sectionKey] = sections[sectionKey];
            });
          }

          dispatch(loadSections(copyOfLocalComponents, projectId));
        }

        setUnsubscribe(unsubscribe);

        resolve(`Loaded section configs for ${projectId}`);
      });
    });

export default {
  fetchSectionsFromFirebase,
  updateObjectConfigsForProject,
  updateSectionConfigsForProject,
  removeSectionConfigForObject,
};
