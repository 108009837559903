import firebase, { db } from "../firebase.js";
import { changeNetworkStatus } from "./project";
import { id } from "../components/utils";

export const addObjectToFirebase = (object, projectId, category, customId) => (
  dispatch,
  getState
) =>
  new Promise((resolve, reject) => {
    if (projectId != null) {
      const copiedObject = Object.assign({}, object);

      copiedObject["last_updated_timestamp"] = new Date();
      copiedObject["created_by_user"] = getState().currentUser.id;
      copiedObject["last_updated_user"] = getState().currentUser.id;

      dispatch(changeNetworkStatus(false, "Saving...."));

      var newId = customId != null ? customId : id();

      db.collection("projects")
        .doc(projectId)
        .collection(category)
        .doc(newId)
        .set(copiedObject)
        .then(() => {
          resolve();
          //  dispatch(addToHistory(object, projectId, category, "add"));
          setTimeout(() => {
            dispatch(changeNetworkStatus(true, "All Changes Saved"));
          }, 1000);
        })
        .catch((error) => {
          dispatch(changeNetworkStatus(false, "Error Saving, Please Refresh!"));
          reject(error);
        });
    } else {
      const copiedObject = Object.assign({}, object);

      copiedObject["last_updated_timestamp"] = new Date();
      copiedObject["created_by_user"] = getState().currentUser.id;
      copiedObject["last_updated_user"] = getState().currentUser.id;
      dispatch(changeNetworkStatus(false, "Saving...."));

      var newId = copiedObject.id ? copiedObject.id : id();

      db.collection("projects")
        .doc(newId)
        .set(copiedObject)
        .then(() => {
          resolve();
          setTimeout(() => {
            dispatch(changeNetworkStatus(true, "All Changes Saved"));
          }, 1000);
        })
        .catch((error) => {
          dispatch(changeNetworkStatus(false, "Error Saving, Please Refresh!"));
          reject(error);
        });
    }
  });

export const deleteObjectFromFirebase = (
  object_id,
  object,
  projectId,
  category
) => (dispatch, getState) =>
  new Promise((resolve, reject) => {
    dispatch(changeNetworkStatus(false, "Saving...."));
    var newId = id();

    var logObject = {
      projectId: projectId,
      collectionName: category,
      documentId: object_id,
      eventId: newId,
      eventType: "delete",
      timestamp: new Date(),
      user: getState().currentUser.id,
      oldValue: object,
    };

    db.collection("projects")
      .doc(projectId)
      .collection(category)
      .doc(object_id)
      .delete()
      .then(function () {
        db.collection("projects")
          .doc(projectId)
          .collection("history")
          .doc(newId)
          .set(logObject)
          .then(() => {
            resolve();
          })
          .catch(() => {});
      })
      .catch(function (error) {
        reject(error);
      });
  });

export const batchDeleteObjectsFromFirebase = (items, projectId) => (
  dispatch,
  getState
) => {
  dispatch(changeNetworkStatus(false, "Saving...."));
  var newId = id();

  const batch = db.batch();

  items.forEach((item) => {
    const projectRef = db.collection("projects").doc(projectId);
    const itemRef = projectRef
      .collection(item.collectionKey)
      .doc(item.object.id);
    batch.delete(itemRef);

    const logObject = {
      projectId: projectId,
      collectionName: item.collectionKey,
      documentId: item.object.id,
      eventId: newId,
      eventType: "delete",
      timestamp: new Date(),
      user: getState().currentUser.id,
      oldValue: item.object,
    };

    const historyRef = db
      .collection("projects")
      .doc(projectId)
      .collection("history")
      .doc(newId);
    batch.set(historyRef, logObject);
  });

  return batch.commit();
};

export const deleteAttachmentFromFirebase = (attachment, projectId, next) => {
  return function (dispatch) {
    dispatch(changeNetworkStatus(false, "Saving...."));

    var attachmentsRef = firebase
      .storage()
      .ref("projects/" + projectId + "/" + attachment.id);

    // Delete the file
    attachmentsRef
      .delete()
      .then(
        function () {
          if (next) {
            next();
          }

          setTimeout(function () {
            dispatch(changeNetworkStatus(true, "All Changes Saved"));
          }, 1000);
        }.bind(this)
      )
      .catch(
        function (error) {
          if (next) {
            next();
          }

          dispatch(changeNetworkStatus(false, "Error Saving, Please Refresh!"));
        }.bind(this)
      );
  };
};

export const updateObjectInFirebase = (object, projectId, category, next) => {
  return function (dispatch, getState) {
    const copiedObject = Object.assign({}, object);

    if (category === "milestones") {
      delete copiedObject["items"];
    }

    copiedObject["last_updated_timestamp"] = new Date();
    copiedObject["last_updated_user"] = getState().currentUser.id;

    dispatch(changeNetworkStatus(false, "Saving...."));

    var docRef;

    if (category == "projects") {
      docRef = db.collection(category).doc(projectId);
    } else {
      docRef = db
        .collection("projects")
        .doc(projectId)
        .collection(category)
        .doc(copiedObject.id);
    }

    return docRef
      .update(copiedObject)
      .then(() => {
        if (next) next();
        setTimeout(() => {
          //       dispatch(addToHistory(object, projectId, category, "update"));
          dispatch(changeNetworkStatus(true, "All Changes Saved"));
        }, 1000);
      })
      .catch(() => {
        // The document probably doesn't exist.
        dispatch(changeNetworkStatus(false, "Error Saving, Please Refresh!"));
      });
  };
};

export const addToHistory = (object, projectId, category, changeType) => (
  dispatch,
  getState
) =>
  new Promise((resolve, reject) => {
    var newId = id();

    var changeObject = {};
    changeObject["timestamp"] = new Date();
    changeObject["user"] = getState().currentUser.id;
    changeObject["changeType"] = changeType;
    changeObject["category"] = category;
    changeObject["object"] = object;

    db.collection("projects")
      .doc(projectId)
      .collection("history")
      .doc(newId)
      .set(changeObject)
      .then(() => {
        resolve();
        setTimeout(() => {
          dispatch(changeNetworkStatus(true, "All Changes Saved"));
        }, 1000);
      })
      .catch((error) => {
        dispatch(changeNetworkStatus(false, "Error Saving, Please Refresh!"));
        reject(error);
      });
  });

export default {
  addObjectToFirebase,
  deleteObjectFromFirebase,
  updateObjectInFirebase,
  addToHistory,
  deleteAttachmentFromFirebase,
};
