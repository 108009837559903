import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Input } from "antd";
import { toggleSearchActive } from "../../actions/search";
import {BiSearchAlt2} from "react-icons/bi";

const { Search } = Input;

export default function SearchInput() {
  const searchState = useSelector((state) => state.search);
  const dispatch = useDispatch();

  return (
    <div className="searchbar"  style={{ width: "250px" }}>
      <BiSearchAlt2 className="searchbar-icon" />
      <input
        type="text"
        className="searchbar-input"
        value={searchState.value}
        onFocus={(e) => {
          e.target.blur();
          dispatch(toggleSearchActive());
        }}
        placeholder="Search this project"
      />
    </div>
  );
}
