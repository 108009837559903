import React from "react";
import { useSelector } from "react-redux";
import * as sanitizeHtml from "sanitize-html";
import styled from "styled-components";
import { createTagSpan } from "./TextField/helpers";
import "./TextField/TextField.scss";

export default function MessageBubbleContent({ message }) {
  let content = message.content;

  const collaborators = useSelector((state) => {
    const projectId = state.project?.data?.id;
    if (projectId) {
      return Object.values(state.collaborators[projectId]).map(
        (entry) => entry.data
      );
    }
    return {};
  });

  const currentUser = useSelector((state) => state.currentUser);

  const tagPhrases = [...content.matchAll(/@([\w|]+)/g)];

  tagPhrases.forEach((phrase) => {
    const user = collaborators.find(
      (collaborator) => collaborator.id === phrase[1]
    );

    if (user) {
      const isCurrentUser = user.id === currentUser.id;
      content = content.replace(phrase[0], createTagSpan(user, isCurrentUser));
    }
  });

  content = content.replace(
    /((http|https|ftp|ftps)\:\/\/[a-zA-Z0-9\-\.]+\.[a-zA-Z]{2,3}(\/\S*)?)/g,
    '<a target="_blank" href="$1">$1</a>'
  );

  return (
    <>
      {message.content && (
        <span
          dangerouslySetInnerHTML={{
            __html: sanitizeHtml(content, {
              allowedTags: ["a", "span"],
              allowedAttributes: {
                a: ["href", "target"],
                span: ["class", "id"],
              },
            }),
          }}
        ></span>
      )}
    </>
  );
}

const CommentBubble = styled.span``;
