import { combineReducers } from "redux";
import { currentUser } from "./user";
import { projects, network_status, project } from "./project";
import threads from "./threads";
import { page, loading } from "./app";
import collaborators from "./collaborator";
import features from "./features";
import collections from "./collections";
import doDeepLinkAction from "./doDeepLinkAction";
import { app } from "./app";
import history from "./history";
import configs from "./configs";
import roles from "./roles";
import growth from "./growth";
import notifications, { notificationsState } from "./notifications";
import search from "./search";

export const initialState = () => {
  return {};
};

export default combineReducers({
  currentUser,
  projects,
  project,
  network_status,
  threads,
  collaborators,
  page,
  loading,
  features,
  collections,
  doDeepLinkAction,
  app,
  history,
  configs,
  roles,
  notifications,
  notificationsState,
  search,
  growth,
});
