import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ReactDOM from "react-dom";
import "./index.css";
import * as serviceWorker from "./serviceWorker";
import { auth, db } from "./firebase.js";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";

// Redux stuff
import reducers from "./reducers/index";
import { loginUser } from "./actions/auth";
import { fetchAppVersion } from "./actions/app";
import { Provider } from "react-redux";
import { createStore, applyMiddleware } from "redux";
import thunkMiddleware from "redux-thunk";

import Auth from "./Auth";

// Actual Components
import { Login } from "./components/Auth";
import Dashboard from "./components/Dashboard";
import Account from "./components/Account";
import { SignUp } from "./components/SignUp";
import App from "./components/App";
import TopBar from "./components/TopBar";
import NotFound from "./components/404";
import ErrorBoundary from "./components/ErrorBoundary";
import Loading from "./components/Loading";
import EulaDialog from "./components/EulaDialog";
import Notifications from "./components/Notifications";
import Growth from "./components/Growth";
import Toggle, { On, Off, featureTypes } from "./components/FeatureToggle";
import fetchFeatures from "./actions/features";
import { updateUserAppVersion } from "./actions/user";

import QuickSwitcher from "./components/Objects/QuickSwitcher";

import actions from "./actions";

import { GlobalHotKeys } from "react-hotkeys";

const auth0 = new Auth();
const store = createStore(reducers, applyMiddleware(thunkMiddleware));
store.dispatch(fetchFeatures());

var user = auth.currentUser;

const basename = process.env.REACT_APP_BASE_ROUTE
  ? process.env.REACT_APP_BASE_ROUTE
  : "";

auth0
  .checkSession()
  .then((result) => {
    const { idToken } = result;
    if (idToken) {
      auth0.handleFirebaseToken(idToken);
    }
  })
  .catch((error) => {
    if (error.error === "login_required") {
      auth.signOut();
      if (!user) {
        if (window.location.pathname.includes("register")) {
          window.location.href = `${process.env.REACT_APP_ACCOUNTS_URL}${window.location.pathname}`;
        } else if (!window.location.pathname.includes("callback")) {
          let redirectLocation = `${process.env.REACT_APP_ACCOUNTS_URL}/login?ref=manage`;

          if (window.location.pathname !== "/") {
            redirectLocation += `&path=${window.location.pathname}`;
          }

          if (window.location.search !== "") {
            redirectLocation += `&query=${window.location.search}`;
          }

          window.location.href = redirectLocation;
        }
      }
    }
  });

const keyMap = {
  PROJECT_QUICK_SWITCH: ["command+k"],
};

const AppNotLoggedInRouter = () => (
  <BrowserRouter basename={basename}>
    <Switch>
      <Route
        exact
        path="/register/:inviteCode"
        render={(props) => (
          <SignUp store={store} inviteCode={props.match.params.inviteCode} />
        )}
      />
      <Route
        exact
        path="/callback"
        render={(props) => {
          auth0
            .handleAuthentication(props)
            .then(() => {
              props.history.push("/");
            })
            .catch((error) => {
              props.history.push("/");
            });
          return <Loading />;
        }}
      />
      <Route path="*" render={(props) => <Login store={store} />} />
    </Switch>
  </BrowserRouter>
);

function AppRouter({ }) {
  const [quickSwitcherActive, setQuickSwitcherActive] = useState(false);

  function toggleQuickSwitcher() {
    setQuickSwitcherActive(!quickSwitcherActive);
  }

  const handlers = {
    PROJECT_QUICK_SWITCH: toggleQuickSwitcher,
  };

  return (
    <BrowserRouter basename={basename}>
      <div>
        <GlobalHotKeys allowChanges={true} handlers={handlers} keyMap={keyMap}>
          <TopBar />
        </GlobalHotKeys>
        {quickSwitcherActive && (
          <QuickSwitcher setQuickSwitcherActive={setQuickSwitcherActive} />
        )}
        <Toggle feature={featureTypes.NATIVE_NOTIFICATIONS}>
          <On>
            <Notifications />
          </On>
          <Off />
        </Toggle>
        <EulaDialog />
        <Switch>
          <Route exact path="/" component={Dashboard} />
          <Route path="/account" component={Account} />
          <Route path="/growth" component={Growth} />
          <Route path="/p/:projectId" component={App} />
          <Route path="/register" component={Dashboard} />
          <Route
            path="/callback"
            render={(routeProps) => {
              const params = new URLSearchParams(routeProps.location.search);
              let redirectUrl = "/";

              if (params.get("path")) {
                redirectUrl = params.get("path");
                if (params.get("query")) {
                  redirectUrl += params.get("query");
                }
              }

              return <Redirect to={redirectUrl} />;
            }}
          />
          <Route path="*" render={(props) => <NotFound store={store} />} />
        </Switch>
      </div>
    </BrowserRouter>
  );
}

function LoginRedirect() {
  return (
    <BrowserRouter basename={basename}>
      <Switch>
        <Route
          exact
          path="/callback"
          render={(props) => {
            auth0
              .handleAuthentication(props)
              .then(() => {
                props.history.push("/");
              })
              .catch((error) => {
                props.history.push("/");
              });
            return <Loading />;
          }}
        />
        <Route exact path="/">
          <Loading />
        </Route>
      </Switch>
    </BrowserRouter>
  );
}

ReactDOM.render(<Loading />, document.getElementById("root"));

auth.onAuthStateChanged(function (tempuser) {
  if (tempuser) {
    // User is signed in
    user = tempuser;

    var profilesRef = db.collection("users").doc(user.uid);

    window.amplitude.getInstance().setUserId(user.uid);

    profilesRef
      .get()
      .then(function (doc) {
        if (doc.exists) {
          var newUser = doc.data();

          newUser["id"] = user.uid;

          // Fetch all growth offers
          store.dispatch(actions.growth.fetchOffers());

          // Fetch server side app version, this is purely to force a refresh
          store.dispatch(fetchAppVersion());

          // This is where we really store the real app version,
          // Run a check to see what version the user is on and update it
          // if necessary
          var current_app_version = 1.774;

          store.dispatch(loginUser(newUser));

          if (newUser.app_version != null) {
            if (newUser.app_version != current_app_version) {
              // User version differs from this local version number

              store.dispatch(updateUserAppVersion(current_app_version));
            } else {
            }
          } else {
            // There isn't a version saved for the user, save this one

            store.dispatch(updateUserAppVersion(current_app_version));
          }

          window.amplitude.getInstance().setUserProperties({
            email: newUser.email,
            company: newUser.company,
          });

          ReactDOM.unmountComponentAtNode(document.getElementById("root"));
          ReactDOM.render(
            <Provider store={store}>
              <ErrorBoundary user={newUser}>
                <AppRouter />
              </ErrorBoundary>
            </Provider>,

            document.getElementById("root")
          );
        } else {
          auth.signOut();
        }
      })
      .catch(function (error) { });
  } else {
    // User is signed out
    ReactDOM.render(
      <Provider store={store}>
        <ErrorBoundary>
          <Toggle feature={featureTypes.SSO}>
            <On>
              <LoginRedirect />
            </On>
            <Off>
              <AppNotLoggedInRouter />
            </Off>
          </Toggle>
        </ErrorBoundary>
      </Provider>,
      document.getElementById("root")
    );
  }
});

serviceWorker.register({
  onUpdate: async (registration) => {
    // We want to run this code only if we detect a new service worker is
    // waiting to be activated.
    // Details about it: https://developers.google.com/web/fundamentals/primers/service-workers/lifecycle
    if (registration && registration.waiting) {
      await registration.unregister();
      // Once the service worker is unregistered, we can reload the page to let
      // the browser download a fresh copy of our app (invalidating the cache)

      window.location.reload();
    }
  },
});
