const search = (state = { value: "", active: false }, action) => {
  switch (action.type) {
    case "SET_SEARCH_VALUE":
      return {
        ...state,
        value: action.value,
      };
    case "SET_SEARCH_ACTIVE":
      return {
        ...state,
        active: action.active,
      };
    default:
      return state;
  }
};

export default search;
