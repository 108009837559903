import React, { useState } from "react";

import { Checkbox, Modal, Button, Input, message } from "antd";

import { useHistory } from "react-router-dom";

import firebase from "../../firebase";

import "./Settings.scss";

export default function DuplicationModal({ projectId, visible, setVisible }) {
  const [duplicationLoading, setDuplicationLoading] = useState(false);
  const [duplicateObjects, setDuplicateObjects] = useState(false);
  const [duplicateCollaborators, setDuplicateCollaborators] = useState(false);
  const [duplicateComments, setDuplicateComments] = useState(false);

  const [projectName, setProjectName] = useState("");

  const history = useHistory();

  function handleDuplication() {
    setDuplicationLoading(true);

    const duplicateProject = firebase
      .functions()
      .httpsCallable("duplicateProject");

    duplicateProject({
      projectIdToDuplicate: projectId,
      newProjectName: projectName,
      duplicateCollaborators: duplicateCollaborators,
      duplicateComments: duplicateComments,
      duplicateObjects: duplicateObjects,
    })
      .then(function (result) {
        setDuplicationLoading(false);

        message.success("Duplication successful!");

        history.push("/p/" + result.data.newId + "/settings");

        setVisible(false);

        window.location.reload();
      })
      .catch(function (error) {
        // Getting the Error details.
        var code = error.code;
        var message = error.message;
        var details = error.details;
        // ...
        message.error(`Error duplicating: ${error.message}`);
        setDuplicationLoading(false);
      });
  }

  return (
    <Modal
      title="Duplicate Project"
      visible={visible}
      footer={null}
      destroyOnClose={true}
      closable={false}
    >
      <div className="input-label-title">Project Name</div>

      <Input
        className="input"
        value={projectName}
        onInput={(e) => setProjectName(e.target.value)}
      />

      <div className="checkbox-container">
        <Checkbox
          className="checkbox"
          checked={duplicateObjects}
          onChange={() => {
            setDuplicateObjects(!duplicateObjects);
          }}
        >
          Duplicate Objects
        </Checkbox>

        <Checkbox
          className="checkbox"
          checked={duplicateComments}
          onChange={() => {
            setDuplicateComments(!duplicateComments);
          }}
        >
          Duplicate Comments
        </Checkbox>

        <Checkbox
          className="checkbox"
          checked={duplicateCollaborators}
          onChange={() => {
            setDuplicateCollaborators(!duplicateCollaborators);
          }}
        >
          Duplicate Collaborator Settings
        </Checkbox>
      </div>

      <div style={{ float: "right" }}>
        <Button
          key="back"
          onClick={() => {
            setVisible(false);
          }}
        >
          Cancel
        </Button>

        <Button
          style={{ marginLeft: "10px" }}
          key="submit"
          type="primary"
          loading={duplicationLoading}
          onClick={handleDuplication}
        >
          Create Project
        </Button>
      </div>
      <div style={{ clear: "right" }}> </div>
    </Modal>
  );
}
