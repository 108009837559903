const types = {
    viewDashboard:                      "viewDashboard",
    createProject:                      "createProject",
    selectAProject:                     "selectAProject",
    selectProjectSettings:              "selectProjectSettings",
    viewStatusTable:                    "viewStatusTable",
    createNewMilestone:                 "createNewMilestone",
    createNewStatusItem:                "createNewStatusItem",
    moveMilestone:                      "moveMilestone",
    moveStatusItem:                     "moveStatusItem",
    renameMilestone:                    "renameMilestone",
    renameStatusItem:                   "renameStatusItem",
    changeStatusItemStatus:             "changeStatusItemStatus",
    changeStatusItemQaStatus:           "changeStatusItemQaStatus",
    changeStatusItemDeliveryDate:       "changeStatusItemDeliveryDate",
    expandStatusItem:                   "expandStatusItem",
    addStatusItemNotes:                 "addStatusItemNotes",
    commentOnStatusItem:                "commentOnStatusItem",
    deleteCommentOnStatusItem:          "deleteCommentOnStatusItem",
    deleteStatusItem:                   "deleteStatusItem",
    deleteMilestone:                    "deleteMilestone",
    changeHideCompletedItems:           "changeHideCompletedItems",
    viewStandupPage:                    "viewStandupPage",
    addNewStandup:                      "addNewStandup",
    deleteStandup:                      "deleteStandup",
    commentOnStandup:                   "commentOnStandup",
    deleteCommentOnStandup:             "deleteCommentOnStandup",
    expandStandupItem:                  "expandStandupItem",
    viewedClarificationPage:            "viewedClarificationPage",
    addNewClarification:                "addNewClarification",
    deleteClarification:                "deleteClarification",
    commentOnClarification:             "commentOnClarification",
    deleteCommentOnClarification:       "deleteCommentOnClarification",
    expandClarification:                "expandClarification",
    addNewCredential:                   "addNewCredential",
    clickedShowCredential:              "clickedShowCredential",
    deleteCredential:                   "deleteCredential",
    changeProjectName:                  "changeProjectName",
    changeSlackNotificationsSettings:   "changeSlackNotificationsSettings",
    clickedAddToSlack:                  "clickedAddToSlack",
    saveProjectSettings:                "saveProjectSettings",
    removeSlackNotificationChannel:     "removeSlackNotificationChannel",
    addCollaborator:                    "addCollaborator",
    removeCollaborator:                 "removeCollaborator",
    deleteProject:                      "deleteProject",
    changeName:                         "changeName",
    changeCompanyName:                  "changeCompanyName",
    changePhoto:                        "changePhoto",
    saveInfo:                           "saveInfo"
}   

module.exports = types;