import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Avatar, Dropdown } from "antd";
import { MoreOutlined } from "@ant-design/icons";
import styled from "styled-components";
import { clearFix } from "polished";
import moment from "moment";
import NotificationContentMenu from "./NotificationContentMenu";
import NotificationText from "./NotificationText";
import addContent from "./ContentBuilder";
import {
  updateNotificationStatus,
  setNotificationsVisible,
  clearNotificationsProject,
} from "../../actions/notifications";
import ParsedNotificationText from "./parse";
import { getInitialsFromUser } from "../utils";
import { useHistory } from "react-router";

function NotificationContent({ notification }) {
  const dispatch = useDispatch();
  const history = useHistory();

  const currentUser = useSelector((state) => state.currentUser);

  const [cardHovered, setCardHovered] = useState(false);
  const [textHovered, setTextHovered] = useState(false);
  const [showOptionsIcon, setShowOptionsIcon] = useState(false);
  const [optionsIconHovered, setOptionsIconHovered] = useState(false);
  const [optionsMenuOpen, setOptionsMenuOpen] = useState(false);

  const handleClickNotification = (e) => {
    if (
      cardHovered &&
      !optionsIconHovered &&
      notification.status === "unread"
    ) {
      dispatch(updateNotificationStatus(notification, "read"));
    }

    if (notification.url && !optionsIconHovered) {
      dispatch(setNotificationsVisible(false));
      dispatch(clearNotificationsProject());
      if (e.target.tagName !== "A") {
        history.push(notification.url);
      }
    }
  };

  addContent(notification);

  return (
    <Card
      status={notification.status}
      onClick={handleClickNotification}
      hover={cardHovered}
      onMouseEnter={() => {
        setCardHovered(true);
        setShowOptionsIcon(true);
      }}
      onMouseLeave={() => {
        setCardHovered(false);
        setShowOptionsIcon(false);
        setOptionsMenuOpen(false);
      }}
    >
      <AvatarContainer>
        {notification.user?.image ? (
          <Avatar
            src={notification.user.image}
            size={30}
            style={{ marginRight: "7px" }}
          />
        ) : (
          <Avatar size={30} style={{ marginRight: "7px" }}>
            {getInitialsFromUser(notification.user)}
          </Avatar>
        )}
      </AvatarContainer>
      <ContentContainer>
        <MessageDiv
          onMouseEnter={() => setTextHovered(true)}
          onMouseLeave={() => setTextHovered(false)}
        >
          <ParsedNotificationText
            notification={notification}
            truncateText={!textHovered}
          />
        </MessageDiv>
        {notification.item.content && (
          <ContentDiv
            hover={cardHovered}
            unread={notification.status === "unread"}
          >
            <NotificationText
              message={notification}
              currentUser={currentUser}
            />
          </ContentDiv>
        )}
        {/* {notification.status === "unread" && notification.callToAction && (
          <ActionContainer>
            <Button onClick={handleAction} type="primary">
              {notification.callToAction}
            </Button>
          </ActionContainer>
        )} */}
        <NotificationFooter>
          <NameDiv>{notification.user?.name}</NameDiv>
          <DateDiv>
            {moment(notification.date.toDate()).format("MMM DD, YYYY h:mm a")}
          </DateDiv>
        </NotificationFooter>
      </ContentContainer>

      <UnreadDiv>
        {notification.status === "unread" && (
          <UnreadCircle width={10} height={10} />
        )}
      </UnreadDiv>

      {showOptionsIcon && (
        <OptionsDiv
          hover={optionsIconHovered}
          onMouseEnter={() => {
            setCardHovered(false);
            setOptionsIconHovered(true);
          }}
          onMouseLeave={() => {
            setCardHovered(true);
            setOptionsIconHovered(false);
          }}
        >
          <Dropdown
            overlay={
              <NotificationContentMenu
                notification={notification}
                setMenuVisible={setOptionsMenuOpen}
                afterSelect={() => {
                  setOptionsIconHovered(false);
                  setCardHovered(true);
                }}
              />
            }
            visible={optionsMenuOpen}
            trigger={["click"]}
            onClick={() => {
              setOptionsMenuOpen(!optionsMenuOpen);
              setOptionsIconHovered(false);
            }}
            onVisibleChange={setOptionsMenuOpen}
          >
            <MoreOutlined rotate={90} />
          </Dropdown>
        </OptionsDiv>
      )}
    </Card>
  );
}

export default React.memo(NotificationContent);

const UnreadCircle = ({ width, height }) => (
  <svg width={width} height={height}>
    <circle cx={5} cy={5} r={5} fill="rgb(24, 144, 255)" />
  </svg>
);

const Card = styled.div`
  width: 100%;
  margin-bottom: 5px;
  padding: 4px;
  border-radius: 5px;
  cursor: pointer;
  color: ${(props) => (props.status === "unread" ? "#222222" : "#AAAAAA")};
  background-color: ${(props) => (props.hover ? "#f4f4f4" : "#ffffff")};
  display: inline-block;
  position: relative;
  ${clearFix()};
`;

const ContentContainer = styled.div`
  float: left;
  width: 85%;
  padding-top: 3px;
  padding-bottom: 3px;
`;

const MessageDiv = styled.div`
  display: inline-block;
  width: 100%;
  font-size: 14px;
  min-height: 30px;
`;

const ContentDiv = styled.div`
  display: inline-block;
  background-color: rgb(242, 243, 245);
  // background-color: rgb(221, 229, 255);
  // background-color: #e6f7ff;
  // background-color: #bae7ff;
  background-color: ${(props) =>
    props.hover && props.unread ? "#bae7ff" : "rgb(242, 243, 245)"};
  padding: 7px;
  border-radius: 7px;
  margin-top: 3px;
  margin-right: 12px;
`;

const ActionContainer = styled.div`
  margin-top: 10px;
`;

const NotificationFooter = styled.div`
  width: 95%;
  margin-top: 5px;
`;

const NameDiv = styled.div`
  display: inline-block;
  width: 50%;
  text-align: left;
  font-size: 12px;
`;

const DateDiv = styled.div`
  display: inline-block;
  width: 50%;
  text-align: right;
  font-size: 12px;
`;

const AvatarContainer = styled.div`
  float: left;
  font-size: 10px;
  color: #333;
  display: inline-box;
  margin-right: 5px;
  margin-left: 3px;
  padding-top: 5px;
`;

const OptionsDiv = styled.div`
  float: right;
  top: 47%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  right: 20px;
  text-align: center;
  font-size: 16px;
  color: #444;
  width: 26px;
  height: 26px;
  background-color: ${(props) => (props.hover ? "#f4f4f4" : "#ffffff")};
  border-radius: 20px;
  border: 1px solid #aaa;
  cursor: pointer;
  position: absolute;
`;

const UnreadDiv = styled.div`
  right: 5px;
  width: 10px;
  height 10px;
  top: 40%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  position: absolute;
`;
