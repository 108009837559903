import React, { useState, useEffect } from "react";

import { useDispatch, useSelector } from "react-redux";
import { Drawer } from "antd";
import { useMediaQuery } from "react-responsive";

import { deepEqualCheck, getPrimaryValueFromObject } from "../utils";
import actions from "../../actions";

import "./ReferenceModal.scss";

import { SearchIcon, InboxIcon } from "@heroicons/react/outline";

import { LinkedItemCard } from "./index";
import { isMobile } from "../utils";

export default function LinkItemsModal({
  visible,
  setVisible,
  types,
  projectId,
  components,
  addNewItem,
  referencedItems,
}) {
  const [linkableItemsLoaded, setLinkableItemsLoaded] = useState([]);
  const collections = useSelector(
    (state) => state.collections[projectId],
    deepEqualCheck
  );

  const is13Inch = useMediaQuery({ maxWidth: 1500 });

  const dispatch = useDispatch();

  const currentUser = useSelector((state) => state.currentUser);

  function handleCancel(e) {
    setVisible(false);
  }

  const [searchQuery, setSearchQuery] = useState("");

  const [filteredItems, setFilteredItems] = useState([]);

  useEffect(() => {
    var availableItems = [];

    // First, let's get all the items that are linked to the current component
    types.map((type) => {
      const collection = collections[type];

      if (!collection) return;

      var component = components[type];

      if (!component) return;

      var tempItems = Object.values(collection)
        .filter((item) => {
          // Don't show if item is in referencedItems and search term does not match the primary field

          if (Object.keys(referencedItems).indexOf(item.id) > -1) {
            return false;
          }

          return true;
        })
        .map((object) => {
          return {
            searchableText: getPrimaryValueFromObject(object, component),
            component: component,
            reactObject: (
              <LinkedItemCard
                projectId={projectId}
                component={component}
                object={object}
                activelyEditing={false}
                isAdding={true}
                addNewItem={addNewItem}
                type={type}
              />
            ),
          };
        });

      availableItems = availableItems.concat(tempItems);
    });

    setFilteredItems(availableItems);
  }, [components, types, collections, referencedItems]);

  return (
    <Drawer
      width={isMobile() ? "100%" : is13Inch ? "350px" : "400px"}
      drawerStyle={{ backgroundColor: "#FBFAF9", borderRadius: "13px" }}
      headerStyle={{
        backgroundColor: "#FBFAF9",
      }}
      bodyStyle={{ padding: "20px" }}
      title={"Add Items"}
      placement={"right"}
      closable={true}
      onClose={handleCancel}
      visible={visible}
      zIndex={10000}
    >
      {!linkableItemsLoaded && <div>Loading...</div>}

      {linkableItemsLoaded && types.length > 0 && (
        <div className="lim-container">
          <div className="lim-search-container">
            <SearchIcon className="lim-search-icon" />
            <input
              type="text"
              placeholder={`Search linkable items`}
              value={searchQuery}
              onChange={(e) => {
                const searchTerm = e.target.value;

                setSearchQuery(searchTerm);
              }}
              className="lim-search-input"
            />
          </div>
          <ul>
            {filteredItems
              .filter((item) => {
                if (!item.searchableText) return false;

                if (searchQuery) {
                  return item.searchableText
                    .toLowerCase()
                    .includes(searchQuery.toLowerCase());
                } else {
                  return true;
                }
              })
              .map((item) => {
                return item.reactObject;
              })}
          </ul>
        </div>
      )}
    </Drawer>
  );
}
