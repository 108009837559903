import React from "react";
import * as Sentry from "@sentry/browser";
import { Button } from "antd";
import error_image from "../../images/502error.svg";

Sentry.init({
  dsn: "https://b08ba77937c24405b217dda486dbeaf5@sentry.io/1411391",
});

export default class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { error: null };
  }

  componentDidMount() {
    const user = this.props.user;

    if (user != null) {
      Sentry.configureScope((scope) => {
        scope.setUser({ id: user.uid, email: user.email });
      });
    }
  }

  componentDidCatch(error, errorInfo) {
    this.setState({ error });

    Sentry.withScope((scope) => {
      Object.keys(errorInfo).forEach((key) => {
        scope.setExtra(key, errorInfo[key]);
      });

      Sentry.captureException(error);
    });
  }

  render() {
    if (this.state.error) {
      // render fallback UI
      return (
        <div style={{ textAlign: "center" }}>
          <img
            style={{ marginTop: "100px", width: "400px" }}
            src={error_image}
          />
          <div
            className="partner-message"
            style={{ fontSize: "18px", marginBottom: "20px" }}
          >
            Something went wrong! Our team has been notified but you can still
            report the issue.
          </div>

          <Button
            type="secondary"
            style={{ marginRight: "10px" }}
            onClick={() => Sentry.showReportDialog()}
          >
            Report Issue
          </Button>

          <Button type="primary" onClick={() => window.open("/", "_self")}>
            Go Home
          </Button>
        </div>
      );
    }
    // when there's not an error, render children untouched
    return this.props.children;
  }
}
