import React, { useState, useEffect } from "react";

import { useSelector, useDispatch } from "react-redux";

import {
  Modal,
  Input,
  message,
  Popover,
  Popconfirm,
  Tooltip,
  Select,
  Switch,
} from "antd";

import { useParams, useHistory } from "react-router";

import Field from "../Fields/Field.js";

import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

import ColumnsEditor from "./Fields/ColumnsEditor";
import SummaryEditor from "./Fields/SummaryEditor";

import FieldEditor from "../Editor";

import _ from "lodash";

import actions from "../../../actions";

import "../Editor.scss";
import { SettingsApplicationsRounded } from "@material-ui/icons";

const { Option } = Select;

export default function SectionEditor({
  projectId,
  id,
  currentUser,
  setPage,
  component = {},
  creation = false,
  existingKeys = [],
  components = {},
}) {
  const [errors, setErrors] = useState();

  const [sectionChangesMade, setSectionChangesMade] = useState(false);

  const history = useHistory();

  const dispatch = useDispatch();

  const roles = useSelector((state) => state.roles[projectId]);

  const [sideEffects, setSideEffects] = useState({});

  console.log("sideEffects ", sideEffects);

  const [sectionPermissionsToUpdate, setSectionPermissionsToUpdate] = useState({
    client: {
      read:
        roles.client.permissions.sections[id]?.read != null
          ? roles.client.permissions.sections[id]?.read
          : true,
      create:
        roles.client.permissions.sections[id]?.create != null
          ? roles.client.permissions.sections[id]?.create
          : true,
      edit:
        roles.client.permissions.sections[id]?.read != null
          ? roles.client.permissions.sections[id]?.edit
          : true,
      delete:
        roles.client.permissions.sections[id]?.delete != null
          ? roles.client.permissions.sections[id]?.delete
          : true,
      comment:
        roles.client.permissions.sections[id]?.comment != null
          ? roles.client.permissions.sections[id]?.comment
          : true,
    },
    vendor: {
      read:
        roles.vendor.permissions.sections[id]?.read != null
          ? roles.vendor.permissions.sections[id]?.read
          : true,
      create:
        roles.vendor.permissions.sections[id]?.create != null
          ? roles.vendor.permissions.sections[id]?.create
          : true,
      edit:
        roles.vendor.permissions.sections[id]?.read != null
          ? roles.vendor.permissions.sections[id]?.edit
          : true,
      delete:
        roles.vendor.permissions.sections[id]?.delete != null
          ? roles.vendor.permissions.sections[id]?.delete
          : true,
      comment:
        roles.vendor.permissions.sections[id]?.comment != null
          ? roles.vendor.permissions.sections[id]?.comment
          : true,
    },
    viewer: {
      read:
        roles.viewer.permissions.sections[id]?.read != null
          ? roles.viewer.permissions.sections[id]?.read
          : true,
      create:
        roles.viewer.permissions.sections[id]?.create != null
          ? roles.viewer.permissions.sections[id]?.create
          : true,
      edit:
        roles.viewer.permissions.sections[id]?.read != null
          ? roles.viewer.permissions.sections[id]?.edit
          : true,
      delete:
        roles.viewer.permissions.sections[id]?.delete != null
          ? roles.viewer.permissions.sections[id]?.delete
          : true,
      comment:
        roles.viewer.permissions.sections[id]?.comment != null
          ? roles.viewer.permissions.sections[id]?.comment
          : true,
    },
  });

  function updateSectionPermissionsToUpdate(roleName, permName, newPerm) {
    var copySectionPermissionsToUpdate = _.cloneDeep(
      sectionPermissionsToUpdate
    );

    if (!copySectionPermissionsToUpdate[roleName]) {
      copySectionPermissionsToUpdate[roleName] = {};
    }

    copySectionPermissionsToUpdate[roleName][permName] = newPerm;

    setSectionPermissionsToUpdate(copySectionPermissionsToUpdate);
    setSectionChangesMade(true);
  }

  const [sectionEditable, setSectionEditable] = useState(
    creation
      ? {
          info: {
            addon: true,
          },
          cardlist: {},
          table: {},
        }
      : component
  );

  useEffect(() => {
    if (!creation) {
      if (!_.isEqual(component, sectionEditable)) {
        setSectionEditable(component[id]);
      }
    }
  }, [component]);

  useEffect(() => {
    if (!_.isEqual(component, sectionEditable)) {
      setSectionChangesMade(true);
    } else {
      setSectionChangesMade(false);
    }
  }, [sectionEditable]);

  function updateSectionObject(path, value) {
    var sectionEditableClone = _.cloneDeep(sectionEditable);

    if (path == "info.collectionKey" || path == "info.type") {
      _.set(sectionEditableClone, path, value.replace(/[\W]+/g, "_"));
    } else {
      _.set(sectionEditableClone, path, value);
    }

    console.log("clone is ", sectionEditableClone);

    setSectionEditable(sectionEditableClone);
  }

  function updateRoles(component) {
    var componentId = component?.info?.collectionKey;

    const defaultRoles = ["admin", "client", "vendor", "viewer"];

    console.log("roles: ", roles);

    const rolesCopy = _.cloneDeep(roles);

    var fieldsTemp = {};

    if (component.fields) {
      Object.keys(component.fields).forEach(function (field_id) {
        fieldsTemp[field_id] = {
          edit: true,
          read: true,
        };
      });
    }
    // Iterate through all the roles
    defaultRoles.forEach(function (role_id) {
      // If no section exists, create one
      if (rolesCopy[role_id].permissions?.sections == null) {
        rolesCopy[role_id].permissions.sections = {};
      }

      if (rolesCopy[role_id].permissions?.sections[componentId] == null) {
        rolesCopy[role_id].permissions.sections[componentId] = {
          create:
            [role_id] == "admin"
              ? true
              : sectionPermissionsToUpdate[role_id].create,
          edit:
            [role_id] == "admin"
              ? true
              : sectionPermissionsToUpdate[role_id].edit,
          read:
            [role_id] == "admin"
              ? true
              : sectionPermissionsToUpdate[role_id].read,
          delete:
            [role_id] == "admin"
              ? true
              : sectionPermissionsToUpdate[role_id].delete,
          comment:
            [role_id] == "admin"
              ? true
              : sectionPermissionsToUpdate[role_id].comment,
          fields: fieldsTemp,
        };
      } else {
        Object.entries(sectionPermissionsToUpdate).forEach(function ([
          roleToUpdate,
          permsToUpdate,
        ]) {
          Object.entries(permsToUpdate).forEach(function ([permName, newPerm]) {
            rolesCopy[roleToUpdate].permissions.sections[componentId][
              permName
            ] = newPerm;
          });
        });
      }

      // If a field is missing, add it
      if (
        component.fields &&
        rolesCopy[role_id].permissions?.sections[componentId].fields
      ) {
        if (
          !Object.keys(component.fields).every((item) =>
            Object.keys(
              rolesCopy[role_id].permissions.sections[componentId].fields
            ).includes(item)
          )
        ) {
          // Get all the fields that have been added that don't exist in roles
          // Add them here
          var newFields = _.difference(
            Object.keys(component.fields),
            Object.keys(
              rolesCopy[role_id].permissions?.sections[componentId].fields
            )
          );

          newFields.forEach(function (field_id) {
            rolesCopy[role_id].permissions.sections[componentId].fields[
              field_id
            ] = {
              edit: true,
              read: true,
            };
          });
        }
      }
    });

    return rolesCopy;
  }

  console.log("sideEffects: ", sideEffects);

  return (
    <div class="section">
      <div className="editor-header">
        <div
          className="back-title"
          onClick={() => history.push(`/p/${projectId}/settings`)}
        >
          <img
            className="back-image"
            src={require("../../../images/back-arrow-purp.svg")}
          />
          Back to Settings
        </div>
      </div>

      <SettingsSection title={"Section Info"}>
        <div>
          <div class="header-fields">
            <span className="header-text">Basic Info</span>
          </div>
          <div className="input-box-with-title">
            <label class="required">Section Title </label>
            <div className={"input-value"}>
              <Input
                value={sectionEditable?.info?.name}
                onChange={(event) => {
                  updateSectionObject("info.name", event.target.value);
                }}
              />
            </div>
          </div>
          <div className="input-box-with-title">
            <label class="required">Section Type </label>
            <div className={"input-value"}>
              <Input
                value={sectionEditable?.info?.type}
                onChange={(event) => {
                  updateSectionObject("info.type", event.target.value);
                }}
                disabled={!creation}
              />
            </div>
          </div>
          <div className="input-box-with-title">
            <label class="required">Collection key </label>
            <div className={"input-value"}>
              <Input
                value={sectionEditable?.info?.collectionKey}
                onChange={(event) => {
                  updateSectionObject("info.collectionKey", event.target.value);
                }}
                disabled={!creation}
              />
            </div>
          </div>

          <div className="input-box-with-title">
            <label class="required">Icon URL </label>
            <div className={"input-value"}>
              <Input
                value={sectionEditable?.info?.icon}
                onChange={(event) => {
                  updateSectionObject("info.icon", event.target.value);
                }}
              />
            </div>
          </div>

          <div className="input-box-with-title">
            <label class="required">Default View</label>
            <div className={"input-value"}>
              <Select
                style={{ width: "100%" }}
                value={sectionEditable?.info?.default_view || "cardlist"}
                onChange={(value) => {
                  updateSectionObject("info.default_view", value);
                }}
              >
                <Option value={"cardlist"}>Cardlist</Option>
                <Option value={"table"}>Table</Option>
              </Select>
            </div>
          </div>

          <div class="header-fields">
            <span className="header-text">Vocabulary</span>
          </div>
          <div className="input-box-with-title">
            <label class="required">Singular Upper </label>
            <div className={"input-value"}>
              <Input
                value={sectionEditable?.info?.vocabulary?.singular_upper}
                onChange={(event) => {
                  updateSectionObject(
                    "info.vocabulary.singular_upper",
                    event.target.value
                  );
                }}
              />
            </div>
          </div>
          <div className="input-box-with-title">
            <label class="required">Singular Lower </label>
            <div className={"input-value"}>
              <Input
                value={sectionEditable?.info?.vocabulary?.singular_lower}
                onChange={(event) => {
                  updateSectionObject(
                    "info.vocabulary.singular_lower",
                    event.target.value
                  );
                }}
              />
            </div>
          </div>
          <div className="input-box-with-title">
            <label class="required">Plural Upper </label>
            <div className={"input-value"}>
              <Input
                value={sectionEditable?.info?.vocabulary?.plural_upper}
                onChange={(event) => {
                  updateSectionObject(
                    "info.vocabulary.plural_upper",
                    event.target.value
                  );
                }}
              />
            </div>
          </div>
          <div className="input-box-with-title">
            <label class="required">Plural Lower </label>
            <div className={"input-value"}>
              <Input
                value={sectionEditable?.info?.vocabulary?.plural_lower}
                onChange={(event) => {
                  updateSectionObject(
                    "info.vocabulary.plural_lower",
                    event.target.value
                  );
                }}
              />
            </div>
          </div>
        </div>
      </SettingsSection>

      <SettingsSection title={"Notifications"}>
        <div>
          <div class="header-fields">
            <span className="header-text">All Notifications</span>
          </div>
          <div className="input-box-with-title">
            <label class="required">Primary Field</label>
            <div className={"input-value"}>
              <Select
                style={{ width: "100%" }}
                value={sectionEditable?.notifications?.slack?.primaryField}
                onChange={(value) => {
                  updateSectionObject(
                    "notifications.slack.primaryField",
                    value
                  );
                }}
              >
                {sectionEditable?.fields &&
                  Object.keys(sectionEditable?.fields).map(function (field_id) {
                    var field = sectionEditable.fields[field_id];
                    return (
                      <Option value={field_id} key={"field_" + field_id}>
                        {field.title}
                      </Option>
                    );
                  })}
              </Select>
            </div>
          </div>

          <div class="header-fields">
            <span className="header-text">Slack Notifications</span>
          </div>
          <div className="input-box-with-title">
            <label class="required">Primary Color</label>
            <div className={"input-value"}>
              <Input
                value={sectionEditable?.notifications?.slack?.color}
                onChange={(event) => {
                  updateSectionObject(
                    "notifications.slack.color",
                    event.target.value
                  );
                }}
              />
            </div>
          </div>
        </div>
      </SettingsSection>

      <SettingsSection title={"Permissions"}>
        <div>
          <div class="header-fields">
            <span className="header-text">Client Permissions</span>
          </div>
          <div className="field-footer">
            <div className="editor-button">
              <span style={{ marginRight: "5px" }}>Read</span>{" "}
              <Switch
                size={"small"}
                onClick={(checked, event) => {
                  // setReadPermission("client", checked)
                  // setClientR(checked);
                  updateSectionPermissionsToUpdate("client", "read", checked);
                }}
                // checked={roles.client.permissions.sections[id]?.read}
                checked={sectionPermissionsToUpdate.client?.read || false}
                disabled={false}
                style={{
                  backgroundColor: sectionPermissionsToUpdate.client?.read
                    ? "#5c56ff"
                    : "#C9CCDF",
                }}
              />
            </div>

            <div className="divider" />
            <div className="editor-button">
              <span style={{ marginRight: "5px" }}>Create</span>{" "}
              <Switch
                size={"small"}
                onClick={(checked, event) => {
                  updateSectionPermissionsToUpdate("client", "create", checked);
                }}
                checked={sectionPermissionsToUpdate.client?.create || false}
                disabled={false}
                style={{
                  backgroundColor: sectionPermissionsToUpdate.client?.create
                    ? "#5c56ff"
                    : "#C9CCDF",
                }}
              />
            </div>

            <div className="divider" />

            <div className="editor-button">
              <span style={{ marginRight: "5px" }}>Edit</span>{" "}
              <Switch
                size={"small"}
                onChange={(checked, event) => {
                  updateSectionPermissionsToUpdate("client", "edit", checked);
                }}
                checked={sectionPermissionsToUpdate.client?.edit || false}
                disabled={false}
                style={{
                  backgroundColor: sectionPermissionsToUpdate.client?.edit
                    ? "#5c56ff"
                    : "#C9CCDF",
                }}
              />
            </div>

            <div className="divider" />

            <div className="editor-button">
              <span style={{ marginRight: "5px" }}>Delete</span>{" "}
              <Switch
                size={"small"}
                onChange={(checked, event) => {
                  updateSectionPermissionsToUpdate("client", "delete", checked);
                }}
                checked={sectionPermissionsToUpdate.client?.delete || false}
                disabled={false}
                style={{
                  backgroundColor: sectionPermissionsToUpdate.client?.delete
                    ? "#5c56ff"
                    : "#C9CCDF",
                }}
              />
            </div>

            <div className="divider" />

            <div className="editor-button">
              <span style={{ marginRight: "5px" }}>Comment</span>{" "}
              <Switch
                size={"small"}
                onChange={(checked, event) => {
                  updateSectionPermissionsToUpdate(
                    "client",
                    "comment",
                    checked
                  );
                }}
                checked={sectionPermissionsToUpdate.client?.comment}
                disabled={false}
                style={{
                  backgroundColor: sectionPermissionsToUpdate.client?.comment
                    ? "#5c56ff"
                    : "#C9CCDF",
                }}
              />
            </div>
          </div>
          <div class="header-fields">
            <span className="header-text">Vendor Permissions</span>
          </div>
          <div className="field-footer">
            <div className="editor-button">
              <span style={{ marginRight: "5px" }}>Read</span>{" "}
              <Switch
                size={"small"}
                onClick={(checked, event) => {
                  updateSectionPermissionsToUpdate("vendor", "read", checked);
                }}
                checked={sectionPermissionsToUpdate.vendor?.read || false}
                disabled={false}
                style={{
                  backgroundColor: sectionPermissionsToUpdate.vendor?.read
                    ? "#5c56ff"
                    : "#C9CCDF",
                }}
              />
            </div>

            <div className="divider" />
            <div className="editor-button">
              <span style={{ marginRight: "5px" }}>Create</span>{" "}
              <Switch
                size={"small"}
                onClick={(checked, event) => {
                  updateSectionPermissionsToUpdate("vendor", "create", checked);
                }}
                checked={sectionPermissionsToUpdate.vendor?.create || false}
                disabled={false}
                style={{
                  backgroundColor: sectionPermissionsToUpdate.vendor?.create
                    ? "#5c56ff"
                    : "#C9CCDF",
                }}
              />
            </div>

            <div className="divider" />

            <div className="editor-button">
              <span style={{ marginRight: "5px" }}>Edit</span>{" "}
              <Switch
                size={"small"}
                onChange={(checked, event) => {
                  updateSectionPermissionsToUpdate("vendor", "edit", checked);
                }}
                checked={sectionPermissionsToUpdate.vendor?.edit || false}
                disabled={false}
                style={{
                  backgroundColor: sectionPermissionsToUpdate.vendor?.edit
                    ? "#5c56ff"
                    : "#C9CCDF",
                }}
              />
            </div>

            <div className="divider" />

            <div className="editor-button">
              <span style={{ marginRight: "5px" }}>Delete</span>{" "}
              <Switch
                size={"small"}
                onChange={(checked, event) => {
                  updateSectionPermissionsToUpdate("vendor", "delete", checked);
                }}
                checked={sectionPermissionsToUpdate.vendor?.delete || false}
                disabled={false}
                style={{
                  backgroundColor: sectionPermissionsToUpdate.vendor?.delete
                    ? "#5c56ff"
                    : "#C9CCDF",
                }}
              />
            </div>

            <div className="divider" />

            <div className="editor-button">
              <span style={{ marginRight: "5px" }}>Comment</span>{" "}
              <Switch
                size={"small"}
                onChange={(checked, event) => {
                  updateSectionPermissionsToUpdate(
                    "vendor",
                    "comment",
                    checked
                  );
                }}
                checked={sectionPermissionsToUpdate.vendor?.comment}
                disabled={false}
                style={{
                  backgroundColor: sectionPermissionsToUpdate.vendor?.comment
                    ? "#5c56ff"
                    : "#C9CCDF",
                }}
              />
            </div>
          </div>
          <div class="header-fields">
            <span className="header-text">Viewer Permissions</span>
          </div>
          <div className="field-footer">
            <div className="editor-button">
              <span style={{ marginRight: "5px" }}>Read</span>{" "}
              <Switch
                size={"small"}
                onClick={(checked, event) => {
                  updateSectionPermissionsToUpdate("viewer", "read", checked);
                }}
                checked={sectionPermissionsToUpdate.viewer?.read || false}
                disabled={false}
                style={{
                  backgroundColor: sectionPermissionsToUpdate.viewer?.read
                    ? "#5c56ff"
                    : "#C9CCDF",
                }}
              />
            </div>

            <div className="divider" />
            <div className="editor-button">
              <span style={{ marginRight: "5px" }}>Create</span>{" "}
              <Switch
                size={"small"}
                onClick={(checked, event) => {
                  updateSectionPermissionsToUpdate("viewer", "create", checked);
                }}
                checked={sectionPermissionsToUpdate.viewer?.create || false}
                disabled={false}
                style={{
                  backgroundColor: sectionPermissionsToUpdate.viewer?.create
                    ? "#5c56ff"
                    : "#C9CCDF",
                }}
              />
            </div>

            <div className="divider" />

            <div className="editor-button">
              <span style={{ marginRight: "5px" }}>Edit</span>{" "}
              <Switch
                size={"small"}
                onChange={(checked, event) => {
                  updateSectionPermissionsToUpdate("viewer", "edit", checked);
                }}
                checked={sectionPermissionsToUpdate.viewer?.edit || false}
                disabled={false}
                style={{
                  backgroundColor: sectionPermissionsToUpdate.viewer?.edit
                    ? "#5c56ff"
                    : "#C9CCDF",
                }}
              />
            </div>

            <div className="divider" />

            <div className="editor-button">
              <span style={{ marginRight: "5px" }}>Delete</span>{" "}
              <Switch
                size={"small"}
                onChange={(checked, event) => {
                  updateSectionPermissionsToUpdate("viewer", "delete", checked);
                }}
                checked={sectionPermissionsToUpdate.viewer?.delete || false}
                disabled={false}
                style={{
                  backgroundColor: sectionPermissionsToUpdate.viewer?.delete
                    ? "#5c56ff"
                    : "#C9CCDF",
                }}
              />
            </div>

            <div className="divider" />

            <div className="editor-button">
              <span style={{ marginRight: "5px" }}>Comment</span>{" "}
              <Switch
                size={"small"}
                onChange={(checked, event) => {
                  updateSectionPermissionsToUpdate(
                    "viewer",
                    "comment",
                    checked
                  );
                }}
                checked={sectionPermissionsToUpdate.viewer?.comment}
                disabled={false}
                style={{
                  backgroundColor: sectionPermissionsToUpdate.viewer?.comment
                    ? "#5c56ff"
                    : "#C9CCDF",
                }}
              />
            </div>
          </div>
        </div>
      </SettingsSection>

      <FieldEditor
        id={id}
        projectId={projectId}
        setPage={setPage}
        currentUser={currentUser}
        editableConfig={sectionEditable}
        setEditableConfig={setSectionEditable}
        changesMade={sectionChangesMade}
        setChangesMade={setSectionChangesMade}
        components={components}
        setSideEffects={setSideEffects}
        creation={creation}
      />

      <SettingsSection title={"Table Settings"}>
        <div>
          <div class="header-fields">
            <span className="header-text">Columns</span>
          </div>
          <ColumnsEditor
            columns={sectionEditable?.table?.columns || []}
            updateField={updateSectionObject}
            objectEditable={sectionEditable}
            pathToUpdate={"table.columns"}
          />
        </div>

        <div className="input-box-with-title">
          <div class="header-fields">
            <span className="header-text">Index Row </span>
          </div>
          <div className={"input-value"}>
            <Select
              style={{ width: "100%" }}
              value={sectionEditable?.table?.index_row_key}
              onChange={(value) => {
                updateSectionObject("table.index_row_key", value);
              }}
            >
              {sectionEditable?.table?.columns &&
                sectionEditable?.table?.columns?.map(function (id, index) {
                  var field = sectionEditable?.fields[id];
                  if (field) {
                    return (
                      <Option value={id} key={"index_row_key_" + id}>
                        {field.title}
                      </Option>
                    );
                  }
                })}
            </Select>
          </div>
        </div>

        <div class="header-fields">
          <span className="header-text">Table Summaries </span>
        </div>
        <SummaryEditor
          updateField={updateSectionObject}
          objectEditable={sectionEditable}
          pathToUpdate={"table.summaries"}
        />
      </SettingsSection>

      <SettingsSection title={"Cardlist Settings"}>
        <div>
          <div class="header-fields">
            <span className="header-text">Field Order</span>
          </div>
          <ColumnsEditor
            columns={sectionEditable?.cardlist?.fieldOrder || []}
            updateField={updateSectionObject}
            objectEditable={sectionEditable}
            pathToUpdate={"cardlist.fieldOrder"}
          />
        </div>
      </SettingsSection>

      <div className="editor-footer">
        {!creation && (
          <Popconfirm
            title="Section data cannot be recovered, do you wish to proceed?"
            onConfirm={() => {
              dispatch(
                actions.configs.removeSectionConfigForObject(
                  sectionEditable,
                  id,
                  projectId,
                  sectionEditable
                )
              ).then(function () {
                message.success("Section deleted!");
                history.push("/p/" + projectId + "/settings");
              });
            }}
            onCancel={() => {
              // Deletion cancelled
            }}
            okText="I understand"
            cancelText="Nevermind"
          >
            <div className="delete-section-button">Delete Section</div>
          </Popconfirm>
        )}
        {sectionChangesMade && (
          <div
            onClick={() => {
              if (sectionChangesMade) {
                if (creation) {
                  if (
                    sectionEditable?.info?.collectionKey == null ||
                    sectionEditable?.info?.collectionKey == ""
                  ) {
                    message.error("Missing Section Collection Key");
                    return;
                  }

                  if (
                    sectionEditable?.info?.name == null ||
                    sectionEditable?.info?.name == ""
                  ) {
                    message.error("Missing Section Name");
                    return;
                  }

                  if (
                    sectionEditable?.info?.type == null ||
                    sectionEditable?.info?.type == ""
                  ) {
                    message.error("Missing Section Type");
                    return;
                  }

                  if (
                    existingKeys.includes(sectionEditable.info.collectionKey)
                  ) {
                    message.error(
                      "Collection Key already exists, please rename"
                    );

                    return;
                  }
                }

                dispatch(
                  actions.configs.updateSectionConfigsForProject(
                    projectId,
                    sectionEditable
                  )
                ).then(function () {
                  // Now lets update the roles
                  var updatedRoles = updateRoles(sectionEditable);

                  dispatch(
                    actions.project.updateRolesForProject(
                      projectId,
                      updatedRoles
                    )
                  ).then(function () {
                    // If there are side effects, lets update them
                    var promises = [];

                    if (Object.values(sideEffects).length > 0) {
                      console.log("there are side effects");
                      Object.values(sideEffects).forEach(function (
                        sideEffectedComponent
                      ) {
                        var newPromise = new Promise((resolve, reject) => {
                          dispatch(
                            actions.configs.updateSectionConfigsForProject(
                              projectId,
                              sideEffectedComponent
                            )
                          ).then(function () {
                            // Now lets update the roles
                            var updatedRoles = updateRoles(
                              sideEffectedComponent
                            );

                            dispatch(
                              actions.project.updateRolesForProject(
                                projectId,
                                updatedRoles
                              )
                            ).then(function () {
                              resolve();
                            });
                          });

                          promises.push(newPromise);
                        });
                      });

                      Promise.all(promises).then(function () {
                        if (creation) {
                          message.success("Section Created!");
                          history.push("/p/" + projectId + "/settings");
                        } else {
                          message.success("Updated Section Configs");
                          window.location.reload();
                        }
                      });
                    } else {
                      // If no side effects, let's reload the page
                      if (creation) {
                        message.success("Section Created!");
                        history.push("/p/" + projectId + "/settings");
                      } else {
                        message.success("Updated Section Configs");
                        window.location.reload();
                      }
                    }
                  });
                });
              }
            }}
            className="publish-button"
          >
            {creation ? "Create Section" : "Publish Changes"}
          </div>
        )}

        {!sectionChangesMade && (
          <div className="publish-button disabled">No Changes to Publish</div>
        )}
        <div style={{ clear: "right" }} />
      </div>
    </div>
  );
}

export function SettingsSection({ title, children }) {
  return (
    <div className="settings-section">
      <div className="settings-section-header">{title}</div>
      <div className="settings-section-inner">{children}</div>
    </div>
  );
}
