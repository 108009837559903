import { useState, useEffect } from "react";
import _ from "lodash";
import { has, distinct, getSubObjects } from "../../utils";

/**
 * UseTableOrder Hook
 * TODO: Custom hook for now, need to see if it needs to be a full hook or just a function
 * @param {object} project
 * @param {object} component
 * @param {object} collections
 */
export default function useTableOrder(
  project,
  component,
  collections = {},
  loading
) {
  const [tableOrder, setTableOrder] = useState([]);
  const [existingProjectViewConfigs, setExistingProjectViewConfigs] = useState(
    {}
  );

  useEffect(() => {
    // We only want to build the table order once collections are done loading
    // Otherwise we'll be buiding with incomplete information

    if (!loading && component.fields) {
      const necessaryKeys = [
        component.info.collectionKey,
        ...getSubObjects(component),
      ];

      const loadedKeys = Object.keys(collections);

      // Only proceed if all the necessary collections are loaded
      if (necessaryKeys.every((key) => loadedKeys.includes(key))) {
        const allObjects = [
          component.info.collectionKey,
          ...getSubObjects(component),
        ].flatMap((key) => Object.keys(collections?.[key] ?? []));

        const tableObjects = tableOrder.flatMap((orderItem) => [
          orderItem.id,
          ...orderItem.items_order,
        ]);

        const unsortedObjectsExist =
          !allObjects?.every((value) => tableObjects.includes(value)) ||
          !tableObjects.every((value) => allObjects?.includes(value));

        const pivotValuesAreFixed = arePivotValuesFixed(component);

        if (unsortedObjectsExist || pivotValuesAreFixed) {
          setTableOrder(buildTableOrder(project, component, collections));
        }
      }
    }
  }, [collections, loading, project]);

  return [tableOrder, setTableOrder];
}

/**
 * Main logic to build the table order
 * @param {object} project
 * @param {object} component
 * @param {object} collections
 */
const buildTableOrder = (project, component, collections) => {
  // If collections or component data is empty, return empty list
  if (_.isEmpty(collections) || _.isEmpty(collections)) {
    return [];
  }

  const collectionKey = component.info.collectionKey;
  const objects = collections[collectionKey] ?? {};

  // Get all objects - we want them all in the order, filteing will happen on render
  const tableObjects = Object.values(objects);

  console.log("tableObjects", tableObjects);
  console.log("component is ", component);
  console.log("collections: ", collections);
  console.log("pivotIsObject: ", isPivotObjectType(component));

  const pivotIsObjectType = isPivotObjectType(component);
  const pivotValuesAreFixed = arePivotValuesFixed(component);

  // Build pivot map based on getPivot logic (see function definition for pivot logic)
  const pivots = buildPivots(
    component,
    tableObjects,
    pivotIsObjectType,
    collections
  );

  console.log("pivots are", pivots);

  // Get existing order from project config if specified
  const existingOrder = getExistingOrder(project, collectionKey);

  console.log("existing order is", existingOrder);

  // Get section keys that may be already specified
  // Filter, keeping sections that do have objects, or if the pivot is an object and the object is exists
  const oldSectionKeys = existingOrder
    .map((section) => section.id)
    .filter(
      (key) =>
        tableObjects.find((object) => pivots[object.id] === key) ||
        (pivotIsObjectType && objectExists(component, collections, key))
    );

  // Get distinct section keys that are not part of the existing config
  const newSectionKeys = tableObjects
    .map((object) => pivots[object.id])
    .filter((pivot) => !oldSectionKeys.includes(pivot));

  const newObjectKeys = pivotIsObjectType
    ? Object.values(getSecondaryObjects(component, collections)).map(
        (object) => object.id
      )
    : [];

  // If there are new secondary objects we want them to float to the top
  let difference = pivotIsObjectType
    ? newObjectKeys.filter((x) => !oldSectionKeys.includes(x))
    : [];

  console.log("difference is ", difference);
  console.log("oldSectionKeys is ", oldSectionKeys);
  console.log("newSectionKeys is ", newSectionKeys);

  // Combine old + new section keys
  var allSectionKeys = difference
    .concat(oldSectionKeys)
    .concat(newSectionKeys)
    .filter(distinct);

  // However, if the pivots are fixed we want to use this fixed order
  if (pivotValuesAreFixed) {
    allSectionKeys = component.table.pivotByFixedValues.map((value) =>
      value.toString()
    );
  }

  // Now use the section keys to build a list
  const newOrder = allSectionKeys.map((sectionKey) => {
    const existindOrderSectionEntry = existingOrder.find(
      (item) => item.id === sectionKey
    );

    // Filter out items from the old config that do not exist anymore
    const oldItemsOrder = existindOrderSectionEntry
      ? existindOrderSectionEntry.items_order.filter((item) =>
          tableObjects.find(
            (object) =>
              object.id === item &&
              pivots[object.id] === existindOrderSectionEntry.id
          )
        )
      : [];

    // Build new items order from listing ids of items that should be
    // part of current section, and are not part of the old order
    const newItemsOrder = tableObjects
      .filter(
        (object) =>
          pivots[object.id] === sectionKey && !oldItemsOrder.includes(object.id)
      )
      .map((object) => object.id);

    return {
      id: sectionKey,
      items_order: oldItemsOrder.concat(newItemsOrder),
    };
  });

  console.log("newOrder", newOrder);

  return newOrder;
};

/**
 * Get the order specified in the project view_config
 * @param {object} project
 * @param {string} collectionKey
 */
const getExistingOrder = (project, collectionKey) =>
  has(project, `view_configs.default.configs.${collectionKey}.table.order`) &&
  project.view_configs.default.configs[collectionKey].table.order
    ? project.view_configs.default.configs[collectionKey].table.order
    : [];

/**
 * Check the component config to figure out type of value that the pivot is
 * Right now, this only supports pivoting on the main object
 * @param {object} component
 */
const isPivotObjectType = (component) => {
  if (!component.table) {
    return false;
  }

  return (
    component.table.pivotBy &&
    component.fields[component.table.pivotBy] &&
    component.fields[component.table.pivotBy].fieldData.type === "object"
  );
};

/**
 * Check component config to see if pivotValues are fixed
 * @param {object} component
 */
const arePivotValuesFixed = (component) => {
  if (component?.table?.pivotByFixedValues == null) {
    return false;
  }

  component.table.pivotByFixedValues.forEach(function (value) {
    if (component.fields[value] == null) {
      return false;
    }
  });

  return true;
};

/**
 * Function to build pivot map for table objects
 * Pivot Logic:
 *  - If there is no pivot value, the pivot key is '_general'
 *  - If the pivot value of an object is null, pivot is 'undefined'
 *  - If the pivot value of an object exists:
 *    - If the pivot type is value, then the pivot key is the value
 *    - If the pivot type is object,
 *      - If the pivot object exists then pivot key is the value (other object id)
 *      - If the pivot object does not exist, then the pivot is 'undefined'
 * @param {*} component
 * @param {*} objects
 * @param {*} pivotIsObjectType
 * @param {*} collections
 */
const buildPivots = (component, objects, pivotIsObjectType, collections) => {
  const secondaryObjects =
    pivotIsObjectType && collections
      ? getSecondaryObjects(component, collections)
      : {};

  console.log("secondary objects are", secondaryObjects);

  // Pivot logic implementation
  const getPivot = (object) => {
    if (component.table?.pivotBy) {
      if (object[component.table.pivotBy] == null) {
        return "undefined";
      } else if (
        pivotIsObjectType &&
        secondaryObjects[object[component.table.pivotBy]] == null
      ) {
        return "undefined";
      } else {
        return object[component.table.pivotBy].toString();
      }
    } else {
      return "_general";
    }
  };

  let pivots = {};
  Object.values(objects).forEach(
    (object) => (pivots[object.id] = getPivot(object))
  );

  return pivots;
};

const objectExists = (component, collections, objectId) => {
  if (collections) {
    const secondaryObjects = getSecondaryObjects(component, collections);
    return !_.isEmpty(secondaryObjects[objectId]);
  } else {
    return false;
  }
};

const getSecondaryObjects = (component, collections) => {
  let secondaryObjects = {};

  getSubObjects(component)
    .filter((key) => key !== component.info.collectionKey && collections?.[key])
    .forEach((key) => {
      secondaryObjects = { ...secondaryObjects, ...collections[key] };
    });

  return secondaryObjects;
};
