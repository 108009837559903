import object, { deleteAttachmentFromFirebase } from "./object";
import { id, has } from "../components/utils";
import notifications from "../api/notifications";
import firebase, { db } from "../firebase.js";

export const loadHistoryToObject = (history, objectId) => {
  return {
    type: "LOAD_HISTORY",
    history: history,
    objectId: objectId,
  };
};

export const addHistoryAction = (history, objectId) => {
  return {
    type: "ADD_HISTORY",
    history: history,
    objectId: objectId,
  };
};

export const fetchHistoryFromFirebase = (
  projectId,
  objectId,
  setUnsubscribe
) => (dispatch) =>
  new Promise((resolve) => {
    var collectionRef = db
      .collection("projects")
      .doc(projectId)
      .collection("history")
      .where("documentId", "==", objectId);

    const unsubscribe = collectionRef.onSnapshot((snapshot) => {
      var objects = {};

      let updateComesFromLocal = false;

      snapshot.forEach((doc) => {
        updateComesFromLocal =
          doc.metadata.hasPendingWrites || updateComesFromLocal;

        if (doc.exists) {
          // We actually don't want to import any sort of data for badge counts
          if (
            !doc.data().delta ||
            !doc.data().delta[0].path ||
            doc.data().delta[0].path[0] != "badges"
          ) {
            objects[doc.id] = doc.data();
            objects[doc.id]["activity"] = true;

            if (!doc.data().id || doc.data().id != doc.id) {
              objects[doc.id].id = doc.id;
            }
          }
        }
      });

      // See https://firebase.google.com/docs/firestore/query-data/listen#events-local-changes
      // Firebase is firing for local changes, but we already handle local updates through redux actions directly
      // Our redux updates fire faster and this way keeps our coupling with firebase down
      // So to avoid duplicate loadCollection calls, we should only reload collections when the *remote* data changes
      if (!updateComesFromLocal) {
        dispatch(loadHistoryToObject(objects, objectId));
      } else {
      }

      setUnsubscribe(unsubscribe);
    });
  });

export default {
  fetchHistoryFromFirebase: fetchHistoryFromFirebase,
};
