import React from "react";

export default function MoreIcon(props) {
  return (
    <svg
      width="4.5px"
      height="18px"
      viewBox="0 0 4 18"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <title>more</title>
      <desc>Created with Sketch.</desc>
      <g
        id="Page-1-Copy"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g id="more" fill="#000000" fillRule="nonzero">
          <g id="Group">
            <path
              d="M-5,11 C-6.1045695,11 -7,10.1045695 -7,9 C-7,7.8954305 -6.1045695,7 -5,7 C-3.8954305,7 -3,7.8954305 -3,9 C-3,10.1045695 -3.8954305,11 -5,11 Z M2,11 C0.8954305,11 -1.77635684e-15,10.1045695 -1.77635684e-15,9 C-1.77635684e-15,7.8954305 0.8954305,7 2,7 C3.1045695,7 4,7.8954305 4,9 C4,10.1045695 3.1045695,11 2,11 Z M9,11 C7.8954305,11 7,10.1045695 7,9 C7,7.8954305 7.8954305,7 9,7 C10.1045695,7 11,7.8954305 11,9 C11,10.1045695 10.1045695,11 9,11 Z"
              id="Shape"
              transform="translate(2.000000, 9.000000) rotate(90.000000) translate(-2.000000, -9.000000) "
            ></path>
          </g>
        </g>
      </g>
    </svg>
  );
}
