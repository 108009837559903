import React from "react";
import { useDispatch } from "react-redux";
import actions from "../../actions";
import { DragDropContext } from "react-beautiful-dnd";
import sendNotification from "../../api/notifications/sendNotification";

import _ from "lodash";

export default function TableDragDropContext({
  component,
  project,
  projectId,
  currentUser,
  objects,
  tableOrder,
  setTableOrder,
  children,
}) {
  const collectionKey = component.info.collectionKey;
  const dispatch = useDispatch();

  /**
   * Function to reorder items in a list for drag and drop
   * @param {any[]} list Original list of items
   * @param {function} visibleFilter Function to filter list for visible items
   * @param {number} startIndex The starting index of the item being moved
   * @param {number} endIndex The end index of the item being moved
   */
  const reorder = (list, visibleFilter, startIndex, endIndex) => {
    let start = startIndex;
    let end = endIndex;

    const visibleItems = list.filter(visibleFilter);
    if (visibleItems.length !== list.length) {
      start = list.indexOf(visibleItems[startIndex]);
      end = list.indexOf(visibleItems[endIndex]);
    }

    const result = _.cloneDeep(list);
    const [removed] = result.splice(start, 1);
    result.splice(end, 0, removed);

    return result;
  };

  /**
   * Moves an item from one list to another list.
   */
  const move = (source, destination, droppableSource, droppableDestination) => {
    const sourceClone = Array.from(source);
    const destClone = Array.from(destination);

    // Handle cases with objects filtered out by updating indices to consider hidden objects
    const movedItem = source.filter((item) => objects[item] != null)[
      droppableSource.index
    ];
    const sourceIndex = source.indexOf(movedItem);
    const destinationItem = destination.filter((item) => objects[item] != null)[
      droppableDestination.index
    ];
    const destIndex = destination.indexOf(destinationItem);

    const [removed] = sourceClone.splice(sourceIndex, 1);
    destClone.splice(destIndex, 0, removed);

    return {
      [droppableSource.droppableId]: sourceClone,
      [droppableDestination.droppableId]: destClone,
    };
  };

  const onDragEnd = (result) => {
    const { source, destination, draggableId } = result;

    const getChildArray = (parentId) => {
      let parent = tableOrder.find((parentTemp) => parentTemp.id === parentId);
      return parent.items_order || [];
    };

    if (!destination) {
      return;
    }

    if (source.droppableId === destination.droppableId) {
      // This currently only applies to re-ordering non pivot items
      // When ready, write a detection for parent items

      if (result.type == "section") {
        // Now lets replace the array within the parent
        var tableOrderTemp = _.cloneDeep(tableOrder);

        const new_items_order = reorder(
          tableOrderTemp,
          () => true, // Currently we cannot hide sections, so no visibility filtering is needed
          source.index,
          destination.index
        );

        setTableOrder(new_items_order);
        dispatch(
          actions.project.updateCollectionViewConfig(
            projectId,
            collectionKey,
            "table",
            "order",
            new_items_order
          )
        );
      }

      if (result.type == "row") {
        // Now lets replace the array within the parent
        var tableOrderTemp = _.cloneDeep(tableOrder);

        tableOrderTemp.find((o, i) => {
          if (o.id === source.droppableId) {
            const new_items_order = reorder(
              tableOrderTemp[i].items_order,
              (row) => objects[row] != null, // 'objects' only contains visible rows, so use this to filter for visibility
              source.index,
              destination.index
            );

            tableOrderTemp[i].items_order = new_items_order;
            return true; // stop searching
          }
        });

        setTableOrder(tableOrderTemp);

        dispatch(
          actions.project.updateCollectionViewConfig(
            projectId,
            collectionKey,
            "table",
            "order",
            tableOrderTemp
          )
        );
      }
    } else {
      const result = move(
        getChildArray(source.droppableId),
        getChildArray(destination.droppableId),
        source,
        destination
      );

      // Now lets replace the array within the parent
      var tableOrderTemp = Array.from(tableOrder);
      var index = 0;

      tableOrderTemp.forEach(function (tempOrder) {
        if (tempOrder.id === source.droppableId) {
          const new_items_order = result[source.droppableId];

          tableOrderTemp[index].items_order = new_items_order;
        }

        if (tempOrder.id === destination.droppableId) {
          const new_items_order = result[destination.droppableId];

          tableOrderTemp[index].items_order = new_items_order;
        }

        index = index + 1;
      });

      // We need to update this new object's pivot by to the new value

      var tempObject = Object.assign({}, objects[draggableId]);
      tempObject[component.table.pivotBy] = destination.droppableId;

      dispatch(
        actions.project.updateCollectionViewConfig(
          projectId,
          collectionKey,
          "table",
          "order",
          tableOrderTemp
        )
      );

      dispatch(
        actions.collections.updateObjectInCollection(
          tempObject,
          collectionKey,
          projectId,
          component.badge?.rules
        )
      );

      sendNotification(
        collectionKey,
        null,
        "update",
        project,
        tempObject,
        currentUser,
        component
      );

      setTableOrder(tableOrderTemp);
    }
  };

  return <DragDropContext onDragEnd={onDragEnd}>{children}</DragDropContext>;
}
