export const currentUser = (state = null, action) => {
  switch (action.type) {
    case "UPDATE_BALANCE":
      return {
        ...state,
        balance: action.balance,
      };
    case "LOGIN_USER":
      return action.user;
    case "UPDATE_USER":
      return action.user;
    default:
      return state;
  }
};
