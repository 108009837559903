import { listenForNewNotifications, fetchNotifications } from "./notifications";

export const loginUser = (user) => (dispatch) => {
  dispatch(fetchNotifications(user));
  dispatch(listenForNewNotifications(user));
  dispatch({
    type: "LOGIN_USER",
    user: user,
  });
};

export const logoutUser = () => {
  return {
    type: "LOGOUT_USER",
  };
};

export const importHistory = (id) => {
  return {
    type: "IMPORT_HISTORY",
    id: id,
  };
};
