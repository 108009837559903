import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Draggable } from "react-beautiful-dnd";
import { scrollToRef, deepEqualCheck } from "../utils";
import TableRowFooter from "./TableRowFooter";
import TableField from "./Fields";
import actions from "../../actions";
import sendNotification from "../../api/notifications/sendNotification";
import { useHistory } from "react-router-dom";

import _ from "lodash";

function TableRow({
  object,
  projectId,
  project,
  component,
  index,
  components,
}) {
  const history = useHistory();

  const [objectEditable, setObjectEditable] = useState(
    Object.assign({}, object)
  );
  const [editedFields, setEditedFields] = useState([]);

  const currentUser = useSelector((state) => state.currentUser, deepEqualCheck);
  const collaborators = useSelector(
    (state) => state.collaborators[projectId] || {},
    deepEqualCheck
  );

  const roles = useSelector((state) => state.roles[projectId]);

  const dispatch = useDispatch();

  const objectRef = useRef(null);

  useEffect(() => {
    const query = new URLSearchParams(history.location.search);
    const openQuery = "open";

    if (query.has("item") && query.get("item") === object.id && objectRef) {
      scrollToRef(objectRef);
      setTableRowHighlighted(true);

      if (!query.has(openQuery) || !query.get(openQuery)) {
        setTableRowHighlighted(false, 4000);
      }
    }
  }, [history.location.search]);

  // If object is updated and is out of sync with objectEditable, we will
  // set object editable (this happens with remote changes and realtime)
  useEffect(() => {
    if (!_.isEqual(object, objectEditable)) {
      setObjectEditable({ ...object });
    }
  }, [object]);

  function updateObject(newValue, type) {
    const objectTemp = Object.assign({}, objectEditable);
    objectTemp[type] = newValue;

    if (!editedFields.includes(type)) {
      editedFields.push(type);
      setEditedFields(editedFields);
    }

    let notificationType = "update";

    // If you're updating the index row of an item for the first time, and
    // it was created within an hour, we'll treat the notification as "new"
    if (
      objectTemp[type] &&
      !objectEditable[type] &&
      type == component?.table?.index_row_key
    ) {
      const dateCreated = objectEditable.created;
      const ONE_HOUR = 60 * 60 * 1000;
      if (dateCreated && new Date() - dateCreated.toDate() < ONE_HOUR) {
        notificationType = "new";
      }
    }

    sendNotification(
      component.info.collectionKey,
      type,
      notificationType,
      project,
      objectTemp,
      currentUser,
      component,
      collaborators
    );

    setObjectEditable(objectTemp);

    dispatch(
      actions.collections.updateObjectInCollection(
        objectTemp,
        component.info.collectionKey,
        projectId,
        component.badge?.rules
      )
    );
  }

  const setTableRowHighlighted = (enabled, highlightDisableDelay) => {
    const currentRef = objectRef.current;
    const highlightClass = "highlight";
    // The baseZ class is necessary on the table row to give a smooth "highlight -> no highlight" transition
    const baseZ = "base-z";

    if (currentRef.classList) {
      if (enabled) {
        if (currentRef.classList) {
          currentRef.classList.add(baseZ);
          currentRef.classList.add(highlightClass);
        }
      } else {
        return setTimeout(() => {
          if (currentRef.classList) {
            currentRef.classList.remove(highlightClass);
            setTimeout(
              () => currentRef.classList.remove(baseZ),
              highlightDisableDelay + 500
            );
          }
        }, highlightDisableDelay);
      }
    }
  };

  // Check if there is an index row, if so, put it at the front
  let hasIndexField = false;
  let indexField = null;

  if (component.table != null && component.table.index_row_key != null) {
    hasIndexField = true;

    const field = component.fields[component.table.index_row_key];

    if (field != null) {
      indexField = (
        <TableField
          field={field}
          key={component.table.index_row_key}
          value={objectEditable[field.valueKey]}
          projectId={projectId}
          updateValue={updateObject}
          collectionKey={component.info.collectionKey}
          isIndexRow={true}
          objectId={object.id}
          project={project}
          created_by_user={objectEditable.created_by_user}
          components={components}
        />
      );
    }
  }

  var tableColumns = [];

  if (component.table.columns != null) {
    tableColumns = component.table.columns;
  } else {
    tableColumns = Object.keys(component.fields);
  }

  return (
    <Draggable draggableId={object.id} key={object.id} index={index}>
      {(provided) => {
        return (
          <div
            ref={provided.innerRef}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            data-cy={"row-draghandle_" + object.id}
          >
            <div
              className="table-row"
              ref={objectRef}
              data-cy={"table-row-" + object.id}
            >
              {hasIndexField && indexField}

              {tableColumns.map((key) => {
                const field = component.fields[key];

                if (
                  component.table?.index_row_key !== key &&
                  field != null &&
                  roles[currentUser.role]?.permissions?.sections[
                    component.info.collectionKey
                  ]?.fields[field.valueKey]?.read != false
                ) {
                  return (
                    <TableField
                      field={field}
                      key={key + "_" + object.id}
                      value={objectEditable[field.valueKey]}
                      projectId={projectId}
                      updateValue={updateObject}
                      collectionKey={component.info.collectionKey}
                      objectId={object.id}
                      project={project}
                      collectionKey={component.info.collectionKey}
                      created_by_user={objectEditable.created_by_user}
                      components={components}
                    />
                  );
                }
              })}

              <TableRowFooter
                object={object}
                projectId={projectId}
                allowComments={true}
                component={component}
                setTableRowHighlighted={setTableRowHighlighted}
              />
            </div>
          </div>
        );
      }}
    </Draggable>
  );
}

/*
  return true if passing nextProps to render would return
  the same result as passing prevProps to render,
  otherwise return false
  */
const rowEqual = (prevProps, nextProps) => {
  const isEqual =
    _.isEqual(prevProps.component, nextProps.component) &&
    _.isEqual(prevProps.components, nextProps.components) &&
    _.isEqual(prevProps.object, nextProps.object) &&
    prevProps.index == nextProps.index;

  return isEqual;
};

export default React.memo(TableRow, rowEqual);
