import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";

const TRUNCATE_LENGTH = 75;

export default function ParsedNotificationText({
  notification,
  noLink,
  truncateText,
}) {
  const markdownTextResult = parseNotificationMarkdown(notification.text);

  return (
    <span>
      {markdownTextResult
        .filter((item) => item !== "")
        .map((item, index) => {
          if (typeof item === "string") {
            if (item.includes("**")) {
              return item.split("**").map((item, j) => {
                if (j === 1) {
                  return (
                    <strong>
                      {capitalizeFirstAndMaybeTruncate(
                        item.replace("**", ""),
                        index,
                        truncateText
                      )}
                    </strong>
                  );
                } else {
                  return (
                    <FlexSpan>
                      {capitalizeFirstAndMaybeTruncate(
                        item,
                        index,
                        truncateText
                      )}
                    </FlexSpan>
                  );
                }
              });
            } else {
              return (
                <FlexSpan key={index}>
                  {capitalizeFirstAndMaybeTruncate(item, index, truncateText)}
                </FlexSpan>
              );
            }
          } else if (noLink) {
            return (
              <a href={`${process.env.REACT_APP_HOST}${item.link}`}>
                {capitalizeFirstAndMaybeTruncate(
                  item.text,
                  index,
                  truncateText
                )}
              </a>
            );
          } else if (notification.status === "unread") {
            return (
              <Link style={{ fontWeight: "450" }} key={index} to={item.link}>
                {capitalizeFirstAndMaybeTruncate(
                  item.text,
                  index,
                  truncateText
                )}
              </Link>
            );
          } else {
            return (
              <Link
                style={{ color: "#A0A0A0", fontWeight: "450" }}
                key={index}
                to={item.link}
              >
                {capitalizeFirstAndMaybeTruncate(
                  item.text,
                  index,
                  truncateText
                )}
              </Link>
            );
          }
        })}
    </span>
  );
}

export const getParsedNotificationText = (
  notification,
  noLink,
  truncateText = false
) => (
  <ParsedNotificationText
    notification={notification}
    noLink={noLink}
    truncateText={truncateText}
  />
);

const parseNotificationMarkdown = (notificationText) => {
  const markdownGroups = [];
  let textSegments = [notificationText];
  notificationText.replace(
    /\[([^\]]*)\]\(([^)]*)\)/g,
    (fullText, group1, group2) => {
      markdownGroups.push({
        fullText,
        text: group1 === "undefined" ? "(Empty Item Name)" : group1,
        link: group2,
      });
      const lastSegment = textSegments[textSegments.length - 1];
      const splitText = lastSegment.split(fullText);
      textSegments[textSegments.length - 1] = splitText[0];
      textSegments.push({
        fullText,
        text: group1 === "undefined" ? "(Empty Item Name)" : group1,
        link: group2,
      });
      textSegments.push(splitText[1]);
      return fullText;
    }
  );
  return textSegments;
};

export const capitalizeFirstAndMaybeTruncate = (
  string,
  index,
  shouldTruncate = false
) => {
  string =
    index === 0 ? string.charAt(0).toUpperCase() + string.substring(1) : string;
  return shouldTruncate ? truncate(string) : string;
};

export const truncate = (string) =>
  string.length > TRUNCATE_LENGTH
    ? string.substring(0, TRUNCATE_LENGTH) + "..."
    : string;

const FlexSpan = styled.span`
  // display: flex;
  width: 80%;
  font-weight: 325;
`;
