import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Menu, Dropdown, Popconfirm, message } from "antd";
import CardHeader from "./CardHeader";
import CardFooter from "./CardFooter";
import { Card } from "../Objects";
import Field from "./Fields";
import actions from "../../actions";
import sendNotification from "../../api/notifications/sendNotification";
import { requiredFieldsSatisfiedForObject } from "../utils";
import "./Card.scss";
import jsonLogic from "json-logic-js";
import ActionModal from "./ActionModal";

export default function ObjectCard({
  object,
  highlight,
  projectId,
  project,
  component,
  components,
}) {
  const [isEditing, setIsEditing] = useState(false);
  const [objectEditable, setObjectEditable] = useState(
    Object.assign({}, object)
  );
  const [highlightActive, setHighlightActive] = useState(false);
  const [editedFields, setEditedFields] = useState([]);
  const dispatch = useDispatch();
  const currentUser = useSelector((state) => state.currentUser);
  const collaborators = useSelector(
    (state) => state.collaborators[projectId] || {}
  );
  const roles = useSelector((state) => state.roles[projectId]);

  const [activeModalAction, setActiveModalAction] = useState(null);

  const objectRef = useRef(null);

  // If there are any clean up we have to do (linked fields, attachments, etc..) store them here
  // Linked fields
  const [processedLinkedFields, setProcessedLinkedFields] = useState([]);

  // Effect to scroll down the page if highlighted
  useEffect(() => {
    // Set the highlight state
    if (highlight) {
      setHighlightActive(true);

      setTimeout(() => {
        setHighlightActive(false);
      }, 2000);

      window.scrollTo({
        top: objectRef.current.offsetTop,
        left: 0,
      });
    }
  }, []);

  useEffect(() => {
    if (object) {
      setObjectEditable(Object.assign({}, object));
    }
  }, [object]);

  function updateObject(newValue, type) {
    setObjectEditable((oldObject) => {
      const objectTemp = Object.assign({}, oldObject);
      objectTemp[type] = newValue;

      return objectTemp;
    });

    setEditedFields((oldFields) => {
      if (!oldFields.includes(type)) {
        const fieldsTemp = Object.assign([], oldFields);
        fieldsTemp.push(type);

        return fieldsTemp;
      }

      return oldFields;
    });
  }

  function toggleEditing() {
    const objectTemp = Object.assign({}, object);

    setIsEditing(!isEditing);
    setObjectEditable(objectTemp);
    setProcessedLinkedFields([]);
  }

  function resetEditing() {
    const objectTemp = Object.assign({}, object);

    setObjectEditable(objectTemp);
    setProcessedLinkedFields([]);
  }

  function confirmDeletion() {
    dispatch(
      actions.collections.removeObjectFromCollection(
        objectEditable,
        component.info.collectionKey,
        projectId,
        component.badge?.rules
      )
    );
  }

  function copyStringToClipboard(str) {
    // Create new element
    var el = document.createElement("textarea");
    // Set value (string to be copied)
    el.value = str;
    // Set non-editable to avoid focus and move outside of view
    el.setAttribute("readonly", "");
    el.style = { position: "absolute", left: "-9999px" };
    document.body.appendChild(el);
    // Select text inside element
    el.select();
    // Copy text to clipboard
    document.execCommand("copy");
    // Remove temporary element
    document.body.removeChild(el);
  }

  function toggleCopy() {
    const url = `${window.location.origin}${window.location.pathname}?item=${object.id}`;
    copyStringToClipboard(url);
    message.success("Link copied to clipboard!");
  }

  function saveChanges() {
    const requiredFieldsResult = requiredFieldsSatisfiedForObject(
      component.fields,
      objectEditable
    );

    if (!requiredFieldsResult.satisfied) {
      requiredFieldsResult.missing.forEach((missingField) => {
        message.error(missingField.title + " is required and cannot be blank");
      });
    } else {
      editedFields.forEach((field) =>
        sendUpdateNotification(field, objectEditable)
      );

      setEditedFields([]);

      dispatch(
        actions.collections.updateObjectInCollection(
          objectEditable,
          component.info.collectionKey,
          projectId,
          component.badge?.rules
        )
      );

      // If there are any linked fields, we need to process them
      if (processedLinkedFields.length > 0) {
        processedLinkedFields.forEach(function (linkedField) {
          dispatch(
            actions.collections.updateObjectInCollection(
              linkedField.updatedObject,
              linkedField.itemType,
              linkedField.projectId,
              null
            )
          );
        });
      }

      setIsEditing(false);
    }
  }

  function getCardStyle() {
    if (highlightActive) {
      return {
        padding: "30px",
        boxShadow: "inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px #79b0fa99",
      };
    } else {
      return {
        padding: "30px",
      };
    }
  }

  const sendUpdateNotification = (field, data) => {
    sendNotification(
      component.info.collectionKey,
      field,
      "update",
      project,
      data,
      currentUser,
      component,
      collaborators
    );
  };

  const menu = (
    <Menu>
      <Menu.Item key="0">
        <a onClick={toggleCopy} style={{ cursor: "pointer" }}>
          Share {component.info.vocabulary.singular_upper}
        </a>
      </Menu.Item>
      {roles[currentUser.role]?.permissions?.sections[
        component.info.collectionKey
      ]?.edit && (
        <Menu.Item key="1">
          <a onClick={toggleEditing} style={{ cursor: "pointer" }}>
            Edit
          </a>
        </Menu.Item>
      )}

      {roles[currentUser.role]?.permissions?.sections[
        component.info.collectionKey
      ]?.delete && (
        <Menu.Item key="2">
          <Popconfirm
            placement="leftTop"
            title={
              "Are you sure you want to delete this " +
              component.info.vocabulary.singular_upper +
              "?"
            }
            onConfirm={confirmDeletion}
            okText="Yes"
            cancelText="No"
          >
            <a>Delete</a>
          </Popconfirm>
        </Menu.Item>
      )}
    </Menu>
  );

  return (
    <div>
      <div ref={objectRef} data-cy={object.id + "_" + "card"}>
        <Card style={getCardStyle()}>
          <div
            style={{
              display: "inline-block",
              float: "right",
            }}
          >
            <Dropdown overlay={menu} trigger={["click"]}>
              <div style={{ cursor: "pointer" }}>
                <img
                  src={require("../../images/more.svg")}
                  style={{
                    display: "inline",
                    height: "17px",
                    marginRight: "6px",
                  }}
                />
              </div>
            </Dropdown>
          </div>

          {object.created_by_user && (
            <CardHeader
              projectId={projectId}
              userId={object.created_by_user}
              object={object}
              objectName={component.info.vocabulary.singular_upper}
            />
          )}

          <div style={{ textAlign: "left" }}>
            {component.cardlist.fieldOrder != null &&
              component.cardlist.fieldOrder.map(function (key) {
                const field = component.fields[key];
                if (
                  !field.showRules ||
                  jsonLogic.apply(field.showRules, object)
                ) {
                  return (
                    <Field
                      field={field}
                      key={key}
                      value={objectEditable[field.valueKey]}
                      projectId={projectId}
                      isEditing={isEditing}
                      updateValue={updateObject}
                      collectionKey={component.info.collectionKey}
                      objectId={object.id}
                      components={components}
                      processedLinkedFields={processedLinkedFields}
                      setProcessedLinkedFields={setProcessedLinkedFields}
                    />
                  );
                }
              })}

            {component.cardlist.fieldOrder == null &&
              Object.keys(component.fields).map(function (key) {
                const field = component.fields[key];

                if (
                  !field.showRules ||
                  jsonLogic.apply(field.showRules, object)
                ) {
                  return (
                    <Field
                      field={field}
                      key={key}
                      value={objectEditable[field.valueKey]}
                      projectId={projectId}
                      isEditing={isEditing}
                      updateValue={updateObject}
                      collectionKey={component.info.collectionKey}
                      objectId={object.id}
                      components={components}
                      processedLinkedFields={processedLinkedFields}
                      setProcessedLinkedFields={setProcessedLinkedFields}
                    />
                  );
                }
              })}

            {isEditing && (
              <div>
                <div className="multiButtonSection">
                  <div onClick={() => toggleEditing()} className="cancelButton">
                    Cancel
                  </div>

                  <div className="createButton" onClick={saveChanges}>
                    Save
                  </div>
                </div>

                <div style={{ clear: "right" }}> </div>
              </div>
            )}

            <div>
              <CardFooter
                isEditing={isEditing}
                actionButtons={component?.cardlist?.actionButtons}
                sendUpdateNotification={sendUpdateNotification}
                object={object}
                projectId={projectId}
                allowComments={true}
                component={component}
                setActiveModalAction={setActiveModalAction}
              />
            </div>

            {activeModalAction && (
              <ActionModal
                objectEditable={objectEditable}
                projectId={projectId}
                updateObject={updateObject}
                collectionKey={component.info.collectionKey}
                object={object}
                components={components}
                processedLinkedFields={processedLinkedFields}
                setProcessedLinkedFields={setProcessedLinkedFields}
                action={activeModalAction}
                setActiveModalAction={setActiveModalAction}
                saveChanges={saveChanges}
                resetEditing={resetEditing}
              />
            )}

            <div style={{ clear: "right" }}> </div>
          </div>
        </Card>
      </div>
    </div>
  );
}
