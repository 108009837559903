import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";
import { Dropdown, Popover } from "antd";
import { Droppable } from "react-beautiful-dnd";
import useTableOrder from "./hooks/tableOrder";
import TableDragDropContext from "./TableDragDropContext";
import TableMenu from "./TableMenu";
import { newTimestamp, getProjectFilters, deepEqualCheck } from "../utils";
import actions from "../../actions";
import TableSection from "./TableSection";
import TableLoading from "./TableLoading";
import TableRow from "./TableRow";
import jsonLogic from "json-logic-js";
import "./Table.scss";

import { useHistory } from "react-router";

let tableLoadCount = 0;

function Table({
  component,
  setPage,
  project,
  projectId,
  currentUser,
  components,
}) {
  const collectionKey = component.info.collectionKey;

  const history = useHistory();

  const dispatch = useDispatch();

  const collections = useSelector(
    (state) => state.collections[projectId],
    deepEqualCheck
  );

  const roles = useSelector((state) => state.roles[projectId]);

  const loading = useSelector((state) => state.loading, deepEqualCheck);

  const [hideComplete, setHideComplete] = useState(false);
  const [showLoading, setShowLoading] = useState(loading);
  const [newItemDropdownActive, setNewItemDropdownActive] = useState(false);

  const [tableOrder, setTableOrder] = useTableOrder(
    project,
    component,
    collections,
    loading
  );

  useEffect(() => {
    setPage(collectionKey);
  }, []);

  useEffect(() => {
    // If hideComplete changes then we want to reset the table order
  }, [hideComplete]);

  useEffect(() => {
    const filters = getProjectFilters(project, collectionKey);

    if (
      filters.or?.find((filter) =>
        _.isEqual(filter, { "!=": [{ var: "status" }, "Complete"] })
      )
    ) {
      setHideComplete(true);
    }
  }, []);

  useEffect(() => {
    if (
      !_.isEqual(project.default?.[collectionKey]?.table?.order, tableOrder) &&
      tableOrder.length > 0
    ) {
      dispatch(
        actions.project.updateCollectionViewConfig(
          projectId,
          collectionKey,
          "table",
          "order",
          tableOrder
        )
      );
    }
  }, [tableOrder]);

  useEffect(() => {
    const blurActiveElement = () => document.activeElement.blur();
    // add when mounted
    document.addEventListener("mousedown", blurActiveElement);
    // return function to be called when unmounted
    return () => {
      document.removeEventListener("mousedown", blurActiveElement);
    };
  }, []);

  // By setting visibility setting in useEffect, we give the table
  // section a chance to render before showing it. This eliminates
  // a pause between skeleton and table for big projects
  useEffect(() => {
    setShowLoading(loading);
  }, [loading]);

  // Set the local objects based on the data from the collection selector
  let objects = collections?.[collectionKey] ?? {};

  const userCanCreate =
    roles[currentUser.role]?.permissions?.sections[collectionKey]?.create;

  // TODO: This is a temporary function, replace it with a real function
  // to edit the filters for the view
  const toggleCompleteItems = () => {
    // If we have a configuration for filters, lets see what it is and act
    const filtersAreEmpty = _.isEmpty(
      getProjectFilters(project, collectionKey)
    );

    const filters = getProjectFilters(project, collectionKey);

    const existingOrFilters = filters.or ?? [];

    var qaIsPresent = false;

    Object.values(component.fields).forEach(function (field) {
      if (field.title == "Client Approval") {
        qaIsPresent = true;
      }
    });

    const newFilters = filtersAreEmpty
      ? qaIsPresent
        ? {
            or: [
              ...existingOrFilters,
              { "!=": [{ var: "status" }, "Complete"] },
              { "!=": [{ var: "qa_status" }, "Approved"] },
            ],
          }
        : {
            or: [
              ...existingOrFilters,
              { "!=": [{ var: "status" }, "Complete"] },
            ],
          }
      : {};

    dispatch(
      actions.project.updateCollectionViewConfig(
        projectId,
        collectionKey,
        "table",
        "filters",
        newFilters
      )
    );
    setHideComplete(!hideComplete);
  };

  const createObject = (collectionKey) => {
    const object = {};

    // This is added because some legacy objectConfigs actually use
    // "created" as an editable field, such as Standups
    object.created = newTimestamp();

    // This is the next source of truth for creation, but those that don't
    // have this should rely on "created"
    object.created_timestamp = newTimestamp();
    object.created_by_user = currentUser.id;

    if (component.table.pivotBy && component.fields) {
      // If the collection key matches the components primary key then this means
      // it is the primary object, not the secondary pivot object
      if (collectionKey == component.info.collectionKey) {
        const fields = component.fields;

        // // If pivot is an object, then add it to the first available object
        // if (fields[component.table.pivotBy].fieldData.type == "object") {
        //   // if no objects available, just leave it null
        //   var pivotKey =
        //     fields[component.table.pivotBy].fieldData.collectionKey;
        //   var pivotObjects = collections[pivotKey] || {};

        //   if (pivotObjects && Object.keys(pivotObjects).length != 0) {
        //     // Once order is implemented, we'll get the first order object here
        //   }
        // }

        // If pivot simply a value, use the default
        if (fields[component.table.pivotBy].fieldData.type != "object") {
          object[component.table.pivotBy] =
            fields[component.table.pivotBy].fieldData.defaultValue;
        }

        Object.keys(fields).forEach((key) => {
          var field = fields[key];
          if (field.required || field.fieldData.defaultValue) {
            // Fill it in
            if (field.fieldData.defaultValue != null) {
              object[key] = field.fieldData.defaultValue;
            }
          }
        });
      } else {
        // If this is the secondary object, we want to make sure it has all defaults possible
        // iterate through defaults and fill them in if there isn't any
        // TODO - Hardcoded right now, come up with better way to support secondary objects in the future

        var keyToPivotBy =
          component.fields[component.table.pivotBy].fieldData.collectionKey;

        const fields = components[keyToPivotBy]?.fields || {};

        Object.keys(fields).forEach((key) => {
          var field = fields[key];
          if (field.required || field.fieldData.defaultValue) {
            // Fill it in
            if (field.fieldData.defaultValue != null) {
              object[key] = field.fieldData.defaultValue;
            }
          }
        });
      }
    }

    dispatch(
      actions.collections.addObjectToCollection(
        object,
        collectionKey,
        projectId,
        component.badge?.rules
      )
    );
  };

  if (!component.table) {
    return <div>Table view not supported for this component</div>;
  }

  if (!component.fields) {
    return (
      <div>
        There are no fields for this table, please add some in the editor
        (settings)
      </div>
    );
  }

  const filterObjects = (project, collectionKey, objects) => {
    const filters = getProjectFilters(project, collectionKey);

    let result = {};
    Object.keys(objects)
      .filter((objectKey) => jsonLogic.apply(filters, objects[objectKey]))
      .forEach((key) => (result[key] = objects[key]));
    return result;
  };

  objects = filterObjects(project, collectionKey, objects);

  var objectToCreateKey = null;

  const fields = component.fields;

  if (fields && fields[component.table.pivotBy]?.fieldData?.type == "object") {
    // If pivotBy is an object, we want to create this instead
    objectToCreateKey = fields[component.table.pivotBy].fieldData.collectionKey;
  } else {
    // If pivot by is not an object then we want to create a normal object
    // The reason being if it is completely empty
    objectToCreateKey = component.info.collectionKey;
  }

  return (
    <TableDragDropContext
      component={component}
      project={project}
      projectId={projectId}
      currentUser={currentUser}
      objects={objects}
      tableOrder={tableOrder}
      setTableOrder={setTableOrder}
    >
      <div className="table-section" data-cy="table-root">
        <div className="table-ribbon" data-cy="table-actions">
          <Popover
            placement="bottomLeft"
            title={"Select View"}
            content={
              <div className="toggle-menu">
                <div
                  className="toggle-inline-button selected"
                  style={{ cursor: "not-allowed" }}
                >
                  <img
                    className="toggle-inline-button-icon"
                    src={require("../../images/tableview-toggle.svg")}
                  />
                  Table
                  <img
                    className="toggle-inline-button-check"
                    src={require("../../images/check-blue.svg")}
                  />
                </div>
                {component.cardlist?.enabled != false && (
                  <div
                    onClick={() => {
                      history.push(
                        "/p/" + projectId + "/cardlist/" + collectionKey
                      );
                    }}
                    className="toggle-inline-button"
                  >
                    <img
                      className="toggle-inline-button-icon"
                      src={require("../../images/cardlist-toggle.svg")}
                    />
                    Cardlist
                  </div>
                )}
              </div>
            }
            trigger="click"
          >
            <div className="toggle-button">
              <img
                className="toggle-icon"
                src={require("../../images/tableview-toggle.svg")}
              />{" "}
              Table
              <img
                className="toggle-arrow"
                src={require("../../images/down-arrow.svg")}
              />
            </div>
          </Popover>

          {collectionKey == "status_items" && (
            <div
              style={{
                marginRight: "20px",
              }}
              className="filter-button"
              onClick={toggleCompleteItems}
            >
              {hideComplete ? `Show Complete` : `Hide Complete`}
            </div>
          )}

          {userCanCreate && objectToCreateKey && (
            <div
              onClick={(e) => createObject(objectToCreateKey)}
              className="topButton"
              style={{
                float: "right",
              }}
            >
              New{" "}
              {components[objectToCreateKey]?.info?.vocabulary?.singular_upper}
            </div>
          )}

          <div style={{ clear: "right" }} />
        </div>
        <TableLoading hidden={!showLoading} />
        <div
          style={{ width: "100%", overflowX: "auto", paddingBottom: "100px" }}
          hidden={showLoading}
        >
          <div className="table-section-header" data-cy="table-body">
            <div className="block">
              <Droppable droppableId="table" type="section">
                {(provided) => (
                  <div ref={provided.innerRef} {...provided.droppableProps}>
                    {tableOrder.map((parent, index) => {
                      const sectionObjects = parent.items_order
                        .filter((itemId) => objects[itemId] != null)
                        .map((itemId) => objects[itemId]);

                      return (
                        <TableSection
                          component={component}
                          project={project}
                          projectId={projectId}
                          sectionHeaderValue={parent.id}
                          key={parent.id}
                          index={index}
                          objects={sectionObjects}
                          order={parent.items_order}
                        >
                          {sectionObjects.map((object, index) => (
                            <TableRow
                              key={object.id}
                              object={object}
                              project={project}
                              projectId={projectId}
                              component={component}
                              components={components}
                              index={index}
                            />
                          ))}
                        </TableSection>
                      );
                    })}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </div>
          </div>
        </div>
      </div>
    </TableDragDropContext>
  );
}

export default React.memo(Table);
