import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Badge } from "antd";
import { copyStringToClipboard, hasUnreadThreads } from "../utils";
import { ThreadView, CommentDrawer } from "../Objects";
import { Menu, Dropdown, Popconfirm, message } from "antd";
import actions from "../../actions";
import ChatBubbleIcon from "../../images/chat_bubble_gray";
import MoreIcon from "../../images/more";

export default function TableRowFooter({
  object,
  projectId,
  allowComments,
  component,
  setTableRowHighlighted,
}) {
  const currentUser = useSelector((state) => state.currentUser);
  const roles = useSelector((state) => state.roles[projectId]);

  const [commentsVisible, setCommentsVisible] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    const query = new URLSearchParams(window.location.search);
    const openQuery = "open";

    if (query.get("item") === object.id && query.get(openQuery)) {
      setCommentsVisible(true);
    }
  }, [window.location.search]);

  const confirmDeletion = () => {
    dispatch(
      actions.collections.removeObjectFromCollection(
        object,
        component.info.collectionKey,
        projectId,
        component.badge?.rules
      )
    );
  };

  const toggleCopy = () => {
    const url = `${window.location.origin}${window.location.pathname}?item=${object.id}`;
    copyStringToClipboard(url);
    message.success("Link copied to clipboard!");
  };

  const toggleCommentsVisible = () => {
    setCommentsVisible(!commentsVisible);
    setTableRowHighlighted(!commentsVisible, 1000);

    const searchQuery = new URLSearchParams(window.location.search);
    const openKey = "open";

    if (!commentsVisible) {
      searchQuery.set("item", object.id);
      searchQuery.set(openKey, true);
    } else {
      searchQuery.delete("item");
      if (searchQuery.has(openKey)) {
        searchQuery.delete(openKey);
      }
    }

    const nextUrl = `${window.location.pathname}${
      commentsVisible ? "" : "?"
    }${searchQuery}`;

    window.history.pushState(null, null, nextUrl);
  };

  const menu = (
    <Menu>
      <Menu.Item key="0">
        <a onClick={toggleCopy} style={{ cursor: "pointer" }}>
          Share {component.info.vocabulary.singular_upper}
        </a>
      </Menu.Item>
      {roles[currentUser.role]?.permissions?.sections[
        component.info.collectionKey
      ]?.delete && (
        <Menu.Item key="2">
          <Popconfirm
            placement="leftTop"
            title={
              "Are you sure you want to delete this " +
              component.info.vocabulary.singular_upper +
              "?"
            }
            onConfirm={confirmDeletion}
            okText="Yes"
            cancelText="No"
          >
            <a>Delete</a>
          </Popconfirm>
        </Menu.Item>
      )}
    </Menu>
  );

  return (
    <div className="table-cell footer">
      <div className="table-footer-row" data-cy="table-footer-actions">
        <CommentDrawer
          title={
            component.info.vocabulary.singular_upper +
            " #" +
            (object.ext_id || 0)
          }
          visible={commentsVisible}
          onClose={toggleCommentsVisible}
        >
          <ThreadView
            key={object.id}
            projectId={projectId}
            object={object}
            type={component.info.collectionKey}
            component={component}
            visible={commentsVisible}
          />
        </CommentDrawer>

        {allowComments && (
          <div
            onClick={toggleCommentsVisible}
            style={{ minWidth: "20px", cursor: "pointer", marginRight: "4px" }}
          >
            <div
              style={{
                fontWeight: 400,
                fontFamily: "Avenir Next",
                fontSize: "1vw",
                width: "100%",
              }}
              className="expand"
              data-cy={"row-openthread_" + object.id}
            >
              {object.badges &&
                object.badges.threads != null &&
                object.badges.threads != 0 && (
                  <div style={{ marginRight: "12px" }}>
                    <Badge
                      style={
                        hasUnreadThreads(currentUser, object)
                          ? { backgroundColor: "#7f05f5" }
                          : { backgroundColor: "#969696" }
                      }
                      count={object.badges.threads}
                    >
                      <ChatBubbleIcon
                        style={{
                          display: "inline",
                          height: "25px",
                          width: "25px",
                        }}
                      />
                    </Badge>
                  </div>
                )}

              {(!object.badges || !object.badges.threads) && (
                <ChatBubbleIcon
                  style={{
                    display: "inline",
                    height: "25px",
                    width: "25px",
                    marginRight: "8px",
                  }}
                />
              )}
            </div>
          </div>
        )}

        <div
          className="table-more-dropdown"
          data-cy={"row-openmore_" + object.id}
        >
          <Dropdown overlay={menu} trigger={["click"]}>
            <div style={{ cursor: "pointer" }}>
              <MoreIcon
                style={{
                  display: "inline",
                  height: "20px",
                  marginLeft: "2px",
                }}
              />
            </div>
          </Dropdown>
        </div>
      </div>
    </div>
  );
}
