import firebase from "../../firebase";

export const callCloudFunction = (functionName, functionData) =>
  new Promise((resolve, reject) => {
    var cloudFunction = firebase.functions().httpsCallable(functionName);

    cloudFunction(functionData)
      .then(function (result) {
     //   console.log("Function Success: " + JSON.stringify(result));
        resolve(result);
      })
      .catch(function (err) {
      //  console.log("Delete failed, see console,");
        console.warn(err);
        reject(err);
      });
  });
