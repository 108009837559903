import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { Badge } from "antd";
import _ from "lodash";
import FeatureToggle, { On, Off, featureTypes } from "../FeatureToggle";
import {
  setNotificationsVisible,
  setNotificationsProject,
} from "../../actions/notifications";

export default function ProjectNotificationBadge({ project, children }) {
  const dispatch = useDispatch();
  const currentUser = useSelector((state) => state.currentUser);

  const notificationCount =
    currentUser.projectUnreadNotifications?.[project.id];

  return (
    <FeatureToggle feature={featureTypes.NATIVE_NOTIFICATIONS}>
      <On>
        <Badge
          count={notificationCount}
          overflowCount={999}
          style={{ cursor: "pointer" }}
          onClick={(event) => {
            // If the user clicks on the badge, then we want to show project-specific notifications
            // Identify the badge by checking the target class list for "ant"
            if (event.target.className?.includes("ant")) {
              dispatch(setNotificationsVisible(true));
              dispatch(setNotificationsProject(project));
            }
          }}
        >
          {children}
        </Badge>
      </On>
      <Off>{children}</Off>
    </FeatureToggle>
  );
}
