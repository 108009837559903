import React, { useState, useRef, useEffect } from "react";

import { Input, Select, Tooltip } from "antd";

import { TrashIcon } from "@heroicons/react/outline";

import _ from "lodash";

const { Option } = Select;

export default function ReferenceField({
  error,
  isEditing,
  field,
  updateField,
  disabled,
  components,
  setSideEffects,
  currentSectionId,
  isNew,
}) {
  const [fieldEditable, setFieldEditable] = useState(field);

  const [addNewFieldVisible, setAddNewFieldVisible] = useState(false);

  function updateTitle(e) {
    var value = e.target.value;

    var fieldCopy = _.cloneDeep(field);

    fieldCopy["title"] = value;

    setFieldEditable(fieldCopy);
  }

  function sendUpdate() {
    updateField(fieldEditable);
  }

  return (
    <div>
      <div className="fields">
        <div class="header-fields">
          <img
            className="header-icon"
            style={{ height: "17px" }}
            src={require("../../../../images/reference_icon.svg")}
          />
          <span className="header-text">Reference Field</span>
          {disabled && (
            <Tooltip
              placement="right"
              title={"This is a default field and cannot be edited"}
            >
              <img
                className="locked-icon"
                src={require("../../../../images/locked_icon.svg")}
              />
            </Tooltip>
          )}
        </div>
        <div className="input-box-with-title">
          <label class="required">
            Field Title{" "}
            {error?.title && (
              <span style={{ marginLeft: "5px", color: "#ff5858" }}>
                Field Cannot be Blank
              </span>
            )}
          </label>
          <div className={"input-value" + (error?.title ? " error" : "")}>
            <Input
              placeholder={"Example: Location Met"}
              value={fieldEditable.title}
              onChange={(e) => updateTitle(e)}
              disabled={disabled}
              onBlur={() => sendUpdate()}
            />
          </div>
        </div>
        {fieldEditable.fieldData?.itemTypes &&
          fieldEditable.fieldData?.itemTypes.map((itemType) => {
            return (
              <ReferencedSection
                sectionToReferenceId={itemType}
                error={error}
                setFieldEditable={setFieldEditable}
                updateField={updateField}
                components={components}
                fieldEditable={fieldEditable}
                currentSectionId={currentSectionId}
                setSideEffects={setSideEffects}
                isNew={isNew}
              />
            );
          })}

        <div>
          {!addNewFieldVisible && (
            <div
              className="add-option-button"
              onClick={() => {
                setAddNewFieldVisible(true);
              }}
            >
              Add linked section
            </div>
          )}
          {addNewFieldVisible && (
            <>
              <label class="required">Choose a section to link</label>
              <div className="add-subfield">
                <Select
                  style={{ width: "100%" }}
                  onChange={(value) => {
                    // For now, we are just supporting one item type per field
                    // It is an array so we can support multiple eventually
                    var fieldCopy = _.cloneDeep(fieldEditable);

                    if (!fieldCopy["fieldData"]) {
                      fieldCopy["fieldData"] = {};
                    }

                    if (!fieldCopy["fieldData"]["itemTypes"]) {
                      fieldCopy["fieldData"]["itemTypes"] = [];
                    }

                    fieldCopy["fieldData"]["itemTypes"].push(value);

                    setFieldEditable(fieldCopy);

                    updateField(fieldCopy);

                    setAddNewFieldVisible(false);
                  }}
                >
                  {Object.values(components).map(function (component) {
                    if (component) {
                      return (
                        <Option
                          value={component.info.collectionKey}
                          key={component.info.collectionKey}
                        >
                          {component.info.name}
                        </Option>
                      );
                    }
                  })}
                </Select>

                <div
                  className="add-option-button"
                  onClick={() => {
                    setAddNewFieldVisible(false);
                  }}
                >
                  Cancel
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
}

export function ReferencedSection({
  error,
  sectionToReferenceId,
  setFieldEditable,
  updateField,
  components,
  fieldEditable,
  currentSectionId,
  setSideEffects,
  isNew,
}) {
  const [createLinkedField, setCreateLinkedField] = useState(false);
  const [updateLinkedFields, setUpdateLinkedFields] = useState(false);
  const [newLinkedFieldTitle, setNewLinkedFieldTitle] = useState("");
  const [newValueKey, setNewValueKey] = useState("");

  useEffect(() => {
    if (
      isNew &&
      !fieldEditable?.fieldData?.linkedFields?.[sectionToReferenceId]
    ) {
      setCreateLinkedField(true);

      var newId = makeid(5);
      setNewValueKey(newId);
      updateSyncedField(newId);
    }
  }, [isNew]);

  useEffect(() => {
    var sectionIdToEdit = sectionToReferenceId;

    if (createLinkedField && sectionIdToEdit) {
      // Let's create a new field

      var newField = {
        title: newLinkedFieldTitle,
        valueKey: newValueKey,
        fieldData: {
          type: "reference",
          itemTypes: [currentSectionId],
          linkedFields: {},
        },
      };

      newField["fieldData"]["linkedFields"][currentSectionId] =
        fieldEditable?.valueKey;

      // Let's add the new field to the component
      var componentToAddLinkedField = _.cloneDeep(components[sectionIdToEdit]);

      componentToAddLinkedField["fields"][newValueKey] = newField;

      // Let's add it to the cardlist and tableview order
      var cardlist = componentToAddLinkedField["cardlist"];

      if (cardlist?.fieldOrder) {
        cardlist["fieldOrder"].push(newValueKey);

        componentToAddLinkedField["cardlist"] = cardlist;
      }

      var tableView = componentToAddLinkedField["table"];

      if (tableView?.columns) {
        tableView["columns"].push(newValueKey);

        componentToAddLinkedField["table"] = tableView;
      }

      setSideEffects((prev) => {
        return {
          ...prev,
          [sectionIdToEdit]: componentToAddLinkedField,
        };
      });
    } else if (updateLinkedFields && sectionIdToEdit) {
      // Remove it from the side effects object

      // If we are linking to an exisitng referenced field, let's see if it exists already and if not, add it

      var componentToAddLinkedField = _.cloneDeep(components[sectionIdToEdit]);

      if (fieldEditable?.fieldData?.linkedFields?.[sectionIdToEdit]) {
        var linkedFieldToAdd =
          fieldEditable.fieldData.linkedFields[sectionIdToEdit];

        if (componentToAddLinkedField["fields"][linkedFieldToAdd]) {
          // if the current item type does not exist, add it

          var newField = componentToAddLinkedField["fields"][linkedFieldToAdd];

          var linkedFields = newField.fieldData.linkedFields;
          var itemTypes = newField.fieldData.itemTypes;

          if (itemTypes && !itemTypes.includes(currentSectionId)) {
            // Let's add the item type if it doesn't exist already*

            newField.fieldData.itemTypes = [...itemTypes, currentSectionId];
          }

          newField.fieldData.linkedFields[currentSectionId] =
            fieldEditable?.valueKey;

          componentToAddLinkedField["fields"][linkedFieldToAdd] = newField;

          setSideEffects((prev) => {
            return {
              ...prev,
              [sectionIdToEdit]: componentToAddLinkedField,
            };
          });
        }
      }
    } else {
      setSideEffects((prev) => {
        return {
          ...prev,
          [sectionIdToEdit]: null,
        };
      });
    }
  }, [
    createLinkedField,
    newLinkedFieldTitle,
    fieldEditable?.fieldData?.itemTypes,
    newValueKey,
    updateLinkedFields,
  ]);

  function updateSyncedField(syncedFieldId) {
    var fieldCopy = _.cloneDeep(fieldEditable);

    if (!fieldCopy["fieldData"]) {
      fieldCopy["fieldData"] = {};
    }

    if (!fieldCopy["fieldData"]["linkedFields"]) {
      fieldCopy["fieldData"]["linkedFields"] = {};
    }

    fieldCopy["fieldData"]["linkedFields"][sectionToReferenceId] =
      syncedFieldId;

    setFieldEditable(fieldCopy);

    updateField(fieldCopy);

    setUpdateLinkedFields(true);
  }

  function removeSyncedField() {
    var fieldCopy = _.cloneDeep(fieldEditable);

    if (!fieldCopy["fieldData"]) {
      fieldCopy["fieldData"] = {};
    }

    if (!fieldCopy["fieldData"]["linkedFields"]) {
      fieldCopy["fieldData"]["linkedFields"] = {};
    }

    fieldCopy["fieldData"]["linkedFields"][sectionToReferenceId] = null;

    setFieldEditable(fieldCopy);

    updateField(fieldCopy);
    setUpdateLinkedFields(false);
  }

  return (
    <div className="subfield-container">
      <TrashIcon
        className="subfield-delete-icon"
        onClick={() => {
          // Remove the field from the type and sync field
          var fieldCopy = _.cloneDeep(fieldEditable);

          fieldCopy["fieldData"]["itemTypes"] = fieldCopy["fieldData"][
            "itemTypes"
          ].filter((itemType) => itemType !== sectionToReferenceId);

          if (fieldCopy["fieldData"]["linkedFields"][sectionToReferenceId]) {
            fieldCopy["fieldData"]["linkedFields"][sectionToReferenceId] = null;
          }

          if (createLinkedField) {
            setSideEffects((prev) => {
              return {
                ...prev,
                [sectionToReferenceId]: null,
              };
            });
          }

          setFieldEditable(fieldCopy);
          updateField(fieldCopy);
        }}
      />
      <div className="input-box-with-title">
        <label class="required">Section to reference</label>
        <div className={"input-value" + (error?.title ? " error" : "")}>
          {components[sectionToReferenceId]?.info?.name}
        </div>
      </div>

      {!createLinkedField &&
        sectionToReferenceId &&
        components[sectionToReferenceId] && (
          <div className="input-box-with-title">
            <label class="required">
              Sync with existing reference field in the{" "}
              {components[sectionToReferenceId]?.info?.name} section
            </label>
            <div className={"input-value" + (error?.title ? " error" : "")}>
              <Select
                style={{ width: "100%" }}
                value={
                  fieldEditable?.fieldData?.linkedFields
                    ? fieldEditable?.fieldData?.linkedFields[
                        sectionToReferenceId
                      ]
                    : null
                }
                onChange={(value) => {
                  // For now, we are just supporting one item type per field
                  // It is an array so we can support multiple eventually
                  if (value != "none_") {
                    updateSyncedField(value);
                  } else {
                    updateSyncedField(null);
                  }
                }}
              >
                {Object.values(components[sectionToReferenceId].fields).map(
                  function (field) {
                    if (field && field?.fieldData?.type === "reference") {
                      return (
                        <Option value={field.valueKey} key={field.valueKey}>
                          {field.title}
                        </Option>
                      );
                    }
                  }
                )}
                <Option value={"none_"} key={"none_"}>
                  None
                </Option>
              </Select>
            </div>
          </div>
        )}

      <div className="input-box-with-title">
        {!createLinkedField &&
          sectionToReferenceId &&
          components[sectionToReferenceId] && (
            <>
              <div
                className="add-option-button"
                onClick={() => {
                  setCreateLinkedField(true);

                  var newId = makeid(5);
                  setNewValueKey(newId);
                  updateSyncedField(newId);
                }}
              >
                or create one
              </div>
              <div
                onClick={() => {
                  removeSyncedField();
                  setNewValueKey(null);
                  setCreateLinkedField(false);
                }}
                className="add-option-button"
              >
                or don't create a sync field
              </div>
            </>
          )}

        {createLinkedField && (
          <div className="input-box-with-title">
            <label class="required">
              Field title for synced{" "}
              {components[sectionToReferenceId]?.info?.name} field{" "}
              {error?.title && (
                <span style={{ marginLeft: "5px", color: "#ff5858" }}>
                  Field Cannot be Blank
                </span>
              )}
            </label>
            <div className={"input-value" + (error?.title ? " error" : "")}>
              <Input
                placeholder={"Example: Location Met"}
                value={newLinkedFieldTitle}
                onChange={(e) => setNewLinkedFieldTitle(e.target.value)}
              />
            </div>
            <div
              onClick={() => {
                updateSyncedField(null);
                setNewValueKey(null);
                setCreateLinkedField(false);
              }}
              className="add-option-button"
            >
              or use existing
            </div>
            <div
              onClick={() => {
                removeSyncedField();
                setNewValueKey(null);
                setCreateLinkedField(false);
              }}
              className="add-option-button"
            >
              or don't create a sync field
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

function makeid(length) {
  var result = "";
  var characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  var charactersLength = characters.length;
  for (var i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
}
