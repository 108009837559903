import React, { useState, useRef, useEffect } from "react";

import Fields from "../Fields";

import { Switch, Popconfirm, message, Tooltip } from "antd";

import "./Fields.scss";

import _ from "lodash";

import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

export default function Field({
  field,
  updateField,
  deleteField,
  duplicateField,
  index,
  isNew,
  error,
  components,
  currentSectionId,
  setSideEffects,
  creation,
}) {
  const [required, setRequired] = useState(field.required);
  const [hidden, setHidden] = useState(field.hidden);
  const [editingDisabled, setEditingDisabled] = useState(field.editingDisabled);

  var disabled = false;
  var deletable = true;

  if (field.editable != null && field.editable == false) {
    disabled = true;
  }

  if (field.deletable != null && field.deletable == false) {
    deletable = false;
  }

  function updateFieldValueForKey(key, value) {
    var fieldCopy = _.cloneDeep(field);

    fieldCopy[key] = value;

    updateField(fieldCopy);
  }

  function updateRequired(isRequired) {
    var fieldCopy = _.cloneDeep(field);

    fieldCopy["required"] = isRequired;

    setRequired(isRequired);

    updateField(fieldCopy);
  }

  function updateHidden(isHidden) {
    var fieldCopy = _.cloneDeep(field);

    fieldCopy["hidden"] = isHidden;

    setHidden(isHidden);

    updateField(fieldCopy);
  }

  function updateEditingDisabled(disabled) {
    var fieldCopy = _.cloneDeep(field);

    fieldCopy["editingDisabled"] = disabled;

    setEditingDisabled(disabled);

    updateField(fieldCopy);
  }

  const objectRef = useRef(null);

  useEffect(() => {
    if (isNew && objectRef) {
      setTableRowHighlighted(true);

      setTableRowHighlighted(false, 4000);
    }
  }, [isNew]);

  const setTableRowHighlighted = (enabled, highlightDisableDelay) => {
    const currentRef = objectRef.current;
    const highlightClass = "new";
    // The baseZ class is necessary on the table row to give a smooth "highlight -> no highlight" transition
    const baseZ = "base-z";

    if (currentRef.classList) {
      if (enabled) {
        if (currentRef.classList) {
          currentRef.classList.add(baseZ);
          currentRef.classList.add(highlightClass);
        }
      } else {
        return setTimeout(() => {
          if (currentRef.classList) {
            currentRef.classList.remove(highlightClass);
            setTimeout(
              () => currentRef.classList.remove(baseZ),
              highlightDisableDelay + 500
            );
          }
        }, highlightDisableDelay);
      }
    }
  };

  if (field.fieldData.type == "object") {
    return null;
  }

  return (
    <div>
      <div className="field-container-2" ref={objectRef}>
        <div style={{ display: "flex" }}>
          <div style={{ width: "100%" }}>
            {(field.fieldData.type == "text" ||
              field.fieldData.type == "textArea") && (
              <Fields.Text
                isEditing={true}
                field={field}
                updateField={updateField}
                disabled={disabled}
                error={error}
              />
            )}

            {field.fieldData.type == "number" && (
              <Fields.Number
                isEditing={true}
                field={field}
                updateField={updateField}
                disabled={disabled}
                error={error}
              />
            )}

            {(field.fieldData.type == "date" ||
              field.fieldData.type == "date_with_age") && (
              <Fields.Date
                isEditing={true}
                field={field}
                updateField={updateField}
                disabled={disabled}
                error={error}
              />
            )}

            {field.fieldData.type == "attachments" && (
              <Fields.Attachment
                isEditing={true}
                field={field}
                updateField={updateField}
                disabled={disabled}
              />
            )}

            {field.fieldData.type == "link" && (
              <Fields.Link
                isEditing={true}
                field={field}
                updateField={updateField}
                disabled={disabled}
              />
            )}

            {field.fieldData.type == "reference" && (
              <Fields.ReferenceField
                isEditing={true}
                field={field}
                updateField={updateField}
                disabled={disabled}
                components={components}
                currentSectionId={currentSectionId}
                setSideEffects={setSideEffects}
                isNew={isNew}
              />
            )}

            {field.fieldData.type == "avatar" && (
              <Fields.Avatar
                isEditing={true}
                field={field}
                updateField={updateField}
                disabled={disabled}
              />
            )}

            {field.fieldData.type == "collaborator" && (
              <Fields.Collaborator
                isEditing={true}
                field={field}
                updateField={updateField}
                disabled={disabled}
              />
            )}

            {field.fieldData.type == "checkbox" && (
              <Fields.Checkbox
                isEditing={true}
                field={field}
                updateField={updateField}
                disabled={disabled}
              />
            )}

            {field.fieldData.type == "password" && (
              <Fields.Password
                isEditing={true}
                field={field}
                updateField={updateField}
                disabled={disabled}
              />
            )}

            {field.fieldData.type == "user" && (
              <Fields.User
                isEditing={true}
                field={field}
                updateField={updateField}
                disabled={disabled}
              />
            )}

            {field.fieldData.type === "select" && (
              <Fields.Dropdown
                isEditing={true}
                field={field}
                updateField={updateField}
                disabled={disabled}
                error={error}
              />
            )}

            {field.fieldData.type === "tag" && (
              <Fields.Tag
                isEditing={true}
                field={field}
                updateField={updateField}
                disabled={disabled}
                error={error}
              />
            )}

            <div className="field-footer">
              <div className="editor-button">
                <span style={{ marginRight: "5px" }}>Required</span>{" "}
                <Switch
                  size={"small"}
                  onClick={(checked, event) => {
                    updateRequired(checked);
                  }}
                  checked={required}
                  disabled={disabled}
                  style={{
                    backgroundColor: required ? "#5c56ff" : "#C9CCDF",
                  }}
                />
              </div>

              <div className="divider" />

              <div className="editor-button">
                <span style={{ marginRight: "5px" }}>Hidden</span>{" "}
                <Switch
                  size={"small"}
                  onClick={(checked, event) => {
                    updateHidden(checked);
                  }}
                  checked={hidden}
                  disabled={disabled}
                  style={{
                    backgroundColor: hidden ? "#5c56ff" : "#C9CCDF",
                  }}
                />
              </div>

              <div className="divider" />

              <div className="editor-button">
                <span style={{ marginRight: "5px" }}>Editing Disabled</span>{" "}
                <Switch
                  size={"small"}
                  onClick={(checked, event) => {
                    updateEditingDisabled(checked);
                  }}
                  checked={editingDisabled}
                  disabled={disabled}
                  style={{
                    backgroundColor: editingDisabled ? "#5c56ff" : "#C9CCDF",
                  }}
                />
              </div>

              <div className="divider" />

              <div className="editor-button">
                <span style={{ marginRight: "5px" }}>Legacy</span>{" "}
                <Switch
                  size={"small"}
                  onClick={(checked, event) => {
                    updateFieldValueForKey("legacy", checked);
                  }}
                  checked={field.legacy}
                  disabled={disabled}
                  style={{
                    backgroundColor: field.legacy ? "#5c56ff" : "#C9CCDF",
                  }}
                />
              </div>

              <div className="divider" />

              {(disabled ||
                (field.duplicationEnabled != null &&
                  field.duplicationEnabled == false)) && (
                <Tooltip
                  placement="right"
                  title={"This is a default field and cannot be duplicated"}
                >
                  <div className="editor-button">
                    <img
                      onClick={() => {}}
                      style={{
                        cursor: "not-allowed",
                      }}
                      className="editor-icon"
                      src={require("../../../images/duplicate_icon.svg")}
                    />
                  </div>
                </Tooltip>
              )}

              {((!disabled && field.duplicationEnabled == null) ||
                field.duplicationEnabled == true) && (
                <div className="editor-button">
                  <img
                    onClick={() => {
                      duplicateField(field, index);
                    }}
                    style={{
                      cursor: "pointer",
                    }}
                    className="editor-icon"
                    src={require("../../../images/duplicate_icon.svg")}
                  />
                </div>
              )}

              <div className="divider" />
              <div className="editor-button">
                {((!disabled && field.deletable == null) ||
                  field.deletable == true) && (
                  <Popconfirm
                    title={
                      <p
                        style={{
                          maxWidth: "250px",
                          padding: "0px",
                          marginBottom: "0px",
                        }}
                      >
                        Deleting this field will delete all data associated with
                        it, do you want to continue?
                      </p>
                    }
                    onConfirm={() => {
                      if (!disabled) {
                        deleteField(field);
                        message.success("Field deleted");
                      }
                    }}
                    onCancel={() => {}}
                    okText="Yes"
                    cancelText="No"
                  >
                    <img
                      style={{
                        cursor: "pointer",
                      }}
                      className="editor-icon"
                      src={require("../../../images/trashv3_icon.svg")}
                    />
                  </Popconfirm>
                )}
                {(disabled ||
                  (field.deletable != null && field.deletable == false)) && (
                  <Tooltip
                    placement="right"
                    title={"This is a default field and cannot be deleted"}
                  >
                    <img
                      style={{
                        cursor: "not-allowed",
                      }}
                      className="editor-icon"
                      src={require("../../../images/trashv3_icon.svg")}
                    />
                  </Tooltip>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
