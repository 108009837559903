export const people = (state = {data: []}, action) => {
    switch (action.type) {
      case 'LOAD_PERSON':
        return {
          ...state,
          data: state.data.concat(action.person)
        }
      case 'LOAD_PEOPLE':
        return {
          ...state,
          data: action.people
        }
      case 'LOAD_INTERACTIONS_INTO_PEOPLE':
      return {
        ...state,
        data: action.loadedPeople
      }
      default:
        return state
    }
}

export const projects = (state = {data: []}, action) => {
  switch (action.type) {
    case 'LOAD_PROJECTS':
      return {
        ...state,
        data: action.projects,
        loaded: true
      }
    default:
      return state
  }
}

export const project = (state = {data: {}}, action) => {
  switch (action.type) {
    case 'LOAD_PROJECT':
      return {
        ...state,
        data: action.project
      }
    default:
      return state
  }
}

export const files = (state = {data: []}, action) => {
  switch (action.type) {
    case 'ADD_FILE': 
      return {
        ...state,
        data: state.data.concat(action.file)
      }
    case 'LOAD_FILES':
      return {
        ...state,
        data: action.files
      }
    default:
      return state
  }
}


export const network_status = (state = {saved: true, message: "All Changes Saved"}, action) => {
  switch (action.type) {
    case 'CHANGE_STATUS': 
      return {
        ...state,
        saved: action.saved,
        message: action.message
      }
    default:
      return state
  }
}


