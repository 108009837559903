import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Route, Link, useParams, useHistory } from "react-router-dom";
import MediaQuery from "react-responsive";
import "../index.css";

// Actual Components
import CardList from "./CardList";
import Analytics from "./Analytics";
import ProjectSettings from "./ProjectSettings";
import SearchModal from "./Search";
import { Menu, MenuMobile } from "./Objects";

import Toggle, { featureTypes, On, Off } from "../components/FeatureToggle";

import { LoadingOutlined } from '@ant-design/icons';

import { Spin, Button } from "antd";
import qs from "query-string";
import { deepEqualCheck, getReferencedObjects, getSubObjects } from "./utils";
import search_icon from "../images/search_icon.svg";

// Actions
import actions from "../actions";

import Table from "./Table";

import DashBackButton from "../images/dash-back.svg";

import _ from "lodash";

import localRoles from "./ProjectConfigs/Roles";
import SearchInput from "./Search/SearchInput";
import { toggleSearchActive } from "../actions/search";

import Editor from "../components/Editor/Editor.js";
import SectionEditor from "../components/Editor/Section/Editor.js";

export default function App({}) {
  let { projectId } = useParams();

  const project = useSelector((state) => state.project.data, deepEqualCheck);
  const page = useSelector((state) => state.page, deepEqualCheck);
  const roles = useSelector((state) => state.roles[projectId]);

  const dispatch = useDispatch();

  const currentUser = useSelector((state) => state.currentUser, deepEqualCheck);
  const components = useSelector(
    (state) => state.configs[projectId]?.sections,
    deepEqualCheck
  );

  function setPage(page) {
    dispatch(actions.app.setPage(page));
  }

  useEffect(() => {
    let cleanupFunctions = [];

    if (project.id == projectId) {
      if (Object.keys(project).length != 0) {
        const fetchSections = dispatch(
          actions.configs.fetchSectionsFromFirebase(projectId, (func) => {
            cleanupFunctions.push(func);
          })
        );

        Promise.allSettled([fetchSections]).then((result) => {
          // Now that we have the configs

          if (project.roles) {
            dispatch(
              actions.roles.loadRolesToProject(projectId, project.roles)
            );
          } else {
            dispatch(actions.roles.loadRolesToProject(projectId, localRoles));
          }
        });
      }
    }

    return () => {
      cleanupFunctions.forEach((cleanup) => cleanup());
    };
  }, [project.id, projectId]);

  useEffect(() => {
    // Clear out any previous project being used
    let unsubscribeProject = () => {};

    dispatch(
      actions.project.fetchProject(projectId, (func) => {
        unsubscribeProject = func;
      })
    );

    return unsubscribeProject;
  }, [projectId]);

  useEffect(() => {
    let cleanupFunctions = [];

    if (
      projectId == project.id &&
      page &&
      !_.isEmpty(components) &&
      components[page]
    ) {
      const collectionKeys = [
        components[page].info.collectionKey,
        ...getSubObjects(components[page]),
        ...getReferencedObjects(components[page]),
      ];

      const start = Date.now();

      dispatch(actions.app.setLoading(true));

      if (collectionKeys) {
        Promise.all(
          collectionKeys.map((key) => {
            try {
              return dispatch(
                actions.collections.fetchCollectionFromFirebase(
                  key,
                  projectId,
                  null,
                  (func) => {
                    cleanupFunctions.push(func);
                  }
                )
              );
            } catch (error) {
              return Promise.resolve();
            }
          })
        ).then(() => {
          const end = Date.now();
          window.amplitude.getInstance().logEvent("FIREBASE_LOAD_TIME", {
            user_id: currentUser?.id ?? "",
            user_name: currentUser?.name ?? "",
            project_id: project?.id ?? "",
            project_name: project?.name ?? "",
            time: end - start,
          });
          dispatch(actions.app.setLoading(false));
        });
      }
    }

    return () => {
      cleanupFunctions.forEach(function (func) {
        func();
      });
    };
  }, [page, components, projectId, project.id]);

  if (project.collaborators == null || currentUser.email == null) {
    return null;
  }

  if (roles == null) {
    return null;
  }

  function goHome() {
    window.location.replace("/");
  }

  function goToDashboard() {
    window.amplitude.getInstance().logEvent("PROJECT_BACK_BUTTON_SELECTED", {
      project_name: project.name,
      project_id: project.id,
    });
  }

  // Get current user 'role' value from project
  if (project.collaborators) {
    // If the user is a collaborator, get their role
    var collaboratorSettings = project.collaborators.find(
      (collaborator) => collaborator.user === currentUser.id
    );

    if (collaboratorSettings) {
      currentUser["role"] = collaboratorSettings.role;
    } else {
      delete currentUser["role"];
    }
  }
  if (currentUser.role == null) {
    document.title = `Aloa Manage`;

    return (
      <div
        style={{
          position: "fixed",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
        }}
      >
        <img src="https://gw.alipayobjects.com/zos/rmsportal/wZcnGqRDyhPOEYFcZDnb.svg" />
        <div
          style={{
            fontFamily: "Avenir Next",
            fontSize: "30px",
            fontWeight: 500,
            marginTop: "20px",
          }}
        >
          Error 403 (Access Denied)
        </div>
        <Button
          style={{ marginTop: "20px" }}
          onClick={() => goHome()}
          type="primary"
        >
          Go Back Home
        </Button>
      </div>
    );
  }

  if (project.name) {
    document.title = `${project.name} - Aloa Manage`;
  }

  if (!components) {
    return null;
  }

  return (
    <div>
      <div className="home">
        {project ? (
          <div>
            <SearchModal projectId={projectId} components={components} />
            <MediaQuery minWidth={701}>
              <div
                style={{
                  display: "flex",
                  paddingTop: "25px",
                  paddingBottom: "25px",
                  alignItems: "center",
                  marginTop: 10,
                  fontSize: "16px",
                  lineHeight: "15px",
                  justifyContent: "space-between",
                }}
              >
                <div>
                  <div
                    style={{
                      display: "inline-block",
                      alignItems: "center",
                      marginLeft: "5px",
                      marginRight: "16px",
                    }}
                  >
                    <Link to="/">
                      <img
                        style={{ height: "20px" }}
                        src={DashBackButton}
                        onClick={goToDashboard}
                      />{" "}
                    </Link>
                  </div>

                  <div
                    style={{
                      display: "inline-block",
                      verticalAlign: "middle",
                      marginRight: "10px",
                      textAlign: "right",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        fontFamily: "Avenir Next",
                        fontWeight: 400,
                        fontSize: "24px",
                      }}
                      data-cy="project-title"
                    >
                      {project.name}
                    </div>
                  </div>
                </div>

                <Toggle user={currentUser} feature={featureTypes.SEARCH}>
                  <On>
                    <div style={{ float: "right" }}>
                      <SearchInput />
                    </div>
                  </On>
                  <Off />
                </Toggle>
              </div>

              <div style={{ marginTop: "10px" }}>
                <Menu
                  currentUser={currentUser}
                  project={project}
                  components={components}
                />
              </div>
            </MediaQuery>
            <MediaQuery maxWidth={700}>
              <div
                style={{
                  display: "flex",
                  paddingTop: "20px",
                  paddingBottom: "20px",
                  alignItems: "center",
                  marginTop: 10,
                  fontSize: "16px",
                  lineHeight: "15px",
                  marginLeft: "20px",
                  marginRight: "20px",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginLeft: "5px",
                    marginRight: "18px",
                  }}
                >
                  <Link to="/">
                    <img
                      style={{ height: "20px" }}
                      src={DashBackButton}
                      //      onMouseEnter={e => (e.currentTarget.src = require("../images/dash-back-expanded.svg"))}
                      //     onMouseLeave={e => (e.currentTarget.src = require("../images/dash-back.svg"))}
                      onClick={() => goToDashboard()}
                    />{" "}
                  </Link>
                </div>

                <div
                  style={{
                    display: "inline-block",
                    verticalAlign: "middle",
                    marginRight: "10px",
                    textAlign: "right",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      fontFamily: "Avenir Next",
                      fontWeight: 400,
                      fontSize: "22px",
                    }}
                    data-cy="project-title"
                  >
                    {project.name}
                  </div>
                </div>
                <div style={{ float: "right" }}>
                  <img
                    src={search_icon}
                    style={{ height: "22px" }}
                    onClick={() => dispatch(toggleSearchActive())}
                  />
                </div>
              </div>

              <div>
                <MenuMobile
                  style={{ marginTop: "15px" }}
                  currentUser={currentUser}
                  project={project}
                  components={components}
                />
              </div>
            </MediaQuery>
            <div>
              <Route
                exact
                path="/p/:projectId/settings"
                render={(props) => (
                  <div>
                    {roles[currentUser.role]?.permissions?.project?.settings
                      ?.edit && (
                      <div>
                        <ProjectSettings
                          projectId={projectId}
                          setPage={setPage}
                          currentUser={currentUser}
                          project={project}
                          projectId={projectId}
                          history={props.history}
                          components={components}
                        />
                      </div>
                    )}

                    {!roles[currentUser.role]?.permissions?.project?.settings
                      ?.edit && (
                      <div
                        style={{
                          position: "fixed",
                          top: "50%",
                          left: "50%",
                          transform: "translate(-50%, -50%)",
                        }}
                      >
                        <img src="https://gw.alipayobjects.com/zos/rmsportal/wZcnGqRDyhPOEYFcZDnb.svg" />
                        <div
                          style={{
                            fontFamily: "Avenir Next",
                            fontSize: "30px",
                            fontWeight: 500,
                            marginTop: "20px",
                          }}
                        >
                          Error 403 (Access Denied)
                        </div>
                        <Button
                          style={{ marginTop: "20px" }}
                          onClick={() => goHome()}
                          type="primary"
                        >
                          Go Back Home
                        </Button>
                      </div>
                    )}
                  </div>
                )}
              />

              <Route
                exact
                path="/p/:projectId/analytics"
                render={() => (
                  <Toggle user={currentUser} feature={featureTypes.ANALYTICS}>
                    <On>
                      {roles[currentUser.role]?.permissions?.project?.settings
                        ?.edit && (
                        <div>
                          <Analytics
                            projectId={projectId}
                            setPage={setPage}
                            currentUser={currentUser}
                            project={project}
                            projectId={projectId}
                          />
                        </div>
                      )}
                      {!roles[currentUser.role]?.permissions?.project?.settings
                        ?.edit && (
                        <div
                          style={{
                            position: "fixed",
                            top: "50%",
                            left: "50%",
                            transform: "translate(-50%, -50%)",
                          }}
                        >
                          <img src="https://gw.alipayobjects.com/zos/rmsportal/wZcnGqRDyhPOEYFcZDnb.svg" />
                          <div
                            style={{
                              fontFamily: "Avenir Next",
                              fontSize: "30px",
                              fontWeight: 500,
                              marginTop: "20px",
                            }}
                          >
                            Error 403 (Access Denied)
                          </div>
                          <Button
                            style={{ marginTop: "20px" }}
                            onClick={() => goHome()}
                            type="primary"
                          >
                            Go Back Home
                          </Button>
                        </div>
                      )}
                      P
                    </On>
                    <Off>
                      <div
                        style={{
                          position: "fixed",
                          top: "50%",
                          left: "50%",
                          transform: "translate(-50%, -50%)",
                        }}
                      >
                        <img src="https://gw.alipayobjects.com/zos/rmsportal/wZcnGqRDyhPOEYFcZDnb.svg" />
                        <div
                          style={{
                            fontFamily: "Avenir Next",
                            fontSize: "30px",
                            fontWeight: 500,
                            marginTop: "20px",
                          }}
                        >
                          Error 403 (Access Denied)
                        </div>
                        <Button
                          style={{ marginTop: "20px" }}
                          onClick={() => goHome()}
                          type="primary"
                        >
                          Go Back Home
                        </Button>
                      </div>
                    </Off>
                  </Toggle>
                )}
              />

              {Object.keys(components).map(function (key) {
                var component = components[key];

                return (
                  <Route
                    key={key}
                    exact
                    path={"/p/:projectId/editor/section/" + key}
                    render={(props) => (
                      <div>
                        {roles[currentUser.role]?.permissions?.project?.settings
                          ?.edit && (
                          <div>
                            <SectionEditor
                              key={key}
                              id={key}
                              projectId={projectId}
                              setPage={setPage}
                              currentUser={currentUser}
                              project={project}
                              projectId={projectId}
                              query={qs.parse(props.location.search)}
                              component={component}
                              existingKeys={Object.keys(components)}
                              components={components}
                            />
                          </div>
                        )}

                        {!roles[currentUser.role]?.permissions?.project
                          ?.settings?.edit && (
                          <div
                            style={{
                              position: "fixed",
                              top: "50%",
                              left: "50%",
                              transform: "translate(-50%, -50%)",
                            }}
                          >
                            <img src="https://gw.alipayobjects.com/zos/rmsportal/wZcnGqRDyhPOEYFcZDnb.svg" />
                            <div
                              style={{
                                fontFamily: "Avenir Next",
                                fontSize: "30px",
                                fontWeight: 500,
                                marginTop: "20px",
                              }}
                            >
                              Error 403 (Access Denied)
                            </div>
                            <Button
                              style={{ marginTop: "20px" }}
                              onClick={() => goHome()}
                              type="primary"
                            >
                              Go Back Home
                            </Button>
                          </div>
                        )}
                      </div>
                    )}
                  />
                );
              })}

              <Route
                exact
                path={"/p/:projectId/editor/section/create"}
                render={(props) => (
                  <div>
                    {roles[currentUser.role]?.permissions?.project?.settings
                      ?.edit && (
                      <div>
                        <SectionEditor
                          creation={true}
                          projectId={projectId}
                          setPage={setPage}
                          currentUser={currentUser}
                          project={project}
                          projectId={projectId}
                          query={qs.parse(props.location.search)}
                          existingKeys={Object.keys(components)}
                          components={components}
                        />
                      </div>
                    )}

                    {!roles[currentUser.role]?.permissions?.project?.settings
                      ?.edit && (
                      <div
                        style={{
                          position: "fixed",
                          top: "50%",
                          left: "50%",
                          transform: "translate(-50%, -50%)",
                        }}
                      >
                        <img src="https://gw.alipayobjects.com/zos/rmsportal/wZcnGqRDyhPOEYFcZDnb.svg" />
                        <div
                          style={{
                            fontFamily: "Avenir Next",
                            fontSize: "30px",
                            fontWeight: 500,
                            marginTop: "20px",
                          }}
                        >
                          Error 403 (Access Denied)
                        </div>
                        <Button
                          style={{ marginTop: "20px" }}
                          onClick={() => goHome()}
                          type="primary"
                        >
                          Go Back Home
                        </Button>
                      </div>
                    )}
                  </div>
                )}
              />

              {Object.keys(components).map(function (key) {
                var component = components[key];

                return (
                  <Route
                    key={key}
                    exact
                    path={"/p/:projectId/cardlist/" + key}
                    render={(props) => (
                      <div>
                        {(roles[currentUser.role]?.permissions?.sections[key]
                          ?.read ||
                          false) && (
                          <CardList
                            key={key}
                            projectId={projectId}
                            setPage={setPage}
                            currentUser={currentUser}
                            project={project}
                            projectId={projectId}
                            query={qs.parse(props.location.search)}
                            component={component}
                            components={components}
                          />
                        )}

                        {!roles[currentUser.role]?.permissions?.sections[key]
                          ?.read && (
                          <div
                            style={{
                              position: "fixed",
                              top: "50%",
                              left: "50%",
                              transform: "translate(-50%, -50%)",
                            }}
                          >
                            <img src="https://gw.alipayobjects.com/zos/rmsportal/wZcnGqRDyhPOEYFcZDnb.svg" />
                            <div
                              style={{
                                fontFamily: "Avenir Next",
                                fontSize: "30px",
                                fontWeight: 500,
                                marginTop: "20px",
                              }}
                            >
                              Error 403 (Access Denied)
                            </div>
                            <Button
                              style={{ marginTop: "20px" }}
                              onClick={() => goHome()}
                              type="primary"
                            >
                              Go Back Home
                            </Button>
                          </div>
                        )}
                      </div>
                    )}
                  />
                );
              })}

              {Object.keys(components).map(function (key) {
                var component = components[key];

                return (
                  <Route
                    key={key}
                    exact
                    path={"/p/:projectId/table/" + key}
                    render={(props) => (
                      <div>
                        {project.id === projectId &&
                          roles[currentUser.role]?.permissions?.sections[key]
                            ?.read && (
                            <Table
                              key={key}
                              setPage={setPage}
                              project={project}
                              projectId={projectId}
                              query={qs.parse(props.location.search)}
                              component={component}
                              components={components}
                              currentUser={currentUser}
                            />
                          )}

                        {!roles[currentUser.role]?.permissions?.sections[key]
                          ?.read && (
                          <div
                            style={{
                              position: "fixed",
                              top: "50%",
                              left: "50%",
                              transform: "translate(-50%, -50%)",
                            }}
                          >
                            <img src="https://gw.alipayobjects.com/zos/rmsportal/wZcnGqRDyhPOEYFcZDnb.svg" />
                            <div
                              style={{
                                fontFamily: "Avenir Next",
                                fontSize: "30px",
                                fontWeight: 500,
                                marginTop: "20px",
                              }}
                            >
                              Error 403 (Access Denied)
                            </div>
                            <Button
                              style={{ marginTop: "20px" }}
                              onClick={() => goHome()}
                              type="primary"
                            >
                              Go Back Home
                            </Button>
                          </div>
                        )}
                      </div>
                    )}
                  />
                );
              })}
            </div>{" "}
          </div>
        ) : (
          <div style={{ textAlign: "center", marginTop: "20%" }}>
            <Spin
              size="large"
              indicator={<LoadingOutlined style={{ fontSize: 25 }} spin />}
            />
          </div>
        )}
      </div>
    </div>
  );
}
