export default (state = {}, action) => {
  switch (action.type) {
    case "LOAD_NOTIFICATIONS":
      return {
        ...state,
        ...action.notifications,
      };
    case "DELETE_NOTIFICATION":
      if (state[action.notification.id]) {
        delete state[action.notification.id];
      }
      return state;
    case "DELETE_ALL_NOTIFICATIONS":
      return {};
    default:
      return state;
  }
};

export const notificationsState = (
  state = { visible: false, project: null, doNotDisturb: false },
  action
) => {
  switch (action.type) {
    case "SET_NOTIFICATIONS_VISIBLE":
      return {
        ...state,
        visible: action.visible,
      };
    case "SET_NOTIFICATIONS_PROJECT":
      return {
        ...state,
        project: action.project,
      };
    case "SET_DO_NOT_DISTRUB":
      return {
        ...state,
        doNotDisturb: action.doNotDisturb,
      };
    default:
      return state;
  }
};
