import firebase, { db } from "../firebase.js";

export const setOffersAction = (offers) => {
  return {
    type: "SET_OFFERS",
    offers: offers,
  };
};

export const fetchOffers = () => (dispatch, getState) =>
  new Promise(() => {
    db.collection("growth")
      .get()
      .then(function (querySnapshot) {
        var offers = [];

        querySnapshot.forEach(function (doc) {
          // doc.data() is never undefined for query doc snapshots
          var offer = doc.data();

          offers.push(offer);
        });

        dispatch(setOffersAction(offers));
      });
  });

export default {
  fetchOffers,
};
