import React, { Component } from 'react';
import typography from '@material-ui/core/Typography';
import { Card } from '../Objects'

export default class SettingsCard extends Component {
    constructor(props) {
        super(props);
  
        this.state = {
          passReveal: false
        };
        this.deleteCredential = this.deleteCredential.bind(this)
    }

    handleClick() {
        this.setState({ passReveal: !this.state.passReveal });
    }

    deleteCredential = () => {
        var credential = this.props.cred;
        this.props.deleteCredential(credential, this.props.projectId);
    }


    render() {
        return(
            <div>      
                <div
                    style={{
                    fontSize: 16,
                    fontWeight: typography.fontWeightMedium,
                    paddingBottom: 15, 
                    color: "#4e4e4e",
                    fontFamily: "Brown",
                    fontWeight: 500,
                    textAlign: "left"
                }}>
                    {this.props.name}
                </div>

                <Card>
                    <div style={{textAlign: "left"}}>
                        {this.props.children}
                    </div>
                </Card>
            </div>
        )
    }
}