import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import actions from "../../actions";
import { deepEqualCheck, isItemInView } from "../utils";
import SearchIcon from "../../images/search_icon.svg";

import "./Objects.scss";

export default function QuickSwitcher({ setQuickSwitcherActive }) {
  const dispatch = useDispatch();
  const history = useHistory();

  const currentUser = useSelector((state) => state.currentUser, deepEqualCheck);
  const projects = useSelector((state) => state.projects.data);

  const [searchQuery, setSearchQuery] = useState("");
  const [selectedProjectIndex, setSelectedProjectIndex] = useState(0);

  useEffect(() => {
    dispatch(actions.project.loadProjectsForUser(currentUser.id));
  }, []);

  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef, setQuickSwitcherActive);

  const selectedItemRef = useRef(null);

  useEffect(() => {
    // Once the search term changes, highlight the first result
    setSelectedProjectIndex(0);
  }, [searchQuery]);

  useEffect(() => {
    if (!isItemInView(selectedItemRef.current)) {
      selectedItemRef.current.scrollIntoView(false);
    }
  }, [selectedProjectIndex]);

  var validProjects = projects.filter(function (project) {
    return project.name
      .toLocaleLowerCase()
      .includes(searchQuery.toLocaleLowerCase());
  });

  return (
    <div ref={wrapperRef} className="quick-switcher">
      <div className="qs-search-container">
        <img className="qs-search-icon" src={SearchIcon} />
        <input
          autoFocus
          onKeyDown={(e) => {
            if (e.keyCode == "38") {
              // up arrow
              if (selectedProjectIndex > 0) {
                setSelectedProjectIndex(selectedProjectIndex - 1);
              }

              e.preventDefault();
            } else if (e.keyCode == "40") {
              // down arrow
              if (selectedProjectIndex < validProjects.length - 1) {
                setSelectedProjectIndex(selectedProjectIndex + 1);
              }

              e.preventDefault();
            } else if (e.keyCode == "13") {
              // enter key
              const selectedProject = validProjects[selectedProjectIndex];
              setQuickSwitcherActive(false);
              history.push("/p/" + selectedProject.id + "/table/status_items");
              e.preventDefault();
            }
          }}
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          className="qs-search-input"
        />
      </div>
      <div className="qs-results">
        {searchQuery != "" &&
          validProjects.map((project, index) => (
            <div
              onClick={() => {
                setQuickSwitcherActive(false);
                history.push("/p/" + project.id + "/table/status_items");
              }}
              key={project.id + index}
              className={
                "qs-result " + (selectedProjectIndex == index ? "switcher-selected" : "")
              }
              ref={selectedProjectIndex === index ? selectedItemRef : null}
            >
              {project.name}
            </div>
          ))}
      </div>
    </div>
  );
}

/**
 * Hook that alerts clicks outside of the passed ref
 */
function useOutsideAlerter(ref, action) {
  useEffect(() => {
    /**
     * Alert if clicked on outside of element
     */
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        action(false);
      }
    }

    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref]);
}
