import React, { useState, useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import { getWidthForFieldType, allowAction, deepEqualCheck } from "../../utils";
import _ from "lodash";
import { message, Tooltip } from "antd";

import UserField from "./UserField";
import CollaboratorField from "./CollaboratorField";
import DateField from "./DateField";
import TagField from "./TagField";
import Checkbox from "./Checkbox";
import PasswordField from "./PasswordField";
import LinkField from "./LinkField.js";
import TextField from "./TextField.js";
import TextArea from "./TextArea.js";
import AttachmentsField from "./AttachmentsField.js";
import ReferenceField from "./ReferenceField";
import NumberField from "./NumberField.js";

import { useMediaQuery } from "react-responsive";

import "./Fields.scss";

// As new fields are created that care about hover
// behavior, add them here. This reduces state updates
// if a field doesn't actually care about hovering
const HOVER_FIELD_TYPES = ["link"];

function TableField({
  field,
  value,
  updateValue,
  isIndexRow,
  objectId = "undefinedObjectId",
  project,
  collectionKey,
  created_by_user,
  components,
}) {
  const is13Inch = useMediaQuery({ maxWidth: 1500 });
  // This is mainly used for text fields
  const [activelyEditing, setActivelyEditing] = useState(false);

  // This is used for non text fields
  const [updateHappened, setUpdateHappened] = useState(false);

  const [hoverActive, setHoverActive] = useState(false);
  const [legacyHoverActive, setLegacyHoverActive] = useState(false);
  const roles = useSelector((state) => state.roles[project.id]);

  const [valueEditable, setValueEditable] = useState(
    value != null ? value : field.fieldData.defaultValue
  );

  const [valueOriginal, setValueOriginal] = useState(
    value != null ? value : field.fieldData.defaultValue
  );

  // Get the current user from the store
  const currentUser = useSelector((state) => state.currentUser, deepEqualCheck);
  const wrapperRef = useRef(null);

  // Check permissions and allow user to edit the value or display an error message
  function checkPermissionsAndUpdateValue(value) {
    var canEdit = false;

    if (
      roles[currentUser.role]?.permissions?.sections[collectionKey]?.fields[
        field.valueKey
      ]?.edit
    ) {
      canEdit = true;
    }

    if (
      roles[currentUser.role]?.permissions?.sections[collectionKey]?.fields[
        field.valueKey
      ]?.edit == null
    ) {
      // If null, check the component permissions

      if (roles[currentUser.role]?.permissions?.sections[collectionKey]?.edit) {
        canEdit = true;
      }
    }

    if (canEdit) {
      // If this is not a text field, send the update immediately
      if (!activelyEditing) {
        setValueEditable(value);
        setValueOriginal({ ...valueEditable });
        updateValue(value, field.valueKey);
      } else {
        // If its a text field see the useEffect below that only sends updates when editing is inactive
        setValueEditable(value);
      }
    } else {
      message.warning("You don't have permission to edit " + field.title);
    }
  }

  // This is only for text fields
  useEffect(() => {
    // Send an update if editing is not active
    if (!activelyEditing && valueOriginal != valueEditable) {
      updateValue(valueEditable, field.valueKey);
      setValueOriginal({ ...valueEditable });
    }
  }, [activelyEditing]);

  // If value is updated and is out of sync with valueEditable, we will
  // update valueEditable (this happens with remote changes and realtime)
  useEffect(() => {
    const valueToSet = value != null ? value : field.fieldData.defaultValue;
    if (!_.isEqual(valueToSet, valueEditable)) {
      setValueOriginal({ ...valueEditable });
      setValueEditable(valueToSet);
    }
  }, [value]);

  useEffect(() => {
    const stopPropagation = (event) => {
      if (wrapperRef.current && wrapperRef.current.contains(event.target)) {
        event.stopPropagation();
      }
    };

    if (activelyEditing) {
      document.addEventListener("mousedown", stopPropagation, true);
    }
    return () =>
      document.removeEventListener("mousedown", stopPropagation, true);
  }, [activelyEditing]);

  if (!field.fieldData) {
    return <div>Error: missing field data</div>;
  }

  // Combine common props for all field, and pass later to individual field implementations
  // Saves us from having to copy this every single time
  const fieldProps = {
    field,
    currentUser,
    valueEditable,
    setValueEditable,
    activelyEditing,
    setActivelyEditing,
    hoverActive,
    checkPermissionsAndUpdateValue,
    project,
    roles,
    components,
    currentObjectId: objectId,
    collectionKey,
  };

  const fieldType = field.fieldData.type;

  return (
      <div
        className={`table-cell ${field.legacy ? "legacy" : ""} ${
          isIndexRow ? "index-row" : ""
        } ${!activelyEditing ? "cell-active" : ""}`}
        onMouseEnter={() => {
          if (HOVER_FIELD_TYPES.includes(fieldType)) {
            setHoverActive(true);
          }

     
        }}
        onMouseLeave={() => {
          if (HOVER_FIELD_TYPES.includes(fieldType)) {
            setHoverActive(false);
          }
        }}
        ref={wrapperRef}
        style={getWidthForFieldType(fieldType, is13Inch)}
        data-cy={`${objectId}_${field.valueKey}`}
      >
        {fieldType === "text" && <TextField {...fieldProps} key={field.id} />}

        {fieldType === "textArea" && (
          <TextArea {...fieldProps} key={field.id} />
        )}

        {fieldType === "link" && <LinkField {...fieldProps} />}

        {fieldType === "password" && <PasswordField {...fieldProps} />}

        {fieldType === "number" && <NumberField {...fieldProps} />}

        {(fieldType === "tag" || fieldType === "select") && (
          <TagField {...fieldProps} />
        )}

        {fieldType === "checkbox" && <Checkbox {...fieldProps} />}

        {(fieldType === "date" || fieldType === "date_with_age") && (
          <DateField {...fieldProps} />
        )}

        {fieldType === "user" && (
          <UserField {...fieldProps} created_by_user={created_by_user} />
        )}

        {fieldType === "collaborator" && (
          <CollaboratorField projectId={project.id} {...fieldProps} />
        )}

        {fieldType === "attachments" && (
          <AttachmentsField projectId={project.id} {...fieldProps} />
        )}

        {fieldType === "reference" && (
          <ReferenceField projectId={project.id} {...fieldProps} />
        )}
      </div>
  );
}

export default React.memo(TableField, _.isEqual);
