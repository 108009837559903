export default {
  info: {
    name: "Documentations",
    addon: true,
    collectionKey: "documentations",
    vocabulary: {
      singular_upper: "Documentation",
      singular_lower: "documentation",
      plural_upper: "Documentations",
      plural_lower: "documentations"
    },
    default_view: "table",
    type: "documentation",
    icon:
      "https://firebasestorage.googleapis.com/v0/b/hermes-50f48.appspot.com/o/icons%2Fdocumentation_icon.svg?alt=media&token=d407e9e6-f98f-44d1-a0f8-f1fcbbb8f727"
  },
  fields: {
    description: {
      title: "Description",
      valueKey: "description",
      fieldData: {
        type: "textArea",
        placeholder: "Documentation Description",
      },
      required: false,
    },
    template_link: {
      title: "Template link",
      valueKey: "template_link",
      fieldData: {
        type: "link",
        placeholder: "Example: https://google.com",
      },
      required: false,
    },
    due_date: {
      title: "Due Date",
      valueKey: "due_date",
      fieldData: {
        type: "date",
        //     defaultValue: newTimestampFromDate(new Date())
      },
    },
    attachments: {
      title: "Attachments",
      valueKey: "attachments",
      fieldData: { type: "attachments" },
      hideTitle: true,
    },
  },
  search: {
    searchableAttributes: ["description"]
  },
  notifications: {
    slack: {
      color: "#2E7EFF",
      primaryField: "description",
      fields: {
        create: ["description", "template_link"],
        edit: ["description", "template_link"]
      },
      buttons: [
        {
          text: "View Documentation"
        }
      ]
    }
  },
  cardlist: {
    enabled: false,
    fieldOrder: [
      "description",
      "template_link",
      "est_delivery_date",
      "attachments",
    ],
  },
  permissions: {
    view: ["viewer", "vendor", "client", "admin"],
    create: ["vendor", "client", "admin"],
    edit: ["vendor", "client", "admin"],
    delete: ["admin"]
  },
  badge: {
    rules: {
      or: [
        { "==": [{ var: "attachments" }, null] },
        { "==": [{ var: "attachments.length" }, 0] }
      ]
    }
  },
  table: {
    index_row_key: "description"
  }
};
