import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Modal, message, Popover } from "antd";
import {
  newTimestamp,
  requiredFieldsSatisfiedForObject,
  newTimestampFromDate,
} from "../utils";
import sendNotification from "../../api/notifications/sendNotification";
import "./Card.scss";
import actions from "../../actions";
import Field from "./Fields";

import { id } from "../utils";
import jsonLogic from "json-logic-js";
import { useHistory } from "react-router";

export default function NewObjectCard({
  project,
  projectId,
  component,
  isAvailable,
  components,
}) {
  const collectionKey = component.info.collectionKey;
  const [object, setObject] = useState({});
  const [active, setActive] = useState(false);
  const currentUser = useSelector((state) => state.currentUser);
  const collaborators = useSelector(
    (state) => state.collaborators[projectId] || {}
  );

  // If there are any clean up we have to do (linked fields, attachments, etc..) store them here
  // Linked fields
  const [processedLinkedFields, setProcessedLinkedFields] = useState([]);

  const history = useHistory();

  const dispatch = useDispatch();

  useEffect(() => {
    // Set up the defaults for the object based on the fields
    populateDefaults();
  }, []);

  function populateDefaults() {
    var defaultObject = {
      id: id(),
    };

    Object.values(component.fields).map((field) => {
      if (field.fieldData.defaultValue != null) {
        if (field.fieldData.defaultValue == "_dateToday") {
          defaultObject[field.valueKey] = newTimestampFromDate(new Date());
        } else {
          defaultObject[field.valueKey] = field.fieldData.defaultValue;
        }
      }
    });

    setObject(defaultObject);
    setProcessedLinkedFields([]);
  }

  function updateObject(newValue, type) {
    var objectTemp = Object.assign({}, object);
    objectTemp[type] = newValue;
    setObject(objectTemp);
  }

  function showCreationModal() {
    setActive(!active);
  }

  function createObject() {
    if (object["created"] == null) {
      // This is added because some legacy objectConfigs actually use
      // "created" as an editable field, such as Standups
      object["created"] = newTimestamp();
    }

    // This is the next source of truth for creation, but those that don't
    // have this should rely on "created"
    object["created_timestamp"] = newTimestamp();
    object["created_by_user"] = currentUser.id;

    var requiredFieldsResult = requiredFieldsSatisfiedForObject(
      component.fields,
      object
    );

    if (!requiredFieldsResult.satisfied) {
      requiredFieldsResult.missing.forEach(function (missingField) {
        message.error(missingField.title + " is required and cannot be blank");
      });
    } else {
      dispatch(
        actions.collections.addObjectToCollection(
          object,
          component.info.collectionKey,
          projectId,
          component.badge?.rules
        )
      );

      // If there are any linked fields, we need to process them
      if (processedLinkedFields.length > 0) {
        processedLinkedFields.forEach(function (linkedField) {
          dispatch(
            actions.collections.updateObjectInCollection(
              linkedField.updatedObject,
              linkedField.itemType,
              linkedField.projectId,
              null
            )
          );
        });
      }

      sendNotification(
        component.info.collectionKey,
        null,
        "new",
        project,
        object,
        currentUser,
        component,
        collaborators
      );

      showCreationModal();
      populateDefaults();
    }
  }

  return (
    <div className="cardlist-header">
      <div className="card-ribbon">
        <Popover
          placement="bottomLeft"
          title={"Select View"}
          content={
            <div className="toggle-menu">
              {component.table && component.table?.enabled != false && (
                <div
                  onClick={() => {
                    history.push("/p/" + projectId + "/table/" + collectionKey);
                  }}
                  className="toggle-inline-button"
                  className="toggle-inline-button"
                >
                  <img
                    className="toggle-inline-button-icon"
                    src={require("../../images/tableview-toggle.svg")}
                  />
                  Table
                </div>
              )}

              <div
                style={{ cursor: "not-allowed" }}
                className="toggle-inline-button selected"
              >
                <img
                  className="toggle-inline-button-icon"
                  src={require("../../images/cardlist-toggle.svg")}
                />
                Cardlist
                <img
                  className="toggle-inline-button-check"
                  src={require("../../images/check-blue.svg")}
                />
              </div>
            </div>
          }
          trigger="click"
        >
          <div className="toggle-button">
            <img
              className="toggle-icon"
              src={require("../../images/cardlist-toggle.svg")}
            />{" "}
            Cardlist
            <img
              className="toggle-arrow"
              src={require("../../images/down-arrow.svg")}
            />
          </div>
        </Popover>

        {isAvailable && (
          <div
            className="topButton no-margin"
            style={{
              float: "right",
              marginBottom: "0px",
            }}
            onClick={() => showCreationModal()}
          >
            New {component.info.vocabulary.singular_upper}
          </div>
        )}
      </div>
      <div style={{ clear: "right" }}> </div>

      <Modal
        visible={active}
        onCancel={() => setActive(false)}
        footer={null}
        title={null}
        bodyStyle={{
          paddingBottom: "20px",
          paddingTop: "20px",
          padding: "30px",
        }}
        closable={false}
      >
        <div>
          <div className="newCardTitle">
            New {component.info.vocabulary.singular_upper}
          </div>

          <div>
            {component?.cardlist?.fieldOrder != null &&
              component?.cardlist?.fieldOrder.map((key) => {
                const field = component.fields[key];

                if (
                  !field.showRules ||
                  jsonLogic.apply(field.showRules, object)
                ) {
                  return (
                    <Field
                      objectId={object.id}
                      field={field}
                      key={key}
                      value={object[field.valueKey]}
                      projectId={projectId}
                      isEditing={true}
                      updateValue={updateObject}
                      collectionKey={component.info.collectionKey}
                      components={components}
                      processedLinkedFields={processedLinkedFields}
                      setProcessedLinkedFields={setProcessedLinkedFields}
                    />
                  );
                }
              })}

            {component?.cardlist?.fieldOrder == null &&
              Object.keys(component.fields).map((key) => {
                const field = component.fields[key];
                if (
                  !field.showRules ||
                  jsonLogic.apply(field.showRules, object)
                ) {
                  return (
                    <Field
                      objectId={object.id}
                      field={field}
                      key={key}
                      value={object[field.valueKey]}
                      projectId={projectId}
                      isEditing={true}
                      updateValue={updateObject}
                      collectionKey={component.info.collectionKey}
                      components={components}
                      processedLinkedFields={processedLinkedFields}
                      setProcessedLinkedFields={setProcessedLinkedFields}
                    />
                  );
                }
              })}

            <div className="multiButtonSection">
              <div onClick={showCreationModal} className="cancelButton">
                Cancel
              </div>

              <div className="createButton" onClick={createObject}>
                Submit {component.info.vocabulary.singular_upper}
              </div>
            </div>

            <div style={{ clear: "right" }}> </div>
          </div>
        </div>
      </Modal>
    </div>
  );
}
