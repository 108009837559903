export default {
  hideOnMenu: true,
  info: {
    name: "Request Group",
    type: "request_group",
    collectionKey: "request_groups",
    vocabulary: {
      singular_upper: "Request Group",
      singular_lower: "request group",
      plural_upper: "Request Groups",
      plural_lower: "request groups",
    },
  },
  fields: {
    name: {
      title: "Name",
      valueKey: "name",
      fieldData: {
        type: "text",
        defaultValue: "New Request Group",
        placeholder: "Group Name",
      },
      required: true,
    },
  },
};
