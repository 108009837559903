import React from "react";
import { useSelector } from "react-redux";
import { Avatar } from "antd";
import { getInitialsFromUser } from "../../utils";

export default function UserField({ project, created_by_user }) {
  const collaborators = useSelector(state => state.collaborators[project.id] || {});

  return (
    <div>
      {collaborators[created_by_user]?.data != null && (
        <div className="user-chip">
          {collaborators[created_by_user].data.image != null &&
            collaborators[created_by_user].data.image != "" && (
              <img
              onError={(ev) => ev.target.src = require("../../../images/default-avatar.png")}
                className="user-image"
                src={collaborators[created_by_user].data.image}
              />
            )}

          {(collaborators[created_by_user].data.image == null ||
            collaborators[created_by_user].data.image == "") && (
            <Avatar
              size={25}
              style={{
                color: "#f56a00",
                backgroundColor: "#fde3cf",
                verticalAlign: "middle"
              }}
            >
              {getInitialsFromUser(collaborators[created_by_user].data)}
            </Avatar>
          )}
          <span className="user-name">
            {collaborators[created_by_user].data.name}
          </span>
        </div>
      )}
    </div>
  );
}