export const page = (state = null, action) => {
  switch (action.type) {
    case "SET_PAGE":
      return action.page;
    default:
      return state;
  }
};

export const app = (state = {}, action) => {
  switch (action.type) {
    case "SET_VERSION":
      return {
        version: action.version,
      };
    default:
      return state;
  }
};

export const loading = (state = true, action) => {
  switch (action.type) {
    case "SET_LOADING":
      return action.loading;
    default:
      return state;
  }
};
