import objectActions, { batchDeleteObjectsFromFirebase } from "./object";
import { id, newTimestampFromDate } from "../components/utils";
import firebase, { db } from "../firebase.js";
import projectActions from "./project";

export const loadCollection = (objects, collectionKey, projectId) => {
  return {
    type: "LOAD_TO_COLLECTION",
    collectionKey: collectionKey,
    objects: objects,
    projectId: projectId,
  };
};

export const addToCollection = (object, collectionKey, projectId) => {
  return {
    type: "ADD_TO_COLLECTION",
    collectionKey: collectionKey,
    object: object,
    projectId: projectId,
  };
};

export const updateInCollection = (object, collectionKey, projectId) => {
  return {
    type: "UPDATE_IN_COLLECTION",
    collectionKey: collectionKey,
    object: object,
    projectId: projectId,
  };
};

export const removeFromCollection = (object, collectionKey, projectId) => {
  return {
    type: "REMOVE_FROM_COLLECTION",
    collectionKey: collectionKey,
    object: object,
    projectId: projectId,
  };
};

export const incrementBadgeForObject = (
  field,
  amount,
  objectId,
  collectionKey,
  projectId
) => {
  return {
    type: "INCREMENT_BADGE_FOR_OBJECT",
    field: field,
    amount: amount,
    collectionKey: collectionKey,
    objectId: objectId,
    projectId: projectId,
  };
};

export const updateThreadReadTimestampForObject = (
  currentUserId,
  timestamp,
  objectId,
  collectionKey,
  projectId
) => {
  return {
    type: "UPDATE_THREADREAD_TIMESTAMP_FOR_OBJECT",
    currentUserId: currentUserId,
    timestamp: timestamp,
    collectionKey: collectionKey,
    objectId: objectId,
    projectId: projectId,
  };
};

export const addThreadTimestampForObject = (
  thread,
  objectId,
  collectionKey,
  projectId
) => {
  return {
    type: "ADD_THREAD_TIMESTAMP_FOR_OBJECT",
    thread: thread,
    collectionKey: collectionKey,
    objectId: objectId,
    projectId: projectId,
  };
};

export const removeThreadTimestampForObject = (
  thread,
  objectId,
  collectionKey,
  projectId
) => {
  return {
    type: "REMOVE_THREAD_TIMESTAMP_FOR_OBJECT",
    thread: thread,
    collectionKey: collectionKey,
    objectId: objectId,
    projectId: projectId,
  };
};

export const fetchCollectionFromFirebase = (
  collectionKey,
  projectId,
  query,
  setUnsubscribe
) => (dispatch) =>
  new Promise((resolve) => {
    var collectionRef = db
      .collection("projects")
      .doc(projectId)
      .collection(collectionKey);

    if (query) {
      collectionRef = db
        .collection("projects")
        .doc(projectId)
        .collection(collectionKey)
        .where(query.fieldPath, query.opStr, query.value);
    }

    const unsubscribe = collectionRef.onSnapshot((snapshot) => {
      var objects = {};

      let updateComesFromLocal = false;

      snapshot.forEach((doc) => {
        updateComesFromLocal =
          doc.metadata.hasPendingWrites || updateComesFromLocal;

        if (doc.exists) {
          objects[doc.id] = doc.data();

          if (!doc.data().id || doc.data().id != doc.id) {
            objects[doc.id].id = doc.id;
          }
        }
      });

      // See https://firebase.google.com/docs/firestore/query-data/listen#events-local-changes
      // Firebase is firing for local changes, but we already handle local updates through redux actions directly
      // Our redux updates fire faster and this way keeps our coupling with firebase down
      // So to avoid duplicate loadCollection calls, we should only reload collections when the *remote* data changes
      if (!updateComesFromLocal) {
        dispatch(loadCollection(objects, collectionKey, projectId));
      } else {
      }

      setUnsubscribe(unsubscribe);

      resolve(`Loaded ${collectionKey}`);
    });
  });

export const incrementThreadCountForObject = (
  amount,
  projectId,
  collection,
  objectId
) => (dispatch) =>
  new Promise((resolve, reject) => {
    const objectRef = db
      .collection("projects")
      .doc(projectId)
      .collection(collection)
      .doc(objectId);

    objectRef
      .update({
        [`badges.threads`]: firebase.firestore.FieldValue.increment(amount),
      })
      .then(() => {
        dispatch(
          incrementBadgeForObject(
            "threads",
            amount,
            objectId,
            collection,
            projectId
          )
        );

        resolve();
      })
      .catch((error) => {
        // The document probably doesn't exist.
        reject(error);
      });
  });

  export const updateThreadReadTimestampsOfObject = (
    currentUser,
    timestamp,
    projectId,
    collection,
    objectId
  ) => (dispatch) =>
    new Promise((resolve, reject) => {

      const objectRef = db
        .collection("projects")
        .doc(projectId)
        .collection(collection)
        .doc(objectId);
  
      objectRef
        .update({
          [`thread_read_timestamps.${currentUser.id}`]: timestamp,
        })
        .then(() => {
          dispatch(
            updateThreadReadTimestampForObject(
              currentUser.id,
              timestamp,
              objectId,
              collection,
              projectId
            )
          );
  
          resolve();
        })
        .catch((error) => {
          // The document probably doesn't exist.
          reject(error);
        });
    });

export const addThreadTimestampToObject = (
  thread,
  projectId,
  collection,
  objectId
) => (dispatch) =>
  new Promise((resolve, reject) => {
    const objectRef = db
      .collection("projects")
      .doc(projectId)
      .collection(collection)
      .doc(objectId);

    objectRef
      .update({
        [`thread_timestamps`]: firebase.firestore.FieldValue.arrayUnion({
          "timestamp": thread.timestamp,
          "thread": thread.id
        }),
      })
      .then(() => {
        dispatch(
          addThreadTimestampForObject(
            thread,
            objectId,
            collection,
            projectId
          )
        );

        resolve();
      })
      .catch((error) => {
        // The document probably doesn't exist.
        reject(error);
      });
  });


  export const removeThreadTimestampFromObject = (
    thread,
    projectId,
    collection,
    objectId
  ) => (dispatch) =>
    new Promise((resolve, reject) => {
      const objectRef = db
        .collection("projects")
        .doc(projectId)
        .collection(collection)
        .doc(objectId);
  
      objectRef
        .update({
          [`thread_timestamps`]: firebase.firestore.FieldValue.arrayRemove({
            "timestamp": thread.timestamp,
            "thread": thread.id
          }),
        })
        .then(() => {
          dispatch(
            removeThreadTimestampForObject(
              thread,
              objectId,
              collection,
              projectId
            )
          );
  
          resolve();
        })
        .catch((error) => {
          // The document probably doesn't exist.
          reject(error);
        });
    });

export const updateObjectInCollection = (
  object,
  collectionKey,
  projectId,
  rules
) => (dispatch) =>
  new Promise(() => {
    if (!object.id) object.id = id();


    dispatch(updateInCollection(object, collectionKey, projectId));
    dispatch(
      objectActions.updateObjectInFirebase(object, projectId, collectionKey)
    );

    if(rules) {
      dispatch(
        projectActions.updateProjectBadgeArrayForObject(
          object,
          projectId,
          collectionKey,
          rules
        )
      );
    }
  
  });

const getCollectionLifetimeCount = (collection, projectId) =>
  new Promise((resolve, reject) => {
    // Let's load the current!
    var projectRef = db.collection("projects").doc(projectId);

    projectRef
      .get()
      .then(function (doc) {
        if (doc.exists) {
          var project = doc.data();

          if (
            project.lifetime_counters &&
            project.lifetime_counters[collection]
          ) {
            resolve(project.lifetime_counters[collection]);
          } else {
            // likely 0
            resolve(0);
          }
        } else {
          // doc.data() will be undefined in this case
          reject(Error("Document does not exist"));
        }
      })
      .catch((error) => {
        reject(error);
      });
  });

const incrementCollectionLifetimeCount = (collection, projectId) =>
  new Promise((resolve, reject) => {
    const projectRef = db.collection("projects").doc(projectId);

    projectRef
      .update({
        [`lifetime_counters.${collection}`]: firebase.firestore.FieldValue.increment(
          1
        ),
      })
      .then(() => {
        resolve();
      })
      .catch((error) => {
        // The document probably doesn't exist.
        reject(error);
      });
  });

export const addObjectToCollection = (
  object,
  collectionKey,
  projectId,
  rules
) => async (dispatch) => {
  if (!object.id) {
    object.id = id();
  }

  try {
    // Let's get this total count for the object
    const totalCount = await getCollectionLifetimeCount(
      collectionKey,
      projectId
    );

    object["ext_id"] = totalCount + 1;
    dispatch(addToCollection(object, collectionKey, projectId));
    // Let's increment the total lifetime count of the collection once it is successfully added

    dispatch(
      objectActions.addObjectToFirebase(
        object,
        projectId,
        collectionKey,
        object.id
      )
    ).then(() => {
      dispatch(
        projectActions.updateProjectBadgeArrayForObject(
          object,
          projectId,
          collectionKey,
          rules
        )
      );
      incrementCollectionLifetimeCount(collectionKey, projectId);
    });
  } catch (error) {}
};

export const removeObjectFromCollection = (
  object,
  collectionKey,
  projectId,
  rules
) => (dispatch) =>
  new Promise((resolve, reject) => {
    try {
      dispatch(removeFromCollection(object, collectionKey, projectId));
      dispatch(
        objectActions.deleteObjectFromFirebase(
          object.id,
          object,
          projectId,
          collectionKey
        )
      ).then(function () {
        dispatch(
          projectActions.updateProjectBadgeArrayForObject(
            object,
            projectId,
            collectionKey,
            rules,
            true
          )
        );
        resolve();
      });
    } catch (error) {
      reject(error);
    }
  });

export const batchRemoveObjectFromCollection = (items, projectId) => (
  dispatch
) => {
  dispatch({
    type: "BATCH_REMOVE_FROM_COLLECTION",
    projectId,
    items,
  });
  return dispatch(batchDeleteObjectsFromFirebase(items, projectId));
};

export default {
  fetchCollectionFromFirebase,
  addObjectToCollection,
  removeObjectFromCollection,
  updateObjectInCollection,
  batchRemoveObjectFromCollection,
  incrementThreadCountForObject,
  updateThreadReadTimestampsOfObject
};
