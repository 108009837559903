import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import jsonLogic from "json-logic-js";
import { Tabs, Select } from "antd";
import useSorting from "./hooks/useSorting";
import useCardListOrder from "./hooks/useCardListOrder";
import ObjectCard from "./ObjectCard";
import CardListDragDrop from "./CardListDragDrop";
import NewObjectCard from "./NewObjectCard";
import { has } from "../utils";
import actions from "../../actions";
import emptyImage from "../../images/empty.svg";
import "../../styles/Dash.css";

import _ from "lodash";

const Option = Select.Option;
const { TabPane } = Tabs;

export function CardList({
  component,
  components,
  setPage,
  currentUser,
  project,
  projectId,
  query,
}) {
  const collectionKey = component.info.collectionKey;

  const roles = useSelector((state) => state.roles[projectId]);

  const shouldUpdateCollections = (left, right) => {
    // check if left and right is equal with lodash
    // if not, return true
    // if yes, return false
    return _.isEqual(
      left[component.info.collectionKey],
      right[component.info.collectionKey]
    );
  };

  const dispatch = useDispatch();

  const collections = useSelector(
    (state) => state.collections[projectId],
    shouldUpdateCollections
  );

  const loading = useSelector((state) => state.loading);

  const [activeTab, setActiveTab] = useState(component?.cardlist?.defaultTab);

  const [sorting, setSorting] = useSorting(currentUser, project, component);
  const [cardlistOrder, setCardListOrder] = useCardListOrder(
    project,
    component,
    collections,
    sorting
  );

  useEffect(() => {
    const collectionKey = component.info.collectionKey;
    setPage(collectionKey);
  }, []);

  if (component?.cardlist?.enabled === false || component?.cardlist == null) {
    return <div>CardList view not supported for this component</div>;
  }

  if (component?.cardlist?.fieldOrder == null) {
    return (
      <div>
        There are no fields for this cardlist, please add some in the editor
        (settings)
      </div>
    );
  }

  // Set local sorting state, and update user preference
  const handleSortingChange = (value) => {
    setSorting(value);
    dispatch(
      actions.user.updateSortingPreferencesForProject(
        projectId,
        collectionKey,
        value
      )
    );
  };

  const handleTabChange = (tabKey) => {
    if (query["item"]) {
      delete query["item"];
      window.history.pushState(null, null, window.location.pathname);
    }
    setActiveTab(tabKey);
  };

  // Set the local objects based on the data from the collection selector
  const objects = collections?.[collectionKey] ?? {};

  if (!project || project == {} || !sorting) {
    return <div>There isn't anything here!</div>;
  }

  // Helper function to add an ObjectCard to a specific array
  const addObjectsToArray = (array, tab = null) => {
    cardlistOrder
      .map((id) => objects[id])
      .filter((object) => !_.isEmpty(object))
      .forEach((object) => {
        const highlight = query["item"] === object.id;

        if (!tab || jsonLogic.apply(tab.rules, object)) {
          if (highlight && tab && activeTab !== tab.key) {
            setActiveTab(tab.key);
          }

          array.push(
            <ObjectCard
              highlight={highlight}
              key={object.id}
              object={object}
              projectId={projectId}
              project={project}
              component={component}
              components={components}
            />
          );
        }
      });
  };

  // If there are tabs, lets go ahead and create an object (of arrays) to store the cards
  let tabCards = {};

  // If there are no tabs, lets just put them in an object
  let objectCards = [];

  // Sort object cards into tabs if the component has tabs configured
  if (component?.cardlist?.tabs) {
    component.cardlist.tabs.map((tab) => {
      tabCards[tab.key] = [];
      addObjectsToArray(tabCards[tab.key], tab);
    });
  } else {
    addObjectsToArray(objectCards);
  }

  // Helper function to render cards from a specified array
  const makeObjectCardsFromArray = (objectCardArray) => (
    <div>
      {component?.cardlist?.sorting && !component.customOrderingOnly && (
        <div style={{ float: "right" }}>
          <Select value={sorting} onChange={handleSortingChange}>
            {Object.keys(component?.cardlist?.sorting).map((key) => (
              <Option key={key} value={key}>
                {component?.cardlist?.sorting[key].name}
              </Option>
            ))}
          </Select>{" "}
        </div>
      )}

      <div style={{ clear: "right" }} />
      <div style={{ height: "20px" }} />

      {component.dragDropEnabled ? (
        <CardListDragDrop order={cardlistOrder} setOrder={setCardListOrder}>
          {objectCardArray}
        </CardListDragDrop>
      ) : (
        objectCardArray
      )}
    </div>
  );

  return (
    <div class="section">
      <NewObjectCard
        project={project}
        projectId={projectId}
        existingObjects={objects}
        component={component}
        components={components}
        key={component.info.collectionKey}
        isAvailable={
          roles[currentUser.role]?.permissions?.sections[collectionKey]?.create
        }
        // addToSorting={addToSorting}
      />

      {/* If the component does not have tabs, just render cards */}
      {component?.cardlist?.tabs == null &&
        objectCards.length > 0 &&
        makeObjectCardsFromArray(objectCards)}

      {/* If the component has tabs, sort and then render cards */}
      {component?.cardlist?.tabs?.length > 0 && (
        <Tabs
          activeKey={activeTab}
          onTabClick={handleTabChange}
          size="large"
          tabBarStyle={{ fontFamily: "Avenir Next" }}
        >
          {component.cardlist.tabs.map((tab) => (
            <TabPane
              tab={`${tab.name} (${tabCards[tab.key].length})`}
              key={tab.key}
              style={{ padding: "3px" }}
            >
              {/* Render empty messasge if no cards in tab */}
              {tabCards[tab.key].length == 0 && (
                <div style={{ textAlign: "center", marginTop: "50px" }}>
                  <div>
                    <img style={{ width: "60px" }} src={emptyImage} />
                  </div>

                  <div
                    style={{
                      fontFamily: "Avenir Next",
                      color: "#00000040",
                      marginTop: "5px",
                      fontSize: "16px",
                    }}
                    data-cy="empty_card_list"
                  >
                    Nothing yet
                  </div>
                </div>
              )}

              {/* Render tab cards */}
              {tabCards[tab.key].length > 0 &&
                makeObjectCardsFromArray(tabCards[tab.key])}
            </TabPane>
          ))}
        </Tabs>
      )}
    </div>
  );
}

export default React.memo(CardList);
