import { newTimestampFromDate } from "../utils";

export default {
  info: {
    name: "Daily Standups",
    addon: false,
    collectionKey: "standups",
    vocabulary: {
      singular_upper: "Standup",
      singular_lower: "standup",
      plural_upper: "Standups",
      plural_lower: "standups",
    },
    default_view: "cardlist",
    type: "standup",
    icon: "https://firebasestorage.googleapis.com/v0/b/hermes-50f48.appspot.com/o/icons%2Fstandup_v2.svg?alt=media&token=16252b6e-dada-4531-8f1a-6607e9f61664",
  },
  fields: {
    created: {
      title: "When was this?",
      valueKey: "created",
      fieldData: {
        type: "date",
        defaultValue: newTimestampFromDate(new Date()),
      },
    },
    yesterday: {
      title: "What did you do yesterday?",
      valueKey: "yesterday",
      fieldData: {
        type: "textArea",
        placeholder: "Example: Worked on P0 Bugs",
      },
      required: true,
    },
    today: {
      title: "What will you do today?",
      valueKey: "today",
      fieldData: {
        type: "textArea",
        placeholder: "Example: Working on P1 Bugs",
      },
      required: true,
    },
    problems: {
      title: "What problems are you facing?",
      valueKey: "problems",
      fieldData: {
        type: "textArea",
        placeholder: "Example: No problems for now",
      },
      required: false,
      legacy: true
    },
    delays: {
      title: "Did the team encounter anything that took longer than anticipated?",
      valueKey: "delays",
      fieldData: {
        type: "textArea",
        placeholder: "Example: Took longer to set up the environment due to dependency issues",
      },
      required: false,
    },
    attachments: {
      title: "Attachments",
      valueKey: "attachments",
      fieldData: { type: "attachments" },
      hideTitle: true,
    },
  },
  search: {
    searchableAttributes: ["today", "yesterday", "delays", "problems"],
  },
  notifications: {
    slack: {
      color: "#2f7eff",
      primaryField: "today",
      fields: {
        create: ["yesterday", "today", "delays", "created"],
        edit: ["yesterday", "today", "delays", "created"],
      },
      buttons: [
        {
          text: "Comment on Standup",
        },
      ],
    },
  },
  cardlist: {
    sorting: {
      age_descending: {
        name: "Sort by age (descending)",
        key: "age_descending",
        value: "created",
        ascending: false,
      },
      age_ascending: {
        name: "Sort by age (ascending)",
        key: "age_ascending",
        value: "created",
        ascending: true,
      }
    },
    fieldOrder: ["created", "yesterday", "today", "delays", "problems", "attachments"],
  },
  permissions: {
    view: ["viewer", "vendor", "client", "admin"],
    create: ["vendor", "client", "admin"],
    edit: ["vendor", "client", "admin"],
    delete: ["vendor", "admin"],
  },
};
