import { createReducer } from "@reduxjs/toolkit";

// Case reducers
function loadHistory(historyState, action) {
  if (!historyState[action.objectId]) {
    historyState[action.objectId] = {};
  }

  historyState[action.objectId] = {
    ...action.history,
  };
  
}

function addHistory(historyState, action) {
  if (!historyState[action.objectId]) {
    historyState[action.objectId] = {};
  }

  historyState[action.objectId][action.history.id] = action.history;
}

// Slice reducer
export default createReducer(
  {},
  {
    LOAD_HISTORY: loadHistory,
    ADD_HISTORY: addHistory,
  }
);
