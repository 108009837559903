import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Input, Button, Select } from "antd";
import _ from "lodash";
import { id } from "../utils";
import { useHistory } from "react-router";

import actions from "../../actions";
const { Option } = Select;
const algoliasearch = require("algoliasearch");

// configure algolia
const algolia = algoliasearch(
  process.env.REACT_APP_ALGOLIA_PROJECT_ID,
  process.env.REACT_APP_ALGOLIA_ADMIN_KEY
);

export default function ProjectCreationModal({
  closeModal,
  currentUser,
  addProject,
}) {
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [projectName, setProjectName] = useState("");
  const [projectTier, setProjectTier] = useState(null);

  const history = useHistory();

  const dispatch = useDispatch();

  const handleCreation = () => {
    setConfirmLoading(true);

    var tempId = id();

    var tempProject = {
      name: projectName,
      active: true,
      archived: false,
      date_started: new Date(),
      slackHooks: [],
      milestonesOrder: [],
      collaborators: [
        {
          user: currentUser.id,
          role: "admin",
        },
      ],
      id: tempId,
      collaborator_ids: [currentUser.id],
      _nonAloa:
        currentUser.email &&
        (currentUser.email.includes("@aloa.co") ||
          currentUser.email.includes("@aloalabs.com"))
          ? false
          : true,
      project_tier: projectTier,
    };

    dispatch(actions.project.addProject(tempProject)).then(() => {
      // Now lets create a new milestone + status item

      window.amplitude.getInstance().logEvent("NEW_PROJ_CREATED", {
        name: tempProject.name,
        id: tempId,
        createdBy: currentUser.id,
      });

      history.push(`p/${tempId}/table/status_items`);
    });

    setTimeout(() => {
      setConfirmLoading(false);
      // closeModal();
    }, 2000);
  };

  return (
    <div style={{ padding: "10px" }}>
      <div style={{ fontSize: "18px", marginBottom: "10px" }}>New Project</div>
      <Input
        value={projectName}
        style={{ marginBottom: "20px" }}
        placeholder="Project Name"
        onChange={(event) => setProjectName(event.target.value)}
      />

      {currentUser.email &&
        (currentUser.email.includes("@aloa.co") ||
          currentUser.email.includes("@aloalabs.com")) && (
          /* Dropdown that lets strategist choose the project tier */
          <div style={{ marginBottom: "20px" }}>
            <Select
              style={{ width: 250 }}
              value={projectTier || undefined}
              onChange={(value) => {
                // If value is None, set it to null
                if (value === "None") {
                  setProjectTier(null);
                } else {
                  setProjectTier(value);
                }
              }}
              placeholder="Project Tier"
            >
              <Option value="Tier 1">Tier 1</Option>
              <Option value="Tier 2">Tier 2</Option>
              <Option value="Tier 1 Custom">Tier 1 Custom</Option>
              <Option value="Tier 2 Custom">Tier 2 Custom</Option>
              <Option value="None">None</Option>
            </Select>
          </div>
        )}
      <div style={{ float: "right" }}>
        <Button key="back" onClick={closeModal}>
          Cancel
        </Button>

        <Button
          style={{ marginLeft: "10px" }}
          key="submit"
          type="primary"
          loading={confirmLoading}
          onClick={handleCreation}
        >
          Create Project
        </Button>
      </div>
      <div style={{ clear: "right" }}> </div>
    </div>
  );
}
