import { createReducer } from "@reduxjs/toolkit";

function loadCollaborator(collaboratorsState, action) {
  if(collaboratorsState[action.projectId] == null) {
    collaboratorsState[action.projectId] = {}
  }

  collaboratorsState[action.projectId][action.collaborator.id] = action.collaborator
}

function loadCollaborators(collaboratorsState, action) {
  if(collaboratorsState[action.projectId] == null) {
    collaboratorsState[action.projectId] = {}
  }

  Object.values(action.collaborators).forEach(function(collaborator) {
    collaboratorsState[action.projectId][collaborator.id]= collaborator
  })
}

// Slice reducer
export default createReducer(
  {},
  {
    LOAD_COLLABORATORS: loadCollaborators,
    LOAD_COLLABORATOR: loadCollaborator
  }
);
