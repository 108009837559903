import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Input } from "antd";
import { Upload, message, Popconfirm, Spin, Tooltip, Progress } from "antd";
import firebase, { auth, db } from "../../firebase.js";

// Icons
import image_file_icon from "../../images/image_file_icon.svg";
import default_file_icon from "../../images/default_file_icon.svg";
import sketch_file_icon from "../../images/sketch_file_icon.svg";
import svg_file_icon from "../../images/svg_file_icon.svg";
import zip_file_icon from "../../images/zip_file_icon.svg";
import pdf_file_icon from "../../images/pdf_file_icon.svg";
import video_file_icon from "../../images/video_file_icon.svg";
import Lightbox from "lightbox-react";
import "lightbox-react/style.css"; // This only needs to be imported once in your app
import ReactPlayer from "react-player";

const Dragger = Upload.Dragger;

const mime = require("mime");

mime.define({ "application/sketch": ["sketch"] });

export default function AttachmentCard({
  attachments,
  attachment,
  projectId,
  updateAttachments,
  editable = true,
}) {
  const [isLightboxOpen, setIsLightboxOpen] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);

  const currentUser = useSelector((state) => state.currentUser);

  const [downloadingStatus, setDownloadingStatus] = useState("idle");
  const [percentDownloaded, setPercentDownloaded] = useState(0);

  function firebaseStorageUpload({ file, onSuccess, onError, onProgress }) {
    // Create a root reference

    var storageRef = firebase.storage().ref();

    var randomId =
      Math.random().toString(36).substring(2, 15) +
      Math.random().toString(36).substring(2, 15);

    var uploadTask = storageRef
      .child("projects/" + projectId + "/" + randomId + file.name)
      .put(file);

    // Register three observers:
    // 1. 'state_changed' observer, called any time the state changes
    // 2. Error observer, called on failure
    // 3. Completion observer, called on successful completion

    uploadTask.on(
      "state_changed",
      function (snapshot) {
        // Observe state change events such as progress, pause, and resume
        // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
        var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;

        onProgress({ percent: progress });
        switch (snapshot.state) {
          case firebase.storage.TaskState.PAUSED: // or 'paused'
            break;
          case firebase.storage.TaskState.RUNNING: // or 'running'
            break;
        }
      },
      function (error) {
        // Handle unsuccessful uploads

        onError(error);
      },
      function () {
        // Handle successful uploads on complete
        // For instance, get the download URL: https://firebasestorage.googleapis.com/...
        uploadTask.snapshot.ref.getDownloadURL().then(
          function (downloadURL) {
            onSuccess({
              url: downloadURL,
              id: randomId + file.name,
              size: uploadTask.snapshot.totalBytes,
            });
          }.bind(this)
        );
      }.bind(this)
    );
  }

  function downloadObject() {
    // Create a reference to the file we want to download
    var storage = firebase.storage();
    var starsRef = storage.ref("projects/" + projectId + "/" + attachment.id);

    setDownloadingStatus("active");
    setPercentDownloaded(0);

    // Get the download URL
    starsRef
      .getDownloadURL()
      .then(function (url) {
        // Insert url into an <img> tag to "download"
        // This can be downloaded directly:

        var xhr = new XMLHttpRequest();
        xhr.responseType = "blob";
        xhr.onload = function () {
          //Create an `a` tag (since it has an `href` has a `download` attribute)
          var a = document.createElement("a");
          a.href = window.URL.createObjectURL(xhr.response);
          a.download = attachment.name;
          a.style.display = "none";
          document.body.appendChild(a);
          a.click(); //Simulates a click event
          setDownloadingStatus("idle");
          setPercentDownloaded(0);
        };
        xhr.addEventListener("progress", function (event) {
          if (event.lengthComputable) {
            var percentLoaded = (event.loaded / event.total) * 100;
            console.log(Math.round(percentLoaded));
            setPercentDownloaded(Math.round(percentLoaded));
          }
        });
        xhr.onreadystatechange = function (oEvent) {
          if (xhr.readyState === 4) {
            if (xhr.status === 200) {
              // All is good, nothing wrong
            } else {
              console.log("Error", xhr.status);
              console.log("download failed");
              setDownloadingStatus("exception");
            }
          }
        };
        xhr.open("GET", url);
        xhr.send();
      })
      .catch(function (error) {
        // A full list of error codes is available at
        // https://firebase.google.com/docs/storage/web/handle-errors
        switch (error.code) {
          case "storage/object-not-found":
            // File doesn't exist
            message.error("The file no longer exits on the server");
            break;

          case "storage/unauthorized":
            // User doesn't have permission to access the object
            message.error("You don't have permission to access this object");
            break;

          case "storage/canceled":
            // User canceled the upload
            message.error("User has canceled the upload");
            break;
          case "storage/unknown":
            // Unknown error occurred, inspect the server response
            message.error("Something went wrong");
            break;
        }
      });
  }

  function deleteAttachment() {
    setIsDeleting(true);
    // Comment this out if you want true attachment deletion

    var attachmentsTemp = [...attachments];

    attachmentsTemp.splice(
      attachmentsTemp.findIndex(({ id }) => id == attachment.id),
      1
    );

    updateAttachments(attachmentsTemp);

    setIsDeleting(false);

    message.success("Attachment Successfully Deleted");

    // Uncomment this to allow for true attachment deletion
    /*
    // Create a reference to the file to delete
    var desertRef = firebase
      .storage()
      .ref("projects/" + projectId + "/" + attachment.id);

    // Delete the file
    desertRef
      .delete()
      .then(function() {
        // File deleted successfully
        
        var attachmentsTemp = [...attachments];

        attachmentsTemp.splice(
          attachmentsTemp.findIndex(({ id }) => id == attachment.id),
          1
        );

        

        updateAttachments(attachmentsTemp);

        setIsDeleting(false);

        message.success("Attachment Successfully Deleted");
      })
      .catch(function(error) {
        // Uh-oh, an error occurred!

        

        setIsDeleting(false);
        // A full list of error codes is available at
        // https://firebase.google.com/docs/storage/web/handle-errors
        switch (error.code) {
          case "storage/object-not-found":
            // File doesn't exist, but we'll delete it anyway
            message.error(
              "The file no longer exists on the server, removing it anyway"
            );

            var attachmentsTemp = [...attachments];

            attachmentsTemp.splice(
              attachmentsTemp.findIndex(({ id }) => id == attachment.id),
              1
            );

            updateAttachments(attachmentsTemp);

            break;

          case "storage/unauthorized":
            // User doesn't have permission to access the object
            message.error("You don't have permission to access this object");
            break;

          case "storage/canceled":
            // User canceled the upload
            message.error("User has canceled the upload");
            break;
          case "storage/unknown":
            // Unknown error occurred, inspect the server response
            message.error("Something went wrong");
            break;
        }
      }); */
  }

  function truncate(fullStr, strLen, separator) {
    if (fullStr.length <= strLen) return fullStr;

    separator = separator || "...";

    var sepLen = separator.length,
      charsToShow = strLen - sepLen,
      frontChars = Math.ceil(charsToShow / 2),
      backChars = Math.floor(charsToShow / 2);

    return (
      fullStr.substr(0, frontChars) +
      separator +
      fullStr.substr(fullStr.length - backChars)
    );
  }

  var attachment_icon = default_file_icon;
  var isImage = false;
  var isVideo = false;

  if (mime.getType(attachment.name) === "application/sketch") {
    attachment_icon = sketch_file_icon;
  }

  if (
    mime.getType(attachment.name) === "image/jpg" ||
    mime.getType(attachment.name) === "image/png" ||
    mime.getType(attachment.name) === "image/gif" ||
    mime.getType(attachment.name) === "image/jpeg"
  ) {
    attachment_icon = image_file_icon;
    isImage = true;
  }

  if (
    mime.getType(attachment.name) === "video/mp4" ||
    mime.getType(attachment.name) === "video/x-flv" ||
    mime.getType(attachment.name) === "video/quicktime" ||
    mime.getType(attachment.name) === "video/x-msvideo" ||
    mime.getType(attachment.name) === "application/x-mpegURL"
  ) {
    attachment_icon = video_file_icon;
    isVideo = true;
  }

  if (mime.getType(attachment.name) === "image/svg+xml") {
    attachment_icon = svg_file_icon;
  }

  if (mime.getType(attachment.name) === "application/x-bzip2") {
    attachment_icon = zip_file_icon;
  }

  if (mime.getType(attachment.name) === "application/pdf") {
    attachment_icon = pdf_file_icon;
  }

  return (
    <Spin spinning={isDeleting}>
      {isLightboxOpen && isImage && (
        <Lightbox
          mainSrc={attachment.url}
          onCloseRequest={() => setIsLightboxOpen(false)}
        />
      )}

      {isLightboxOpen && isVideo && (
        <Lightbox
          clickOutsideToClose={true}
          enableZoom={false}
          mainSrc={
            <ReactPlayer
              url={attachment.url}
              playing
              controls={true}
              style={{
                maxWidth: "97%",
                position: "absolute",
                left: 0,
                right: 0,
                margin: "auto",
                top: "50%",
                transform: "translateY(-50%)",
              }}
            />
          }
          onCloseRequest={() => setIsLightboxOpen(false)}
        />
      )}

      <div
        style={{
          //    border: "1px solid #dedede",
          borderRadius: "6px",
          padding: "10px",
          marginTop: "10px",
          backgroundColor: "#F3F5F9",
          display: "inline-block",
        }}
        data-cy="attachments_chip"
      >
        <div style={{ display: "flex", float: "left" }}>
          {((!isImage && !isVideo) || attachment.url == null) && (
            <img
              style={{
                height: "20px",
                marginRight: "7px",
                marginLeft: "5px",
                verticalAlign: "middle",
              }}
              src={attachment_icon}
            />
          )}

          {isImage && attachment.url != null && (
            <img
              style={{
                height: "20px",
                marginRight: "7px",
                marginLeft: "5px",
                verticalAlign: "middle",
                cursor: "pointer",
              }}
              onClick={() => setIsLightboxOpen(true)}
              src={attachment.url}
            />
          )}

          {isVideo && attachment.url != null && (
            <img
              style={{
                height: "20px",
                marginRight: "7px",
                marginLeft: "5px",
                verticalAlign: "middle",
                cursor: "pointer",
              }}
              onClick={() => setIsLightboxOpen(true)}
              src={attachment_icon}
            />
          )}
          <div style={{ verticalAlign: "middle" }}>
            {((!isImage && !isVideo) || attachment.url == null) && (
              <div>
                <div
                  style={{
                    fontSize: "14px",
                    fontFamily: "Avenir Next",
                    fontWeight: "600",
                    cursor: "pointer",
                  }}
                  onClick={() => downloadObject()}
                >
                  <Tooltip
                    placement="topLeft"
                    title={
                      attachment.name +
                      " (" +
                      formatBytes(attachment.size) +
                      ")"
                    }
                  >
                    {truncate(attachment.name, 30)}
                  </Tooltip>
                </div>
                {downloadingStatus != "idle" && (
                  <div className="download-progress">
                    {" "}
                    <Progress
                      percent={percentDownloaded}
                      status={downloadingStatus}
                    />
                  </div>
                )}
              </div>
            )}
            {(isImage || isVideo) && attachment.url != null && (
              <div
                style={{
                  fontSize: "14px",
                  fontFamily: "Avenir Next",
                  fontWeight: "600",
                  cursor: "pointer",
                }}
                onClick={() => setIsLightboxOpen(true)}
              >
                <Tooltip
                  placement="topLeft"
                  title={
                    attachment.name + " (" + formatBytes(attachment.size) + ")"
                  }
                >
                  {truncate(attachment.name, 30)}
                </Tooltip>
              </div>
            )}
          </div>

          <div
            style={{
              verticalAlign: "middle",
              textAlign: "right",
              marginLeft: "12px",
              marginRight: "4px",
            }}
          >
            {attachment.user == currentUser.id && editable && (
              <Popconfirm
                title="Are you sure you want to delete this attachment?"
                onConfirm={() => deleteAttachment()}
                okText="Yes"
                cancelText="No"
                data-cy="confirm_action"
              >
                <img
                  src={require("../../images/cross.svg")}
                  style={{
                    height: "10px",
                    fontFamily: "Avenir Next",
                    fontWeight: "500",
                    cursor: "pointer",
                    color: "#777777",
                  }}
                />
              </Popconfirm>
            )}
          </div>
        </div>

        <div style={{ clear: "both" }} />
      </div>
    </Spin>
  );
}

function formatBytes(a, b) {
  if (0 == a) return "0 Bytes";
  var c = 1024,
    d = b || 2,
    e = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"],
    f = Math.floor(Math.log(a) / Math.log(c));
  return parseFloat((a / Math.pow(c, f)).toFixed(d)) + " " + e[f];
}
