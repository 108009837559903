import React, { useState, useEffect } from "react";

import _ from "lodash";

import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

import { Input, Select, Tooltip, Popover, Switch } from "antd";

export default function SummaryEditor({
  objectEditable,
  updateField,
  pathToUpdate,
}) {
  const [summariesEditable, setSummariesEditable] = useState(objectEditable?.table?.summaries || {});

  var fields = objectEditable?.fields || {};

  /*
  useEffect(() => {
    // If the parent has been updated, let's update the child
    if (!_.isEqual(summariesEditable, objectEditable?.table?.summaries)) {
      var summariesEditableTemp = _.cloneDeep(objectEditable?.table?.summaries);

      setSummariesEditable(summariesEditableTemp);
    }
  }, [objectEditable]);  */

    
  useEffect(() => {
    // If we upated the child, let's update the parent
    if (!_.isEqual(summariesEditable, objectEditable?.table?.summaries)) {
        updateField(pathToUpdate, summariesEditable);
      }
    
  }, [summariesEditable]); 

  return (
    <div>
      <div className="fields">
        <div style={{ padding: "0px 10px" }} className="data-item">
          <div className="data-item-value">
            <div className="selection-options-box">
              {objectEditable?.table?.columns?.map(function (column_id) {
                var field = fields[column_id];
                if (field && field.fieldData?.type == "number") {
                  return (
                    <div key={"summaryeditor" + column_id}>
                      <div>{field.title}</div> <div></div>
                      <Switch
                        checked={summariesEditable && Object.keys(summariesEditable).includes(
                          column_id
                        )}
                        onChange={(checked) => {
                          var summariesEditableTemp = _.cloneDeep(
                            summariesEditable
                          );
                          if (checked) {
                            summariesEditableTemp[column_id] = {
                              type: "sum",
                            };
                          } else {
                            delete summariesEditableTemp[column_id];
                          }

                          setSummariesEditable(summariesEditableTemp);
                        }}
                      />
                    </div>
                  );
                }
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
