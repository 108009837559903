import React, { useState, useRef, useEffect } from "react";
import { useSelector } from "react-redux";
import { Avatar, Select } from "antd";
import { getInitialsFromUser } from "../../utils";
import deleteButton from "../../../images/delete_x.svg";
import addButton from "../../../images/add.svg";

import "./Fields.scss";

const { Option } = Select;

export default function CollaboratorField({
  field,
  currentUser,
  valueEditable = [],
  checkPermissionsAndUpdateValue,
  activelyEditing,
  projectId
}) {
  const collaborators = useSelector((state) => state.collaborators[projectId] || {});

  const [dropdownActive, setDropdownActive] = useState(false);

  const multipleEnabled = field.fieldData.multipleEnabled || false;

  const project = useSelector((state) => state.project.data);

  var projectCollaborators = Object.keys(collaborators).reduce(function (r, e) {
    if (project.collaborator_ids.includes(e)) r[e] = collaborators[e];
    return r;
  }, {});

  useEffect(() => {
    const completeEditing = () => {
      // If the user is clicking outside of the field, and the
      // dropdown is not enabled, close and save field
      if (!dropdownActive) {
        //   setActivelyEditing(false);
      }
    };

    // add when mounted
    document.addEventListener("mousedown", completeEditing);
    // return function to be called when unmounted
    return () => {
      document.removeEventListener("mousedown", completeEditing);
    };
  }, [dropdownActive]);

  function removeUser(uid) {
    if (Array.isArray(valueEditable)) {
      var newArray = [...valueEditable];
      newArray.splice(newArray.indexOf(uid), 1);

      checkPermissionsAndUpdateValue(newArray);
    }
  }

  function userSelected(uid) {
    if (Array.isArray(valueEditable)) {
      if (!valueEditable.includes(uid)) {
        let newArray = [...valueEditable];
        newArray.push(uid);

        checkPermissionsAndUpdateValue(newArray);
      }
    } else {
      let newArray = [];
      newArray.push(uid);

      checkPermissionsAndUpdateValue(newArray);
    }
  }

  return (
    <div style={{ width: "100%" }}>
      {!activelyEditing && (
        <div style={{ overflow: "hidden" }}>
          {!Array.isArray(valueEditable) && (
            <img
              src={addButton}
              className="add-button"
              onClick={() => setDropdownActive(!dropdownActive)}
            />
          )}

          {Array.isArray(valueEditable) && (
            <div className="collaborators-container">
              <div className="users-container">
                {valueEditable.map(function (uid) {
                  if (projectCollaborators[uid]?.data == null) {
                    return null;
                  }

                  return (
                    <span>
                      <div className="card-chip">
                        {projectCollaborators[uid].data.image != null &&
                          projectCollaborators[uid].data.image != "" && (
                            <img
                            onError={(ev) => ev.target.src = require("../../../images/default-avatar.png")}
                              className="user-image"
                              src={projectCollaborators[uid].data.image}
                            />
                          )}

                        {(projectCollaborators[uid].data.image == null ||
                          projectCollaborators[uid].data.image == "") && (
                          <Avatar
                            size={25}
                            style={{
                              color: "#f56a00",
                              backgroundColor: "#fde3cf",
                              verticalAlign: "middle",
                            }}
                          >
                            {getInitialsFromUser(
                              projectCollaborators[uid].data
                            )}
                          </Avatar>
                        )}
                        <span className="user-name">
                          {projectCollaborators[uid].data.name}
                        </span>
                      </div>
                    </span>
                  );
                })}
              </div>
            </div>
          )}
        </div>
      )}

      {activelyEditing && (
        <div>
          <div className="collaborators-container">
            <div className="users-container">
              {Array.isArray(valueEditable) &&
                valueEditable.map(function (uid) {
                  if (projectCollaborators[uid]?.data == null) {
                    return null;
                  }

                  return (
                    <span>
                      <div className="card-chip">
                        {projectCollaborators[uid].data.image != null &&
                          projectCollaborators[uid].data.image != "" && (
                            <img
                            onError={(ev) => ev.target.src = require("../../../images/default-avatar.png")}
                              className="user-image"
                              src={projectCollaborators[uid].data.image}
                            />
                          )}

                        {(projectCollaborators[uid].data.image == null ||
                          projectCollaborators[uid].data.image == "") && (
                          <Avatar
                            size={25}
                            style={{
                              color: "#f56a00",
                              backgroundColor: "#fde3cf",
                              verticalAlign: "middle",
                            }}
                          >
                            {getInitialsFromUser(
                              projectCollaborators[uid].data
                            )}
                          </Avatar>
                        )}
                        <span className="user-name">
                          {projectCollaborators[uid].data.name}
                        </span>
                        <img
                          onClick={() => removeUser(uid)}
                          src={deleteButton}
                          className="delete-button"
                        />
                      </div>
                    </span>
                  );
                })}
              {(multipleEnabled || valueEditable.length < 1) && (
                <img
                  src={addButton}
                  className="add-button"
                  onClick={() => setDropdownActive(!dropdownActive)}
                />
              )}
            </div>
            <div
              style={{
                visibility: "hidden",
                width: "0px",
                height: "0px",
              }}
            >
              <Select
                style={{ width: 170, height: 0 }}
                open={dropdownActive}
                onDropdownVisibleChange={setDropdownActive}
                onChange={userSelected}
              >
                {Object.values(projectCollaborators)
                  .filter(
                    (collaborator) => !valueEditable.includes(collaborator.id)
                  )
                  .map((collaborator) => (
                    <Option
                      key={"option_" + collaborator.id}
                      value={collaborator.id}
                    >
                      {collaborator.data && (
                        <div
                          key={"data_" + collaborator.id}
                          className="user-chip"
                          style={{ backgroundColor: "transparent" }}
                        >
                          {collaborator.data.image != null &&
                            collaborator.data.image != "" && (
                              <img
                              onError={(ev) => ev.target.src = require("../../../images/default-avatar.png")}
                                className="user-image"
                                src={collaborator.data.image}
                              />
                            )}

                          {(collaborator.data.image == null ||
                            collaborator.data.image == "") && (
                            <Avatar
                              size={25}
                              style={{
                                color: "#f56a00",
                                backgroundColor: "#fde3cf",
                                verticalAlign: "middle",
                              }}
                            >
                              {getInitialsFromUser(collaborator.data)}
                            </Avatar>
                          )}
                          <span className="user-name">
                            {collaborator.data.name}
                          </span>
                        </div>
                      )}
                    </Option>
                  ))}
              </Select>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
