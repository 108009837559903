import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Badge } from "antd";
import jsonLogic from "json-logic-js";
import { ThreadView, CommentDrawer } from "../Objects";
import actions from "../../actions";
import ChatBubbleIcon from "../../images/chat_bubble_gray";
import "./Card.scss";
import { hasUnreadThreads } from "../utils";

export default function CardFooter({
  actionButtons = {},
  isEditing,
  object,
  projectId,
  allowComments,
  component,
  sendUpdateNotification,
  setActiveModalAction = { setActiveModalAction },
}) {
  const currentUser = useSelector((state) => state.currentUser);
  const threads = useSelector((state) => state.threads);

  const [messages, setMessages] = useState(threads[object.id] || []);
  const [commentsVisible, setCommentsVisible] = useState(false);

  const [modalVisible, setModalVisible] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    if (threads[object.id] != null) {
      setMessages(threads[object.id]);
    } else {
      setMessages([]);
    }
  }, [threads]);

  useEffect(() => {
    const query = new URLSearchParams(window.location.search);
    const openQuery = "open";

    if (query.get("item") === object.id && query.get(openQuery)) {
      setCommentsVisible(true);
    }
  }, [window.location.search]);

  const toggleCommentsVisible = () => {
    setCommentsVisible(!commentsVisible);

    const searchQuery = new URLSearchParams(window.location.search);
    const openKey = "open";

    if (!commentsVisible) {
      searchQuery.set("item", object.id);
      searchQuery.set(openKey, true);
    } else {
      searchQuery.delete("item");
      if (searchQuery.has(openKey)) {
        searchQuery.delete(openKey);
      }
    }

    const nextUrl = `${window.location.pathname}${
      commentsVisible ? "" : "?"
    }${searchQuery}`;

    window.history.pushState(null, null, nextUrl);
  };

  function actionButtonPressed(actionButton) {
    // If there is an "actions" key, use that, if not, look for field/newValue

    if (actionButton.actions) {
      // For now, we can assume that there is 1 action only (in the future we will support multiple)

      const action = actionButton.actions[0];

      // We support 2 types of actions, update and modal
      if (action.type === "update") {
        // Let's go through the fields array and update the values
        const fields = action.fields;

        var objectTemp = Object.assign({}, object);

        fields.forEach((fieldUpdateData) => {
          objectTemp[fieldUpdateData.field] = fieldUpdateData.newValue;
        });

        sendUpdateNotification(field, objectTemp);

        dispatch(
          actions.collections.updateObjectInCollection(
            objectTemp,
            component.info.collectionKey,
            projectId,
            component.badge?.rules
          )
        );
      } else if (action.type === "modal") {
        // Let's display a modal
        setActiveModalAction(action);
      }
    } else {
      // Legacy support
      var field = actionButton.field;
      var newValue = actionButton.newValue;

      var objectTemp = Object.assign({}, object);
      objectTemp[field] = newValue;

      sendUpdateNotification(field, objectTemp);

      dispatch(
        actions.collections.updateObjectInCollection(
          objectTemp,
          component.info.collectionKey,
          projectId,
          component.badge?.rules
        )
      );
    }
  }

  if (isEditing) {
    return null;
  }

  return (
    <div style={{ marginTop: "25px" }}>
      <CommentDrawer
        title={
          component.info.vocabulary.singular_upper + " #" + (object.ext_id || 0)
        }
        visible={commentsVisible}
        onClose={toggleCommentsVisible}
      >
        <ThreadView
          key={object.id}
          messages={messages}
          projectId={projectId}
          object={object}
          type={component.info.collectionKey}
          component={component}
          visible={commentsVisible}
        />
      </CommentDrawer>

      {Object.values(actionButtons).map((actionButton) => {
        if (jsonLogic.apply(actionButton.showRules, object)) {
          // Let's check the permissions
          const permissions = actionButton.permissions;

          if (permissions != null && permissions.edit != null) {
            if (permissions.edit.includes(currentUser.role)) {
              return (
                <div
                  key={actionButton.name}
                  style={{ display: "inline-block", marginRight: "7px" }}
                  onClick={() => actionButtonPressed(actionButton)}
                  className="actionButton"
                >
                  {actionButton.name}
                </div>
              );
            }
          } else {
            // No permissions defined, so let everybody modify
            return (
              <div
                style={{ display: "inline-block", marginRight: "7px" }}
                onClick={() =>
                  actionButtonPressed(actionButton.newValue, actionButton.field)
                }
                className="actionButton"
              >
                {actionButton.name}
              </div>
            );
          }
        }
      })}

      <div style={{ clear: "right" }}> </div>

      {allowComments && (
        <div style={{ display: "inline-block", float: "right" }}>
          <div style={{ minWidth: "30px" }}>
            <div
              onClick={toggleCommentsVisible}
              style={{ minWidth: "20px", cursor: "pointer" }}
            >
              <div
                style={{
                  fontWeight: 400,
                  fontFamily: "Avenir Next",
                  fontSize: "1vw",
                  width: "100%",
                }}
                className="expand"
              >
                {object.badges &&
                  object.badges.threads != null &&
                  object.badges.threads != 0 && (
                    <Badge
                      style={
                        hasUnreadThreads(currentUser, object)
                          ? { backgroundColor: "#7f05f5" }
                          : { backgroundColor: "#969696" }
                      }
                      count={object.badges.threads}
                    >
                      <ChatBubbleIcon
                        style={{
                          display: "inline",
                          height: "25px",
                          width: "25px",
                        }}
                      />
                    </Badge>
                  )}

                {(!object.badges || !object.badges.threads) && (
                  <ChatBubbleIcon
                    style={{
                      display: "inline",
                      height: "25px",
                      width: "25px",
                    }}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
