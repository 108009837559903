import { items, types } from "./config";
import sendNotification from "./sendNotification";

const fieldNotSpecified = "";

/**
 * Send a notification for a new comment
 * @param {Object} project Project notification involves
 * @param {Object} comment New comment notification is about
 * @param {Object} user User intializing notification
 */
export const newComment = (
  project,
  comment,
  user,
  component,
  collaborators = {}
) =>
  sendNotification(
    items.comment,
    fieldNotSpecified,
    types.new,
    project,
    comment,
    user,
    component,
    collaborators
  );

export default {
  newComment,
};
