import React from "react";
import { LoadingOutlined } from '@ant-design/icons';
import { Spin } from "antd";
import IconImage from "../../images/aloa_a.png";
import "./Loading.css";

const antIcon = (
  <LoadingOutlined style={{ fontSize: 30, color: "#1D82F6" }} spin />
);

export default function Loading() {
  return (
    <div
      style={{
        color: "#999",
        fontSize: "23px",
        fontWeight: "250",
        textAlign: "center",
        fontFamily: "Brown",
        margin: "auto",
        position: "absolute",
        top: "30%",
        width: "100%",
        transform: "translateY(-30%)"
        // -ms-transform: "translateY(-30%)",
      }}
    >
      <div style={{ margin: "auto", textAlign: "center" }}>
        <div
          style={{
            margin: "auto",
            textAlign: "center",
            width: "100px",
            height: "100px",
            backgroundColor: "#1D293F",
            overflow: "hidden"
          }}
        >
          <img
            width="50"
            height="50"
            style={{ marginTop: "20px" }}
            src={IconImage}
          />
          <div
            className="animate"
            style={{
              width: "43px",
              height: "6px",
              backgroundColor: "#1D82F6",
              marginTop: "8px",
              marginLeft: "30px"
            }}
          />
        </div>
        {/* <Spin indicator={antIcon} size="large" style={{ marginTop: "25px" }} /> */}
        {/* Loading */}
      </div>
    </div>
  );
}
