import React, { useState, useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import * as algoliasearch from 'algoliasearch'
import SearchCard from './SearchCard'
import SearchResultSection from './SearchResultSection'
import { setSearchValue } from '../../actions/search'

import comment_icon from '../../images/chat_bubble_black.svg'
import search_icon from '../../images/search_icon.svg'
import close_icon from '../../images/close_icon.svg'
import searching_placeholder from '../../images/searching-1.png'
import no_results_image from '../../images/no-messages.png'
import loading_image from '../../images/svg-loaders/puff.svg'

import { createNullCache } from '@algolia/cache-common';



const client = algoliasearch(
  process.env.REACT_APP_ALGOLIA_PROJECT_ID,
  process.env.REACT_APP_ALGOLIA_SEARCH_ONLY_KEY,
  {
    // Caches responses from Algolia
    responsesCache: createNullCache(),
    // Caches Promises with the same request payload
    requestsCache: createNullCache()
  }
)

export default function Search ({ projectId, onClose, isActive, components }) {
  const [searchSections, setSearchSections] = useState([])
  const [searchStatus, setSearchStatus] = useState({
    loading: false,
    term: '',
    initialView: true
  })

  const inputRef = useRef(null)

  const searchValue = useSelector(state => state.search?.value)

  const dispatch = useDispatch()
  const updateSearchValue = value => dispatch(setSearchValue(value))

  useEffect(() => {
    if (isActive) {
      inputRef.current.focus()
    }
  }, [isActive])

  function keyPressed (event) {
    if (event.key === 'Enter' && searchValue.replace(/\s/g, '').length) {
      // only query string
      setSearchStatus({ loading: true, term: '', initialView: false })

      var queries = []

      Object.keys(components).forEach(function (component_id) {
        var component = components[component_id]

        if (component.search != null) {
          var query = {
            indexName: projectId + '_' + component_id,
            query: searchValue,
            params: {
              highlightPreTag:
                '<b style="border-bottom: solid 2px rgba(0,137,255,0.3);">',
              highlightPostTag: '</b>',
              removeWordsIfNoResults: 'allOptional'
              //   attributesToHighlight: component.searchableAttributes,
            }
          }

          queries.push(query)
        }
      })

      // Make sure to add a query for threads which is special to all projects
      queries.push({
        indexName: projectId + '_threads',
        query: searchValue,
        params: {
          highlightPreTag:
            '<b style="border-bottom: solid 2px rgba(0,137,255,0.3);">',
          highlightPostTag: '</b>',
          removeWordsIfNoResults: 'allOptional'
        }
      })

      // perform 3 queries in a single API call:
      //  - 1st query targets index `categories`
      //  - 2nd and 3rd queries target index `products`
      client.multipleQueries(queries).then(({ results }) => {
        let sections = []
        results.forEach(function (result) {
          let component_id = result.index.replace(`${projectId}_`, '')

          var component = components[component_id]

          var searchCards = []

          result.hits.forEach(function (hit) {
            let highlightResult = 'Nothing'
            let id = hit.objectID

            if (component_id != 'threads') {
              // Iterate through each possible searchable attribute and highlight the result
              component.search.searchableAttributes.forEach(function (
                field_id
              ) {
                if (
                  hit._highlightResult[field_id]?.matchLevel &&
                  hit._highlightResult[field_id]?.matchLevel != 'none'
                ) {
                  highlightResult = hit._highlightResult[field_id].value
                }
              })
            } else {
              if (hit.item_type) {
                //
                highlightResult = hit._highlightResult['content'].value

                var commentedOnItemComponent = components[hit.item_type]
                component = {
                  info: {
                    name: 'Threads',
                    icon: comment_icon,
                    default_view:
                      commentedOnItemComponent.info.default_view || 'cardlist',
                    collectionKey: commentedOnItemComponent.info.collectionKey,
                    type: commentedOnItemComponent.info.type,
                    vocabulary: commentedOnItemComponent.info.vocabulary
                  }
                }
              }
            }

            if (highlightResult != 'Nothing') {
              searchCards.push(
                <SearchCard
                  projectId={projectId}
                  highlightResult={highlightResult}
                  image={component.info.icon}
                  created={hit.created}
                  timestamp={hit.timestamp}
                  created_by_user={hit.created_by_user}
                  comment_user={hit.user}
                  componentInfo={component.info}
                  id={id}
                  item_id={hit.item_id}
                  key={hit.objectID}
                  close={onClose}
                />
              )
            }
          })

          if (searchCards.length != 0) {
            sections.push(
              <SearchResultSection
                componentInfo={component.info}
                searchCards={searchCards}
              />
            )
          }
        })
        setTimeout(function () {
          setSearchStatus({
            loading: false,
            term: searchValue,
            initialView: false
          })
          setSearchSections(sections)
        }, 250)
      })
    }
  }

  return (
    <div>
      <div
        style={{
          borderBottom: '1px solid #d1d1d1',
          display: 'flex',
          alignItems: 'center',
          borderTopLeftRadius: '10px',
          borderTopRightRadius: '10px'
        }}
      >
        <div
          style={{
            display: 'inline-block',
            marginLeft: '20px',
            marginRight: '15px'
          }}
        >
          <img style={{ height: '15px' }} src={search_icon} />
        </div>
        <input
          style={{
            width: '100%',
            border: 'none',
            height: '55px',
            display: 'inline-block',
            fontSize: '15px'
          }}
          value={searchValue}
          onChange={e => {
            updateSearchValue(e.target.value)
          }}
          ref={inputRef}
          onKeyPress={keyPressed}
          type='text'
          placeholder='Search everything in this project'
        />
        <div
          style={{
            display: 'inline-block',
            marginLeft: '10px',
            marginRight: '20px',
            float: 'right',
            cursor: 'pointer'
          }}
          onClick={onClose}
        >
          <img style={{ height: '15px' }} src={close_icon} />
        </div>
      </div>

      <div
        style={{
          padding: '1px 20px 20px 20px',
          borderRadius: '10px',
          backgroundColor: '#F8F8F8'
        }}
      >
        {searchSections.length == 0 && searchStatus.initialView == true && (
          <div style={{ marginTop: '20px', textAlign: 'center' }}>
            <img src={searching_placeholder} style={{ width: '300px' }} />

            {searchValue.length > 0 && (
              <div
                style={{
                  fontFamily: 'Avenir Next',
                  fontSize: '20px',
                  marginBottom: '30px'
                }}
              >
                Press "Enter" to start search
              </div>
            )}

            {searchValue.length == 0 && (
              <div
                style={{
                  fontFamily: 'Avenir Next',
                  fontSize: '20px',
                  marginBottom: '30px'
                }}
              >
                Search through comments, bugs, status items, etc...
              </div>
            )}
          </div>
        )}

        {searchStatus.loading == true && (
          <div style={{ textAlign: 'center', padding: '50px' }}>
            {' '}
            <img src={loading_image} style={{ width: '75px' }} />{' '}
          </div>
        )}

        {searchSections.length == 0 &&
          searchStatus.loading == false &&
          searchStatus.initialView == false && (
            <div style={{ marginTop: '20px', textAlign: 'center' }}>
              <img src={no_results_image} style={{ width: '300px' }} />
              <div
                style={{
                  fontFamily: 'Avenir Next',
                  fontSize: '20px',
                  marginBottom: '30px'
                }}
              >
                No Results for "{searchStatus.term}"
              </div>
            </div>
          )}

        {searchStatus.loading == false && searchSections}
      </div>
    </div>
  )
}
