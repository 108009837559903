import { createReducer } from "@reduxjs/toolkit";

function setOffers(growthState, action) {
  growthState["offers"] = action.offers;
}

// Slice reducer
export default createReducer(
  {},
  {
    SET_OFFERS: setOffers,
  }
);
