export default {
  info: {
    name: "Environments",
    addon: true,
    collectionKey: "environments",
    vocabulary: {
      singular_upper: "Environment",
      singular_lower: "environment",
      plural_upper: "Environments",
      plural_lower: "environments",
    },
    type: "environment",
    icon:
      "https://firebasestorage.googleapis.com/v0/b/hermes-50f48.appspot.com/o/icons%2Fserver2.svg?alt=media&token=03cf0c31-b001-4279-8746-303fae87f043",
  },
  fields: {
    type: {
      title: "Environment Type",
      valueKey: "type",
      hideTitle: true,
      fieldData: {
        type: "tag",
        defaultValue: "development",
        options: {
          development: {
            id: "development",
            name: "Development",
            color: "#ffffff",
            backgroundColor: "#858585",
          },
          staging: {
            id: "staging",
            name: "Staging",
            color: "#ffffff",
            backgroundColor: "#ff7b00",
          },
          production: {
            id: "production",
            name: "Production",
            color: "#ffffff",
            backgroundColor: "#4400ff",
          },
        },
        options_order: ["development", "staging", "production"],
      },
    },
    name: {
      title: "Environment name",
      valueKey: "name",
      fieldData: {
        type: "text",
        placeholder: "Example: Production Server",
      },
      required: true,
    },
    link: {
      title: "Environment link",
      valueKey: "link",
      fieldData: {
        type: "link",
        placeholder: "Example: https://staging.aloa.co",
      },
      required: true,
    },
    created: {
      title: "Date Added",
      valueKey: "created",
      fieldData: {
        type: "date",
      },
      editingDisabled: true,
    },
    email: {
      title: "Email or Username (test account)",
      valueKey: "email",
      fieldData: {
        type: "text",
        placeholder: "Example: temp-email@gmail.com",
      },
      required: false,
    },
    password: {
      title: "Password (test account)",
      valueKey: "password",
      fieldData: {
        type: "password",
        placeholder: "Example: password123",
      },
      required: false,
    },
    server_notes: {
      title: "Server Access Info",
      valueKey: "server_notes",
      fieldData: {
        type: "textArea",
        placeholder:
          "Example: SSH to this IP address (insert IP) with root and password is 'password123', I've also attached a PEM file",
      },
      required: false,
    },
    description: {
      title: "Additional Notes",
      valueKey: "notes",
      fieldData: {
        type: "textArea",
        placeholder:
          "Example: Remember to log in via SSH and not through the DigitalOcean panel",
      },
      required: false,
    },
    status: {
      title: "Status",
      valueKey: "status",
      fieldData: {
        type: "select",
        defaultValue: "valid",
        options: {
          valid: { id: "valid", name: "Valid" },
          invalid: { id: "invalid", name: "Invalid" },
        },
        options_order: ["valid", "invalid"],
      },
      editingDisabled: true,
      hidden: true,
    },
    attachments: {
      title: "Attachments",
      valueKey: "attachments",
      fieldData: { type: "attachments" },
      hideTitle: true,
    },
  },
  search: {
    searchableAttributes: ["name"],
  },
  notifications: {
    slack: {
      color: "#707070",
      primaryField: "name",
      fields: {
        create: ["name", "type", "link"],
        edit: ["name", "type", "link"],
      },
      buttons: [
        {
          text: "View Environment",
        },
      ],
    },
  },
  permissions: {
    view: ["viewer", "vendor", "client", "admin"],
    create: ["vendor", "client", "admin"],
    edit: ["vendor", "client", "admin"],
    delete: ["vendor", "client", "admin"],
  },
  customOrderingOnly: true,
  dragDropEnabled: true,
  cardlist: {
    fieldOrder: [
      "type",
      "name",
      "link",
      "email",
      "password",
      "server_notes",
      "description",
      "attachments",
    ],
    actionButtons: [
      {
        name: "Mark as 'Not Working'",
        field: "status",
        newValue: "invalid",
        showRules: {
          or: [
            { "==": [{ var: "status" }, null] },
            { "==": [{ var: "status" }, "valid"] },
          ],
        },
        permissions: {
          edit: ["vendor", "client", "admin"],
        },
      },
      {
        name: "Mark as 'Working'",
        field: "status",
        newValue: "valid",
        showRules: { "==": [{ var: "status" }, "invalid"] },
        permissions: {
          edit: ["vendor", "client", "admin"],
        },
      },
    ],
    defaultTab: "valid",
    tabs: [
      {
        name: "Working",
        key: "valid",

        rules: {
          or: [
            { "==": [{ var: "status" }, null] },
            { "==": [{ var: "status" }, "valid"] },
          ],
        },
      },
      {
        name: "Not Working",
        key: "invalid",
        rules: { "==": [{ var: "status" }, "invalid"] },
      },
    ],
  },
  badge: {
    rules: { "==": [{ var: "status" }, "invalid"] },
  },
};
