import React from "react";

export default function LinkField({
  field,
  currentUser,
  valueEditable,
  checkPermissionsAndUpdateValue,
  activelyEditing,
  setActivelyEditing,
  hoverActive,
  roles
}) {
  return (
    <div className="full-width">
      {!activelyEditing && valueEditable != null && (
        <div style={{ padding: "10px" }}>
          <a
            onClick={() => {
              var url = valueEditable.match(/^https?:/)
                ? valueEditable
                : "http://" + valueEditable;
              window.open(url);
            }}
          >
            {valueEditable}
          </a>
          {hoverActive && (
              <span
                className="cell-edit-button"
                onClick={() => setActivelyEditing(true)}
              >
                (Edit)
              </span>
            )}
        </div>
      )}

      {!activelyEditing && (valueEditable == null || valueEditable == "") && (
        <div style={{ padding: "10px" }}>
          <span
                className="cell-add-button"
                onClick={() => setActivelyEditing(true)}
              >
                Add Link
              </span>
        </div>
      )}

      {activelyEditing && (
        <div className="pop-out">
          <input
            autoFocus
            className="expanded-input single-line"
            placeholder={field.fieldData.placeholder}
            value={valueEditable}
            onChange={(e) => checkPermissionsAndUpdateValue(e.target.value)}
            //      onBlur={e => finishedEditing(e, "name")}
            onBlur={() => setActivelyEditing(false)}
          />
        </div>
      )}
    </div>
  );
}
