import React, { useState, useEffect } from "react";

import { useSelector, useDispatch } from "react-redux";
import { deepEqualCheck, getPrimaryValueFromObject } from "../utils";

import { Modal } from "antd";

import "./ReferenceModal.scss";

import LinkItemsModal from "./LinkItemsModal";
import actions from "../../actions";

import { XIcon, ExternalLinkIcon } from "@heroicons/react/outline";
import { current } from "immer";
import addButton from "../../images/add.svg";

import _ from "lodash";

import { useHistory } from "react-router";

export default function ReferenceModal({
  referencedItems = {},
  projectId,
  updateReferencedItems,
  collectionKey,
  viewType,
  field,
  components,
  currentObjectId,
  activelyEditing,
  processedLinkedFields,
  setProcessedLinkedFields,
}) {
  const history = useHistory();

  const collections = useSelector(
    (state) => state.collections[projectId],
    deepEqualCheck
  );

  const [modalVisible, setModalVisible] = useState(false);

  const [linkItemsModalVisible, setLinkItemsModalVisible] = useState(false);

  const currentUser = useSelector((state) => state.currentUser);

  const dispatch = useDispatch();

  function handleCancel(e) {
    setModalVisible(false);
  }

  // If there are any linkedFields, let's update them
  function updateLinkedFields(item, itemType, actionType) {
    if (field?.fieldData?.linkedFields) {
      const linkedFieldKey = field.fieldData.linkedFields[itemType];

      if (linkedFieldKey) {
        var updatedReferencedItems = _.cloneDeep(item[linkedFieldKey] || {});

        if (actionType === "add") {
          // Add the new item to the currentReferencedItems
          updatedReferencedItems[currentObjectId] = {
            id: currentObjectId,
            itemType: collectionKey,
          };
        } else if (actionType === "remove") {
          // Remove the item from the currentReferencedItems
          delete updatedReferencedItems[currentObjectId];
        }

        const updatedObject = _.cloneDeep(item);
        updatedObject[linkedFieldKey] = updatedReferencedItems;

        // For card, we want to process linked fields manually (later)
        // For list, we want to process linked fields as they happen
        if (viewType === "card") {
          // Let's add to processedLinkedFields array
          const updatedProcessedLinkedFields = _.cloneDeep(
            processedLinkedFields
          );
          updatedProcessedLinkedFields.push({
            updatedObject,
            itemType,
            projectId,
          });

          setProcessedLinkedFields(updatedProcessedLinkedFields);
        } else {
          dispatch(
            actions.collections.updateObjectInCollection(
              updatedObject,
              itemType,
              projectId,
              null
            )
          );
        }
      }
    }
  }

  // Function to add a new item to the related items field
  function addNewItem(item, itemType) {
    const newReferencedItems = { ...referencedItems };
    newReferencedItems[item.id] = {
      id: item.id,
      itemType: itemType,
    };
    updateReferencedItems(newReferencedItems);
    updateLinkedFields(item, itemType, "add");
  }

  // Function to remove an item from the related items field
  function removeItem(item, itemType) {
    const newReferencedItems = { ...referencedItems };
    delete newReferencedItems[item.id];

    updateReferencedItems(newReferencedItems);
    updateLinkedFields(item, itemType, "remove");
  }

  if (viewType == "table") {
    return (
      <div className="users" style={{ width: "100%", overflow: "hidden" }}>
        {linkItemsModalVisible && (
          <LinkItemsModal
            projectId={projectId}
            visible={linkItemsModalVisible}
            setVisible={setLinkItemsModalVisible}
            types={field?.fieldData?.itemTypes}
            components={components}
            addNewItem={addNewItem}
            referencedItems={referencedItems}
            zIndex={10000}
          />
        )}

        {!referencedItems ||
          (Object.keys(referencedItems).length === 0 && (
            <img
              src={addButton}
              className="add-button"
              onClick={() => {
                setLinkItemsModalVisible(true);
              }}
            />
          ))}

        {referencedItems && Object.keys(referencedItems).length > 0 && (
          <div onClick={() => setModalVisible(true)}>
            {Object.values(referencedItems).length > 0 && (
              <div className="lim-count-chip">
                {Object.values(referencedItems).length}
              </div>
            )}
            {Object.values(referencedItems).map((linkedItem) => {
              if (!collections[linkedItem.itemType]) {
                return null;
              }
              var component = components[linkedItem.itemType];
              const objectName = component.info.vocabulary.singular_upper;

              var object = collections[linkedItem.itemType][linkedItem.id];

              if (object) {
                return (
                  <LinkedItemChip
                    projectId={projectId}
                    component={component}
                    linkedItem={linkedItem}
                    object={object}
                    removeItem={removeItem}
                    activelyEditing={activelyEditing}
                  />
                );
              }
            })}

            <img src={addButton} className="add-button" />
          </div>
        )}

        <Modal
          title={`${Object.keys(referencedItems).length} Linked items`}
          footer={null}
          visible={modalVisible}
          onCancel={handleCancel}
        >
          <div>
            {referencedItems &&
              Object.values(referencedItems).map((linkedItem) => {
                if (!collections[linkedItem.itemType]) {
                  return null;
                }
                var component = components[linkedItem.itemType];
                const objectName = component.info.vocabulary.singular_upper;

                var object = collections[linkedItem.itemType][linkedItem.id];

                if (object) {
                  return (
                    <LinkedItemCard
                      projectId={projectId}
                      component={component}
                      linkedItem={linkedItem}
                      object={object}
                      removeItem={removeItem}
                      activelyEditing={true}
                    />
                  );
                }
              })}

            <div
              className="lim-add-items-button"
              onClick={() => {
                setLinkItemsModalVisible(true);
              }}
            >
              <ExternalLinkIcon className="lim-add-button-icon" />
              Link Items
            </div>
          </div>
        </Modal>
      </div>
    );
  } else {
    return (
      <div className="rm-card">
        {linkItemsModalVisible && (
          <LinkItemsModal
            projectId={projectId}
            visible={linkItemsModalVisible}
            setVisible={setLinkItemsModalVisible}
            types={field?.fieldData?.itemTypes}
            components={components}
            addNewItem={addNewItem}
            referencedItems={referencedItems}
          />
        )}

        <div>
          {referencedItems &&
            Object.values(referencedItems).map((linkedItem) => {
              if (!collections[linkedItem.itemType]) {
                return null;
              }
              var component = components[linkedItem.itemType];
              const objectName = component.info.vocabulary.singular_upper;

              var object = collections[linkedItem.itemType][linkedItem.id];

              if (object) {
                return (
                  <LinkedItemCard
                    projectId={projectId}
                    component={component}
                    linkedItem={linkedItem}
                    object={object}
                    removeItem={removeItem}
                    activelyEditing={activelyEditing}
                  />
                );
              }
            })}
          {activelyEditing && (
            <div
              className="lim-add-items-button"
              onClick={() => {
                setLinkItemsModalVisible(true);
              }}
            >
              <ExternalLinkIcon className="lim-add-button-icon" />
              Link Items
            </div>
          )}
        </div>
      </div>
    );
  }
}

export function LinkedItemCard({
  projectId,
  component,
  linkedItem,
  object,
  removeItem,
  activelyEditing,
  isAdding = false,
  addNewItem,
  type,
}) {
  const objectName = component.info.vocabulary.singular_upper;

  const history = useHistory();
  return (
    <div
      onClick={() => {
        if (isAdding) {
          addNewItem(object, type);
        } else {
          history.push(
            `/p/${projectId}/${component.info.default_view || "cardlist"}/${
              linkedItem.itemType
            }?item=${object.id}`
          );
        }
      }}
      className="lim-card"
      key={object.id}
    >
      <div className="lim-card-container">
        <div className="lim-card-content">
          <div className="lim-card-item-number">
            <img src={component.info.icon} className="lim-card-title-icon" />
            {objectName} #{object.ext_id}
          </div>
          <div className="lim-card-field-title">
            {
              component.fields[component?.notifications?.slack?.primaryField]
                ?.title
            }
          </div>
          <div className="lim-card-item-primary-value">
            {getPrimaryValueFromObject(object, component)}
          </div>
        </div>
        {activelyEditing && (
          <XIcon
            className="lim-remove-button"
            onClick={(e) => {
              e.stopPropagation();
              removeItem(object, linkedItem.itemType);
            }}
          />
        )}
      </div>
    </div>
  );
}

export function LinkedItemChip({
  projectId,
  component,
  linkedItem,
  object,
  removeItem,
  activelyEditing,
  isAdding = false,
  addNewItem,
  type,
}) {
  const objectName = component.info.vocabulary.singular_upper;

  const history = useHistory();
  return (
    <div className="user-chip" key={object.id}>
      <img src={component.info.icon} className="component-image" />
      <span className="user-name">
        {getPrimaryValueFromObject(object, component)}
      </span>
    </div>
  );
}
