import React, { Component } from 'react';

export default class SolidButton extends Component {
    render() {
        return (
            <div>
                <div style={{
                        "fontFamily": "Avenir Next",
                        "fontSize": "13px",
                        "border": "1px solid",
                        "borderTopLeftRadius": "5px",
                        "borderTopRightRadius": "5px",
                        "borderBottomRightRadius": "5px",
                        "borderBottomLeftRadius": "5px",
                        "padding": "7px",
                        "backgroundColor": "#056cff",
                        "color": "#ffffff",
                        "cursor": "pointer",
                        "float": "right"
                    }}
                    onClick={event => this.props.action()}>
                    <div style={{
                        "display": "inline-block",
                        "fontSize": "13px",
                        "verticalAlign": "middle"
                        }}>
                        {this.props.name}
                    </div>
                </div>
                <div style={{clear: "right"}}> </div>
            </div>
        )
    }
}