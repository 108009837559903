import React from "react";
import ComponentConfigs from "../ComponentConfigs";
import moment from "moment";
import jsonLogic from "json-logic-js";
import CollaboratorContentWidget from "./CollaboratorContetntWidget";

// TODO - This is a rough brute force approach right now
// Once we have some categories of different things, it will be generalized
// And then shouldn't branch based on notification type
export default (notification) => {
  if (notification.itemType !== "comment") {
    let objectTitle = getObjectTitle(notification, true, true);
    const itemTitle = getItemTitle(notification);
    const itemUrl = getItemUrl(notification);
    const projectName = getProjectName(notification);
    const projectUrl = getProjectUrl(notification);

    notification.url = itemUrl;

    if (notification.notificationType === "update") {
      objectTitle = getObjectTitle(notification, true, true);
      const objectFieldTitle = getObjectFieldTitle(notification);
      const itemFieldValue = getItemFieldValue(
        notification,
        notification.field
      );

      notification.text = `${objectTitle} [${itemTitle}](${itemUrl}) updated in [${projectName}](${projectUrl})`;

      if (typeof itemFieldValue !== "object") {
        notification.item.content = `${objectFieldTitle}: ${itemFieldValue}`;
      } else {
        notification.item.content = (
          <div>
            <div style={{ marginBottom: "4px" }}>{objectFieldTitle}:</div>
            {itemFieldValue}
          </div>
        );
      }

      notification.callToAction = `View Update`;
    } else if (notification.notificationType === "new") {
      objectTitle = getObjectTitle(notification, true, false);
      notification.text = `[New ${objectTitle}](${itemUrl}) created in [${projectName}](${projectUrl})`;

      // TODO - This condition should not be here, should be in a component configuration
      // Or we should have some other type of filtering based on the required attribute
      if (
        notification.itemType === "standups" ||
        notification.itemType === "feedbacks" ||
        notification.itemType === "bugs"
      ) {
        notification.item.content = getAllItemFields(notification);
      } else {
        notification.item.content = itemTitle;
      }

      notification.callToAction = `View New ${getObjectTitle(
        notification,
        true,
        true
      )}`;
    }
  } else {
    const itemTitle = getItemTitle(notification);
    const objectTitle = getObjectTitle(notification, true, false);
    const itemUrl = getItemUrl(notification);
    const projectName = getProjectName(notification);
    const projectUrl = getProjectUrl(notification);

    notification.callToAction = `Reply`;
    notification.text = `New comment on ${objectTitle} [${itemTitle}](${itemUrl}) in [${projectName}](${projectUrl})`;

    if (notification.item.item_type === "standups") {
      notification.text = `New comment on ${objectTitle} from [${getItemFieldValue(
        notification,
        "created"
      )}](${itemUrl}) in [${projectName}](${projectUrl})`;
    }

    notification.url = getCommentUrl(notification);
  }
};

const getComponentConfig = (notification) => {
  if (notification.configs) {
    return notification.configs.component;
  }

  switch (notification.itemType) {
    case "comment":
      return ComponentConfigs[notification.item.item_type];
    default:
      return ComponentConfigs[notification.itemType];
  }
};

const getComponent = (notification) => {
  const componentConfig = getComponentConfig(notification);
  const itemType =
    notification.itemType !== "comment"
      ? notification.itemType
      : notification.item.item_type;
  return componentConfig;
};

const getObjectTitle = (notification, singular, upper) => {
  const componentConfig = getComponentConfig(notification);

  const vocab = componentConfig.info.vocabulary;
  switch ((singular, upper)) {
    case (true, true):
      return vocab.singular_upper;
    case (true, false):
      return vocab.singular_lower;
    case (false, true):
      return vocab.plural_upper;
    case (false, false):
      return vocab.plural_lower;
  }
};

const getObjectFieldTitle = (notification) => {
  const component = getComponent(notification);

  return component.fields
    ? component.fields[notification.field]?.title
    : "No title";
};

const getItemTitle = (notification) => {
  const componentConfig = getComponentConfig(notification);
  const { primaryField } = componentConfig.notifications.slack;
  switch (notification.itemType) {
    case "comment":
      return notification.project?.[notification.item.item_type]?.[
        notification.item.item_id
      ]?.[primaryField];
    default:
      if (
        notification.item[primaryField] &&
        notification.item[primaryField].seconds
      ) {
        // This is a date object
        return moment(notification.item[primaryField].seconds * 1000).format(
          "MMM D, YYYY"
        );
      } else {
        return notification.item[primaryField];
      }
  }
};

const getItemUrl = (notification) => {
  const componentConfig = getComponentConfig(notification);

  const itemId =
    notification.itemType !== "comment"
      ? notification.item.id
      : notification.item.item_id;

  return `/p/${notification.project.id}/${
    componentConfig.info.default_view ?? "cardlist"
  }/${componentConfig.info.collectionKey}?item=${itemId}`;
};

const getCommentUrl = (notification) => {
  const componentConfig = getComponentConfig(notification);
  return `/p/${notification.project.id}/${
    componentConfig.info.default_view ?? "cardlist"
  }/${componentConfig.info.collectionKey}?item=${
    notification.item.item_id
  }&open=true`;
};

const getItemFieldValue = (notification, field) => {
  if (field) {
    const componentConfig = getComponentConfig(notification);

    if (componentConfig.fields == null) {
      return "";
    }
    // const objectConfig = getObjectConfig(notification);
    const fieldConfig = componentConfig.fields[field];
    const itemValue =
      notification.itemType !== "comment"
        ? notification.item[field]
        : notification.project[notification.item.item_type][
            notification.item.item_id
          ].created;

    if (componentConfig?.cardlist?.tabs) {
      const isTabChange = componentConfig.cardlist.tabs.every(
        (item) =>
          Object.values(item.rules).filter((condition) =>
            condition.find((item) => item.var === field)
          ).length > 0
      );

      if (isTabChange) {
        const tab = componentConfig.cardlist.tabs.find((category) =>
          jsonLogic.apply(category.rules, notification.item)
        );
        return tab.name;
      }
    }

    if (fieldConfig === undefined) {
      return "";
    }

    if (fieldConfig.fieldData.type === "select") {
      return fieldConfig.fieldData.options[itemValue].name;
    } else if (fieldConfig.fieldData.type === "date") {
      return moment(itemValue.seconds * 1000).format("MMM DD, YYYY");
    } else if (fieldConfig.fieldData.type === "attachments") {
      return notification.item.attachments.reduce((prev, curr, index) => {
        if (index === 0) {
          return curr.name;
        } else {
          return `${prev}, ${curr.name}`;
        }
      }, "");
    } else if (fieldConfig.fieldData.type === "collaborator") {
      // TODO - Need to figure out how to display collaborators
      return (
        <>
          {itemValue.map((collaboratorId) => (
            <CollaboratorContentWidget id={collaboratorId} />
          ))}
        </>
      );
    } else {
      return itemValue;
    }
  } else {
    return "";
  }
};

const getAllItemFields = (notification) =>
  Object.values(getComponentConfig(notification).fields || {})
    .filter((field) => field.required)
    .map((field) => (
      <div>
        <div style={{ fontWeight: 600 }}>{field.title}</div>
        <div>{getItemFieldValue(notification, field.valueKey)}</div>
      </div>
    ));

const getProjectName = (notification) => notification.project.name;
const getProjectUrl = (notification) => {
  const componentConfig = getComponentConfig(notification);
  return `/p/${notification.project.id}/${
    componentConfig.info.default_view ?? "cardlist"
  }/${componentConfig.info.collectionKey}`;
};
