import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Badge } from "antd";
import { NotificationTwoTone, NotificationOutlined } from "@ant-design/icons";
import styled from "styled-components";
import _ from "lodash";
import Toggle, {
  On,
  Off,
  featureTypes,
  isFeatureEnabledForUser,
} from "../FeatureToggle";
import { setNotificationsVisible } from "../../actions/notifications";

export default function NotificationsIcon() {
  const projectUnreadNotifications = useSelector(
    (state) => state.currentUser.projectUnreadNotifications,
    _.isEqual
  );
  const notificationsVisible = useSelector(
    (state) => state.notificationsState?.visible
  );
  const doNotDisturb = useSelector(
    (state) => state.notificationsState?.doNotDisturb
  );

  const notificationCount = Object.values(
    projectUnreadNotifications ?? {}
  ).reduce((acc, curr) => acc + curr, 0);

  const nativeNotificationsFeature = useSelector(
    (state) => state.features[featureTypes.NATIVE_NOTIFICATIONS]
  );
  const currentUser = useSelector((state) => state.currentUser);

  useEffect(() => {
    if (
      notificationCount > 0 &&
      isFeatureEnabledForUser(nativeNotificationsFeature, currentUser)
    ) {
      window.document.title = `(${notificationCount}) Aloa Manage`;
    } else {
      window.document.title = `Aloa Manage`;
    }
  }, [notificationCount]);

  const dispatch = useDispatch();
  const handleToggle = () => {
    dispatch(setNotificationsVisible(!notificationsVisible));
  };

  return (
    <Toggle feature={featureTypes.NATIVE_NOTIFICATIONS}>
      <On>
        <IconContainer onClick={handleToggle}>
          <Badge
            count={doNotDisturb ? "zzz" : notificationCount}
            style={{
              background: doNotDisturb ? "rgb(0, 0, 0, 0.4)" : "#f5222d",
            }}
            overflowCount={999}
          >
            {notificationsVisible ? (
              <NotificationTwoTone style={{ fontSize: "23px" }} />
            ) : (
              <NotificationOutlined style={{ fontSize: "23px" }} />
            )}
          </Badge>
        </IconContainer>
      </On>
      <Off></Off>
    </Toggle>
  );
}

const IconContainer = styled.div`
  margin-right: 20px;
  cursor: pointer;
`;
