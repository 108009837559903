import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import "./Search.scss";

export default function SearchResultSection({ componentInfo, searchCards }) {
  const [expanded, setExpanded] = useState(false);

  return (
    <div>
      <div key={componentInfo.collection_key} className="searchSectionHeader">
        <div className="searchLink">
          <div
            style={{ textDecoration: "none", color: "#616161" }}
          >
            <div style={{ display: "flex", alignItems: "center" }}>
              {componentInfo.name} {"(" + searchCards.length + ")"}
            </div>
          </div>
        </div>
      </div>

      {searchCards.slice(0, 3)}

      {expanded && searchCards.slice(3)}

      {searchCards.length > 3 && (
        <div className="expandButton" onClick={() => setExpanded(!expanded)}>
          {expanded && <span>Show Less</span>}
          {!expanded && <span>Show More</span>}
        </div>
      )}
    </div>
  );
}