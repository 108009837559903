import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import { useSelector, useDispatch } from "react-redux";
import { Dropdown } from "antd";
import { MoreOutlined } from "@ant-design/icons";
import _ from "lodash";
import NotificationMenu from "./NotificationMenu";
import NotificationContent from "./NotificationContent";
import EmptyMessage from "./EmptyMessage";
import Loading from "./Loading";
import {
  fetchNotifications,
  setNotificationsVisible,
  clearNotificationsProject,
} from "../../actions/notifications";
import { useOutsideClick } from "../utils";

export default function Notifications() {
  const notifications = useSelector((state) => state.notifications);
  const visible = useSelector((state) => state.notificationsState?.visible);
  const notificationsProject = useSelector(
    (state) => state.notificationsState?.project
  );

  const [menuVisible, setMenuVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [maybeMoreToLoad, setMaybeMoreToLoad] = useState(true);

  const dispatch = useDispatch();
  const ref = useRef();

  const handleScroll = async (e) => {
    const scrollAtBottom =
      e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight;

    if (scrollAtBottom && maybeMoreToLoad) {
      setLoading(true);
      try {
        const allNotificationsFetched = await dispatch(fetchNotifications());
        setMaybeMoreToLoad(!allNotificationsFetched);
        setLoading(false);
      } catch (error) {
        console.error(error);
      }
    }
  };

  useOutsideClick(
    ref,
    () => {
      if (visible && !menuVisible) {
        dispatch(setNotificationsVisible(!visible));
        dispatch(clearNotificationsProject());
      }
    },
    [visible, menuVisible]
  );

  useEffect(() => {
    if (visible) {
      dispatch(fetchNotifications());
    }
  }, [visible]);

  const sortedNotifications = Object.values(notifications)
    .filter(
      (notification) =>
        notificationsProject === null ||
        notificationsProject?.id === notification.project.id
    )
    .sort((a, b) => b.date.toDate() - a.date.toDate());

  return (
    <StickyContainer onScroll={handleScroll}>
      <NotificationsContainer hidden={!visible} ref={ref}>
        <Header>
          <span>
            {notificationsProject ? `${notificationsProject.name} ` : ""}
            Notifications
          </span>
          <MoreIconContainer>
            <Dropdown
              overlay={<NotificationMenu setMenuVisible={setMenuVisible} />}
              visible={menuVisible}
              trigger={["click"]}
              onClick={() => setMenuVisible(!menuVisible)}
              onVisibleChange={setMenuVisible}
            >
              <MoreOutlined rotate={90} />
            </Dropdown>
          </MoreIconContainer>
        </Header>
        <Content>
          {sortedNotifications.map((notification) => (
            <NotificationContent
              key={notification.id}
              notification={notification}
            />
          ))}
          {sortedNotifications.length === 0 && <EmptyMessage />}
        </Content>
        <Loading visible={loading} />
      </NotificationsContainer>
    </StickyContainer>
  );
}

const NotificationsContainer = styled.div`
  background-color: #fff;
  z-index: 100;
  border-radius: 8px;
  padding: 15px;
  overflow: scroll;
  cursor: pointer;
  @media (max-width: 425px) {
    right: 0px;
    width: 100%;
    top: 0px;
    height: 95vh;
  }
  @media (min-width: 426px) {
    right: 10px;
    width: 400px;
    margin-top: 10px;
    height: 90vh;
    box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.1);
  }
`;

const StickyContainer = styled.div`
  position: -webkit-sticky;
  position: sticky;
  float: right;
  top: 0;
  right: 10px;
  height: 0px;
  z-index: 100;
`;

const Header = styled.div`
  font-family: "Avenir Next";
  font-size: 20px;
  font-weight: 500;
  color: rgb(0, 0, 0);
  text-decoration: none;
`;

// TODO - SubHeader Style for Project Divisions in Notification Component
const SubHeader = styled.div`
  font-family: "Avenir Next";
  font-size: 18px;
  font-weight: 400;
  color: rgb(0, 0, 0);
  text-decoration: none;
`;

const Content = styled.div`
  overflow-wrap: anywhere;
`;

const MoreIconContainer = styled.div`
  float: right;
  cursor: pointer;
`;
