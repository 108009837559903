import { createReducer } from '@reduxjs/toolkit'

// Case reducers
function loadSectionsForProject (configsState, action) {
  if (configsState[action.projectId] == null) {
    configsState[action.projectId] = {}
  }

  if (configsState[action.projectId]['sections'] == null) {
    configsState[action.projectId]['sections'] = {}
  }

  configsState[action.projectId]['sections'] = {
    ...action.sections
  }
}

function loadObjectsForSection (configsState, action) {
  if (configsState[action.projectId] == null) {
    configsState[action.projectId] = {}
  }

  if (configsState[action.projectId]['objects'] == null) {
    configsState[action.projectId]['objects'] = {}
  }

  configsState[action.projectId]['objects'] = {
    ...action.objects
  }
}

// Slice reducer
export default createReducer(
  {},
  {
    LOAD_SECTIONS_FOR_PROJECT: loadSectionsForProject,
    LOAD_OBJECT_CONFIGS: loadObjectsForSection
  }
)
