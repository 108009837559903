import React, { useState } from "react";

import { useSelector } from "react-redux";

import { styles } from "../../styles/styles.js";
import Paper from "@material-ui/core/Paper";
import { Link } from "react-router-dom";
import settings_image from "../../images/settings_v2.svg";
import down_image from "../../images/down.svg";
import up_image from "../../images/up.svg";
import analytics_icon from "../../images/analytics_icon.svg";
import { Badge } from "antd";

import Toggle, { featureTypes, On, Off } from "../FeatureToggle";

import { has, getBadgeCountForRules, makeLinkForComponent } from "../utils";

export default function MenuMobile({ components, project, style }) {
  const [expanded, setExpanded] = useState(false);

  const active = useSelector((state) => state.page);

  const currentUser = useSelector((state) => state.currentUser);

  // TODO - There is still some optimization required for this component
  // so it won't flicker every time it is opened or closed

  if (project.addonsEnabled == null) {
    project["addonsEnabled"] = [];
  }

  const collections = useSelector((state) => state.collections);

  // TODO this does not do anything with latest updates
  let openDocsCount = 0;

  // Let's get the badge counts!
  const openCounts = {};

  Object.keys(components).forEach((collectionKey) => {
    const component = components[collectionKey];

    if (!component.badge) {
      return;
    }

    let objects = {};

    if (has(collections, project.id + "." + collectionKey)) {
      objects = collections[project.id][collectionKey];
      openCounts[collectionKey] = getBadgeCountForRules(
        components[collectionKey].badge.rules,
        objects
      );
    }
  });

  const pageData = {
    settings: {
      title: "Settings",
      image: settings_image,
      imageHeight: "10px",
    },
    analytics: {
      title: "Analytics",
      image: analytics_icon,
      feature: featureTypes.ANALYTICS,
      addon: true,
    },
    null: {
      disabled: true,
    },
  };

  Object.keys(components).forEach((collectionKey) => {
    var component = components[collectionKey];

    pageData[collectionKey] = {};

    pageData[collectionKey]["image"] = component.info.icon;

    if (openCounts[collectionKey] != null) {
      pageData[collectionKey]["title"] = (
        <>
          {component.info.name} <TitleBadge count={openCounts[collectionKey]} />
        </>
      );
    } else {
      pageData[collectionKey]["title"] = component.info.name;
    }

    if (component.info.default_view == "table") {
      pageData[collectionKey]["type"] = "table";
    } else {
      pageData[collectionKey]["type"] = "cardlist";
    }

    pageData[collectionKey]["addon"] = component.info.addon;
    pageData[collectionKey]["addon"] = component.info.addon;

    if (component.info.addon) {
      pageData[collectionKey]["disabled"] = !(
        project.addonsEnabled.includes(collectionKey) || false
      );
    }

    if (component.hideOnMenu) {
      pageData[collectionKey]["disabled"] = true;
    }
  });

  // Will replace this with a component order eventually
  var order = [
    "status_items",
    "standups",
    "clarifications",
    "feedbacks",
    "bugs",
    "credentials",
    "meeting_minutes",
    "timesheet_entries",
    "additional_requests",
    "environments",
    "documentations",
    "files",
    "overages",
    "analytics",
    "settings",
  ];

  const enabledPageDataList = Object.keys(pageData)
    .filter((key) => !pageData[key].disabled)
    .map((key) => ({ ...pageData[key], id: key }))
    .sort(function (a, b) {
      return order.indexOf(a.id) - order.indexOf(b.id);
    });

  return (
    <div style={{ ...styles.stickDivStatusMobile, ...style }}>
      <Paper
        style={styles.profileInfoCardStatusMobile}
        onClick={() => setExpanded(!expanded)}
      >
        {!expanded && (
          <PageRow
            data={pageData[active]}
            index={0}
            link={makeLinkForComponent(active, project.id)}
            expanded={false}
            active={false}
            lastItem={false}
          />
        )}

        {expanded && (
          <>
            <div>
              {enabledPageDataList.map((data, index) => {
                const pageActive = active === data.id;
                const lastItem = index === enabledPageDataList.length - 1;

                const rowItem = (
                  <PageRow
                    data={data}
                    index={index}
                    link={makeLinkForComponent(data.id, project.id, data.type)}
                    expanded={true}
                    active={pageActive}
                    lastItem={lastItem}
                  />
                );

                if (data.feature) {
                  return (
                    <Toggle feature={data.feature} user={currentUser}>
                      <On>{rowItem}</On>
                      <Off>
                        <div />
                      </Off>
                    </Toggle>
                  );
                }
                return rowItem;
              })}
            </div>
            <div style={{ clear: "both" }} />
          </>
        )}
      </Paper>
    </div>
  );
}

function PageRow({ data, index, link, expanded, active, lastItem }) {
  return (
    <div key={index}>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          float: "left",
          clear: "both",
          marginBottom: lastItem ? 0 : "16px",
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            marginLeft: "20px",
            marginRight: "10px",
          }}
        >
          <img
            style={{
              height: data?.imageHeight ?? "20px",
            }}
            src={data?.image}
          />
        </div>

        <div
          style={{
            display: "inline-block",
            verticalAlign: "middle",
            marginRight: "10px",
            textAlign: "right",
          }}
        >
          <Link
            to={link ?? "/"}
            style={{
              textDecoration: "none",
              color: active ? "#2F7EFF" : "#9C9C9C",
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
              }}
            >
              {data?.title}
            </div>
          </Link>
        </div>
      </div>

      {index === 0 && (
        <>
          <div style={{ display: "inline" }}>
            <div style={{ float: "right", marginRight: "20px" }}>
              <img
                style={{ height: "0.5em" }}
                src={expanded ? up_image : down_image}
              />
            </div>
          </div>
          <div style={{ clear: "right" }} />
        </>
      )}
    </div>
  );
}

function TitleBadge({ count }) {
  return (
    <Badge
      count={count}
      style={{
        backgroundColor: "#005fff",
        color: "#ffffff",
        marginLeft: "7px",
      }}
    />
  );
}
