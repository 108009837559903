import jsonLogic from "json-logic-js";
import firebase from "../firebase";
import _ from "lodash";

import { useEffect, useRef } from "react";

import { useMediaQuery } from "react-responsive";

import moment from "moment";

export const numberWithCommas = (x) => {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

export const numToMonth = (n) => {
  switch (n) {
    case 0:
      return "Jan";
    case 1:
      return "Feb";
    case 2:
      return "Mar";
    case 3:
      return "Apr";
    case 4:
      return "May";
    case 5:
      return "Jun";
    case 6:
      return "Jul";
    case 7:
      return "Aug";
    case 8:
      return "Sep";
    case 9:
      return "Oct";
    case 10:
      return "Nov";
    case 11:
      return "Dec";
    default:
      return "";
  }
};

export const sumCost = (delivs) => {
  var cost = 0;

  for (var i in delivs) {
    cost += delivs[i].cost;
  }

  return cost;
};

export const id = () => {
  return (
    Math.random().toString(36).substring(2, 15) +
    Math.random().toString(36).substring(2, 15)
  );
};

export const isEquivalent = (a, b) => {
  // Create arrays of property names
  var aProps = Object.getOwnPropertyNames(a);
  var bProps = Object.getOwnPropertyNames(b);

  for (var i = 0; i < aProps.length; i++) {
    var propName = aProps[i];

    // If values of same property are not equal,
    // objects are not equivalent
    if (a[propName] !== b[propName]) {
      // Check if its a date
      if (
        Object.prototype.toString.call(a[propName]) === "[object Date]" &&
        Object.prototype.toString.call(b[propName]) === "[object Date]"
      ) {
        if (a[propName].getTime() !== b[propName].getTime()) {
          return false;
        }
      } else {
        return false;
      }
    }
  }

  // If we made it this far, objects
  // are considered equivalent
  return true;
};

export const isMobile = () => {
  return (
    Math.max(document.documentElement.clientWidth, window.innerWidth || 0) < 750
  );
};

export const newTimestamp = () => {
  const now = new Date();
  return firebase.firestore.Timestamp.fromDate(now);
};

export const newTimestampFromDate = (date) => {
  return firebase.firestore.Timestamp.fromDate(date);
};

export const has = (obj, key) => {
  return key.split(".").reduce(function (o, x) {
    return typeof o == "undefined" || o === null ? o : o[x];
  }, obj);
};

export const requiredFieldsSatisfiedForObject = (fields, object) => {
  const result = {
    satisfied: true,
    missing: [],
  };

  console.log("checking required fields for object", object);

  // Iterate through the fields
  Object.values(fields).forEach((field) => {
    if (field.required && !object[field.valueKey]) {
      result.satisfied = false;
      result.missing.push(field);
    }

    if (
      field.required &&
      field.fieldData.type == "reference" &&
      object[field.valueKey]
    ) {
      // If the field is a reference field, also check for empty array
      if (Object.keys(object[field.valueKey]).length == 0) {
        result.satisfied = false;
        result.missing.push(field);
      }
    }

    if (
      field.required &&
      field.fieldData.type == "collaborator" &&
      object[field.valueKey]
    ) {
      // If the field is a reference field, also check for empty array
      if (object[field.valueKey].length == 0) {
        result.satisfied = false;
        result.missing.push(field);
      }
    }
  });

  return result;
};

export const requiredFieldsSatisfiedForAction = (action, object, fields) => {
  const result = {
    satisfied: true,
    missing: [],
  };

  // Iterate through the fields
  action.fields.forEach((updateData) => {
    var field = fields[updateData.field];

    if (updateData.required && !object[field.valueKey]) {
      result.satisfied = false;
      result.missing.push(field);
    }

    if (
      updateData.required &&
      field.fieldData.type == "reference" &&
      object[field.valueKey]
    ) {
      // If the field is a reference field, also check for empty array
      if (Object.keys(object[field.valueKey]).length == 0) {
        result.satisfied = false;
        result.missing.push(field);
      }
    }

    if (
      updateData.required &&
      field.fieldData.type == "collaborator" &&
      object[field.valueKey]
    ) {
      // If the field is a reference field, also check for empty array
      if (object[field.valueKey].length == 0) {
        result.satisfied = false;
        result.missing.push(field);
      }
    }
  });

  return result;
};

export const getBadgeCountForRules = (rules, objects) => {
  let count = 0;
  if (rules && objects) {
    Object.values(objects).forEach((object) => {
      if (jsonLogic.apply(rules, object)) {
        count++;
      }
    });
  }
  return count;
};

export const getWidthForFieldType = (type, is13Inch) => {
  var heights = {};

  if (is13Inch) {
    heights = {
      tag: { flexBasis: "160px", flexGrow: "1", flexShrink: "0" },
      select: { flexBasis: "160px", flexGrow: "1", flexShrink: "0" },
      date: { flexBasis: "150px", flexGrow: "1", flexShrink: "0" },
      date_with_age: { flexBasis: "250px", flexGrow: "1", flexShrink: "0" },
      text: { flexBasis: "200px", flexGrow: "1", flexShrink: "0" },
      link: { flexBasis: "100px", flexGrow: "1", flexShrink: "0" },
      checkbox: { flexBasis: "85px", flexGrow: "1", flexShrink: "0" },
      textArea: { flexBasis: "200px", flexGrow: "1", flexShrink: "0" },
      password: { flexBasis: "200px", flexGrow: "1", flexShrink: "0" },
      attachments: { flexBasis: "200px", flexGrow: "1", flexShrink: "0" },
      reference: {
        flexBasis: "200px",
        flexGrow: "1",
        flexShrink: "0",
        justifyContent: "left",
      },
      user: { flexBasis: "150px", flexGrow: "1", flexShrink: "0" },
      collaborator: {
        flexBasis: "180px",
        flexGrow: "1",
        flexShrink: "0",
        justifyContent: "left",
      },
      number: { flexBasis: "85px", flexGrow: "1", flexShrink: "0" },
    };
  } else {
    heights = {
      tag: { flexBasis: "180px", flexGrow: "1", flexShrink: "0" },
      select: { flexBasis: "180px", flexGrow: "1", flexShrink: "0" },
      date: { flexBasis: "150px", flexGrow: "1", flexShrink: "0" },
      date_with_age: { flexBasis: "250px", flexGrow: "1", flexShrink: "0" },
      text: { flexBasis: "250px", flexGrow: "1", flexShrink: "0" },
      link: { flexBasis: "100px", flexGrow: "1", flexShrink: "0" },
      checkbox: { flexBasis: "85px", flexGrow: "1", flexShrink: "0" },
      textArea: { flexBasis: "300px", flexGrow: "1", flexShrink: "0" },
      password: { flexBasis: "250px", flexGrow: "1", flexShrink: "0" },
      attachments: { flexBasis: "300px", flexGrow: "1", flexShrink: "0" },
      reference: {
        flexBasis: "300px",
        flexGrow: "1",
        flexShrink: "0",
        justifyContent: "left",
      },
      user: { flexBasis: "200px", flexGrow: "1", flexShrink: "0" },
      collaborator: {
        flexBasis: "200px",
        flexGrow: "1",
        flexShrink: "0",
        justifyContent: "left",
      },
      number: { flexBasis: "85px", flexGrow: "1", flexShrink: "0" },
    };
  }

  return heights[type];
};

export const getTotalWidthFromComponent = (component, is13Inch) => {
  var widths = {};

  if (is13Inch) {
    widths = {
      tag: 160,
      select: 160,
      date: 150,
      date_with_age: 250,
      text: 200,
      password: 200,
      textArea: 200,
      attachments: 200,
      reference: 200,
      link: 100,
      user: 150,
      checkbox: 85,
      collaborator: 180,
      number: 85,
    };
  } else {
    widths = {
      tag: 180,
      select: 180,
      date: 150,
      date_with_age: 250,
      text: 250,
      password: 250,
      textArea: 300,
      attachments: 300,
      reference: 300,
      link: 100,
      user: 200,
      checkbox: 85,
      collaborator: 200,
      number: 85,
    };
  }

  var totalWidth = 60;

  Object.keys(component.fields).forEach(function (key) {
    var field = component.fields[key];
    var type = field.fieldData.type;

    var width = widths[type];

    if (width) {
      totalWidth += width;
    }
  });

  return totalWidth;
};

export const makeLinkForComponent = (componentId, projectId, type) => {
  if (type != null) {
    return `/p/${projectId}/${type}/${componentId}`;
  } else {
    return `/p/${projectId}/${componentId}`;
  }
};

export const getInitialsFromUser = (user) => {
  var name = "";
  var initials = "";

  if (user != null && user.name != " ") {
    name = user.name;
    //   initials = name.match(/\b\w/g) || [];
    initials = name.charAt(0);
  }

  return initials;
};

export const scrollToRef = (ref) => {
  if (ref.current) {
    const width = Math.min(window.document.body.clientHeight / 2, 200);
    const scrollToY = ref.current.offsetTop - 30;

    window.scrollTo({
      top: scrollToY,
    });
  }
};

export const distinct = (value, index, self) => self.indexOf(value) === index;

export const copyStringToClipboard = (str) => {
  // Create new element
  var el = document.createElement("textarea");
  // Set value (string to be copied)
  el.value = str;
  // Set non-editable to avoid focus and move outside of view
  el.setAttribute("readonly", "");
  el.style = { position: "absolute", left: "-9999px" };
  document.body.appendChild(el);
  // Select text inside element
  el.select();
  // Copy text to clipboard
  document.execCommand("copy");
  // Remove temporary element
  document.body.removeChild(el);
};

export const isArrayEqual = (x, y) => {
  let equal = true;

  if (x.length != y.length) {
    equal = false;
  }

  let i = 0;

  x.forEach((x_object) => {
    if (_.isEqual(x_object, y[i])) {
      i = i + 1;
    } else {
      equal = false;
    }
  });

  return equal;
};

export const getProjectFilters = (project, collectionKey) =>
  project.view_configs?.default?.configs?.[collectionKey]?.table?.filters ?? {};

// How to replace all occurences of a string?
// https://stackoverflow.com/a/1144788
export const stringReplaceAll = (string, find, replace) => {
  const escapeRegExp = (string) => {
    return string.replace(/[.*+\-?^${}()|[\]\\]/g, "\\$&"); // $& means the whole matched string
  };
  return string?.replace(new RegExp(escapeRegExp(find), "g"), replace);
};

export const allowAction = (action, user, permissions) => {
  /*
    actions are either [view, edit, create, delete]
  */

  // If there are no permissions at all, just default to everything is fair game
  if (permissions[action] == null) {
    return true;
  }

  if (user.role == null) {
    // They are likely a viewer, so we should just check whatever the permission is for a viewer
    if (permissions[action].includes("viewer")) {
      return true;
    } else {
      return false;
    }
  } else {
    if (permissions[action].includes(user.role)) {
      return true;
    } else {
      return false;
    }
  }
};

export const validateEmail = (email) => {
  var re =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
};

export const deepEqualCheck = (left, right) => {
  return _.isEqual(left, right);
};

export const deepArrayEqualCheck = (left, right) => {
  return _.isArrayEqual(left, right);
};

function deepCompareEquals(a, b) {
  // TODO: implement deep comparison here
  // something like lodash
  return _.isEqual(a, b);
}

function useDeepCompareMemoize(value) {
  const ref = useRef();
  // it can be done by using useMemo as well
  // but useRef is rather cleaner and easier

  if (!deepCompareEquals(value, ref.current)) {
    ref.current = value;
  }

  return ref.current;
}

export function useDeepCompareEffect(callback, dependencies) {
  useEffect(callback, useDeepCompareMemoize(dependencies));
}

export const calculateSummaryForObjects = (type, key, objects) => {
  return Object.values(objects).reduce(
    (accum, object) => accum + parseFloat(object[key] || 0),
    0
  );
};

export const isItemInView = (element) => {
  if (!element) {
    return true;
  }

  const elementRect = element.getBoundingClientRect();
  const parentRect = element.parentElement.getBoundingClientRect();

  return (
    elementRect.bottom < parentRect.bottom && elementRect.top > parentRect.top
  );
};

export const useOutsideClick = (ref, callback, dependencies) => {
  const handleClick = (e) => {
    const className = `${e.target?.className}`;

    if (
      ref.current &&
      !ref.current.contains(e.target) &&
      !className.includes("ant-menu-item")
    ) {
      callback();
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClick);

    return () => {
      document.removeEventListener("click", handleClick);
    };
  }, dependencies);
};

export const getSubObjects = (component) => {
  // Iterate through fields and find any fields with object type
  var subObjectKeys = [];

  if (component.fields) {
    Object.values(component.fields).map(function (field) {
      if (field.fieldData.type == "object") {
        if (!subObjectKeys.includes(field.fieldData.collectionKey)) {
          subObjectKeys.push(field.fieldData.collectionKey);
        }
      }
    });
  }

  return subObjectKeys;
};

export const getReferencedObjects = (component) => {
  // Iterate through fields and find any fields with object type
  var referencedObjectKeys = [];

  if (component.fields) {
    Object.values(component.fields).map(function (field) {
      if (field.fieldData.type == "reference") {
        return field.fieldData?.itemTypes.map((itemType) => {
          if (!referencedObjectKeys.includes(itemType)) {
            referencedObjectKeys.push(itemType);
          }
        });
      }
    });
  }

  return referencedObjectKeys;
};

export const hasUnreadThreads = (currentUser, object) => {
  if (object.thread_timestamps && object.thread_timestamps.length > 0) {
    var timestamps = [...object.thread_timestamps];
    timestamps.sort((a, b) => {
      var timeA = a.timestamp.toDate ? a.timestamp.toDate() : a.timestamp;
      var timeB = b.timestamp.toDate ? b.timestamp.toDate() : b.timestamp;

      return timeA - timeB;
    });

    var lastestTimestamp = timestamps.slice(-1)[0].timestamp.toDate
      ? timestamps.slice(-1)[0].timestamp.toDate()
      : timestamps.slice(-1)[0].timestamp;

    if (
      object.thread_read_timestamps &&
      object.thread_read_timestamps[currentUser.id]
    ) {
      var lastReadTimestamp = object.thread_read_timestamps[currentUser.id]
        .toDate
        ? object.thread_read_timestamps[currentUser.id].toDate()
        : object.thread_read_timestamps[currentUser.id];
      if (lastReadTimestamp >= lastestTimestamp) {
        return false;
      }
    }

    return true;
  } else {
    if (
      object.thread_read_timestamps &&
      object.thread_read_timestamps[currentUser.id]
    ) {
      // We have a last read, but there are no objects (likely deleted or pre-read/unread)
      return false;
    }
    return true;
  }
};

export const getUserInitials = (user) => {
  const name = typeof user === "string" ? user : user?.name;
  const initials = name.match(/\b\w/g) || [];
  return ((initials.shift() || "") + (initials.pop() || "")).toUpperCase();
};

export const stringIsAtEnd = (search, fullString) => {
  let fullLength = fullString.length;


  if (
    fullString.charAt(fullString.length - 1) === "\n" &&
    search?.charAt(search.length - 1) !== "\n"
  ) {
    fullLength += -1;
  }

  return fullString.lastIndexOf(search) + search?.length === fullLength;
};

// TODO: Optimize this by starting at end since most likely it will be there
export const findNewCharacter = (oldString, newString) => {
  const oldStringNoNbsp = oldString.replace(/&nbsp;/g, " ");
  const newStringNoNbsp = newString.replace(/&nbsp;/g, " ");

  return [...newStringNoNbsp].find(
    (character, index) => character !== oldStringNoNbsp.charAt(index)
  );
};

export const findNewCharacterIndex = (oldString, newString) => {
  const newCharacterIndex = [...newString].findIndex(
    (character, index) => character !== oldString.charAt(index)
  );

  if (
    oldString.endsWith("&nbsp;") &&
    newString.charAt(newCharacterIndex) === " "
  ) {
    return newCharacterIndex + 1;
  }

  if (newCharacterIndex > -1) {
    return newCharacterIndex;
  } else {
    return newString.length - 1;
  }
};

export const getPrimaryValueFromObject = (object, component) => {
  var fieldId = component?.notifications?.slack?.primaryField;

  var field = component.fields[fieldId];
  if (field) {
    var fieldType = field.fieldData?.type;


    if (fieldType === "date") {

      // object[fieldId] is a firestore timestamp value, convert to date
      var date = object[fieldId].toDate();
      if(date) {
        return moment(date).format("MMM D, YYYY");
      } else {
        return "Invalid"
      }
      
    } else {
      return object[fieldId];
    }
  } else {
    return "Invalid";
  }
};
