import { createReducer } from "@reduxjs/toolkit";

// Case reducers
function loadRoles(rolesState, action) {
  if (rolesState[action.projectId] == null) {
    rolesState[action.projectId] = {};
  }

  rolesState[action.projectId] = {
    ...action.roles,
  };
}


// Slice reducer
export default createReducer(
  {},
  {
   LOAD_ROLES: loadRoles
  }
);
